import React, { useState, useEffect, useRef } from 'react';
import './QuickLinks.scss';
import { Modal, Form } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import helpers from '../helpers';


const AddQuickLink = (props: any) => {

    const { show, onHide, getAllQuickLinks, action, selectedQuickLink } = props;
    const [linkTitle, setLinkTitle] = useState(action == "Edit" ? selectedQuickLink.Title : "");
    const [linkUrl, setLinkUrl] = useState(action == "Edit" ? selectedQuickLink.LinkURL : "");
    const [linkError, setLinkError] = useState(false);
    const [linkUrlError, setlinkUrlError] = useState(false);
    const [linkTitleLength, setLinkTitleLength] = useState((action == "Edit" && selectedQuickLink) ? selectedQuickLink.Title.length : 0);
    const [showProcessing, setShowProcessing] = useState(action == "Delete" ? <span>Delete Quick Link</span> : <span>Save Quick Link</span>);


    return (
        <div>
            {
                action == "Delete" ?
                    <Modal id="add-quick-link" onHide={onHide}
                        backdrop="static"
                        show={show}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Delete Quick Link
                </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to remove the below quick link ?
                            <p className="delete-link-title">{selectedQuickLink.Title}</p>
                        </Modal.Body>
                        <Modal.Footer >
                            <Button className="button-cancel-menu" variant="secondary" onClick={() => { setLinkTitle(""); setLinkUrl(""); onHide(); }}>Cancel</Button>
                            <Button className="btn-danger" variant="primary" onClick={() => { deleteQuickLink(); }}>
                                {showProcessing}
                            </Button>
                        </Modal.Footer>
                    </Modal> :
                    <Modal id="add-quick-link" onHide={onHide}
                        backdrop="static"
                        show={show}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {action == "Edit" ? "Edit Quick Link" : "Add Quick Link"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className='add-form' noValidate>
                                <Form.Group className='add-field'>
                                    <Form.Label>Quick Link Title <span className="star">*</span></Form.Label>
                                    <Form.Control type='input' pattern="^[a-zA-Z1-9].*" maxLength={50} value={linkTitle} placeholder='Enter Link Title'
                                        onChange={(e: any) => {
                                            setLinkTitle(e.target.value);
                                            setLinkTitleLength(e.target.value.length);
                                        }}
                                        required></Form.Control>
                                    {linkError ? <Form.Text className = "linktitle-validate">
                                        <span className='error-new'>
                                            Please provide a valid Link Title !</span> 
                                    </Form.Text> : ''}
                                    <Form.Text className="text-muted">
                                    {linkTitleLength
                                        ? "" + (50 - linkTitleLength) + " characters remaining"
                                        : "50 characters max"}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className='add-field'>
                                    <Form.Label>Link <span className="star">*</span></Form.Label>
                                    <Form.Control type='input' pattern="^[a-zA-Z1-9].*" maxLength={1000} value={linkUrl} placeholder='Enter link to a page' onChange={(e: any) => {
                                        setLinkUrl(e.target.value);
                                    }} ></Form.Control>
                                    {linkUrlError ? <Form.Text className = "linktitle-validate"> 
                                        <span className='error-new'>
                                            Please provide a valid Link Url !</span> 
                                    </Form.Text> : ''}
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer >
                            <Button className="button-cancel-menu" variant="secondary" onClick={() => { setLinkTitle(""); setLinkUrl(""); onHide(); }}>Cancel</Button>
                            <Button className="proceed-button" variant="primary" onClick={() => { saveQuickLink(); }}>
                                {showProcessing}
                            </Button>
                        </Modal.Footer>
                    </Modal >
            }

        </div>
    )

    function deleteQuickLink() {
        setShowProcessing(<span>Deleting...</span>);
        helpers.deleteItemByID('Quick Links', selectedQuickLink.Id, function () {
            setShowProcessing(<span>Save Quick Link</span>);
            setLinkTitle("");
            setLinkUrl("");
            getAllQuickLinks();
            onHide();
        });

    }

    function saveQuickLink() {
        setLinkError(false);
        setlinkUrlError(false);
        if (linkTitle.trim() == '') {
            setLinkError(true);
        }
        else if (linkUrl.trim() == '') {
            setlinkUrlError(true);
        }
        else {
            setShowProcessing(<span>Saving...</span>);
            if (action == 'Add') {
                let toAdd = {
                    Title: linkTitle,
                    LinkURL: linkUrl,
                    isDefault: false,
                };
                helpers.addData("Quick Links", toAdd, function (iar: any) {
                    setShowProcessing(<span>Save Quick Link</span>);
                    setLinkTitle("");
                    setLinkUrl("");
                    getAllQuickLinks();
                    onHide();
                });
            }
            else if (action == 'Edit') {
                let toUpdate = {
                    Title: linkTitle,
                    LinkURL: linkUrl,                    
                    isDefault: false,
                };
                helpers.updateData("Quick Links", toUpdate, selectedQuickLink.Id, function (iar: any) {
                    setShowProcessing(<span>Save Quick Link</span>);
                    setLinkTitle("");
                    setLinkUrl("");
                    getAllQuickLinks();
                    onHide();
                });
            }
        }

    }

};



export default AddQuickLink;