import React, { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';
import helpers from '../helpers';
import AddLeader from "../addLeader/addLeader";
import EditLeader from "../editLeader/editLeader";
import DeleteLeader from "../deleteLeader/deleteLeader";
import AddSection from "../addLeader/addSection";
import { Button, Modal } from "react-bootstrap";
import ReactExport from "react-export-excel-fixed-xlsx";

const Leadership = (props: any) => {

    let sitecollectionurl = helpers.sitecollectionurl;
    const EF_URL = 'https://my.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c';
    const NO_LEADERS_MESSAGE = 'Currently there are no leaders available in this section. Please reach out to respective moderator(s) to add a Leader';
    const NO_SECTIONS_MESSAGE = 'Currently there are no sections/leaders available on this page. Please reach out to respective moderator(s) to add Section/Leaders';
    const { editMode, leaderCategories, allLeaders, getItems, addTo, loading, tabDisplayName, excelLeadershipOtherRolesData } = props;
    const [addLeader, setAddLeader] = useState<boolean>(false);
    const [editLeader, setEditLeader] = useState<boolean>(false);
    const [deleteLeader, setDeleteLeader] = useState<boolean>(false);
    const [addSection, setAddSection] = useState<boolean>(false);
    const [currentSection, setCurrentSection] = useState('');
    const [currentSectionLeaders, setCurrentSectionLeaders] = useState([]);
    const [leaderSections, setLeaderSections] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    let currentDateTime = new Date();

    return (
        <div id="leadership" >
            <div className="leadership-otherrolestab">
                {
                    excelLeadershipOtherRolesData.length > 0 ?
                        <div className="export-excel">
                            <ExcelFile element={<button className="btn btn-info">Export to Excel</button>} filename={tabDisplayName + "_" + currentDateTime.toUTCString()}>
                                <ExcelSheet data={excelLeadershipOtherRolesData} name={tabDisplayName}>
                                    <ExcelColumn label="Expert Name" value="ExpertName" />
                                    <ExcelColumn label="Leader Group" value="LeaderGroup" />
                                    <ExcelColumn label="Title" value="Title" />
                                    <ExcelColumn label="Description" value="Description" />
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                        : ""
                }
                {leaderCategories.length > 0
                    ? leaderCategories.map((item: any, index: any) => {
                        let leadersBySection = [];
                        if (addTo === "Leader") {
                            leadersBySection = allLeaders.filter(leader => (leader.SpoALeaderGroup && leader.SpoALeaderGroup.indexOf(item) > -1));
                        }
                        else if (addTo === "Other Role") {
                            leadersBySection = allLeaders.filter(leader => (leader.SpoAOtherDiscplineGroup && leader.SpoAOtherDiscplineGroup.indexOf(item) > -1));
                        }
                        return (

                            <div key={index} className="leadership-section">
                                <h6 className="category-heading">
                                    {item}
                                    {editMode ? (
                                        <DropdownButton
                                            alignRight
                                            title={<FiMoreVertical />}
                                            className="edit-dots active"
                                            id={`category-heading-` + index}
                                        >
                                            <Dropdown.Item eventKey="1" onClick={() => { handleAddLeader(item); }} ><MdAddCircleOutline className='addlinkicon' /> Add Leaders / Sections</Dropdown.Item>
                                            <Dropdown.Item eventKey="1" onClick={() => { setEditLeader(true); }} ><MdCreate className='editlinkicon' /> Edit Leaders / Sections</Dropdown.Item>
                                            <Dropdown.Item eventKey="1" onClick={() => { handleDeleteLeader(item); }} ><GiTrashCan className='deletelinkicon' /> Delete Leaders / Sections</Dropdown.Item>
                                        </DropdownButton>
                                    ) : null}
                                </h6>


                                <div className="leader-cards">

                                    {leadersBySection.length > 0
                                        ? leadersBySection.map((z: any, i: any) => {
                                            return (
                                                <div key={i} className="category-experts">
                                                    <div onClick={() => { window.open(EF_URL + `${z.SpoAUser.EMail}`, '_blank') }}>
                                                        <img src={`${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${z.SpoAUser.EMail}`} alt={z.SpoAUser.Title} />
                                                        <div className="title">{z.SpoAUser.Title} </div>
                                                        <div className="category"> {z.SpoALeaderType ? truncateString(z.SpoALeaderType, 40) : ''} </div>
                                                        <div className="description">{z.SpoADescription ? truncateString(z.SpoADescription, 90) : ''}</div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        :
                                        <div className="no-data-message">{NO_LEADERS_MESSAGE}</div>
                                    }
                                </div>
                            </div>

                        );
                    })
                    :
                    <div>
                        {
                            editMode ?
                                <Button className="add-link-btn" variant="link" onClick={() => setAddSection(true)}>
                                    <MdAddCircleOutline /> Add New Section </Button> : ''
                        }
                        <div className="no-data-message" >{NO_SECTIONS_MESSAGE}</div>
                    </div>
                }
                {addLeader ? <AddLeader show={addLeader} onHide={() => { setAddLeader(false) }} leaderSections={leaderSections} allLeaders={allLeaders} currentSection={currentSection} getItems={getItems} addTo={addTo} /> : ''}
                {editLeader ? <EditLeader show={editLeader} onHide={() => { setEditLeader(false) }} leaderCategories={leaderCategories} allLeadersProp={allLeaders} getItems={getItems} addTo={addTo} /> : ''}
                {deleteLeader ? <DeleteLeader show={deleteLeader} onHide={() => { setDeleteLeader(false) }} allLeaders={allLeaders} getItems={getItems} currentSection={currentSection} sectionLeaders={currentSectionLeaders} addTo={addTo} /> : ''}
                {addSection ? <AddSectionModal /> : ''}
            </div>
        </div>

    );

    function handleDeleteLeader(section: string) {
        setDeleteLeader(true);
        setCurrentSection(section);
        if (addTo === "Leader") {
            setCurrentSectionLeaders(allLeaders.filter(leader => (leader.SpoALeaderGroup && leader.SpoALeaderGroup.indexOf(section) > -1)));
        }
        else if (addTo === "Other Role") {
            setCurrentSectionLeaders(allLeaders.filter(leader => (leader.SpoAOtherDiscplineGroup && leader.SpoAOtherDiscplineGroup.indexOf(section) > -1)));
        }
    }

    function handleAddLeader(currSection: string) {
        setCurrentSection(currSection);
        let sections = [];
        leaderCategories.map((item: any) => {
            sections.push({
                Title: item,
                isChecked: item === currSection ? true : false,
            });
        })
        setLeaderSections(sections);
        setAddLeader(true);
    }

    function truncateString(str: string, num: number) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }

    function AddSectionModal() {
        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="edit-leader-section"
                show={addSection}
                onHide={() => setAddSection(false)}
                backdrop="static"
            >
                <AddSection onHide={() => setAddSection(false)} leaderSections={null} leaderGroups={null} getItems={getItems} addTo={addTo} />
            </Modal>
        );
    }

};

export default Leadership;
