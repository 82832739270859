import React, { useState, useEffect } from 'react';
import './YammerWidget1.scss';
import { MdCreate } from "react-icons/md";
import { Button, Modal, Form } from "react-bootstrap";
import $scriptjs from 'scriptjs';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiMoreVertical } from "react-icons/fi";


const YammerWidget1 = ({ editMode, isModerator, hide, AppInsights, userEmail }) => {
    const [feedID, setFeedID] = useState("");
    const [itemID, setItemID] = useState("");
    const [itemTitle, setItemTitle] = useState("");
    const [defaultItemTitle, setdefaultItemTitle] = useState("");
    const [titleLength, setTitleLength] = useState(0);
    const [viewMoreLink, setViewMoreLink] = useState("");
    const [loading, setLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [noTitle, setNoTitle] = useState(false);
    const [isHidden, setIsHidden] = useState(hide);
    const YAMMER_GROUP_URL = "https://www.yammer.com/shell.com/#/threads/inGroup?type=in_group&feedId=";

    useEffect(() => {
        getConfigurationItems();
    }, []);

    return (
        <div id="yammer-widget">
            <div className="widget-title">
                {itemTitle !== "" ? <h1>{itemTitle}</h1> : ""}
                <div id="moderator-tools">

                    {
                        !isHidden ? (editMode &&
                            <DropdownButton alignRight title={<FiMoreVertical />} id="dropdown-menu-align-right" >
                                <Dropdown.Item eventKey="1" onClick={() => setModalShow(true)} >Edit Query </Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={() => hideWebpart(true)} >Hide </Dropdown.Item>
                            </DropdownButton>) :
                            (isModerator && <span onClick={() => hideWebpart(false)} className="unhide">Unhide </span>)
                    }
                </div>
            </div>
            {
                !loading ?
                    <div className="widget-body1" id="embedded-feed1">
                    </div>
                    :
                    <div className="card-loading">
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={500} width={1060}></Skeleton>
                        </SkeletonTheme>
                    </div>
            }
            <div className="view-more" onClick={() => {
                window.open(viewMoreLink); sendToAppInsights('Yammer');
            }}>View More ...</div>

            <Modal id="custom-webpart-alert"
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        For changing the feed to a different yammer group, please contact the <a href="mailto:km-support@shell.com">KM Support Desk</a>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setModalShow(false) }}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/*<Modal id="edit-yammer-modal"*/}
            {/*    aria-labelledby="contained-modal-title-vcenter"*/}
            {/*    centered*/}
            {/*    show={modalShow}*/}
            {/*    onHide={() => setModalShow(false)}*/}
            {/*    backdrop="static"*/}
            {/*    keyboard={false}*/}
            {/*>*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>Edit Yammer Query</Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}
            {/*        <Form noValidate>*/}
            {/*            <Form.Group>*/}
            {/*                <Form.Label>Yammer Group Title <span className="star">*</span></Form.Label>*/}
            {/*                <Form.Control*/}
            {/*                    required*/}
            {/*                    type="input"*/}
            {/*                    value={itemTitle}*/}
            {/*                    placeholder="Enter title for this Yammer Group"*/}
            {/*                    pattern="^[a-zA-Z1-9].*"*/}
            {/*                    maxLength={100}*/}
            {/*                    onChange={(e) => { setItemTitle(e.currentTarget.value.trim()); setTitleLength(e.currentTarget.value.length) }}></Form.Control>*/}
            {/*                {noTitle ? <Form.Text className="invalid-input"> Please provide a Yammer Group title</Form.Text> : ''}*/}
            {/*                <Form.Text className="text-muted">*/}
            {/*                    {titleLength ? "" + (100 - titleLength) + " characters remaining" : "100 characters max"}*/}
            {/*                </Form.Text>*/}
            {/*            </Form.Group>*/}
            {/*            <Form.Group>*/}
            {/*                <Form.Label>Enter Yammer Group Id</Form.Label>*/}
            {/*                <Form.Control className='query-control'*/}
            {/*                    type="input"*/}
            {/*                    value={feedID}*/}
            {/*                    placeholder="Ex. 234567"*/}
            {/*                    pattern="[0-9]*"*/}
            {/*                    maxLength={255}*/}
            {/*                    onChange={(e) => {*/}
            {/*                        if (Number(e.target.value) || e.target.value == "") {*/}
            {/*                            setFeedID(e.currentTarget.value)*/}
            {/*                        }*/}
            {/*                        //return;*/}
            {/*                    }}*/}
            {/*                ></Form.Control>*/}
            {/*            </Form.Group>*/}
            {/*        </Form>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer>*/}
            {/*        <Button className="button-cancel-menu" variant="secondary" onClick={() => { setModalShow(false); setItemTitle(defaultItemTitle); setTitleLength(defaultItemTitle.length); setNoTitle(false) }} > Cancel </Button>*/}
            {/*        <Button variant="primary" className="proceed-button" onClick={() => { updateConfigurationItems() }} >Save Query</Button>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
        </div>
    )

    function updateConfigurationItems() {
        setNoTitle(false);
        if (itemTitle == "") {
            setNoTitle(true);
        }
        else if (itemTitle != "") {
            let ele = document.getElementById("embedded-feed1");
            if (ele) {
                ele.innerHTML = "";
            }
            setModalShow(false)
            setLoading(true);
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(itemID).update({
                Query: feedID,
                Title: itemTitle
            }).then((item) => {
                console.log('Updated');
                getConfigurationItems();
            });
        }
    }


    function getConfigurationItems() {
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Section eq 'YammerDetails1'").get().then((res) => {
            let viewmorelink = YAMMER_GROUP_URL + res[0].Query;
            setFeedID(res[0].Query);
            setItemID(res[0].Id);
            setItemTitle(res[0].Title);
            setTitleLength(res[0].Title.length);
            setdefaultItemTitle(res[0].Title);
            setViewMoreLink(viewmorelink);
            setTimeout(function () {
                $scriptjs('https://c64.assets-yammer.com/assets/platform_embed.js', function () {
                    window.yam.connect.embedFeed({
                        container: '#embedded-feed1',
                        network: 'shell.com',
                        feedType: 'group',
                        feedId: res[0].Query,
                        config: {
                            header: false
                        }
                    });
                });
                setLoading(false);
            }, 3000)
        }).catch(e => {
            console.error('Fetching query failed!')
        });
    }

    function hideWebpart(toHide) {
        let ele = document.getElementById("embedded-feed1");
        if (ele) {
            ele.innerHTML = "";
        }
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(itemID, 10)).update({ IsHidden: toHide }).then(i => {
            setIsHidden(toHide);
            getConfigurationItems();

        }).catch(e => {
            console.log("error updating Yammer Webpart!", e)
        });

    }

    function sendToAppInsights(webpartname) {
        AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Yammer Web Part',
            WebPartHeader: webpartname,
            UserEmail: userEmail
        });
        AppInsights.flush();
    }


};

export default YammerWidget1;