import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SearchResults.scss';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { FaSearch } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination'
import Skeleton from "react-loading-skeleton";
import { FaPaperclip } from 'react-icons/fa';
import helpers from '../helpers';
import SearchFilters from '../SearchFilters/SearchFilters'
import DiscussionWidget from './DiscussionWidget'
import SPoAWidget from './SPoAWidget'
import DocumentWidget from './DocumentWidget'
import PeopleWidget from './PeopleWidget'
import LearningWidget from './LearningWidget'
import ArticleWidget from './ArticleWidget'
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";
import * as $ from "jquery";
import Alert from 'react-bootstrap/Alert'
import { AppInsights } from "applicationinsights-js";
import moment from 'moment-timezone';
//import tempDiscussionsData from './DocumentResponse.json';
//import tempDocumentsData from './d.json'
//import onAdditionalFilters from './SearchHelper';

const SearchResults = (props: any) => {

    let isSiteName = props.isSiteName + " - SPoA";

    let filterTechFunctions = '';
    let filterDisciplines = '';
    let filterPTEs = '';
    let filterSMEs = '';

    let filterTechFunctionsId = '';
    let filterDisciplinesId = '';
    let filterPTEsId = '';
    let filterSMEsId = '';

    let searchQueryText = '';
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [cardClass, setCardClass] = useState('');
    const [defaultFunction, setDefaultFunction] = useState([]);
    const [defaultDiscipline, setDefaultDiscipline] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(1);
    const [fitleredData, setFilteredData] = useState([]);
    const [searchResponse, setSearchResponse] = useState([]);
    const [paginationSize, setPaginationSize] = useState(parseInt((searchResponse.length / 5).toFixed(), 10));
    const [paginationItems, setPaginationItems] = useState(Array(paginationSize).fill(paginationSize));
    const [headerLinks, setHeaderLinks] = useState([
        { name: 'All', active: true, filters: [] },
        //{ name: 'People', active: false, filters: [] },
        { name: 'Documents', active: false, filters: [] },
        {
            name: 'Articles', active: false, filters: [
                {
                    label: 'Principal Expert Approved',
                    type: 'checkbox',
                    callBack: helpers.searchCallBacks.ON_ARTICLE_EXPERT_APPROVED
                },
                {
                    label: 'Peer Reviewed',
                    type: 'checkbox',
                    callBack: helpers.searchCallBacks.ON_ARTICLE_PEER_REVIEWED
                },
                {
                    label: 'User Contributed',
                    type: 'checkbox',
                    callBack: helpers.searchCallBacks.ON_ARTICLE_USER_CONTRIBUTED
                },
            ]
        },
        {
            name: 'Discussions', active: false, filters: [{
                label: 'Forum Name',
                type: 'react-select',
                callBack: helpers.searchCallBacks.ON_FORUM_NAME
            },
            {
                label: 'Concluded Discussions',
                type: 'checkbox',
                callBack: helpers.searchCallBacks.ON_CONLCUDED_DISCUSSIONS
            }
            ]
        },
        {
            name: 'Learning', active: false, topFilters: [
                {
                    label: "Learning Type",
                    options: [
                        {
                            label: 'Practice Worth Replicating',
                            type: 'checkbox',
                            callBack: helpers.searchCallBacks.ON_PRACTICE_WORTH_REPLICATING
                        },
                        {
                            label: 'Lessons Learned',
                            type: 'checkbox',
                            callBack: helpers.searchCallBacks.ON_LESSONS_LEARNED
                        },
                        {
                            label: 'Learning From Incidents',
                            type: 'checkbox',
                            callBack: helpers.searchCallBacks.ON_LEARNING_FROM_INCIDENT
                        },
                    ]
                },
                {
                    label: "Learning Status",
                    options: [
                        {
                            label: 'Expert Approved',
                            type: 'checkbox',
                            callBack: helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED
                        },
                        {
                            label: 'Submitted',
                            type: 'checkbox',
                            callBack: helpers.searchCallBacks.ON_LEARNING_SUBMITTED
                        }
                    ]
                },
            ]
            //bottomFilters: [
            //    { label: 'Asset', type: 'react-select', bottomBorder: true, },
            //    { label: 'Business', type: 'react-select' },
            //    { label: 'Business Class', type: 'react-select' },
            //    { label: 'Division', type: 'react-select' },
            //    { label: 'Unit', type: 'react-select' },
            //    { label: 'Component', type: 'react-select', bottomBorder: true, },
            //    { label: 'Project', type: 'react-select', bottomBorder: true, },
            //]
        },
        {
            name: isSiteName, active: false, filters: []
        },
    ])
    const [selectedMenu, setSelectedMenu] = useState(0);
    const [spoaData, setSPoAData] = useState([]);
    const [discussionsData, setDiscussionData] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    //const [peopleData, setPeopleData] = useState([]);
    const [articleData, setArticleData] = useState([]);
    const [learningData, setLearningData] = useState([]);
    const [forumName, setForumName] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationFrom, setPaginationFrom] = useState(0);
    const [paginationTo, setPaginationTo] = useState(10);
    const [defaultFilterState, setDefaultFilterState] = useState(false);
    const [spoaDefaultFunction, setSPoADefaultFunction] = useState("");
    const [spoaDefaultDiscipline, setSPoADefaultDiscipline] = useState("");
    const [filterStates, setFilterState] = useState<any>({
        [helpers.searchCallBacks.ON_CONLCUDED_DISCUSSIONS]: false,
        [helpers.searchCallBacks.ON_FORUM_NAME]: false,
        [helpers.searchCallBacks.ON_PRACTICE_WORTH_REPLICATING]: false,
        [helpers.searchCallBacks.ON_LESSONS_LEARNED]: false,
        [helpers.searchCallBacks.ON_LEARNING_FROM_INCIDENT]: false,
        [helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED]: false,
        [helpers.searchCallBacks.ON_LEARNING_SUBMITTED]: false,
        [helpers.searchCallBacks.ON_ARTICLE_EXPERT_APPROVED]: false,
        [helpers.searchCallBacks.ON_ARTICLE_PEER_REVIEWED]: false,
        [helpers.searchCallBacks.ON_ARTICLE_USER_CONTRIBUTED]: false,
        [helpers.searchCallBacks.ON_SPOA_ANNOUNCEMENTS]: false,
        [helpers.searchCallBacks.ON_SPOA_LEADERSHIPTEAM]: false,
        [helpers.searchCallBacks.ON_SPOA_LINKS]: false,
        [helpers.searchCallBacks.ON_SPOA_EVENTS]: false,
    })

    useEffect(() => {

        if (selectedMenu === 2) {
            let filter1 = [];
            let filter2 = [];
            let filter3 = [];

            if (filterStates[helpers.searchCallBacks.ON_ARTICLE_EXPERT_APPROVED] || filterStates[helpers.searchCallBacks.ON_ARTICLE_PEER_REVIEWED] ||
                filterStates[helpers.searchCallBacks.ON_ARTICLE_USER_CONTRIBUTED]) {

                if (filterStates[helpers.searchCallBacks.ON_ARTICLE_EXPERT_APPROVED]) {
                    filter1 = [...articleData];
                    filter1 = filter1.filter(o => o.articleApproved === "Principal Expert Approved");
                }

                if (filterStates[helpers.searchCallBacks.ON_ARTICLE_PEER_REVIEWED]) {
                    filter2 = [...articleData];
                    filter2 = filter2.filter(o => o.articleApproved === "Peer Reviewed");
                }

                if (filterStates[helpers.searchCallBacks.ON_ARTICLE_USER_CONTRIBUTED]) {
                    filter3 = [...articleData];
                    filter3 = filter3.filter(o => o.articleApproved === "User Contributed");
                }
                const filteredData = [...filter1, ...filter2, ...filter3];
                setFilteredData(filteredData);
                setSearchResponse(filteredData);
            }
            else {
                setFilteredData(articleData);
                setSearchResponse(articleData);
            }
        }
        if (selectedMenu === 3) {
            let temp = [...discussionsData];
            let filteredData = [];

            if (filterStates[helpers.searchCallBacks.ON_FORUM_NAME] || filterStates[helpers.searchCallBacks.ON_CONLCUDED_DISCUSSIONS]) {

                if (filterStates[helpers.searchCallBacks.ON_CONLCUDED_DISCUSSIONS]) {
                    temp = temp.filter(o => o.dConclude === "1");
                }

                if (filterStates[helpers.searchCallBacks.ON_FORUM_NAME]) {
                    filterStates[helpers.searchCallBacks.ON_FORUM_NAME].map(o => {
                        temp.forEach(innerO => {
                            if (innerO.dForumName === o.value) {
                                filteredData.push(innerO)
                            }
                        })
                    });
                    setFilteredData(filteredData);
                    setSearchResponse(filteredData);
                } else {
                    setFilteredData(temp);
                    setSearchResponse(temp);
                }
            }
            else {
                setFilteredData(discussionsData);
                setSearchResponse(discussionsData);
            }



        }

        if (selectedMenu === 4) {
            let filter1 = [];
            let filter2 = [];
            let filter3 = [];
            let filter4 = [];
            let filter5 = [];

            if (filterStates[helpers.searchCallBacks.ON_PRACTICE_WORTH_REPLICATING] || filterStates[helpers.searchCallBacks.ON_LESSONS_LEARNED] ||
                filterStates[helpers.searchCallBacks.ON_LEARNING_FROM_INCIDENT] || filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED] ||
                filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {

                if (filterStates[helpers.searchCallBacks.ON_PRACTICE_WORTH_REPLICATING]) {
                    filter1 = [...learningData];
                    filter1 = filter1.filter(o => o.learnType === "PWR");
                    if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED] && filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                    }
                    else if (filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                        filter1 = filter1.filter(o => o.LearnStatus === "Submitted");
                    }
                    else if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED]) {
                        filter1 = filter1.filter(o => o.LearnStatus === "Expert Approved");
                    }
                }

                if (filterStates[helpers.searchCallBacks.ON_LESSONS_LEARNED]) {
                    filter2 = [...learningData];
                    filter2 = filter2.filter(o => o.learnType === "LL");
                    if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED] && filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                    }
                    else if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED]) {
                        filter2 = filter2.filter(o => o.LearnStatus === "Expert Approved");
                    }
                    else if (filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                        filter2 = filter2.filter(o => o.LearnStatus === "Submitted");
                    }
                }

                if (filterStates[helpers.searchCallBacks.ON_LEARNING_FROM_INCIDENT]) {
                    filter3 = [...learningData];
                    filter3 = filter3.filter(o => o.learnType === "LFI");
                    if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED] && filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                    }
                    else if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED]) {
                        filter3 = filter3.filter(o => o.LearnStatus === "Expert Approved");
                    }
                    else if (filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                        filter3 = filter3.filter(o => o.LearnStatus === "Submitted");
                    }
                }

                if (filterStates[helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED]) {
                    if (filter1.length > 0 || filter2.length > 0 || filter3.length > 0) {
                    }
                    else {
                        filter4 = [...learningData];
                        filter4 = filter4.filter(o => o.LearnStatus === "Expert Approved");
                    }
                }

                if (filterStates[helpers.searchCallBacks.ON_LEARNING_SUBMITTED]) {
                    if (filter1.length > 0 || filter2.length > 0 || filter3.length > 0) {
                    }
                    else {
                        filter5 = [...learningData];;
                        filter5 = filter5.filter(o => o.LearnStatus === "Submitted");
                    }
                }

                const filteredData = [...filter1, ...filter2, ...filter3, ...filter4, ...filter5];

                const uniqueItems = new Set(filteredData);

                setFilteredData(Array.from(uniqueItems));
                setSearchResponse(Array.from(uniqueItems));
            }
            else {
                setFilteredData(learningData);
                setSearchResponse(learningData);
            }
        }

        if (selectedMenu === 5) {
            let temp = [...spoaData];
            let filteredData = [];
            if (filterStates[helpers.searchCallBacks.ON_SPOA_ANNOUNCEMENTS] || filterStates[helpers.searchCallBacks.ON_SPOA_LEADERSHIPTEAM] || filterStates[helpers.searchCallBacks.ON_SPOA_LINKS] || filterStates[helpers.searchCallBacks.ON_SPOA_EVENTS]) {
                if (filterStates[helpers.searchCallBacks.ON_SPOA_ANNOUNCEMENTS]) {
                    for (let k = 0; k < spoaData.length; k++) {
                        if (spoaData[k].oPath.toLowerCase().indexOf('spoaannouncements') !== -1) {
                            filteredData.push(spoaData[k]);
                        }
                    }
                }
                if (filterStates[helpers.searchCallBacks.ON_SPOA_LEADERSHIPTEAM]) {
                    for (let l = 0; l < spoaData.length; l++) {
                        if (spoaData[l].oPath.toLowerCase().indexOf('spoaexperts') !== -1) {
                            filteredData.push(spoaData[l]);
                        }
                    }
                }
                if (filterStates[helpers.searchCallBacks.ON_SPOA_LINKS]) {
                    for (let m = 0; m < spoaData.length; m++) {
                        if (spoaData[m].oPath.toLowerCase().indexOf('spoalinks') !== -1) {
                            filteredData.push(spoaData[m]);
                        }
                    }
                }
                if (filterStates[helpers.searchCallBacks.ON_SPOA_EVENTS]) {
                    for (let n = 0; n < spoaData.length; n++) {
                        if (spoaData[n].oPath.toLowerCase().indexOf('spoacalendar') !== -1) {
                            filteredData.push(spoaData[n]);
                        }
                    }
                }
                setFilteredData(filteredData);
                setSearchResponse(filteredData);
            }
            else {
                setFilteredData(spoaData);
                setSearchResponse(spoaData);
            }
        }

    }, [filterStates])

    useEffect(() => {
        AppInsights.downloadAndSetup({ instrumentationKey: helpers.appinsightsinstrumentationKey, maxBatchSizeInBytes: 0 });
        helpers.getUser((e: any) => {
            AppInsights.trackPageView(
                document.title,
                window.location.href,
                { userEmail: e.Email },
                null,
            );
        });
        const ele = document.querySelector('#header .mr-sm-2.form-control') as HTMLInputElement;
        let searchKeyword = encodeURIComponent(ele.value);
        setSearchQuery(searchKeyword);
        document.querySelector('#header').classList.add('search');
        document.querySelector('.search-spoa-box').classList.add('hide');
        if (ele.value != "") {
            let TDisciplineId = '';
            let TfunctionsId = '';
            let TfunctionsLabel = '';
            let TDisciplineLabel = '';
            const caml: any = {
                ViewXml: "<View><Query></Query></View>",
            };
            window['SP_Propertyweb'].lists.getByTitle("ExpertsConfiguration").getItemsByCAMLQuery(caml).then((response: any) => {
                const expertsconfigurationresponse = response
                if (response.length > 0) {
                    setDefaultFilterState(true);
                    let TechFunc = []
                    let displne = []
                    for (var j = 0; j < expertsconfigurationresponse.length; j++) {
                        let defaultExpertiseArea = expertsconfigurationresponse[j].ExpertiseArea.Label;
                        TDisciplineId = expertsconfigurationresponse[j].ExpertiseArea.TermGuid + ";";
                        //TfunctionsId = expertsconfigurationresponse[j].Title + ";";
                        defaultExpertiseArea = defaultExpertiseArea.split(":");
                        for (var i = 0; i < defaultExpertiseArea.length; i++) {
                            if (i === 0) {
                                TfunctionsLabel = defaultExpertiseArea[i] + ";";
                                setSPoADefaultFunction(defaultExpertiseArea[i]);
                                TfunctionsId = defaultExpertiseArea[i];
                                TechFunc.push({
                                    key: defaultExpertiseArea[i],
                                    value: defaultExpertiseArea[i],
                                    label: defaultExpertiseArea[i],
                                })
                            } else if (i === 1) {
                                TDisciplineLabel = defaultExpertiseArea[i] + ";";
                                setSPoADefaultDiscipline(defaultExpertiseArea[i]);
                                displne.push({
                                    key: defaultExpertiseArea[i],
                                    value: defaultExpertiseArea[i],
                                    label: defaultExpertiseArea[i],
                                })
                            }
                        }
                    }
                    setDefaultFunction(TechFunc)
                    setDefaultDiscipline(displne)
                    getEnterpriseSignsearch(ele.value, TfunctionsLabel, TfunctionsId, TDisciplineLabel, TDisciplineId);
                } else {
                    getEnterpriseSignsearch();
                }
            });
        }

        // Use 100% screen width for search results screen
        document.querySelector(".body-content").classList.add('container-fluid');
        document.querySelector(".body-content").classList.remove('container');

        //Localhost test
        //debugger;
        //setLoading(false);
        //let discData = tempDiscussionsData;
        //let docData = tempDocumentsData;
        //setDiscussionData(discData);
        //setDocumentsData(docData);

        //var allData:any = [...discData, ...tempDocumentsData];

        //let sortedData = allData.sort(function(x:any, y:any) { 
        //    const a:any = new Date(x.Cells.results[12].Value);
        //    const b:any = new Date(y.Cells.results[12].Value);
        //    return  a - b;
        //});

        //sortedData.reverse();
        //setSearchResponse(sortedData);
        //setFilteredData(sortedData.slice(0, 10))
        //setFilteredData([]);

        // Reset 100% screen width for search results screen
        return () => {
            document.querySelector('#header').classList.remove('search');
            document.querySelector('.search-spoa-box').classList.remove('hide');
            document.querySelector(".body-content").classList.add('container-fluid');
            document.querySelector(".body-content").classList.remove('container-fluid');
            const ele = document.querySelector('#header .mr-sm-2.form-control') as HTMLInputElement;
            ele.value = "";
        }
    }, []);

    useEffect(() => {
        const paginationSize = parseInt((searchResponse.length / 10).toFixed(), 10);
        setPaginationSize(paginationSize);
        setPaginationItems(Array(paginationSize).fill(1));
        const to = 10
        const frm = 0;
        const temp = searchResponse.slice(frm, to);
        setFilteredData(temp);
        window.scrollTo(0, 0);
    }, [searchResponse]);
    useEffect(() => {
        if (selectedMenu === 0) {
            let allData: any = [...discussionsData, ...learningData, ...articleData, ...documentsData];
            //let allData: any = [...documentsData];
            let sortedAllData = allData.sort(function (x: any, y: any) {
                const a: any = new Date(x.Created);
                const b: any = new Date(y.Created);
                return a - b;
            });
            sortedAllData.reverse();
            setSearchResponse(sortedAllData);
        }
    }, [discussionsData]);
    useEffect(() => {
        if (selectedMenu === 0) {
            let allData: any = [...discussionsData, ...learningData, ...articleData, ...documentsData];
            //let allData: any = [...documentsData];
            let sortedAllData = allData.sort(function (x: any, y: any) {
                const a: any = new Date(x.Created);
                const b: any = new Date(y.Created);
                return a - b;
            });
            sortedAllData.reverse();
            setSearchResponse(sortedAllData);
        }
    }, [documentsData]);
    useEffect(() => {
        if (selectedMenu === 0) {
            let allData: any = [...discussionsData, ...learningData, ...articleData, ...documentsData];
            //let allData: any = [...documentsData];
            let sortedAllData = allData.sort(function (x: any, y: any) {
                const a: any = new Date(x.Created);
                const b: any = new Date(y.Created);
                return a - b;
            });
            sortedAllData.reverse();
            setSearchResponse(sortedAllData);
        }
    }, [articleData]);
    useEffect(() => {
        if (selectedMenu === 0) {
            let allData: any = [...discussionsData, ...learningData, ...articleData, ...documentsData];
            //let allData: any = [...documentsData];
            let sortedAllData = allData.sort(function (x: any, y: any) {
                const a: any = new Date(x.Created);
                const b: any = new Date(y.Created);
                return a - b;
            });
            sortedAllData.reverse();
            setSearchResponse(sortedAllData);
        }
    }, [learningData]);

    useEffect(() => {
        if (selectedMenu === 5) {
            setSearchResponse(spoaData);
        }
    }, [spoaData]);

    useEffect(() => {

        setActiveIndex(1);
        setPaginationFrom(0);
        setPaginationTo(10);
        if (selectedMenu === 0) {
            let allData: any = [...discussionsData, ...learningData, ...articleData, ...documentsData];
            //let allData: any = [...documentsData];
            let sortedData = allData.sort(function (x: any, y: any) {
                const a: any = new Date(x.Created);
                const b: any = new Date(y.Created);
                return a - b;
            });
            sortedData.reverse();
            setSearchResponse(sortedData);
        }
        else if (selectedMenu === 1) {
            setSearchResponse(documentsData);
        } else if (selectedMenu === 2) {
            setSearchResponse(articleData);
        } else if (selectedMenu === 3) {
            setSearchResponse(discussionsData);
        } else if (selectedMenu === 4) {
            setSearchResponse(learningData);
        }
        else if (selectedMenu === 5) {
            setSearchResponse(spoaData);
        }

        setFilterState({
            [helpers.searchCallBacks.ON_CONLCUDED_DISCUSSIONS]: false,
            [helpers.searchCallBacks.ON_FORUM_NAME]: false,
            [helpers.searchCallBacks.ON_PRACTICE_WORTH_REPLICATING]: false,
            [helpers.searchCallBacks.ON_LESSONS_LEARNED]: false,
            [helpers.searchCallBacks.ON_LEARNING_FROM_INCIDENT]: false,
            [helpers.searchCallBacks.ON_LEARNING_EXPERT_APPROVED]: false,
            [helpers.searchCallBacks.ON_LEARNING_SUBMITTED]: false,
            [helpers.searchCallBacks.ON_ARTICLE_EXPERT_APPROVED]: false,
            [helpers.searchCallBacks.ON_ARTICLE_PEER_REVIEWED]: false,
            [helpers.searchCallBacks.ON_ARTICLE_USER_CONTRIBUTED]: false,
            [helpers.searchCallBacks.ON_SPOA_ANNOUNCEMENTS]: false,
            [helpers.searchCallBacks.ON_SPOA_LEADERSHIPTEAM]: false,
            [helpers.searchCallBacks.ON_SPOA_LINKS]: false,
            [helpers.searchCallBacks.ON_SPOA_EVENTS]: false,
        })

    }, [selectedMenu])

    return (
        <div id="search-results">

            <Container fluid={true} >
                <Col md={{ span: 9, offset: 2 }} className="mb-sm-4">
                    <Row>
                        <Form inline onSubmit={(e) => onFormSubmit(e)}>
                            <FormControl type="text" placeholder="Search" className="mr-sm-2" value={decodeURIComponent(searchQuery)} onChange={(e: any) => setSearchQuery(encodeURIComponent(e.currentTarget.value))} />
                            <Button variant="secondary" onClick={() => getResults()}> <FaSearch className="search-icon" /> </Button>
                        </Form>
                        {
                            !isLoading &&
                            <span className="results-count">{searchResponse.length} Search Results</span>
                        }
                        {
                            !isLoading && defaultFilterState === true ?
                                <div className="Alert-info">
                                    <Alert variant={'info'}>
                                        Default Filter has been applied for the Search results:
                                        Function : {spoaDefaultFunction} and Discipline : {spoaDefaultDiscipline}
                                    </Alert>
                                </div> : ""
                        }
                        {
                            !isLoading &&
                            <div className="enterprise-search">
                                <span className="enterprise-search-text">Not able to find desired results?</span>
                                <Button onClick={() => handleClick()} className="enterprise-search-button">Try Enterprise Search</Button>
                            </div>
                        }
                    </Row>
                </Col>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div className="event-types">
                            {
                                headerLinks.map((o, i) => {
                                    return (
                                        <span key={i} className={i === selectedMenu ? 'nav-link active' : 'nav-link'} onClick={() => setSelectedMenu(i)}>{o.name}</span>
                                    )
                                })
                            }

                        </div>
                    </Col>
                </Row>


                <Row>
                    {selectedMenu !== 5 ? <SearchFilters selectedMenu={selectedMenu} headerLinks={headerLinks} getEnterpriseSignsearch={getEnterpriseSignsearch} onAdditionalFilters={onAdditionalFilters} isLoading={isLoading} forumName={forumName} showRefiners={false} defaultFunction={defaultFunction} defaultDiscipline={defaultDiscipline} setDefaultFilterState={setDefaultFilterState} setLoading={setLoading} /> :
                        <Col md={2} className="text-left-search-filters">
                            <div className="txt-lft-fltrs">
                                <Form.Check type="checkbox" label="Announcements" onChange={(e) => onAdditionalFilters(helpers.searchCallBacks.ON_SPOA_ANNOUNCEMENTS, e)} />
                                <Form.Check type="checkbox" label="Leadership & Team" onChange={(e) => onAdditionalFilters(helpers.searchCallBacks.ON_SPOA_LEADERSHIPTEAM, e)} />
                                <Form.Check type="checkbox" label="Links" onChange={(e) => onAdditionalFilters(helpers.searchCallBacks.ON_SPOA_LINKS, e)} />
                                <Form.Check type="checkbox" label="Events" onChange={(e) => onAdditionalFilters(helpers.searchCallBacks.ON_SPOA_EVENTS, e)} />
                            </div>
                        </Col>
                    }
                    {
                        isLoading ?
                            <Col md={10} className="text-left loading-skeleton">
                                <Skeleton height={100} />
                                <Skeleton height={100} />
                                <Skeleton height={100} />
                                <Skeleton height={100} />
                            </Col>
                            :
                            <Col md={9} className="text-left">
                                <SearchFilters selectedMenu={selectedMenu} headerLinks={headerLinks} getEnterpriseSignsearch={getEnterpriseSignsearch} onAdditionalFilters={onAdditionalFilters} isLoading={isLoading} forumName={forumName} showRefiners={true} defaultFunction={defaultFunction} defaultDiscipline={defaultDiscipline} setDefaultFilterState={setDefaultFilterState} setLoading={setLoading} />
                                <div className="serch-results-container">
                                    {

                                        <>
                                            {
                                                fitleredData.length === 0 ?
                                                    <div className="no-results">No search results available.</div>
                                                    :
                                                    fitleredData.map((o, i) => {

                                                        return (

                                                            (o && o !== undefined && o.dPath && o.dPath !== undefined &&
                                                                o.dPath.indexOf("Community Discussion")) ?
                                                                <DiscussionWidget o={o} />
                                                                :
                                                                (o && o !== undefined && o.filePath && o.filePath !== undefined &&
                                                                    o.filePath.indexOf("Knowledge Items")) ?
                                                                    <DocumentWidget o={o} />
                                                                    :
                                                                    (o && o !== undefined && o.articleType && o.articleType !== undefined &&
                                                                        o.articleType === "Article") ?
                                                                        <ArticleWidget o={o} />
                                                                        :
                                                                        (o && o !== undefined && o.learnId && o.learnId !== undefined) ?
                                                                            <LearningWidget o={o} />
                                                                            :
                                                                            (o && o !== undefined) &&
                                                                            <SPoAWidget o={o} />

                                                        )

                                                    })
                                            }
                                        </>

                                    }


                                </div>

                                {
                                    isLoading ? <Skeleton width={500} /> :

                                        fitleredData.length > 0 &&

                                        <Pagination>

                                            {
                                                paginationFrom > 0 &&
                                                <>
                                                    <Pagination.First onClick={showPrevPaginations} />
                                                    <Pagination.Ellipsis onClick={showPrevPaginations} />
                                                </>
                                            }

                                            {
                                                paginationItems.map((o: any, i: any) => (
                                                    (i >= paginationFrom && i < paginationTo) &&
                                                    <Pagination.Item key={i} active={(i + 1) === activeIndex} onClick={(e: any) => {
                                                        setActiveIndex(parseInt(e.target.innerText, 10));
                                                        const to = e.target.innerText === "1" ? 10 : parseInt(e.target.innerText, 10) * 10;
                                                        const frm = to === 1 ? 0 : to - 10;
                                                        const temp = searchResponse.slice(frm, to);
                                                        setFilteredData(temp);
                                                        window.scrollTo(0, 0);
                                                    }}>
                                                        {i + 1}
                                                    </Pagination.Item>
                                                ))
                                            }

                                            {
                                                paginationTo < paginationItems.length &&
                                                <>
                                                    <Pagination.Ellipsis onClick={showNextPaginations} />
                                                    <Pagination.Last onClick={showNextPaginations} />
                                                </>
                                            }


                                        </Pagination>
                                }

                            </Col>

                    }
                </Row>


            </Container>
        </div>
    )

    function handleClick() {
        window.open("https://eu001-sp.shell.com/search/Pages/results.aspx?k=" + decodeURIComponent(searchQuery));
    }

    function onAdditionalFilters(type, e) {

        let t = JSON.parse(JSON.stringify(filterStates));
        if (e === null) {
            t[type] = false;
        }
        else if (e.currentTarget === undefined) {
            t[type] = e;
        }
        else {
            t[type] = e.currentTarget.checked;
        }
        setFilterState(t);
    }

    function onConcludedDiscussions(isChecked) {
        if (isChecked === true) {
            isChecked = "1";
            let tempFilteredData = [];
            let temp = [...discussionsData];
            tempFilteredData = temp.filter(o => o.dConclude === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(discussionsData);
            setSearchResponse(discussionsData);
        }

    }

    function onForumName(isChecked) {
        console.log(isChecked)
        if (isChecked !== null && isChecked !== "null") {
            let temp = [];
            isChecked.map(o => {
                discussionsData.map(innerO => {
                    if (innerO.dForumName === o.value) {
                        temp.push(innerO)
                    }
                })
            });
            setFilteredData(temp)
            setSearchResponse(temp)
        }
        else {
            setFilteredData(discussionsData)
            setSearchResponse(discussionsData)
        }
    }
    function onPracticeWorthReplicating(isChecked) {
        if (isChecked === true) {
            isChecked = "PWR";
            let tempFilteredData = [];
            let temp = [...learningData];
            tempFilteredData = temp.filter(o => o.learnType === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(learningData);
            setSearchResponse(learningData);
        }
    }

    function onLessonsLearned(isChecked) {
        if (isChecked === true) {
            isChecked = "LL";
            let tempFilteredData = [];
            let temp = [...learningData];
            tempFilteredData = temp.filter(o => o.learnType === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(learningData);
            setSearchResponse(learningData);
        }
    }
    function onLearningFromIncident(isChecked) {
        if (isChecked === true) {
            isChecked = "LFI";
            let tempFilteredData = [];
            let temp = [...learningData];
            tempFilteredData = temp.filter(o => o.learnType === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(learningData);
            setSearchResponse(learningData);
        }
    }
    function onLearningExpertApproved(isChecked) {
        if (isChecked === true) {
            isChecked = "Expert Approved";
            let tempFilteredData = [];
            let temp = [...learningData];
            tempFilteredData = temp.filter(o => o.LearnStatus === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(learningData);
            setSearchResponse(learningData);
        }
    }
    function onLearningSubmitted(isChecked) {
        if (isChecked === true) {
            isChecked = "Submitted";
            let tempFilteredData = [];
            let temp = [...learningData];
            tempFilteredData = temp.filter(o => o.LearnStatus === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(learningData);
            setSearchResponse(learningData);
        }
    }
    function onArticleExpertApproved(isChecked) {
        if (isChecked === true) {
            isChecked = "Principal Expert Approved";
            let tempFilteredData = [];
            let temp = [...articleData];
            tempFilteredData = temp.filter(o => o.articleApproved === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(articleData);
            setSearchResponse(articleData);
        }
    }
    function onArticlePeerReviewed(isChecked) {
        if (isChecked === true) {
            isChecked = "Peer Reviewed";
            let tempFilteredData = [];
            let temp = [...articleData];
            tempFilteredData = temp.filter(o => o.articleApproved === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(articleData);
            setSearchResponse(articleData);
        }
    }
    function onArticleUserContributed(isChecked) {
        if (isChecked === true) {
            isChecked = "User Contributed";
            let tempFilteredData = [];
            let temp = [...articleData];
            tempFilteredData = temp.filter(o => o.articleApproved === isChecked)
            setFilteredData(tempFilteredData)
            setSearchResponse(tempFilteredData)
        }
        else {
            setFilteredData(articleData);
            setSearchResponse(articleData);
        }
    }

    function showNextPaginations() {
        let from = paginationFrom;
        let to = paginationTo;
        setPaginationFrom(from + 10);
        setPaginationTo(to + 10);
    }

    function showPrevPaginations() {
        let from = paginationFrom;
        let to = paginationTo;
        setPaginationFrom(from - 10);
        setPaginationTo(to - 10);
    }

    function getEnterpriseDocumentSearchResults() {

        let refinablestring00 = ''
        let refinablestring01 = ''
        let refinablestring190 = ''

        let tfCount = 0;
        let disciplineCount = 0;
        let pteCount = 0;


        if (filterTechFunctionsId !== undefined && filterTechFunctionsId !== null) {
            let splitTechFunctions = filterTechFunctionsId.split(';');
            for (let i = 0; i < splitTechFunctions.length; i++) {
                if (splitTechFunctions[i] !== "" && splitTechFunctions[i] !== null) {
                    tfCount++;
                    refinablestring00 = refinablestring00 + 'refinablestring00:"' + splitTechFunctions[i] + '",'
                }
            }
        }
        if (filterDisciplinesId !== undefined && filterDisciplinesId !== null) {
            let splitDisciplines = filterDisciplinesId.split(';');
            for (let j = 0; j < splitDisciplines.length; j++) {
                if (splitDisciplines[j] !== "" && splitDisciplines[j] !== null) {
                    disciplineCount++;
                    refinablestring01 = refinablestring01 + 'refinablestring01:"' + splitDisciplines[j] + '",';
                }
            }
        }
        if (filterPTEsId !== undefined && filterPTEsId !== null) {
            let splitPTEs = filterPTEsId.split(';');
            for (let k = 0; k < splitPTEs.length; k++) {
                if (splitPTEs[k] !== "" && splitPTEs[k] !== null) {
                    pteCount++;
                    refinablestring190 = refinablestring190 + 'refinablestring190:"' + splitPTEs[k] + '",';
                }
            }
        }

        let wrapper;
        if (tfCount === 1 && disciplineCount === 1 && pteCount === 1) {
            wrapper = "'AND (";
        }
        else if (tfCount > 1 || disciplineCount > 1 || pteCount > 1) {
            wrapper = "'OR (";
        }
        else {
            wrapper = "'AND (";
        }

        let finalQueryString;


        if (tfCount === 1 && refinablestring01 === '' && refinablestring190 === '') {
            finalQueryString = ("'" + refinablestring00.replace(/,\s*$/, "") + "'");
        }
        else {
            finalQueryString = (wrapper + refinablestring00 + refinablestring01 + refinablestring190).replace(/,\s*$/, "") + ")'";
        }

        let searchUrl = '';
        let executor;
        if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAB2112%2fKnowledge+Items\"+AND+refinablestring136:\"Approved\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAB2112%2fLists%2fKnowledge+Links\")+ " + searchQueryText + " '&rowlimit=100&selectproperties='Title%2cFileName%2cPath%2cowstaxIdKRExpertiseAreaDiscipline%2cfileextension%2cowstaxIdKRExpertiseAreaDiscipline%2cowstaxIdKRAdditionalExpertiseAreas%2cowstaxIdKRExpertiseAreaFunction%2cowstaxIdKRPrimaryExpertiseArea%2cValidToOWSDATE%2cdocumentstatus%2cCreated%2cWrite'&trimduplicates=true&sortlist='write:descending'&refinementfilters=" + finalQueryString + "&clienttype='ContentSearchRegular'";
            executor = new SP.RequestExecutor(helpers.appweburl);
        }
        else {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAB2112%2fKnowledge+Items\"+AND+refinablestring136:\"Approved\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAB2112%2fLists%2fKnowledge+Links\")+ " + searchQueryText + " '&rowlimit=100&selectproperties='Title%2cFileName%2cPath%2cowstaxIdKRExpertiseAreaDiscipline%2cfileextension%2cowstaxIdKRExpertiseAreaDiscipline%2cowstaxIdKRAdditionalExpertiseAreas%2cowstaxIdKRExpertiseAreaFunction%2cowstaxIdKRPrimaryExpertiseArea%2cValidToOWSDATE%2cdocumentstatus%2cCreated%2cWrite'&trimduplicates=true&sortlist='write:descending'&clienttype='ContentSearchRegular'";
            executor = new SP.RequestExecutor(helpers.appweburl);
        }


        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchResultsDocumnetSuccess,
                error: onGetSearchResultsFail
            }
        );
    }

    //Removing the term set guid from search values
    function getTermValue(value) {
        // Expertise area is returned by search in a specific format, for example: GP0|#0{guid}|text;L0|#{guid}|text. Other formats are also possible.
        // Approach to get the correct text values:
        // Split the value on ';'
        // Per item check if it the element contains 'L0|'.
        // 		If so, split the item on '|'and use the last (2nd) element.
        var terms = value.split(';');
        var result = [];
        for (var i = 0; i < terms.length; i++) {
            if (terms[i].indexOf('L0|#') >= 0) {
                result.push(terms[i].split('|')[2]);
            }
        }

        // Return the text values of the terms separated by a colon.
        return result.join(', ');
    }

    function onGetSearchResultsDocumnetSuccess(data) {
        const monthNames = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let items = [];
        let tempFilterResults: any = [];
        let tempObjResults = [];
        let tempPteQuerySet = [];
        let tempDisciplineQuerySet = [];
        let tempTechFunctionQSet = [];
        let jsonObjectDiscussion = JSON.parse(data.body);
        let jsonDiscussionResults = jsonObjectDiscussion.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        let discData = jsonDiscussionResults;
        let techincalFunction = '';
        let disciplineVal = '';
        let pteAreaVal = '';
        let smeAreaVal = '';
        let docTitle = '';
        let validDocTill = '';
        let formattedValidDate = '';
        let docApproved = '';
        let docCreated = '';
        let docFilePath = '';
        let docFilenme = '';
        if (jsonDiscussionResults.length > 0 && jsonDiscussionResults !== null) {
            for (let i = 0; i < jsonDiscussionResults.length; i++) {
                techincalFunction = '';
                disciplineVal = '';
                pteAreaVal = '';
                smeAreaVal = '';
                docTitle = '';
                validDocTill = '';
                docApproved = '';
                docCreated = '';
                docFilePath = '';
                docFilenme = '';
                for (var j = 0; j < jsonDiscussionResults[i].Cells.results.length; j++) {
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "owstaxIdKRExpertiseAreaFunction") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            techincalFunction = getTermValue(jsonDiscussionResults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "owstaxIdKRExpertiseAreaDiscipline") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            disciplineVal = getTermValue(jsonDiscussionResults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "owstaxIdKRPrimaryExpertiseArea") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            pteAreaVal = getTermValue(jsonDiscussionResults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "owstaxIdKRAdditionalExpertiseAreas") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            smeAreaVal = getTermValue(jsonDiscussionResults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "documentstatus") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            docApproved = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "ValidToOWSDATE") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            validDocTill = jsonDiscussionResults[i].Cells.results[j].Value;
                            if (validDocTill) {
                                let validDate = new Date(validDocTill);
                                let validMonth = validDate.getUTCMonth();
                                let validYear = validDate.getFullYear();
                                formattedValidDate = monthNames[validMonth] + " " + validYear;
                            }
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "Title") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            docTitle = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }

                    if (jsonDiscussionResults[i].Cells.results[j].Key === "Created") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            docCreated = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "FileName") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            docFilenme = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "Path") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            docFilePath = jsonDiscussionResults[i].Cells.results[j].Value;
                            if (docFilePath.indexOf('Knowledge Links') > -1) {
                                if (docFilenme !== "" && docFilenme !== null) {
                                    docFilePath = docFilenme;
                                }
                            }
                        }
                    }
                }
                if (docFilePath !== null && docFilePath !== "") {
                    items.push({
                        techFunction: techincalFunction,
                        discipline: disciplineVal,
                        pteArea: pteAreaVal,
                        smeArea: smeAreaVal,
                        docApprove: docApproved,
                        validDoc: formattedValidDate,
                        doctitle: docTitle,
                        Created: docCreated,
                        filePath: docFilePath,
                    });
                }
            }


            tempObjResults = [...items].slice();
            if (filterSMEs !== null && filterSMEs !== "" && filterSMEs !== undefined) {
                let splitSMEs = filterSMEs.split(';');
                splitSMEs = splitSMEs.filter(item => item);
                tempFilterResults = splitSMEs;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.smeArea.indexOf(',') > -1) {
                        let tempDiscFilters = o.smeArea.split(",").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.smeArea)
                    }
                });
            }
            if (filterPTEs !== null && filterPTEs !== "" && filterPTEs !== undefined) {
                let splitPTEs = filterPTEs.split(';');
                splitPTEs = splitPTEs.filter(item => item);
                tempFilterResults = splitPTEs;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.pteArea.indexOf(',') > -1) {
                        let tempDiscFilters = o.pteArea.split(",").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.pteArea)
                    }
                });
            }

            if (filterDisciplines !== null && filterDisciplines !== "" && filterDisciplines !== undefined) {
                let splitDisciplines = filterDisciplines.split(';');
                splitDisciplines = splitDisciplines.filter(item => item);
                tempFilterResults = splitDisciplines;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.discipline.indexOf(',') > -1) {
                        let tempDiscFilters = o.discipline.split(",").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.discipline)
                    }
                });
            }
            //console.log(tempObjResults);
            if (filterTechFunctions !== null && filterTechFunctions !== "" && filterTechFunctions !== undefined) {
                let splitTechFunctions = filterTechFunctions.split(';');
                splitTechFunctions = splitTechFunctions.filter(item => item);
                tempFilterResults = splitTechFunctions;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.techFunction.indexOf(',') > -1) {
                        let tempFunctionFilters = o.techFunction.split(",").map(item => item.trim());
                        tempFunctionFilters = tempFunctionFilters.map(tempObj => tempFilterResults.includes(tempObj))
                        return tempFunctionFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.techFunction)
                    }
                });
            }
            console.log(tempObjResults);
        }


        var allData: any = [...tempObjResults];
        let sortedData = allData.sort(function (x: any, y: any) {
            const a: any = new Date(x.Created);
            const b: any = new Date(y.Created);
            return a - b;
        });
        sortedData.reverse();

        setDocumentsData([...sortedData]);

        if (selectedMenu === 1) {
            setSearchResponse([...sortedData]);
            setFilteredData(sortedData.slice(0, 10));
        }

        getEnterpriseArticleSearch();

        //setLoading(false);
    }


    //function getEnterprisePeopleSearch() {
    //    if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {

    //    }
    //    else {
    //        const caml: any = {
    //            ViewXml: "<View><Query></Query></View>",
    //        };
    //        window['SP_Propertyweb'].lists.getByTitle("ExpertsConfiguration").getItemsByCAMLQuery(caml).then((response: any) => {
    //            const expertsconfigurationresponse = response
    //            if (response.length > 0) {
    //                getSPoAEFMLMasterItems(expertsconfigurationresponse);
    //            }
    //            else {
    //                setLoading(false);
    //            }
    //        });
    //    }

    //}


    //function getSPoAEFMLMasterItems(expertsconfigurationresponse) {
    //    let EFMLDisciplines = '';
    //    let EFMLDiscipliness = '';
    //    for (var j = 0; j < expertsconfigurationresponse.length; j++) {
    //        let Discipline = expertsconfigurationresponse[j].ExpertiseArea.Label.replace(/:/g, ';');
    //        let EFMLDisciplines1 = "substringof('" + Discipline + "', TermPath)";

    //        if (j == expertsconfigurationresponse.length - 1) {
    //            EFMLDisciplines = EFMLDisciplines + EFMLDisciplines1;
    //        }
    //        else {
    //            EFMLDisciplines = EFMLDisciplines + EFMLDisciplines1 + ' or ';
    //        }
    //    }
    //    EFMLDiscipliness = "(" + EFMLDisciplines + ")";
    //    getData(EFMLDiscipliness);

    //}

    //function getData(EFMLDiscipliness) {
    //    sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoAEFMLMaster").items.select("ExpertName,EFMLRole,TechnicalFunction,EFMLDiscipline,TermPath,PTEArea,SMEArea,EmailID,ID").filter(EFMLDiscipliness + "and (EFMLRole eq 'PTE' or EFMLRole eq 'SME' or EFMLRole eq 'Associate SME')").top(4999).orderBy('PTEArea').get().then((response: any) => {
    //        console.log(response);

    //        setPeopleData(response);
    //        if (selectedMenu === 1) {
    //            setFilteredData(response.slice(0, 25));
    //        }
    //        setLoading(false);
    //    });
    //}

    function getEnterpriseSignsearch(txt?: any, TfunctionsLabel?: any, TfunctionsId?: any, TDisciplineLabel?: any, TDisciplineId?: any, TPTELabel?: any, TPTEId?: any, TSMELabel?: any, TSMEId?: any) {
        setLoading(true);
        setActiveIndex(1);
        let _tempQuery = searchQuery;
        searchQueryText = _tempQuery;
        if (_tempQuery === "") {
            if (txt === undefined || txt === "" || txt === null) {
                _tempQuery = "*";
                searchQueryText = "*";
            }
            else {
                _tempQuery = txt;
                searchQueryText = txt;
                setSearchQuery(txt);
            }
        }



        setSearchResponse([]);
        setArticleData([]);
        setDocumentsData([]);
        setDiscussionData([]);
        setLearningData([]);

        filterTechFunctions = TfunctionsLabel;
        filterDisciplines = TDisciplineLabel;
        filterPTEs = TPTELabel;
        filterSMEs = TSMELabel;

        filterTechFunctionsId = TfunctionsId;
        filterDisciplinesId = TDisciplineId;
        filterPTEsId = TPTEId;
        filterSMEsId = TSMEId;

        let finalTermValue = '';

        if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {
            let splitTechFunctionsLabel = filterTechFunctions.split(';');
            for (let i = 0; i < splitTechFunctionsLabel.length; i++) {
                if (splitTechFunctionsLabel[i] !== "" && splitTechFunctionsLabel[i] !== null) {
                    finalTermValue = finalTermValue + splitTechFunctionsLabel[i] + ":"
                    break;
                }
            }
        }
        if (filterDisciplines !== null && filterDisciplines !== undefined && filterDisciplines != '') {
            let splitDisciplineLabel = filterDisciplines.split(';');
            for (let j = 0; j < splitDisciplineLabel.length; j++) {
                if (splitDisciplineLabel[j] !== "" && splitDisciplineLabel[j] !== null) {
                    finalTermValue = finalTermValue + splitDisciplineLabel[j] + ":"
                    break;
                }
            }
        }
        if (filterPTEs !== null && filterPTEs !== undefined && filterPTEs != '') {
            let splitPTELabel = filterPTEs.split(';');
            for (let k = 0; k < splitPTELabel.length; k++) {
                if (splitPTELabel[k] !== "" && splitPTELabel[k] !== null) {
                    finalTermValue = finalTermValue + splitPTELabel[k] + ":"
                    break;
                }
            }
        }
        if (filterSMEs !== null && filterSMEs !== undefined && filterSMEs != '') {
            let splitSMELabel = filterSMEs.split(';');
            for (let L = 0; L < splitSMELabel.length; L++) {
                if (splitSMELabel[L] !== "" && splitSMELabel[L] !== null) {
                    finalTermValue = finalTermValue + splitSMELabel[L] + ":"
                    break;
                }
            }
        }

        if (finalTermValue != "" && finalTermValue !== undefined && finalTermValue !== null) {
            finalTermValue = finalTermValue.slice(0, -1);
            finalTermValue = "'" + 'refinablestring70:"' + finalTermValue + '"' + "'"
        }

        let searchUrl = '';
        let executor;
        if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA5493%2f\")+" + _tempQuery + "'&rowlimit=100&selectproperties='Title%2cBodyOWSMTXT%2cDiscussionPost%2cRefinableString195%2cSignV2ConcludedOWSBOOL%2cSignV2PinnedOWSBOOL%2cRefinableString70%2cViewsLifetime%2cReplyCount%2cSignV2ValidatedOWSBOOL%2cWrite%2cCreated%2cPath%2cOriginalPath'&sortlist='write:descending'&trimduplicates=true&refinementfilters=" + finalTermValue + "&clienttype='ContentSearchRegular'"
            executor = new SP.RequestExecutor(helpers.appweburl);
        }
        else {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA5493%2f\")+" + _tempQuery + "'&rowlimit=100&selectproperties='Title%2cBodyOWSMTXT%2cDiscussionPost%2cRefinableString195%2cSignV2ConcludedOWSBOOL%2cSignV2PinnedOWSBOOL%2cRefinableString70%2cViewsLifetime%2cReplyCount%2cSignV2ValidatedOWSBOOL%2cWrite%2cCreated%2cPath%2cOriginalPath'&trimduplicates=true&sortlist='write:descending'&clienttype='ContentSearchRegular'"
            executor = new SP.RequestExecutor(helpers.appweburl);
        }

        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchResultSignresults,
                error: onGetSearchResultsFail
            }
        );
    }

    function onGetSearchResultSignresults(data) {
        let jsonObjectDiscussion = JSON.parse(data.body);
        let jsonDiscussionResults = jsonObjectDiscussion.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        let discData = jsonDiscussionResults;

        let discTitle = '';
        let discFilePath = '';
        let discForumName = '';
        let discConcluded = '';
        let discBody = '';
        let discReplies = '';
        let discViews = '';
        let discCreated = '';
        let discExpertiseArea = '';
        let forumnames = [];
        let items = [];

        let tempFilterResults: any = [];
        let tempObjResults = [];
        let tempPteQuerySet = [];
        let tempDisciplineQuerySet = [];
        let tempTechFunctionQSet = [];

        if (jsonDiscussionResults.length > 0 && jsonDiscussionResults !== null) {
            for (let i = 0; i < jsonDiscussionResults.length; i++) {
                discTitle = '';
                discFilePath = '';
                discForumName = '';
                discConcluded = '';
                discBody = '';
                discReplies = '';
                discViews = '';
                discCreated = '';
                discExpertiseArea = '';
                for (var j = 0; j < jsonDiscussionResults[i].Cells.results.length; j++) {
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "Title") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discTitle = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "Path") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discFilePath = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "RefinableString195") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discForumName = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "SignV2ConcludedOWSBOOL") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discConcluded = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "DiscussionPost") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discBody = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "ReplyCount") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discReplies = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "ViewsLifetime") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discViews = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "Created") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discCreated = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonDiscussionResults[i].Cells.results[j].Key === "RefinableString70") {
                        if (jsonDiscussionResults[i].Cells.results[j].Value !== "" && jsonDiscussionResults[i].Cells.results[j].Value !== null) {
                            discExpertiseArea = jsonDiscussionResults[i].Cells.results[j].Value;
                        }
                    }
                }
                if (discTitle != "DispForm.aspx" && discFilePath !== null && discFilePath !== "") {
                    items.push({
                        dTitle: discTitle,
                        dPath: discFilePath,
                        dForumName: discForumName,
                        dConclude: discConcluded,
                        dBody: discBody,
                        dReply: discReplies,
                        dView: discViews,
                        Created: discCreated,
                        dExpertiseArea: discExpertiseArea,
                    });

                    forumnames.push({
                        key: i,
                        value: discForumName,
                        label: discForumName
                    })
                }
            }
            console.log(items)
        }
        if (forumnames.length > 0) {
            var flags = [], forumDistictVal = [], l = forumnames.length, i;
            for (i = 0; i < l; i++) {
                if (flags[forumnames[i].value]) continue;
                flags[forumnames[i].value] = true;
                forumDistictVal.push(forumnames[i]);
            }
        }
        setForumName(forumDistictVal);
        var allData: any = [...items];
        let sortedData = allData.sort(function (x: any, y: any) {
            const a: any = new Date(x.Created);
            const b: any = new Date(y.Created);
            return a - b;
        });
        sortedData.reverse();

        setDiscussionData([...sortedData]);

        if (selectedMenu === 3) {
            setSearchResponse([...sortedData]);
            setFilteredData(sortedData.slice(0, 10));
        }
        getEnterpriseSPoASearch();


    }

    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }

    function getResults() {
        setDefaultFilterState(false);
        getEnterpriseSignsearch();
    }

    function getExpertsConfigurationItems() {
        setLoading(true)

    }

    function onFormSubmit(e) {
        setDefaultFilterState(false);
        e.preventDefault();
        getEnterpriseSignsearch();
    }

    function getEnterpriseSPoASearch() {
        let searchUrl = helpers.appweburl + "/_api/search/query?querytext='(Path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoAAnnouncements\"+OR+Path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoaLinks\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoAExperts\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fManual%2520Experts\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoACalendar\")+" + searchQueryText + "'&trimduplicates=true&rowlimit=100&selectproperties='Title%2cSpoASummaryOWSMTXT%2cCreated%2cSpoAURL%2cPath%2cSpoAUser%2cSpoADescription%2cSpoALeaderTitle%2cSpoAExpertType%2cSpoAExpertName%2cSpoAColumn1%2cPeople%2cLocation%2cDescriptionOWSMTXT%2cEventDateOWSDATE'&sortlist='Created:descending'&clienttype='ContentSearchRegular'";
        //let searchUrl = helpers.appweburl + "/_api/search/query?querytext='(Path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoAAnnouncements\"+OR+Path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoaLinks\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoAExperts\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fManual%2520Experts\"+OR+path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAA9133%2f" + window.location.hash.split("/")[1] + "%2fLists%2fSpoACalendar\")\"+" + searchQueryText + "'&trimduplicates=true&rowlimit=100&selectproperties='Title%2cSpoASummaryOWSMTXT%2cCreated%2cSpoAURL%2cPath%2cSpoAUser%2cSpoADescription%2cSpoALeaderTitle%2cSpoAExpertType%2cSpoAExpertName%2cSpoAColumn1%2cPeople%2cLocation%2cDescription%2cEventDateOWSDATE'&sortlist='Created:descending'&clienttype='ContentSearchRegular'";
        let executor = new SP.RequestExecutor(helpers.appweburl);
        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchEnterpriseSPoAresults,
                error: onGetSearchResultsFail
            }
        );

    }

    function onGetSearchEnterpriseSPoAresults(data) {
        let items = [];
        let spoaTitle = '';
        let spoaSummaryText = '';
        let spoaCreated = '';
        let spoaPath = '';
        let spoaURL = '';
        let spoaUser = '';
        let spoaDescription = '';
        let spoaLeaderTitle = '';
        let spoaExpertType = '';
        let spoaExpertName = '';
        let spoaColumn1 = '';
        let spoaEmail = '';
        let eventLocation = '';
        let eventDescription = '';
        let eventDate = '';
        let eventMonth = '';
        let eventUTCDate;
        let eventUTCYear;
        let eventUTCTime = '';
        let eventLocalTime = '';
        let jsonObjectArticle = JSON.parse(data.body);
        let jsonArticleResults = jsonObjectArticle.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        console.log("SPoASearchresult:" + jsonArticleResults);
        if (jsonArticleResults.length > 0 && jsonArticleResults !== null) {
            for (let i = 0; i < jsonArticleResults.length; i++) {
                spoaTitle = '';
                spoaSummaryText = '';
                spoaCreated = '';
                spoaPath = '';
                spoaURL = '';
                spoaUser = '';
                spoaDescription = '';
                spoaLeaderTitle = '';
                spoaExpertType = '';
                spoaExpertName = '';
                spoaColumn1 = '';
                spoaEmail = '';
                eventLocation = '';
                eventDescription = '';
                eventDate = '';
                for (var j = 0; j < jsonArticleResults[i].Cells.results.length; j++) {
                    if (jsonArticleResults[i].Cells.results[j].Key === "Title") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaTitle = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoASummaryOWSMTXT") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaSummaryText = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "Created") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaCreated = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "Path") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaPath = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoAURL") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "") {
                            spoaURL = jsonArticleResults[i].Cells.results[j].Value;
                            if (spoaURL === null) {
                                spoaURL = spoaPath;
                            }
                        }
                    }

                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoAUser") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaUser = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoADescription") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaDescription = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoALeaderTitle") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaLeaderTitle = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoAExpertType") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaExpertType = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoAExpertName") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaExpertName = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "SpoAColumn1") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaColumn1 = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "People") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            spoaEmail = jsonArticleResults[i].Cells.results[j].Value;
                            if (spoaEmail !== null) {
                                spoaEmail = spoaEmail.split('|')[0];
                            }
                            else {
                                spoaEmail = '';
                            }
                        }
                    }

                    if (jsonArticleResults[i].Cells.results[j].Key === "Location") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            eventLocation = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "DescriptionOWSMTXT") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            eventDescription = jsonArticleResults[i].Cells.results[j].Value;
                            if (eventDescription !== null) {
                                eventDescription = eventDescription.replace(/<img src="[/]*sites/g, '<img src="' + helpers.rootSiteCollection);
                            }
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "EventDateOWSDATE") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            eventDate = jsonArticleResults[i].Cells.results[j].Value;
                            if (eventDate !== null) {
                                eventMonth = monthNames[new Date(eventDate).getUTCMonth()];
                                eventUTCDate = new Date(eventDate).getUTCDate();
                                eventUTCYear = new Date(eventDate).getUTCFullYear();
                                eventUTCTime = new Date(eventDate).getUTCHours() + ":" + (new Date(eventDate).getUTCMinutes() < 10 ? "0" + new Date(eventDate).getUTCMinutes() + " GMT" : new Date(eventDate).getUTCMinutes() + " GMT");
                                eventLocalTime = moment(moment.utc(eventDate).toDate()).local().format('HH:mm');
                            } else {
                                eventMonth = ''; eventUTCDate = ''; eventUTCYear = ''; eventUTCTime = ''; eventLocalTime = '';
                            }
                        }
                    }
                }

                items.push({
                    oTitle: spoaTitle,
                    oSummary: spoaSummaryText,
                    oCreated: spoaCreated,
                    oPath: spoaPath,
                    oURL: spoaURL,
                    oUser: spoaUser,
                    oDescription: spoaDescription,
                    oLeaderTitle: spoaLeaderTitle,
                    oExpertType: spoaExpertType,
                    oExpertName: spoaExpertName,
                    oColumn1: spoaColumn1,
                    oEmail: spoaEmail,
                    oSiteName: props.isSiteName,
                    oUserProfileLink: "https://profile.shell.com/?accountname=i:0%23.f%7cmembership%7c" + spoaEmail,
                    oEventLocation: eventLocation,
                    oEventDesc: eventDescription,
                    oEventMonth: eventMonth,
                    oEventUTCDate: eventUTCDate,
                    oEventUTCYear: eventUTCYear,
                    oEventUTCTime: eventUTCTime,
                    oEventLocalTime: eventLocalTime
                });

            }
        }

        var allData: any = [...items];
        let sortedData = allData.sort(function (x: any, y: any) {
            const a: any = new Date(x.oCreated);
            const b: any = new Date(y.oCreated);
            return a - b;
        });
        sortedData.reverse();

        setSPoAData([...sortedData]);

        if (selectedMenu === 5) {
            setSearchResponse([...sortedData]);
            setFilteredData(sortedData.slice(0, 10));
        }

        getEnterpriseDocumentSearchResults();

    }

    function getEnterpriseArticleSearch() {

        let refinablestring189 = ''   //Techincal function
        let refinablestring166 = ''   //Discipline
        let refinablestring161 = ''   // PTE
        let refinablestring162 = ''   //SME

        let tfCount = 0;
        let disciplineCount = 0;
        let pteCount = 0;
        let smeCount = 0;


        if (filterTechFunctions !== undefined && filterTechFunctions !== null) {
            let splitTechFunctions = filterTechFunctions.split(';');
            for (let i = 0; i < splitTechFunctions.length; i++) {
                if (splitTechFunctions[i] !== "" && splitTechFunctions[i] !== null) {
                    tfCount++;
                    refinablestring189 = refinablestring189 + 'refinablestring189:"' + splitTechFunctions[i] + '",'
                }
            }
        }
        if (filterDisciplines !== undefined && filterDisciplines !== null) {
            let splitDisciplines = filterDisciplines.split(';');
            for (let j = 0; j < splitDisciplines.length; j++) {
                if (splitDisciplines[j] !== "" && splitDisciplines[j] !== null) {
                    disciplineCount++;
                    refinablestring166 = refinablestring166 + 'refinablestring166:"' + splitDisciplines[j] + '",';
                }
            }
        }
        if (filterPTEs !== undefined && filterPTEs !== null) {
            let splitPTEs = filterPTEs.split(';');
            for (let k = 0; k < splitPTEs.length; k++) {
                if (splitPTEs[k] !== "" && splitPTEs[k] !== null) {
                    pteCount++;
                    refinablestring161 = refinablestring161 + 'refinablestring161:"' + splitPTEs[k] + '",';
                }
            }
        }

        if (filterSMEs !== undefined && filterSMEs !== null) {
            let splitSMEs = filterSMEs.split(';');
            for (let l = 0; l < splitSMEs.length; l++) {
                if (splitSMEs[l] !== "" && splitSMEs[l] !== null) {
                    smeCount++;
                    refinablestring162 = refinablestring162 + 'refinablestring162:"' + splitSMEs[l] + '",';
                }
            }
        }

        let wrapper;
        if (tfCount === 1 && disciplineCount === 1 && pteCount === 1 && smeCount === 1) {
            wrapper = "'AND (";
        }
        else if (tfCount > 1 || disciplineCount > 1 || pteCount > 1 || smeCount > 1) {
            wrapper = "'OR (";
        }
        else {
            wrapper = "'AND (";
        }

        let finalQueryString;


        if (tfCount === 1 && refinablestring166 === '' && refinablestring161 === '' && refinablestring162 === '') {
            finalQueryString = ("'" + refinablestring189.replace(/,\s*$/, "") + "'");
        }
        else {
            finalQueryString = (wrapper + refinablestring189 + refinablestring166 + refinablestring161 + refinablestring162).replace(/,\s*$/, "") + ")'";
        }

        let searchUrl = '';
        let executor;
        if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAB1732%2fPages\"+and+FileExtension:\"aspx\")+" + searchQueryText + "'&rowlimit=100&selectproperties='Title,Path,PublishingPageContentOWSHTML,RefinableString160,EEArticleStatusOWSCHCS,RefinableString189,RefinableString166,RefinableString161,RefinableString162,Write,Created,LNDescriptionOWSHTML'&sortlist='write:descending'&trimduplicates=true&refinementfilters=" + finalQueryString + "&clienttype='ContentSearchRegular'";
            executor = new SP.RequestExecutor(helpers.appweburl);
        }
        else {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAAAB1732%2fPages\"+and+FileExtension:\"aspx\")+" + searchQueryText + "'&rowlimit=100&selectproperties='Title,Path,PublishingPageContentOWSHTML,RefinableString160,EEArticleStatusOWSCHCS,RefinableString189,RefinableString166,RefinableString161,RefinableString162,Write,Created,LNDescriptionOWSHTML'&trimduplicates=true&sortlist='write:descending'&clienttype='ContentSearchRegular'";
            executor = new SP.RequestExecutor(helpers.appweburl);
        }

        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchArticleresults,
                error: onGetSearchResultsFail
            }
        );
    }

    function onGetSearchArticleresults(data) {

        let items = [];
        let tempFilterResults: any = [];
        let tempObjResults = [];
        let tempPteQuerySet = [];
        let tempDisciplineQuerySet = [];
        let tempTechFunctionQSet = [];

        let jsonObjectArticle = JSON.parse(data.body);
        let jsonArticleResults = jsonObjectArticle.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;

        let techincalFunction = '';
        let disciplineVal = '';
        let pteAreaVal = '';
        let smeAreaVal = '';
        let articleTitle = '';
        let articleBody = '';
        let articleApproved = '';
        let articleCreated = '';
        let articleFilePath = '';
        let articleType = '';
        if (jsonArticleResults.length > 0 && jsonArticleResults !== null) {
            for (let i = 0; i < jsonArticleResults.length; i++) {
                techincalFunction = '';
                disciplineVal = '';
                pteAreaVal = '';
                smeAreaVal = '';
                articleTitle = '';
                articleBody = '';
                articleApproved = '';
                articleCreated = '';
                articleFilePath = '';
                articleType = '';
                for (var j = 0; j < jsonArticleResults[i].Cells.results.length; j++) {
                    if (jsonArticleResults[i].Cells.results[j].Key === "RefinableString189") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            techincalFunction = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "RefinableString166") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            disciplineVal = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "RefinableString161") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            pteAreaVal = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "RefinableString162") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            smeAreaVal = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "Title") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            articleTitle = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "PublishingPageContentOWSHTML") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            articleBody = jsonArticleResults[i].Cells.results[j].Value;
                            if (articleBody !== null && articleBody !== "" && articleBody !== "null" && articleBody !== undefined) {
                                var div = document.createElement("div");
                                div.innerHTML = articleBody;
                                var convertedText = div.textContent || div.innerText || "";
                                if (convertedText != "") {
                                    articleBody = convertedText;
                                } else {
                                    articleBody = "";
                                }
                                //articleBody = articleBody.replace(/(<([^>]+)>)/ig, '');
                            }
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "EEArticleStatusOWSCHCS") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            articleApproved = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }

                    if (jsonArticleResults[i].Cells.results[j].Key === "Created") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            articleCreated = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "Path") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            articleFilePath = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonArticleResults[i].Cells.results[j].Key === "RefinableString160") {
                        if (jsonArticleResults[i].Cells.results[j].Value !== "" && jsonArticleResults[i].Cells.results[j].Value !== null) {
                            articleType = jsonArticleResults[i].Cells.results[j].Value;
                        }
                    }
                }
                if (articleApproved != "Pending Peer Review" && articleApproved != "Pending Approval" && articleType !== null && articleType !== "") {
                    items.push({
                        techFunction: techincalFunction,
                        discipline: disciplineVal,
                        pteArea: pteAreaVal,
                        smeArea: smeAreaVal,
                        articleApproved: articleApproved,
                        articleBody: articleBody,
                        articleTitle: articleTitle,
                        Created: articleCreated,
                        articleFilePath: articleFilePath,
                        articleType: articleType,
                    });
                }

            }


            tempObjResults = [...items].slice();
            if (filterSMEs !== null && filterSMEs !== "" && filterSMEs !== undefined) {
                let splitSMEs = filterSMEs.split(';');
                splitSMEs = splitSMEs.filter(item => item);
                tempFilterResults = splitSMEs;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.smeArea.indexOf(';') > -1) {
                        let tempDiscFilters = o.smeArea.split(";").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.smeArea)
                    }
                });
            }
            if (filterPTEs !== null && filterPTEs !== "" && filterPTEs !== undefined) {
                let splitPTEs = filterPTEs.split(';');
                splitPTEs = splitPTEs.filter(item => item);
                tempFilterResults = splitPTEs;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.pteArea.indexOf(';') > -1) {
                        let tempDiscFilters = o.pteArea.split(";").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.pteArea)
                    }
                });
            }

            if (filterDisciplines !== null && filterDisciplines !== "" && filterDisciplines !== undefined) {
                let splitDisciplines = filterDisciplines.split(';');
                splitDisciplines = splitDisciplines.filter(item => item);
                tempFilterResults = splitDisciplines;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.discipline.indexOf(';') > -1) {
                        let tempDiscFilters = o.discipline.split(";").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.discipline)
                    }
                });
            }
            //console.log(tempObjResults);
            if (filterTechFunctions !== null && filterTechFunctions !== "" && filterTechFunctions !== undefined) {
                let splitTechFunctions = filterTechFunctions.split(';');
                splitTechFunctions = splitTechFunctions.filter(item => item);
                tempFilterResults = splitTechFunctions;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.techFunction.indexOf(';') > -1) {
                        let tempFunctionFilters = o.techFunction.split(";").map(item => item.trim());
                        tempFunctionFilters = tempFunctionFilters.map(tempObj => tempFilterResults.includes(tempObj))
                        return tempFunctionFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.techFunction)
                    }
                });
            }
            console.log(tempObjResults);
        }

        var allData: any = [...tempObjResults];
        let sortedData = allData.sort(function (x: any, y: any) {
            const a: any = new Date(x.Created);
            const b: any = new Date(y.Created);
            return a - b;
        });
        sortedData.reverse();

        setArticleData([...sortedData]);

        if (selectedMenu === 2) {
            setSearchResponse([...sortedData]);
            setFilteredData(sortedData.slice(0, 10));
        }

        getEnterpriseLearningSearch();
    }

    function getEnterpriseLearningSearch() {

        let finalTermValue = '';

        if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {
            let splitTechFunctionsLabel = filterTechFunctions.split(';');
            for (let i = 0; i < splitTechFunctionsLabel.length; i++) {
                if (splitTechFunctionsLabel[i] !== "" && splitTechFunctionsLabel[i] !== null) {
                    finalTermValue = finalTermValue + splitTechFunctionsLabel[i] + ":"
                    break;
                }
            }
        }
        if (filterDisciplines !== null && filterDisciplines !== undefined && filterDisciplines != '') {
            let splitDisciplineLabel = filterDisciplines.split(';');
            for (let j = 0; j < splitDisciplineLabel.length; j++) {
                if (splitDisciplineLabel[j] !== "" && splitDisciplineLabel[j] !== null) {
                    finalTermValue = finalTermValue + splitDisciplineLabel[j] + ":"
                    break;
                }
            }
        }
        if (filterPTEs !== null && filterPTEs !== undefined && filterPTEs != '') {
            let splitPTELabel = filterPTEs.split(';');
            for (let k = 0; k < splitPTELabel.length; k++) {
                if (splitPTELabel[k] !== "" && splitPTELabel[k] !== null) {
                    finalTermValue = finalTermValue + splitPTELabel[k] + ":"
                    break;
                }
            }
        }
        if (filterSMEs !== null && filterSMEs !== undefined && filterSMEs != '') {
            let splitSMELabel = filterSMEs.split(';');
            for (let L = 0; L < splitSMELabel.length; L++) {
                if (splitSMELabel[L] !== "" && splitSMELabel[L] !== null) {
                    finalTermValue = finalTermValue + splitSMELabel[L] + ":"
                    break;
                }
            }
        }

        if (finalTermValue != "" && finalTermValue !== undefined && finalTermValue !== null) {
            finalTermValue = finalTermValue.slice(0, -1);
            finalTermValue = "'" + 'refinablestring55:"' + finalTermValue + '"' + "'"
        }

        let searchUrl = '';
        let executor;
        if (filterTechFunctions !== null && filterTechFunctions !== undefined && filterTechFunctions != '') {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings%2f\")+" + searchQueryText + "+AND+LFEItemStatus:\"Active\"'&rowlimit=100&selectproperties='LFELearningID%2cTitle%2cLFELearningDesc%2cLFELearningStatus%2cLFELearningType%2cDocId%2cRefinableString55%2cViewsLifeTime%2cPath%2cOriginalPath%2cWrite%2cCreated%2cLFEValue%2cLFELearningBenefitType%2cLFELearningBenefitEffort%2cLFELearningSourceType'&sortlist='write:descending'&trimduplicates=true&refinementfilters=" + finalTermValue + "&clienttype='ContentSearchRegular'"
            executor = new SP.RequestExecutor(helpers.appweburl);
        }
        else {
            searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings%2f\")+" + searchQueryText + "+AND+LFEItemStatus:\"Active\"'&rowlimit=100&selectproperties='LFELearningID%2cTitle%2cLFELearningDesc%2cLFELearningStatus%2cLFELearningType%2cDocId%2cRefinableString55%2cViewsLifeTime%2cPath%2cOriginalPath%2cWrite%2cCreated%2cLFEValue%2cLFELearningBenefitType%2cLFELearningBenefitEffort%2cLFELearningSourceType'&sortlist='write:descending'&trimduplicates=true&clienttype='ContentSearchRegular'";
            executor = new SP.RequestExecutor(helpers.appweburl);

        }
        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchLearningresults,
                error: onGetSearchResultsFail
            }
        );
    }

    function onGetSearchLearningresults(data) {
        let jsonObjectLearning = JSON.parse(data.body);
        let jsonLearningResults = jsonObjectLearning.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        console.log(jsonLearningResults);
        let lfeLearningResults = [];
        let learningStatCount = [];
        let statCount = 0;
        var deferred = $.Deferred();
        let LearningID = '';
        let LearningPath = '';
        let learningTitle = '';
        let learningDesc = '';
        let learningStatus = '';
        let learningType = '';
        let learningExpArea = '';
        let learningCreated = '';
        let learningValue = '';
        let learningBenefitType = '';
        let learningBenefitEffort = '';
        let learningSourceType = '';
        if (jsonLearningResults.length > 0 && jsonLearningResults !== null) {
            for (let i = 0; i < jsonLearningResults.length; i++) {
                LearningID = '';
                LearningPath = '';
                learningTitle = '';
                learningDesc = '';
                learningStatus = '';
                learningType = '';
                learningExpArea = '';
                learningCreated = '';
                learningValue = '';
                learningBenefitType = '';
                learningBenefitEffort = '';
                learningSourceType = '';
                for (var j = 0; j < jsonLearningResults[i].Cells.results.length; j++) {
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningID") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            LearningID = jsonLearningResults[i].Cells.results[j].Value;
                            LearningPath = "https://lfe.shell.com/#/ViewLearning/" + LearningID;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "Title") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningTitle = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningDesc") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningDesc = jsonLearningResults[i].Cells.results[j].Value;
                            if (learningDesc !== null && learningDesc !== "" && learningDesc !== "null" && learningDesc !== undefined) {
                                //learningDesc = learningDesc.replace(/(<([^>]+)>)/ig, '');
                                var div = document.createElement("div");
                                div.innerHTML = learningDesc;
                                var convertedText = div.textContent || div.innerText || "";
                                if (convertedText != "") {
                                    learningDesc = convertedText;
                                } else {
                                    learningDesc = "";
                                }
                            }
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningStatus") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningStatus = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningType") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningType = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "RefinableString55") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningExpArea = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "Created") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningCreated = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFEValue") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "") {
                            learningValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(jsonLearningResults[i].Cells.results[j].Value).slice(1);
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningBenefitType") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "") {
                            learningBenefitType = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningBenefitEffort") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "") {
                            learningBenefitEffort = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonLearningResults[i].Cells.results[j].Key === "LFELearningSourceType") {
                        if (jsonLearningResults[i].Cells.results[j].Value !== "" && jsonLearningResults[i].Cells.results[j].Value !== null) {
                            learningSourceType = jsonLearningResults[i].Cells.results[j].Value;
                        }
                    }
                    if (learningSourceType.trim() === "Learning from Incidents (LFI) - HSSE") {
                        learningType = "LFI";
                    }
                }
                if (learningStatus != "Draft" && LearningID !== null && LearningID !== "") {
                    lfeLearningResults.push({
                        learnId: LearningID,
                        learnPath: LearningPath,
                        learnTitle: learningTitle,
                        LearnDesc: learningDesc,
                        LearnStatus: learningStatus,
                        learnType: learningType,
                        learnExpArea: learningExpArea,
                        Created: learningCreated,
                        learnValue: learningValue,
                        learnBenefitType: learningBenefitType,
                        learnBenefitEffort: learningBenefitEffort,
                    });
                }

                let searchUrl = '';
                let executor;
                searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMLearningSocialCounts%2f\") AND (Title:" + LearningID + ")'&selectproperties='Title%2cDocId%2cLFELearningEmpCount%2cLFELearningFollowCount%2cLFELearningRateCount%2cLFELearningShareCount%2cLFELearningsLikesCount%2cWrite%2cCreated%2cLFELearningAdoptCount'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
                executor = new SP.RequestExecutor(helpers.appweburl);
                executor.executeAsync(
                    {
                        url: searchUrl,
                        method: "GET",
                        headers: { "Accept": "application/json; odata=verbose" },
                        success: function (data) {
                            deferred.resolve(data);
                            statCount++;
                            let jsonObjectStatCount = JSON.parse(data.body);
                            let jsonLearningStatCountResults = jsonObjectStatCount.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
                            let lfeEmpCount = '0';
                            let lfeRating = '';
                            let lfeshare = '';
                            let lfeLike = '';
                            let lfefollow = '';
                            let lfeTitle = '';
                            let lfeRepCount = '0';
                            if (jsonLearningStatCountResults.length > 0 && jsonLearningStatCountResults !== null) {
                                for (let i = 0; i < jsonLearningStatCountResults.length; i++) {
                                    for (var j = 0; j < jsonLearningStatCountResults[i].Cells.results.length; j++) {
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "Title") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfeTitle = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "LFELearningEmpCount") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfeEmpCount = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "LFELearningFollowCount") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfefollow = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "LFELearningRateCount") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfeRating = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "LFELearningShareCount") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfeshare = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "LFELearningsLikesCount") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfeLike = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                        if (jsonLearningStatCountResults[i].Cells.results[j].Key === "LFELearningAdoptCount") {
                                            if (jsonLearningStatCountResults[i].Cells.results[j].Value !== "" && jsonLearningStatCountResults[i].Cells.results[j].Value !== null) {
                                                lfeRepCount = jsonLearningStatCountResults[i].Cells.results[j].Value;
                                            }
                                        }
                                    }
                                    learningStatCount.push({
                                        lTitle: lfeTitle,
                                        lEmpCount: lfeEmpCount,
                                        lFollow: lfefollow,
                                        lRate: lfeRating,
                                        lShare: lfeshare,
                                        lLike: lfeLike,
                                        lRepCount: lfeRepCount,
                                    });

                                }
                            }
                            if (lfeLearningResults.length == statCount) {
                                onGetEnterpriseLearningDoc(learningStatCount, lfeLearningResults);
                            }
                        },
                        error: onGetSearchResultsFail
                    }
                );
            }
        } else {
            setLoading(false)
        }

        return deferred;
    }

    function onGetEnterpriseLearningDoc(learningStatCount, lfeLearningResults) {
        let learningId = '';
        let learningDocs = [];
        let docCount = 0;
        var deferred = $.Deferred();
        if (learningStatCount.length > 0 && learningStatCount !== null) {
            setTimeout(() => {
                for (let i = 0; i < learningStatCount.length; i++) {
                    learningId = '';
                    if (learningStatCount[i].lTitle !== "") {
                        learningId = "(ParentLink:https://eu001-sp.shell.com/sites/AAFAA4724/LearningDocuments/" + learningStatCount[i].lTitle + ")";
                        let searchUrl = '';
                        let executor;
                        searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLearningDocuments%2f\")+AND+" + learningId + "'&rowlimit=1&sourceid='21942739-2977-4f08-a905-586de47b5294'&trimduplicates=true&selectproperties='Title,Path,OriginalPath,FileType,DocId,CreatedBy,ParentLink,Write,Created'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
                        executor = new SP.RequestExecutor(helpers.appweburl);
                        executor.executeAsync(
                            {
                                url: searchUrl,
                                method: "GET",
                                headers: { "Accept": "application/json; odata=verbose" },
                                success: function (data) {
                                    deferred.resolve(data);
                                    docCount++;
                                    let jsonObjectAttachment = JSON.parse(data.body);
                                    let jsonLearningAttchResults = jsonObjectAttachment.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
                                    console.log(jsonLearningAttchResults);
                                    let learningFileType = '';
                                    let learningFilePath = '';
                                    let learningDocTitle = '';
                                    if (jsonLearningAttchResults.length > 0 && jsonLearningAttchResults !== null) {
                                        for (let ij = 0; ij < jsonLearningAttchResults.length; ij++) {
                                            learningFileType = '';
                                            learningFilePath = '';
                                            learningDocTitle = '';

                                            for (var j = 0; j < jsonLearningAttchResults[ij].Cells.results.length; j++) {
                                                if (jsonLearningAttchResults[ij].Cells.results[j].Key === "FileType") {
                                                    if (jsonLearningAttchResults[ij].Cells.results[j].Value !== "" && jsonLearningAttchResults[ij].Cells.results[j].Value !== null) {
                                                        learningFileType = jsonLearningAttchResults[ij].Cells.results[j].Value;
                                                    }
                                                }
                                                if (jsonLearningAttchResults[ij].Cells.results[j].Key === "Path") {
                                                    if (jsonLearningAttchResults[ij].Cells.results[j].Value !== "" && jsonLearningAttchResults[ij].Cells.results[j].Value !== null) {
                                                        learningFilePath = jsonLearningAttchResults[ij].Cells.results[j].Value;
                                                    }
                                                }
                                                if (jsonLearningAttchResults[ij].Cells.results[j].Key === "Title") {
                                                    if (jsonLearningAttchResults[ij].Cells.results[j].Value !== "" && jsonLearningAttchResults[ij].Cells.results[j].Value !== null) {
                                                        learningDocTitle = jsonLearningAttchResults[ij].Cells.results[j].Value;
                                                    }
                                                }
                                            }
                                            learningDocs.push({
                                                lfileType: learningFileType,
                                                lfilePath: learningFilePath,
                                                lDocTitle: learningDocTitle,
                                            });
                                        }
                                    } else {
                                        console.log("Looped into no record statement" + docCount);
                                    }

                                    if (learningStatCount.length == docCount) {
                                        lfeLearningResults.map((o, i) => {
                                            learningStatCount.map(arrObj => {
                                                if (arrObj.lTitle === o.learnId) {
                                                    lfeLearningResults[i].lEmpCount = arrObj.lEmpCount
                                                    lfeLearningResults[i].lFollow = arrObj.lFollow
                                                    lfeLearningResults[i].lRate = arrObj.lRate
                                                    lfeLearningResults[i].lShare = arrObj.lShare
                                                    lfeLearningResults[i].lLike = arrObj.lLike
                                                    lfeLearningResults[i].lRepCount = arrObj.lRepCount
                                                }
                                            })
                                            learningDocs.forEach(arrObj => {
                                                if (arrObj.lfilePath.includes(o.learnId)) {
                                                    lfeLearningResults[i].lfileType = arrObj.lfileType
                                                    lfeLearningResults[i].lfilePath = arrObj.lfilePath
                                                    lfeLearningResults[i].lDocTitle = arrObj.lDocTitle
                                                    lfeLearningResults[i].lfileType = arrObj.lfileType
                                                }
                                            })
                                        });
                                        var allData: any = [...lfeLearningResults];
                                        let sortedData = allData.sort(function (x: any, y: any) {
                                            const a: any = new Date(x.Created);
                                            const b: any = new Date(y.Created);
                                            return a - b;
                                        });
                                        sortedData.reverse();
                                        setLearningData([...sortedData]);
                                        if (selectedMenu === 4) {
                                            setSearchResponse([...sortedData]);
                                            setFilteredData(sortedData.slice(0, 10));
                                        }
                                        setLoading(false);
                                    }
                                },
                                error: function (data) {
                                    console.log("Looped into error statement" + docCount);
                                    docCount++;
                                    if (learningStatCount.length == docCount) {
                                        lfeLearningResults.map((o, i) => {
                                            learningStatCount.map(arrObj => {
                                                if (arrObj.lTitle === o.learnId) {
                                                    lfeLearningResults[i].lEmpCount = arrObj.lEmpCount
                                                    lfeLearningResults[i].lFollow = arrObj.lFollow
                                                    lfeLearningResults[i].lRate = arrObj.lRate
                                                    lfeLearningResults[i].lShare = arrObj.lShare
                                                    lfeLearningResults[i].lLike = arrObj.lLike
                                                    lfeLearningResults[i].lRepCount = arrObj.lRepCount
                                                }
                                            })
                                            learningDocs.forEach(arrObj => {
                                                if (arrObj.lfilePath.includes(o.learnId)) {
                                                    lfeLearningResults[i].lfileType = arrObj.lfileType
                                                    lfeLearningResults[i].lfilePath = arrObj.lfilePath
                                                    lfeLearningResults[i].lDocTitle = arrObj.lDocTitle
                                                    lfeLearningResults[i].lfileType = arrObj.lfileType
                                                }
                                            })
                                        });
                                        var allData: any = [...lfeLearningResults];
                                        let sortedData = allData.sort(function (x: any, y: any) {
                                            const a: any = new Date(x.Created);
                                            const b: any = new Date(y.Created);
                                            return a - b;
                                        });
                                        sortedData.reverse();
                                        setLearningData([...sortedData]);
                                        if (selectedMenu === 4) {
                                            setSearchResponse([...sortedData]);
                                            setFilteredData(sortedData.slice(0, 10));
                                        }
                                        setLoading(false);
                                    }
                                },
                            }
                        );
                    }
                }
            }, 2000)
        }
        else {
            setLoading(false);
        }
        return deferred;
    }

};

export default SearchResults;