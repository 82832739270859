import React, {useState} from 'react';
import Card from 'react-bootstrap/Card'
import './menuModaldelete.scss'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import {FaArrowLeft} from 'react-icons/fa';
import  helpers  from '../helpers';

const MenuModaldelete = (props:any) => {
    let c = 0;
    const deleteMainCategoriesconfmessage = <div>Are you sure you want to delete the below selected <b>Main Link Category</b>, which will also delete its <b>Link Categories</b> and respective <b>Links</b> added to it?</div>
    const deletelinksconfmessage = <div>Are you sure you want to delete the below selected  <b>Link Category</b>, which will also delete the  <b>Links</b> added it?</div>;
    const deletesublinksconfmessage = <div>Are you sure you want to delete the below selected <b>Link</b>?</div>;
    const mainlinsdeletemessage = "No links Present"
    const [deleteView, setDeleteView] = useState("");
    const [toDelete, setDelete]:any = useState([]);
    const [toDeleteMain, setDeleteMain] = useState([]);
    const [subcategorydisp , setsubcategorydisp] = useState([]);
    const { linksDelete, showDialog, dialogType, MainlinkscatDelete,  getData, setShowLoading , getLinksForHeader} = props;
    const [linkstoDelete , setlinksDelete] = useState(linksDelete);
    const [MainlinksDelete , setMainlinksDelete] = useState(MainlinkscatDelete);
    const [deletemessage, setdeletemessage] = useState("");
    //const [pagescrollheight , setpagescrollheight] = useState(scrollheight);
    return(
       
        <div className = 'mainsubandlinksdelete'>
            
            <Modal size="lg" id="menu-modal-delete" show={showDialog} onHide={() => onDialogClose(false)} backdrop="static" onShow = {() => {
                 setlinksDelete(linksDelete);
                 setMainlinksDelete(MainlinkscatDelete);
                 //setpagescrollheight(scrollheight);
            }}>
            <div id="category-wrapper-delete" className={(deleteView == "DeleteMainCategory" ? "active" : "") || (deleteView == "Deletelinks" ? "active" : "") || (deleteView == "DeleteCategory" ? "active" : "")}>
            
                <Modal.Header closeButton>
                    <FaArrowLeft onClick={() => {
                        setDeleteView('')
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-link-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-link-card-height');
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-DeleteCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-DeleteCategory-card-height');
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-DeleteMainCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-DeleteMainCategory-card-height');
                        document.querySelector("#menu-modal-delete .modal-content .deletecontent").classList.remove('deletecontent-DeleteMainCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .deletecontent").classList.remove('deletecontent-DeleteMainCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .Categorylinks").classList.add('to-remove');
                        document.querySelector("#menu-modal-delete .modal-content .to-remove").classList.remove('Categorylinks');
                        setTimeout( ()=>  { document.querySelector("#menu-modal-delete .modal-content .to-remove").classList.add('Categorylinks') } , 500);
                        
                        //document.querySelector("#menu-modal-delete .modal-content .deletecategoryname").classList.remove('deletecategoryname-DeleteMainCategory-height');
                        }} className="arrow-back-categorydelete"/>  <Modal.Title className = 'deletemodeltitle'>Delete Links / Categories</Modal.Title>
                    <div className='emptydiv'></div>
                    {/* <Button  className = 'crosssimbol' onClick={ () => {
                        setDeleteView('');
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-link-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-link-card-height');
                     } }> X</Button> */}
                </Modal.Header>
                <Modal.Body className = {deleteView === ""? 'modaldeleteinactive': 'modaldeleteactive'}>
                     <Form className="form-controlsdelete">
                        <div className='deleteconfmessage'>{deleteView == "DeleteCategory" ?
                                deletelinksconfmessage : deleteView == 'DeleteMainCategory' ? deleteMainCategoriesconfmessage : deletesublinksconfmessage
                                    }</div><br></br>
                                    <div className='deletecontent'>{
                                         <div className = 'deletecontentdiv'>
                                            
                                               {deleteView == "DeleteMainCategory" ?   <div className = 'deletecategoryname'>  
                                               
                                               <div className = 'DeleteMainCategoryconfirm'>{toDeleteMain}</div>
                                                {
                                                    
                                                Object.keys(linkstoDelete).map((subCategory: any, subCategoryIndex: number) => {
                                                        if(subCategory !== "ID" && subCategory !== "Description" && subCategory !== "Title" && subCategory !== "SpoAOrder"){
                                                            return (
                                                                <div className = 'SubCategorylinksconfirmDeleteMainCategor'>
                                                                <div className = 'SubLinkCategoryDeleteMainCategor'>
                                                                    <div  className = 'Sublink-title-Delete'> { subCategory } </div>
                                                                </div>
                                                                <div className = 'all-links-in-sublinkconfirmDeleteMainCategory'>
                                                                {linkstoDelete[subCategory].map((link: any, linkIndex: number) => {
                                                                return(
                                                                    <div className= "delete-sublinkDeleteMainCategor">
                                                                            <div id={ `link-title-`  + linkIndex } className = 'linkTitleDeleteMainCategor'> { link.Title } </div>
                                                                        </div>
                                                                        )
                                                                })
                                                            }
                                                            </div>
                                                            </div>
                                                        
                                                        )
                                                            }else {
                                                                return ('')
                                                            } 
                                                    })
                                                }
                                               </div> : ''}
                                               {deleteView == "DeleteCategory" ?   <div className = 'deletelinkcategoryname'> {
                                              
                                                <div>
                                                <div className = 'DeleteCategoryconfirmDeleteCategory'>{subcategorydisp}</div>
                                                
                                                <div>
                                                <div className = 'all-links-in-sublinkconfirmDeleteCategory'>
                                                {toDelete.map((link: any, linkIndex: number) => {
                                                               return(
                                                                   <div className= "delete-sublinkDeleteCategory">
                                                                        <div id={ `link-title-`  + linkIndex } className = 'linkTitleDeleteCategory'> { link.Title } </div>
                                                                    </div>
                                                                    )
                                                            })
                                                           }
                                                
                                                
                                                </div>
                                                </div>
                                                </div>
                                                }
                                                 </div> : ''}

                                                 {deleteView == "Deletelinks" ?   <div className = 'deletelinkconfirmDeletelinks'>{ toDelete.Title }</div> : ''}

                                                 
                                         </div>
                                       
                                    }</div>
                                   
                                </Form>
                            </Modal.Body>
                <Modal.Footer className="modal-footer-categorydelete">
                    <Button  className = 'deleteCancel' variant="outline-secondary" onClick={ () => {
                        setDeleteView(''); 
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-link-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-link-card-height');
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-DeleteCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-DeleteCategory-card-height');
                        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-DeleteMainCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-DeleteMainCategory-card-height');
                        document.querySelector("#menu-modal-delete .modal-content .deletecontent").classList.remove('deletecontent-DeleteMainCategory-height');
                        document.querySelector("#menu-modal-delete .modal-content .deletecontent").classList.remove('deletecontent-DeleteMainCategory-height');
                        }}>Cancel</Button>
                    {deleteView == "DeleteMainCategory" ?
                    <Button className ="btn-danger button-delete-menudelete" variant="primary" onClick={ () => deleteitemsinsharepointlists(linkstoDelete)}>Delete</Button>
                    : ''}
                     {deleteView == "DeleteCategory" ?
                    <Button className ="btn-danger button-delete-menudelete" variant="primary" onClick={ () => deleteitemsinsharepointlists(toDelete)}>Delete</Button>
                    : ''}
                    {deleteView == "Deletelinks" ?
                    <Button className ="btn-danger button-delete-menudelete" variant="primary" onClick={ () => deleteitemsinsharepointlists(toDelete)}>Delete</Button>
                    : ''}
                </Modal.Footer> 
            </div>

            

                <Modal.Header  closeButton>
                    <Modal.Title>Delete Links / Categories </Modal.Title>
                    {/* <Button className = 'deleteclosex' variant="primary" onClick={() => onDialogClose(false)}>
                        X
                    </Button> */}
                </Modal.Header>
                
                <Modal.Body className = {deleteView === ""? 'modaldeleteactive': 'modaldeleteinactive'}>
                    {
                
                        <Card className="card-container" key={`accord-card` + MainlinksDelete}>
                         {
                            MainlinksDelete === "" ?
                            <div className = 'MainLinkCategorydeletemessage'>{mainlinsdeletemessage}</div> 
                            :
                            Object.keys(MainlinksDelete).map((mainCategory: any, subCategoryIndex: number) => {
                              
                             if(mainCategory == "Title"){
                               
                                return (
                                        <div className = 'MainLinkCategory'  key={'links-to-del-main-cat' + subCategoryIndex}>
                                            <div className = {MainlinksDelete.Title.length > 52 ?'Categorylinkslong':'Categorylinks'}>{MainlinksDelete.Title}</div>
                                            <div className = {MainlinksDelete.Title.length > 52 ?'deleteCategorylong':'deleteCategory'}><div className = 'onDeleteMainLinkCategory' onClick={(evt) =>{evt.stopPropagation();initDelete('DeleteMainCategory',"",linkstoDelete)}}>Delete Main Link Category</div></div>
                                        </div>
                                        )
                                    }else {
                                        return ("")
                                      }
                             
                                    })
                        } 
                        <Card.Body className="links-wrapperdelete">
                                                {
                                                   Object.keys(linkstoDelete).map((subCategory: any, subCategoryIndex: number) => {
                                                   
                                                    if(subCategory !== "ID" && subCategory !== "Description" && subCategory !== "Title" && subCategory !== "SpoAOrder"){
                                                        return (
                                                            <div className = 'SubCategorylinks' key={'links-to-del-cat' + subCategoryIndex}>
                                                            <div className = 'SubLinkCategory'>
                                                                <div id={ `Sublink-title-` + subCategoryIndex } className = {subCategory.length > 52 ? "long-subcategory-name":"short-subcategory-name"}> { subCategory } </div>
                                                                <div className ={subCategory.length > 52 ? "deleteCategorylong":"deleteCategory"}><div className = "onDeleteLinkCategory" onClick={(evt) =>{evt.stopPropagation();initDelete('DeleteCategory',subCategory, linkstoDelete)}}>Delete Link Category</div></div>
                                                            </div>
                                                            <div className = 'all-links-in-sublink'>
                                                            {linkstoDelete[subCategory].map((link: any, linkIndex: number) => {
                                                                if(link.Title != null)
                                                                {
                                                               return(
                                                                   <div className= "delete-sublink" key={'links-to-del-' + linkIndex}>
                                                                        <div id={ `link-title-`  + linkIndex } className = {link.Title.length > 52 ? "linkTitlelong":"linkTitle"}> { link.Title } </div>
                                                                        <div className ={link.Title.length > 52 ? "delete-subcategorylong":'delete-subcategory'}><div className = "onDeleteLink" onClick={(evt) =>{evt.stopPropagation();initDelete('Deletelinks',"",link)}}>Delete Link</div></div>
                                                                    </div>
                                                                    )
                                                               }
                                                            })
                                                           }
                                                           </div>
                                                        </div>
                                                     
                                                    )
                                                        }else {
                                                            return ('')
                                                          } 
                                                 
                                                })
                                                }
                                            </Card.Body>
                        </Card>
                    }
                        
                </Modal.Body>
                <Modal.Footer>
                    <Button className = 'deleteclose' variant="outline-secondary" onClick={() => onDialogClose(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    
   
    function initDelete( DeleteCategory:string, childitem:any, links:any)
    {
        setDeleteView(DeleteCategory);
        if(DeleteCategory === "DeleteMainCategory")
        {
            setDeleteMain(MainlinksDelete.Title);
            setDelete(links[childitem]);
            setsubcategorydisp(childitem);

            document.querySelector("#menu-modal-delete .modal-content").classList.add('delete-DeleteMainCategory-height');
            document.querySelector("#menu-modal-delete .modal-content .card-container").classList.add('delete-DeleteMainCategory-card-height');
            document.querySelector("#menu-modal-delete .modal-content .deletecontent").classList.add('deletecontent-DeleteMainCategory-height');
            //document.querySelector("#menu-modal-delete .deleteconten .deletecategoryname").classList.add('deletecategoryname-DeleteMainCategory-height');
        }
        else if(DeleteCategory === "DeleteCategory")
        {
            setDeleteMain(MainlinksDelete.Title);
            setDelete(links[childitem]);
            setsubcategorydisp(childitem);

            document.querySelector("#menu-modal-delete .modal-content").classList.add('delete-DeleteCategory-height');
            document.querySelector("#menu-modal-delete .modal-content .card-container").classList.add('delete-DeleteCategory-card-height');

        }else
        {
            document.querySelector("#menu-modal-delete .modal-content").classList.add('delete-link-height');
            document.querySelector("#menu-modal-delete .modal-content .card-container").classList.add('delete-link-card-height');
            
            setDelete(links);
        }
        
    }
    
    function deleteitemsinsharepointlists(itemstodelete:any)
    {
        let tempLinkDelete = linksDelete;
        let MainLinkCategoryID =[];
        let SubLinkCategoryID = [];
        let SubLinkCategoryname = '';
        let LinksID = [];
        if(deleteView == "DeleteMainCategory")
        {
            MainLinkCategoryID = itemstodelete.ID;
            Object.keys(linkstoDelete).forEach((subCategory: any) => {
                if(subCategory !== "ID" && subCategory !== "Description" && subCategory !== "Title" && subCategory !== "SpoAOrder")
                {
                    
                            SubLinkCategoryID.push(itemstodelete[subCategory].ID);

                            itemstodelete[subCategory].map((linkid: any) => {
                                LinksID.push(linkid.ID);
                                deleteitemsinsharepointlist("Links",linkid.ID)
                            })
                  
                     deleteitemsinsharepointlist("Link Categories",itemstodelete[subCategory].ID)   
                    }else 
                    {
                       
                    } 
                })
                deleteitemsinsharepointlist("Main Link Categories",itemstodelete.ID)
                setlinksDelete("");
                setdeletemessage(mainlinsdeletemessage);
                setMainlinksDelete("");
                      
        }
        if(deleteView == "DeleteCategory")
        {
            SubLinkCategoryID = itemstodelete.ID;
            Object.keys(tempLinkDelete).forEach((subCategory: any) => {
                if(subCategory !== "ID" && subCategory !== "Description" && subCategory !== "Title" && subCategory !== "SpoAOrder"){
                    if(tempLinkDelete[subCategory].ID === SubLinkCategoryID)
                    {
                        SubLinkCategoryname = subCategory;
                    }
                           
                    }else {
                       
                    } 
                })
            
            itemstodelete.map((linkid: any) => {
                if(linkid !== "ID"){
                LinksID.push(linkid.ID);
                deleteitemsinsharepointlist("Links",linkid.ID)
                }
                else {
                   
                } 
            })
            delete tempLinkDelete[SubLinkCategoryname]
           deleteitemsinsharepointlist("Link Categories",itemstodelete.ID)
            setlinksDelete(tempLinkDelete);
        }
        if(deleteView == "Deletelinks")
        {
            LinksID.push(itemstodelete.ID);
            
            let subcat =""
            let linkidtodeleteinarray;
            Object.keys(tempLinkDelete).forEach((o) => {
                if(o !== "ID" && o !== "Description" && o !== "Title" && o !== "SpoAOrder"){
                    tempLinkDelete[o].map( z => {
                        if(z.ID === itemstodelete.ID) {
                            tempLinkDelete[o].splice(tempLinkDelete[o].indexOf(z), 1);
                        }
                    })
                }
            })
            deleteitemsinsharepointlist("Links",itemstodelete.ID)
            setlinksDelete(tempLinkDelete);
        }

        
        setDeleteView('')
        setShowLoading(true);
        getData();
        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-link-height');
        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-link-card-height');
        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-DeleteCategory-height');
        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-DeleteCategory-card-height');
        document.querySelector("#menu-modal-delete .modal-content").classList.remove('delete-DeleteMainCategory-height');
        document.querySelector("#menu-modal-delete .modal-content .card-container").classList.remove('delete-DeleteMainCategory-card-height');
        document.querySelector("#menu-modal-delete .modal-content .deletecontent").classList.remove('deletecontent-DeleteMainCategory-height');
        //document.querySelector("#menu-modal-delete .modal-content .deletecategoryname").classList.remove('deletecategoryname-DeleteMainCategory-height');  
        getLinksForHeader();
    }
function deleteitemsinsharepointlist(listname,MainLinkCategoryIDtodelete)
{
    helpers.deletelinks(listname,function(){},MainLinkCategoryIDtodelete)
}   



    function onDialogClose(passData: boolean) {
        props.handleClose();
       
        setDeleteView('');
        setShowLoading(true);
        // setTimeout( () => { getData();  }, 4000)
        getData();  
        
    }
    
}

export default MenuModaldelete