import React, { useState } from "react";
import helpers from '../helpers';
import './editCustomExpertsHeader.scss';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";


const EditCustomExpertsHeader = (props: any) => {
    const { showDialog, setDialog, expertHeaders, getManualExpertsTabData, tabHeaderId, isHeaderVisible } = props;

    const [header1Value, setHeader1Value] = useState(expertHeaders[1] ? expertHeaders[1] : 'Column 1');
    const [header2Value, setHeader2Value] = useState(expertHeaders[2] ? expertHeaders[2] : 'Column 2');
    const [header3Value, setHeader3Value] = useState(expertHeaders[0] ? expertHeaders[0] : 'Expert Name');
    const [header4Value, setHeader4Value] = useState(expertHeaders[3] ? expertHeaders[3] : 'Column 4');
    const [header5Value, setHeader5Value] = useState(expertHeaders[4] ? expertHeaders[4] : 'Column 5');
    const [header6Value, setHeader6Value] = useState(expertHeaders[5] ? expertHeaders[5] : 'Column 6');
    const [header7Value, setHeader7Value] = useState(expertHeaders[6] ? expertHeaders[6] : 'Column 7');

    const [header1Length, setHeader1Length] = useState(header1Value ? header1Value.length : 0);
    const [header2Length, setHeader2Length] = useState(header2Value ? header2Value.length : 0);
    const [header3Length, setHeader3Length] = useState(header3Value ? header3Value.length : 0);
    const [header4Length, setHeader4Length] = useState(header4Value ? header4Value.length : 0);
    const [header5Length, setHeader5Length] = useState(header5Value ? header5Value.length : 0);
    const [header6Length, setHeader6Length] = useState(header6Value ? header6Value.length : 0);
    const [header7Length, setHeader7Length] = useState(header7Value ? header7Value.length : 0);

    const [header1VisibleValue, setHeader1VisisbleValue] = useState(isHeaderVisible[1] === "true" ? true : false);
    const [header2VisibleValue, setHeader2VisisbleValue] = useState(isHeaderVisible[2] === "true" ? true : false);
    const [header3VisibleValue, setHeader3VisisbleValue] = useState(isHeaderVisible[0] === "true" ? true : false);
    const [header4VisibleValue, setHeader4VisisbleValue] = useState(isHeaderVisible[3] === "true" ? true : false);
    const [header5VisibleValue, setHeader5VisisbleValue] = useState(isHeaderVisible[4] === "true" ? true : false);
    const [header6VisibleValue, setHeader6VisisbleValue] = useState(isHeaderVisible[5] === "true" ? true : false);
    const [header7VisibleValue, setHeader7VisisbleValue] = useState(isHeaderVisible[6] === "true" ? true : false);

    const [header1DisableColumn, setHeader1DisableColumn] = useState(isHeaderVisible[1] === "true" ? false : true);
    const [header2DisableColumn, setHeader2DisableColumn] = useState(isHeaderVisible[2] === "true" ? false : true);
    const [header3DisableColumn, setHeader3DisableColumn] = useState(isHeaderVisible[0] === "true" ? false : true);
    const [header4DisableColumn, setHeader4DisableColumn] = useState(isHeaderVisible[3] === "true" ? false : true);
    const [header5DisableColumn, setHeader5DisableColumn] = useState(isHeaderVisible[4] === "true" ? false : true);
    const [header6DisableColumn, setHeader6DisableColumn] = useState(isHeaderVisible[5] === "true" ? false : true);
    const [header7DisableColumn, setHeader7DisableColumn] = useState(isHeaderVisible[6] === "true" ? false : true);


    const [showProcessing, setShowProcessing] = useState(<span>Save Headers</span>);
    const [validated, setvalidated] = useState(false);

    return (
        <Modal id="edit-custom-experts-header" aria-labelledby="contained-modal-title-vcenter"
            centered show={showDialog} onHide={() => setDialog(false)}
            backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Edit Table Header</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form noValidate validated={validated}>
                    <Form.Group>
                        <Form.Label>Column 1</Form.Label>
                        <Form.Control
                            required disabled={header1DisableColumn}
                            placeholder="Enter column 1 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header1Value}
                            maxLength={50}
                            autoFocus
                            onChange={(e) => { setHeader1Value(e.currentTarget.value); setHeader1Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header1Length ? "" + (50 - header1Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Check
                        type="switch"
                        id="custom-switch-1"
                        label="Show/Hide Column 1"
                        title=""
                        checked={header1VisibleValue}
                        onClick={() => { header1VisibleValue ? setHeader1VisisbleValue(false) : setHeader1VisisbleValue(true); header1DisableColumn ? setHeader1DisableColumn(false) : setHeader1DisableColumn(true); }} />

                    <Form.Group>
                        <Form.Label>Column 2</Form.Label>
                        <Form.Control
                            required disabled={header2DisableColumn}
                            placeholder="Enter column 2 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header2Value}
                            maxLength={50}
                            autoFocus
                            onChange={(e) => { setHeader2Value(e.currentTarget.value); setHeader2Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header2Length ? "" + (50 - header2Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Check
                        type="switch"
                        id="custom-switch-2"
                        label="Show/Hide Column 2"
                        title=""
                        checked={header2VisibleValue}
                        onClick={() => { header2VisibleValue ? setHeader2VisisbleValue(false) : setHeader2VisisbleValue(true); header2DisableColumn ? setHeader2DisableColumn(false) : setHeader2DisableColumn(true); }} />
                    <Form.Group>
                        <Form.Label>Column 3</Form.Label>
                        <Form.Control
                            required
                            placeholder="Enter column 3 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header3Value}
                            maxLength={50}
                            autoFocus
                            onChange={(e) => { setHeader3Value(e.currentTarget.value); setHeader3Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header3Length ? "" + (50 - header3Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Column 4</Form.Label>
                        <Form.Control
                            required
                            placeholder="Enter column 4 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header4Value}
                            maxLength={50}
                            autoFocus disabled={header4DisableColumn}
                            onChange={(e) => { setHeader4Value(e.currentTarget.value); setHeader4Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header4Length ? "" + (50 - header4Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Check
                        type="switch"
                        id="custom-switch-4"
                        label="Show/Hide Column 4"
                        title=""
                        checked={header4VisibleValue}
                        onClick={() => { header4VisibleValue ? setHeader4VisisbleValue(false) : setHeader4VisisbleValue(true); header4DisableColumn ? setHeader4DisableColumn(false) : setHeader4DisableColumn(true); }} />

                    <Form.Group>
                        <Form.Label>Column 5</Form.Label>
                        <Form.Control
                            required
                            placeholder="Enter column 5 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header5Value}
                            maxLength={50}
                            autoFocus disabled={header5DisableColumn}
                            onChange={(e) => { setHeader5Value(e.currentTarget.value); setHeader5Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header5Length ? "" + (50 - header5Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Check
                        type="switch"
                        id="custom-switch-5"
                        label="Show/Hide Column 5"
                        title=""
                        checked={header5VisibleValue}
                        onClick={() => { header5VisibleValue ? setHeader5VisisbleValue(false) : setHeader5VisisbleValue(true); header5DisableColumn ? setHeader5DisableColumn(false) : setHeader5DisableColumn(true); }} />
                    <Form.Group>
                        <Form.Label>Column 6</Form.Label>
                        <Form.Control
                            required
                            placeholder="Enter column 6 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header6Value}
                            maxLength={50}
                            autoFocus disabled={header6DisableColumn}
                            onChange={(e) => { setHeader6Value(e.currentTarget.value); setHeader6Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header6Length ? "" + (50 - header6Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Check
                        type="switch"
                        id="custom-switch-6"
                        label="Show/Hide Column 6"
                        title=""
                        checked={header6VisibleValue}
                        onClick={() => { header6VisibleValue ? setHeader6VisisbleValue(false) : setHeader6VisisbleValue(true); header6DisableColumn ? setHeader6DisableColumn(false) : setHeader6DisableColumn(true); }} />
                    <Form.Group>
                        <Form.Label>Column 7</Form.Label>
                        <Form.Control
                            required
                            placeholder="Enter column 7 here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={header7Value}
                            maxLength={50}
                            autoFocus disabled={header7DisableColumn}
                            onChange={(e) => { setHeader7Value(e.currentTarget.value); setHeader7Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {header7Length ? "" + (50 - header7Length) + " characters remaining" : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Check
                        type="switch"
                        id="custom-switch-7"
                        label="Show/Hide Column 7"
                        title=""
                        checked={header7VisibleValue}
                        onClick={() => { header7VisibleValue ? setHeader7VisisbleValue(false) : setHeader7VisisbleValue(true); header7DisableColumn ? setHeader7DisableColumn(false) : setHeader7DisableColumn(true); }} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-cancel-menu" variant="secondary" onClick={() => { setDialog(false) }}> Cancel </Button>
                <Button variant="primary" className="proceed-button" onClick={() => { updateHeaders() }}>{showProcessing}</Button>
            </Modal.Footer>
        </Modal>
    );

    function updateHeaders() {

        setShowProcessing(<span>Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
        let toUpdate = {
            SPoALongConfigValue: header3Value.trim() + ";" + header1Value.trim() + ";" + header2Value.trim() + ";" + header4Value.trim() + ";" + header5Value.trim() + ";" + header6Value.trim() + ";" + header7Value.trim(),
            SPoATableHeaderVisible: "true;" + header1VisibleValue + ";" + header2VisibleValue + ";" + header4VisibleValue + ";" + header5VisibleValue + ";" + header6VisibleValue + ";" + header7VisibleValue,
        };
        helpers.updateData('Discipline Configuration', toUpdate, tabHeaderId, function () {
            getManualExpertsTabData();
            setShowProcessing(<span>Save Headers</span>);
        });

    }
};

export default EditCustomExpertsHeader;