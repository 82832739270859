import React, { useState, useEffect, useRef } from 'react';
import './aboutContact.scss';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import helpers from '../helpers';
import AboutEdit from './aboutEdit';
import Skeleton from 'react-loading-skeleton';
import { FaArrowLeft } from 'react-icons/fa';
import Select from 'react-select';
import { AppInsights } from "applicationinsights-js";

const AboutContact = (props: any) => {
    let count = 0;
    let timer;
    const [editAbout, setEditAbout] = useState<boolean>(false);
    const { siteName, isModerator, editMode, show, onHide } = props;
    const [modalShow, setModalShow] = useState<boolean>(show);
    const [welcomeDesc, setWelcomeDesc] = useState('');
    const [expert, setExpert] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editContact, setEditContact] = useState(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Contact</span>);
    const [options, setOptions] = useState([]);
    const [contactId, setContactId] = useState();
    const [userContactEmail, setUserContactEmail] = useState();
    const [desc, setDesc] = useState('');
    const [descLength, setDescLength] = useState(0);
    const [removeText, setremoveText] = useState({ 0: <span>Remove</span>, 1: <span>Remove</span> });
    const [validated, setvalidated] = useState(false);
    const [noContactId, setNoContactId] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [loadingContact, setLoadingContact] = useState(false);
    const [removingContact, setRemovingContact] = useState(false);

    useEffect(() => {
        getItems();
        setOptions([]);
        /* Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key */
        AppInsights.downloadAndSetup({ instrumentationKey: helpers.appinsightsinstrumentationKey, maxBatchSizeInBytes: 0 });

        // Sent a page view to Application Insights
        //helpers.getUser((e: any) => {
        //    AppInsights.trackPageView(
        //        document.title,
        //        window.location.href + '/AboutContact',
        //        { userEmail: e.Email },
        //        null,
        //    );
        //});

    }, []);

    return (
        <Modal id="about-us-and-contact" onShow={() => getItems()} onHide={onHide}
            backdrop="static"
            show={show}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton onClick={() => {
                closeWindow()
            }}>
                {editAbout && editMode ? (
                    <FaArrowLeft
                        onClick={() => {
                            setEditAbout(false);
                        }}
                        className="arrow-back-category"
                    />
                ) : null}
                {editContact ?
                    <Modal.Title>
                        <FaArrowLeft onClick={() => { setEditContact(false); count > 0 ? getItems() : setEditContact(false); }} className="arrow-back" />Edit Contacts
                    </Modal.Title> :
                    <Modal.Title id="contained-modal-title-vcenter">
                        About {siteName}
                    </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <div id='about-edit' className={editAbout ? "active-content" : ""}><AboutEdit htmlData={welcomeDesc} getItems={getItems} onHide={() => setEditAbout(false)} /></div>
                <div id='about-section-wrapper' className={editContact || editAbout ? 'not-active' : ''}> <div className='about-section'><div className='about-text' dangerouslySetInnerHTML={{ __html: welcomeDesc }}></div>
                    <div className="edit-link">{isModerator && editMode ? <Link to='#' onClick={() => setEditAbout(true)}>Edit</Link> : null}  </div></div>
                    <div className='contact-section'>
                        <div className='contact-label'> <p><b>Contact:</b></p><span> <p>Note:</p><p> If you have any queries, feel free to reach out to your Community Focal Point, or visit our <a href="https://eu001-sp.shell.com/:w:/r/sites/AAAAA0345/PublicDocuments/SPoA%203.0%20FAQ_4.docx?d=w7f4b19d9210f4227b5d0de1948ea9612&csf=1&web=1&e=o04dcb" target="_blank">FAQs.</a> section. <br /><br /><a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMzQxNDYwMzc3NiJ9/all" target="_blank">Click here</a>, to share your feedback/suggestions about SpoA3.0.</p></span></div>
                        {expert.map((item: any, index: number) => {
                            return (
                                <div key={index} className='expert-details' >
                                    {!loading ?
                                        <div>
                                            <div>
                                                <img className='userIMG' src={item.UserDispImg} alt='Moderator Name'></img>
                                                <div className='expert-title' onClick={() => { window.open(item.UserDispLink, '_blank') }} >{item.DispName}</div>
                                                {item.Description !== "" && item.Description !== null ? <div className='expert-description'>{truncateString(item.Description, 70)}</div> : null}
                                            </div>
                                        </div>
                                        : <Skeleton />}
                                </div>)
                        }
                        )}
                        <div>{isModerator && editMode ? <span className='edit-button-contact' onClick={() => { setOptions([]); setEditContact(true); setDescLength(0); setDesc(null) }}>Edit</span> : null}</div>
                    </div>
                </div>
                <div id='edit-contacts' className={editContact ? 'active' : ''}>
                    <div className='exists-div'>
                        <span className='existing-contact'>Existing Contact:</span><br />
                        {expert.map((item: any, index: number) => { return (<span key={index}>&nbsp;&nbsp;{item.DispName}{expert.length > 1 ? <span className='remove-contact'><a onClick={() => deleteContact(item.ID, index)}>{removeText[index]}</a></span> : ''}<br /></span>) })}
                    </div>
                    {expert.length >= 2 ? <div className='add-many'><span>Please remove a contact to add another one</span></div> :
                        <div className='add-contacts'>
                            <span className='add-contacts-text'>Add Contact:</span>
                            <Form className='add-form' noValidate validated={validated}>
                                <Form.Group className='add-field'>
                                    <Form.Label>Name (LastName, FirstName) <span className="star">*</span></Form.Label>
                                    <Select isLoading={loadingContact} options={options} maxMenuHeight={100} onInputChange={(val) => {
                                        clearTimeout(timer);
                                        timer = setTimeout(() => {
                                            getOptions(val);
                                        }, 1000)

                                    }} onChange={(newVal) => handleChangeOptions(newVal)} placeholder='Enter LastName, FirstName of the person' required></Select>
                                    {/* {options.length > 0 ? "" : <i className="fa fa-spinner fa-pulse fa-fw"></i>} */}
                                    {noContactId ?
                                        <Form.Text className="text-nocontact">
                                            Please provide a valid Name/Email id
                                        </Form.Text> : ''}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Role <span className="star">*</span></Form.Label>
                                    <Form.Control type='input' pattern="^[a-zA-Z1-9].*" maxLength={50} placeholder='Enter Role of the person' onChange={(e) => { setDesc(e.currentTarget.value); setDescLength(e.currentTarget.value.length) }} required></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Role Description
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        {descLength ? "" + (50 - descLength) + " characters remaining" : "50 characters max"}
                                    </Form.Text>
                                </Form.Group>
                                {duplicate ? <Form.Text className="text-nocontact">
                                    User is already added as a contact
                                </Form.Text> : ''}
                            </Form>
                        </div>}
                </div>
            </Modal.Body>
            {!editAbout ? <Modal.Footer className='about-footer'>
                {editContact ? expert.length >= 2 ? '' :
                    <div className='contact-footer'>
                        <Button className="button-cancel-menu" variant="secondary" onClick={() => { setEditContact(false); count > 0 ? getItems() : setEditContact(false); }} >
                            Cancel
                        </Button>
                        <Button variant="primary" className='proceed-button' onClick={() => { saveContact() }}>
                            {showProcessing}
                        </Button></div> :
                    <Button className="button-cancel-menu" variant="secondary" onClick={onHide}>Close</Button>}
            </Modal.Footer> : null}
        </Modal>
    )

    function saveContact() {
        setNoContactId(false);
        setvalidated(false);
        let exists = false;
        expert.map(item => {
            if (item.DispName == contactId) {
                exists = true;
            }
        })

        if (contactId == null || contactId == undefined) {
            setNoContactId(true);
        }


        else if (desc == null || desc == undefined || desc.trim().length === 0) {
            setvalidated(true);
        }

        else if (exists) {
            setDuplicate(true);
            setOptions([]);
        }
        else {
            setNoContactId(false);
            setvalidated(false);
            setDuplicate(false);
            setShowProcessing(<span>Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);

            helpers.getWebEnsureUser(function (data) {
                console.log(data);
                let Obj = {
                    SpoAUserId: data.data.Id,
                    SpoADescription: desc,
                    SpoAExpertType: {
                        results: ['Is Contact']
                    }
                }
                helpers.addData('Experts', Obj, function () {
                    setShowProcessing(<span>Save Contact</span>);
                    getItems();
                    setEditContact(false);
                });

            }, userContactEmail);
        }
    }

    function deleteContact(id, indexRemove) {
        if (removingContact)
            return;

        setRemovingContact(true);
        let obj: any = { ...removeText };
        obj[indexRemove] = <span>Removing... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>
        setremoveText(obj);
        let removeItem;
        expert.map(item => {
            if (item.ID == id) {
                removeItem = item.SpoAExpertType;
            }
        })
        let index = removeItem.indexOf('Is Contact');
        if (index > -1)
            removeItem.splice(index, 1);

        helpers.updateData('Experts', { SpoAExpertType: { results: removeItem } }, id, function () {
            setExpert(expert.filter(item => item.ID != id));
            setContactId(null);
            setUserContactEmail(null);
            obj[indexRemove] = <span>Remove</span>
            setremoveText(obj);
            count++;
            setRemovingContact(false);
        });
    }

    function truncateString(str: string, num: number) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }

    function closeWindow() {
        setEditContact(false);
        setEditAbout(false);
    }

    function handleChangeOptions(newVal: any) {
        setContactId(newVal.value);
        setUserContactEmail(newVal.userAccname);
    }


    function getOptions(name: string) {
        if (!name) return;
        setLoadingContact(true);
        const searchName = name.replace(/^.{1}/g, name[0].toUpperCase());
        let allOptions = [];
        var searchUrl = helpers.appweburl + "/_api/search/query?querytext='" + searchName + "'&rowlimit=100&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'";
        var executor = new SP.RequestExecutor(helpers.appweburl);
        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchResultsSuccess,
                error: onGetSearchResultsFail
            }
        );

        //helpers.getUserNames(function (data) {
        //    data.map((item: any) => {
        //        allOptions.push({
        //            key: item.Id,
        //            value: item.Title,
        //            label: <div><img src={item.UserDispImg} className='img-add' />&nbsp;{item.Title}</div>
        //        })
        //    });
        //    console.log("pnp output: " + allOptions);
        //    //Before setting the options, filtering already added contacts
        //    setOptions(allOptions.filter(obj => expert.filter(selectedExperts => selectedExperts.SPoAUserId !== obj.key).length));
        //    setOptions(allOptions);
        //    setLoadingContact(false);
        //}, searchName)

    }

    function onGetSearchResultsSuccess(data) {
        let userEmail = '';
        let value = '';
        let userAccname = '';
        let allOptions = [];
        let jsonObject = JSON.parse(data.body);
        let results = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        if (results.length > 0 && results !== null) {
            for (let i = 0; i < results.length; i++) {
                userEmail = '';
                value = '';
                userAccname = '';

                for (var j = 0; j < results[i].Cells.results.length; j++) {
                    if (results[i].Cells.results[j].Key === "PreferredName") {
                        value = results[i].Cells.results[j].Value;
                    }
                    if (results[i].Cells.results[j].Key === "AccountName") {
                        userAccname = results[i].Cells.results[j].Value;
                    }
                    if (results[i].Cells.results[j].Key === "WorkEmail") {
                        userEmail = results[i].Cells.results[j].Value;
                    }
                }
                allOptions.push({
                    key: value,
                    value: value,
                    label: <div><img src={userEmail} className='img-add' />&nbsp;{value}</div>,
                    userAccname: userAccname,
                })
            }
        }
        console.log(allOptions);
        console.log("filter results: " + allOptions.filter(obj => expert.filter(selectedExperts => selectedExperts.DispName !== obj.key).length));
        setOptions(allOptions.filter(obj => expert.filter(selectedExperts => selectedExperts.DispName !== obj.key).length));
        setOptions(allOptions);
        setLoadingContact(false);
    }


    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }
    function getItems() {
        let aboutContactResponse = '';
        helpers.getAllData("SpoaWelcomeList", function (response) {
            aboutContactResponse = response[0].SPoAWelcomeDescription;
            aboutContactResponse = aboutContactResponse.replace(/<img src="[/]*sites/g, '<img src="' + helpers.rootSiteCollection);
            aboutContactResponse = aboutContactResponse.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection);
            aboutContactResponse = aboutContactResponse.replace(/<a href="[/&#58;x&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
            aboutContactResponse = aboutContactResponse.replace(/<a href="[/&#58;w&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
            aboutContactResponse = aboutContactResponse.replace(/<a href="[/&#58;p&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
            aboutContactResponse = aboutContactResponse.replace(/<a href="[/&#58;b&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
            setWelcomeDesc(aboutContactResponse);
        }, "SPoAWelcomeDescription");
        helpers.getAllData("Experts", function (response) {

            let allContacts = [];
            response.map((item: any) => {
                allContacts.push({
                    ID: item.ID,
                    DispName: item.SpoAUser.Title,
                    UserDispImg: helpers.sitecollectionurl + "/_layouts/15/userphoto.aspx?size=L&accountname=" + item.SpoAUser.Name.split("|")[2],
                    UserDispLink: helpers.sitecollectionurl + '/_layouts/userdisp.aspx?ID=' + item.SpoAUser.Id,
                    SPoAUserId: item.SpoAUser.Id,
                    Description: item.SpoADescription,
                    SpoAExpertType: item.SpoAExpertType
                })
            });
            setExpert(allContacts);
            setLoading(false);
        }, 'SpoAUser/Name,SpoAUser/Title,SpoAUser/Id,SpoADescription, ID, SpoAExpertType', 'SpoAUser', 'SpoAOrder', "SpoAExpertType eq 'Is Contact'", 2);
    }

}

export default AboutContact;