import React, { useEffect, useCallback, useReducer, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Banner from '../banner/Banner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Layout from 'react-grid-layout';
import GridLayout from 'react-grid-layout';
import Hidden from '@material-ui/core/Hidden';
import './Dashboard.scss';
import SignWidget from '../signWidget/SignWidget';
import WorkdayLearning from '../workdayLearning/WorkdayLearning';
import LfeWidget from '../lfeWidget/LfeWidget';
import LfiWidget from '../lfiWidget/LfiWidget';
import EeWidget from '../eeWidget/EeWidget';
import NewsWidget from '../newsWidget/NewsWidget';
import YammerWidget from '../yammerWidget/YammerWidget';
import YammerWidget1 from '../yammerWidget1/YammerWidget1';
import Events from '../events/Events';
import Experts from '../experts/Experts';
import AnnouncementWidget from '../announcementWidget/announcementWidget';
import CustomWebpartWidget from '../customWebpartWidget/customWebpartWidget';
import { SPFetchClient } from "@pnp/nodejs";
import { Session } from '@pnp/sp-taxonomy';
import { taxonomy } from "@pnp/sp-taxonomy";
import { useDispatch } from 'react-redux';
import { updateTermsStore } from '../../redux/actions';
import helpers from '../helpers';
import { sp as pnpsp } from '@pnp/sp';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import CustomWebpart from '../customWebpart/CustomWebpart';

const Dashboard = (params: any) => {

    const [minClasses, setMinClasses] = useState('');
    const [layout, setLayout] = useState(params.layout);
    const [response, setResponse] = useState([]);
    const [showLoading, setShowLoading] = useState(true);
    const dispatch = useDispatch();
    const isModRef = useRef(params.isModerator);
    isModRef.current = params.isModerator;

    useEffect(() => {
        onResize(JSON.parse(localStorage.layout))
    }, [params.editMode]);

    useEffect(() => {
        setLayout(params.layout)
    }, [params.layout]);

    useEffect(() => {
        //Localhost setup

        /*taxonomy.setup({
                            sp: {
                                fetchClientFactory: () => {
                                    return new SPFetchClient("https://shelldevelopment.sharepoint.com/sites/SPODA0019/CSO", "a48d4dc1-a362-4632-bc11-ceb4be63131e", "D8uRZQgzS6dNkkNsjU2+pCFt3ncb7Y34Ch1L6+zsnUQ=");
                                }
                            },
                        });
        
                        const _taxonomy = new Session('https://shelldevelopment.sharepoint.com/sites/SPODA0019');
                        const store: any = _taxonomy.termStores.getByName('Taxonomy_gDEyvD+7z7yw1UIiW72syA==');
                        store.getTermSetById('6dbfc9a3-9d84-49aa-bef7-53e188059280').terms.select('Name', 'Id', 'Parent').get()
                            .then((data: any[]) => {
                                dispatch(updateTermsStore(data));
        
                            }).catch(fail => {
                                console.warn(fail);
                            });*/


        //PHA Setup
        var executor = new SP.RequestExecutor(helpers.appweburl);
        executor.executeAsync({
            url: helpers.taxonomyURLAppWebURL + "/_api/web/getFileByServerRelativeUrl('" + helpers.taxonomyURL + "')/$value?binaryStringResponseBody=true",
            // url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/lists/getbytitle('Calendar')/items(" + iar.data.ID + ")/AttachmentFiles/add(FileName='" + fileAttachmentText + "')?@target='" + helpers.hostweburl + "'",
            method: "GET",
            headers: {
                "Accept": "application/json; odata=verbose"
            },
            success: function (data: any) {
                dispatch(updateTermsStore(JSON.parse(data.body)));
            },
            error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                console.log(thrownError);
            }
        });
    }, []);

    //useEffect(() => {
    //    setTimeout(() => {
    //        if (params.isSiteName !== undefined) {
    //            helpers.getUser((e: any) => {
    //                params.AppInsights.trackPageView(
    //                    params.isSiteName,
    //                    window.location.href,
    //                    { userEmail: e.Email },
    //                    null,
    //                );
    //            });
    //        } else {
    //            helpers.getUser((e: any) => {
    //                params.AppInsights.trackPageView(
    //                    document.title,
    //                    window.location.href,
    //                    { userEmail: e.Email },
    //                    null,
    //                );
    //            });
    //        }
    //    }, 9000);
    //}, []);


    useEffect(() => {
        setTimeout(() => {
            sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoASiteName").items.select("Title,SiteURL").top(4999).get().then((response: any) => {
                response.map(o => {
                    if (o.SiteURL.Url.toLowerCase() === helpers.hostweburl.toLowerCase()) {
                        helpers.getUser((e: any) => {
                            console.log("Entries added in App Insights - Dashboard page");
                            params.AppInsights.trackPageView(
                                o.Title,
                                window.location.href,
                                { userEmail: e.Email },
                                null,
                            );
                        });
                    }
                });
            });
        }, 3000);
    }, []);

    function onUpdate(layout: Layout) {
        // window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Section eq 'Layout'").update(data).then((iar: any) => {
        //     _callback(iar);
        // }).catch(e => {
        //     UserAutheticateCall();
        // });

        // window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Section eq 'Layout'").get().then((res) => {


        //   }).catch(e => {
        //     console.error('Fetching query failed!')
        //   });
        localStorage.setItem("layout", JSON.stringify(layout));
    }

    function onResize(e) {
        if (e === undefined) return
        //.sign-min, .lfe-min, .lfi-min, .ee-min
        //3 = d = Sign Widget
        //4 = e = Lfe widget
        //5 = f = Lfi Widget
        //6 = g = Ee widget

        setMinClasses('')
        if ((e[3].i != "null") && (e[3].w < 12)) {
            setMinClasses(classes => classes + ' sign-min');
        }
        else {
            setMinClasses(classes => classes.replace('sign-min', ''));
        }

        if ((e[4].i != "null") && (e[4].w < 12)) {
            setMinClasses(classes => classes + ' lfe-min');
        }
        else {
            setMinClasses(classes => classes.replace('lfe-min', ''));
        }

        if ((e[5].i != "null") && (e[5].w < 12)) {
            setMinClasses(classes => classes + ' lfi-min');
        }
        else {
            setMinClasses(classes => classes.replace('lfi-min', ''));
        }

        if ((e[6].i != "null") && (e[6].w < 12)) {
            setMinClasses(classes => classes + ' ee-min');
        }
        else {
            setMinClasses(classes => classes.replace('ee-min', ''));
        }
        if ((e[17].i != "null") && (e[17].w < 12)) {
            setMinClasses(classes => classes + ' workday-min');
        }
        else {
            setMinClasses(classes => classes.replace('workday-min', ''));
        }
    }

    return (

        <Container id="dashboard" className={minClasses}>
            <Row>
                <Col>
                    <GridLayout onDragStart={() => {
                        if (document.querySelector('.fade.modal.show')) {
                            return false;
                        }
                    }} onResize={(e) => onResize(e)} compactType="vertical" className={"layout " + (params.editMode ? "edit-layout" : "")} layout={layout} cols={12} rowHeight={30} width={1100} onLayoutChange={(obj: any) => onUpdate(obj)}>
                        {(!params.editMode && params.webpartVisibilty["Carousel"]) ?
                            <Hidden>
                                <div key="a"></div>
                            </Hidden>
                            :
                            <div key="a">
                                <Banner editMode={params.editMode} AppInsights={params.AppInsights} userEmail={params.userEmail} hidden={params.webpartVisibilty["Carousel"]} />
                            </div>
                        }
                        {(!params.editMode && params.webpartVisibilty["Upcoming Events"]) ?
                            <Hidden>
                                <div key="b">
                                    <div className="box">
                                    </div>
                                </div>
                            </Hidden>
                            :
                            <div key="b">
                                <div className="box">
                                    <Events editMode={params.editMode} AppInsights={params.AppInsights} userEmail={params.userEmail} hidden={params.webpartVisibilty["Upcoming Events"]} />
                                    {/* { params.editMode ? <FiMoreVertical /> : <a href="#a">View All</a> } */}

                                </div>
                            </div>
                        }
                        {
                            (!params.editMode && params.webpartVisibilty["Experts"]) ?
                                <Hidden>
                                    <div key="c">
                                        <div className="box">
                                            <header></header>
                                        </div>
                                    </div>
                                </Hidden>
                                :
                                <div key="c">
                                    <div className="box">
                                        <header>
                                            <Experts editMode={params.editMode} setActive={params.setActive} AppInsights={params.AppInsights} userEmail={params.userEmail} hidden={params.webpartVisibilty["Experts"]} isModerator={params.isModerator} />
                                        </header>
                                    </div>
                                </div>
                        }
                        {
                            (!params.editMode && params.webpartVisibilty["SIGN3.0"]) ?
                                <Hidden>
                                    <div key="d">
                                    </div>
                                </Hidden>
                                :
                                <div key="d">
                                    <SignWidget editMode={params.editMode} isModerator={params.isModerator} />
                                </div>

                        }
                        {
                            (!params.editMode && params.webpartVisibilty["LFE3.0"]) ?
                                <Hidden>
                                    <div key="e">
                                    </div>
                                </Hidden>
                                :
                                <div key="e">
                                    <LfeWidget editMode={params.editMode} isModerator={params.isModerator} />
                                </div>
                        }
                        {
                            <Hidden>
                                <div key="f" >
                                </div>
                            </Hidden>
                        }
                        {
                            (!params.editMode && params.webpartVisibilty["EE3.0"]) ?
                                <Hidden>
                                    <div key="g">
                                    </div>
                                </Hidden>
                                :
                                <div key="g">
                                    <EeWidget editMode={params.editMode} isModerator={params.isModerator} />
                                </div>
                        }
                        {
                            (!params.editMode && params.webpartVisibilty["Yammer"]) ?
                                <Hidden>
                                    <div key="h">
                                    </div>
                                </Hidden>
                                :
                                <div key="h">
                                    <YammerWidget editMode={params.editMode} isModerator={params.isModerator} hide={params.webpartVisibilty["Yammer"]} AppInsights={params.AppInsights} userEmail={params.userEmail} />
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[0] && params.response[0].IsVisible)) ?
                                <Hidden>
                                    <div key="i" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="i" className={!params.response[0] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[0] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[0]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>

                        }
                        {
                            (!params.editMode && (params.response[1] && params.response[1].IsVisible)) ?
                                <Hidden>
                                    <div key="j" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="j" className={!params.response[1] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[1] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[1]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[2] && params.response[2].IsVisible)) ?
                                <Hidden>
                                    <div key="k" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="k" className={!params.response[2] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[2] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[2]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[3] && params.response[3].IsVisible)) ?
                                <Hidden>
                                    <div key="l" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="l" className={!params.response[3] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[3] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[3]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[4] && params.response[4].IsVisible)) ?
                                <Hidden>
                                    <div key="m" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="m" className={!params.response[4] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[4] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[4]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[5] && params.response[5].IsVisible)) ?
                                <Hidden>
                                    <div key="n" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="n" className={!params.response[5] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[5] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[5]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[6] && params.response[6].IsVisible)) ?
                                <Hidden>
                                    <div key="o" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="o" className={!params.response[6] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[6] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[6]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }
                        {
                            (!params.editMode && (params.response[7] && params.response[7].IsVisible)) ?
                                <Hidden>
                                    <div key="p" className="custom-webpart-hide">
                                    </div>
                                </Hidden> :
                                <div key="p" className={!params.response[7] ? "custom-webpart-hide" : "custom-webpart-active"}>
                                    {params.response[7] && <CustomWebpartWidget editMode={params.editMode} isModerator={params.isModerator} response={params.response[7]} showLoading={params.showLoading} getCustomWebpart={params.getCustomWebpart} setShowLoading={params.setShowLoading} AppInsights={params.AppInsights} userEmail={params.userEmail} />}
                                </div>
                        }

                        {
                            (!params.editMode && params.webpartVisibilty["Announcements"]) ?
                                <Hidden>
                                    <div key="q">
                                    </div>
                                </Hidden>
                                :
                                <div key="q">
                                    <div className="box">
                                        <header>
                                            <AnnouncementWidget editMode={params.editMode} setActive={params.setActive} AppInsights={params.AppInsights} userEmail={params.userEmail} hidden={params.webpartVisibilty["Announcements"]} isModerator={params.isModerator} />
                                        </header>
                                    </div>
                                </div>
                        }
                        {
                            (!params.editMode && params.webpartVisibilty["WorkDay"]) ?
                                <Hidden>
                                    <div key="r">
                                    </div>
                                </Hidden>
                                :
                                <div key="r">
                                    <WorkdayLearning editMode={params.editMode} isModerator={params.isModerator} />
                                </div>
                        }
                        {
                            (!params.editMode && params.webpartVisibilty["YammerDetails1"]) ?
                                <Hidden>
                                    <div key="s">
                                    </div>
                                </Hidden>
                                :
                                <div key="s">
                                    <YammerWidget1 editMode={params.editMode} isModerator={params.isModerator} hide={params.webpartVisibilty["YammerDetails1"]} AppInsights={params.AppInsights} userEmail={params.userEmail} />
                                </div>
                        }
                    </GridLayout>
                </Col>
            </Row>
        </Container>

    );

}



export default Dashboard;