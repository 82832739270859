import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AllExperts from "../allExperts/AllExperts";
import { Link, useLocation } from "react-router-dom";
import "./leadershipTabs.scss";
import Leadership from "./leadership";
import Mentors from "../mentors/mentors";
import AllDisciplineMembers from "../allDisciplineMembers/allDisciplineMembers";
import CustomExperts from '../customExperts/customExperts'
import helpers from "../helpers";
import EditTabsModal from "./editTabsModal";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";

const LeadershipTabs = (props: any) => {
    let location = useLocation();
    const [selectedTab, setSelectedTab] = useState('');
    const [allTabs, setAllTabs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [leaderCategories, setLeaderCategories] = useState([]);
    const [otherRoleCategories, setOtherRoleCategories] = useState([]);
    const [allLeaders, setAllLeaders] = useState([]);
    const [otherRoleLeaders, setOtherRoleLeaders] = useState([]);
    const [expertsListMode, setExpertsListMode] = useState('');
    const [allExperts, setAllExperts] = useState([]);
    const CUSTOM_TAB_DEFAULT_HEADERS_VISIBLE = "true;true;true;true;true;true;true";
    //Const value for Mentors and AllDiscipline members tab
    const recordsPerPage = 10; //This will decide the pagination count.
    const [mentorsSearchResponse, setMentorsSearchResponse] = useState([]);
    const [queryVal, setQueryVal] = useState(null);
    const [mentorsLoading, setMentorsLoading] = useState(true);
    const [allDisciplineLoading, setAllDisciplineLoading] = useState(true);
    const [allDisciplineMembersSearchResponse, setAllDisciplineMembersSearchResponse] = useState([]);

    const [excelGroupedLeadershipData, setExcelGroupedLeadershipData] = useState([]);
    const [excelGroupedOtherRolesData, setExcelGroupedOtherRolesData] = useState([]);

    let tabTitleConfig = [
        {
            SPTitle: "OurTeamLeadershipTabTitle",
            isDefault: true,
        },
        {
            SPTitle: "OurTeamExpertsTabTitle",
            isDefault: true,
        },
        {
            SPTitle: "OurTeamMentorsTabTitle",
            isDefault: true,
        },
        {
            SPTitle: "OurTeamAllDisciplineMembersTabTitle",
            isDefault: true,
        },
        {
            SPTitle: "OurTeamOtherDisciplineRolesTabTitle",
            isDefault: true,
        },
    ];

    const { editMode } = props;
    const [editTabsModal, setEditTabsModal] = useState(false);

    useEffect(() => {
        //props.AppInsights.trackPageView(
        //    document.title,
        //    window.location.href,
        //    { userEmail: props.userEmail },
        //    null,
        //);
        setTimeout(() => {
            sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoASiteName").items.select("Title,SiteURL").top(4999).get().then((response: any) => {
                response.map(o => {
                    if (o.SiteURL.Url.toLowerCase() === helpers.hostweburl.toLowerCase()) {
                        helpers.getUser((e: any) => {
                            console.log("Entries added in App Insights - Leadership and team page");
                            props.AppInsights.trackPageView(
                                o.Title,
                                window.location.href,
                                { userEmail: e.Email },
                                null,
                            );
                        });
                    }
                });
            });
        }, 3000);
        getItems();
        if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
            //Load the Empty mentors and All discipline memebers tab for local setup
        }
        else {
            //Load the Mentors data
            getMentorsfromSearch();
            //Load the All Discipline Memebers data
            getAllDisciplineMembersfromSearch();
        }
    }, []);

    return (
        <div id="leadership-tab">
            <Container>
                {!loading ? (
                    <Row>
                        <Col>
                            {<AllTabs />}
                            {editMode ? (
                                <Link
                                    className="editTabsLink"
                                    to="#"
                                    onClick={() => setEditTabsModal(true)}
                                >
                                    Edit Tabs
                                </Link>
                            ) : null}
                        </Col>

                        {
                            <EditTabsModal
                                show={editTabsModal}
                                onHide={() => setEditTabsModal(false)}
                                allTabs={allTabs}
                                getItems={getItems}
                                allExperts={allExperts}
                            />
                        }
                    </Row>
                ) : (
                    <div className="tabs-loading">
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={40}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={30}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={100}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={100}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={100}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={100}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={100}></Skeleton>{" "}
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={100}></Skeleton>{" "}
                        </SkeletonTheme>

                    </div>
                )}
            </Container>
        </div>
    );

    function getItems() {
        window.scrollTo(0, 0);
        setLoading(true);
        helpers.getAllData("Discipline Configuration", function (response) {
            let expertMode = response.filter(t => t.Title === "ExpertsListMode")[0].SpoAConfigValue;
            setExpertsListMode(expertMode);

            let tabsData = [];
            // Push default Tabs
            tabTitleConfig.forEach((tab: any) => {
                response.forEach((item: any) => {
                    if (tab.SPTitle === item.Title) {
                        tabsData.push({
                            SPTitle: tab.SPTitle,
                            ID: item.ID,
                            Name: item.SpoAConfigValue,
                            isDefault: tab.isDefault,
                            showTab: item.Required,
                            headers: item.SPoALongConfigValue ? item.SPoALongConfigValue.split(";") : null,
                            isVisible: item.SPoATableHeaderVisible ? item.SPoATableHeaderVisible.split(";") : CUSTOM_TAB_DEFAULT_HEADERS_VISIBLE.split(";"),
                            tabMode: null,
                            order: item.SpoAOrder,
                        });
                    }
                });
            });

            //Push user defined Tabs;
            response.map((item: any) => {
                if (item.Title.indexOf("OurTeamCustomTab_") === 0) {
                    let tabmode;
                    let efmlcolumns;
                    let queryfilter;
                    let efmlConfig = response.find(t => t.Title == item.SpoAConfigValue + "Mode");
                    if (efmlConfig) {
                        tabmode = efmlConfig.SpoAConfigValue;
                        if (efmlConfig.SPoALongConfigValue) {
                            efmlcolumns = efmlConfig.SPoALongConfigValue.split(";filter:")[0].split("select:")[1];
                            queryfilter = efmlConfig.SPoALongConfigValue.split(";filter:")[1];
                        }
                    }

                    tabsData.push({
                        SPTitle: item.Title,
                        ID: item.ID,
                        Name: item.SpoAConfigValue,
                        isDefault: false,
                        showTab: item.Required,
                        headers: item.SPoALongConfigValue ? item.SPoALongConfigValue.split(";") : null,
                        isVisible: item.SPoATableHeaderVisible ? item.SPoATableHeaderVisible.split(";") : CUSTOM_TAB_DEFAULT_HEADERS_VISIBLE.split(";"),
                        tabMode: tabmode ? tabmode : null,
                        efmlColumns: efmlcolumns,
                        queryFilter: queryfilter,
                        order: item.SpoAOrder,
                    });
                }
            });
            tabsData.sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
            setSelectedTab((location.state && location.state.tabname === "experts") ? 'OurTeamExpertsTabTitle' : tabsData[0].SPTitle);
            setAllTabs(tabsData);
            setTimeout(() => setLoading(false), 3000);
        });

        helpers.getAllChoices("Experts", "SpoALeaderGroup", function (choices) {
            setLeaderCategories(choices);
        })

        helpers.getAllChoices("Experts", "SpoAOtherDiscplineGroup", function (choices) {
            setOtherRoleCategories(choices);
        })

        helpers.getAllData("Experts", ((leaders: []) => {
            const expertLeaders = [];
            const otherLeaders = [];
            const expertLeaderGroup = [];
            const otherLeaderGroup = [];
            //Fetching all the experts and filtering here based on tabs.
            leaders.map((o: any) => {
                if (o.SpoAExpertType && o.SpoAExpertType.includes('Leader')) {
                    expertLeaders.push(o);
                    o.SpoALeaderGroup.map((leadergroup: any) => {
                        expertLeaderGroup.push({
                            ExpertName: o.SpoAUser.Title,
                            LeaderGroup: leadergroup,
                            Title: o.SpoALeaderType,
                            Description: o.SpoADescription
                        })
                    })

                }
                if (o.SpoAExpertType && o.SpoAExpertType.includes('Other Role')) {
                    otherLeaders.push(o);
                    o.SpoAOtherDiscplineGroup.map((otherleadergroup: any) => {
                        otherLeaderGroup.push({
                            ExpertName: o.SpoAUser.Title,
                            LeaderGroup: otherleadergroup,
                            Title: o.SpoALeaderType,
                            Description: o.SpoADescription
                        })
                    })
                }
            })
            setAllLeaders(expertLeaders);
            setExcelGroupedLeadershipData(expertLeaderGroup);
            setExcelGroupedOtherRolesData(otherLeaderGroup);
            setOtherRoleLeaders(otherLeaders);
        }), ["Id", "Title", "SpoADescription", "SpoALeaderType", "SpoALeaderGroup", "SpoAOtherDiscplineGroup", "SpoAExpertType", "SpoAUser/EMail", "SpoAUser/Title", "SpoAUser/Id", "SpoAOrder"], "SpoAUser/Id", ["SpoAOrder"]);

        getManualExpertsTabData();
    }



    function getManualExpertsTabData() {
        window.scrollTo(0, 0);
        helpers.getAllData("Manual Experts", ((experts: []) => {
            setAllExperts(experts);
        }), ["Id", "Expert_x0020_Name/Title", "Expert_x0020_Name/EMail", "Expert_x0020_Name/Id", "Column_x0020_1", "Column_x0020_2", "Column_x0020_3", "Column_x0020_4", "Column_x0020_5", "Column_x0020_6", "Tab_x0020_Name"], "Expert_x0020_Name/Id");
    }

    function AllTabs() {
        let tabComponent;
        return (
            <Tabs defaultActiveKey={selectedTab} onSelect={o => setSelectedTab(o)} id="leadership-all-tabs">
                {allTabs.map((item: any, index: number) => {
                    switch (item.SPTitle) {
                        case "OurTeamLeadershipTabTitle": {
                            tabComponent = <Leadership editMode={editMode} leaderCategories={leaderCategories} allLeaders={allLeaders} getItems={getItems} addTo={'Leader'} loading={loading} tabDisplayName={item.Name} excelLeadershipOtherRolesData={excelGroupedLeadershipData} />;
                            if (selectedTab === item.SPTitle) {
                                sendToAppInsights('Leadership');
                            }
                            break;
                        }
                        case "OurTeamExpertsTabTitle": {
                            if (expertsListMode === "EFML") {
                                tabComponent = <AllExperts editMode={editMode} component={AllExperts} tabDisplayName={item.Name} />
                                if (selectedTab === item.SPTitle) {
                                    sendToAppInsights('EFML Experts');
                                }
                            }
                            else
                                tabComponent = <CustomExperts editMode={editMode} allExperts={allExperts} expertHeaders={item.headers} getManualExpertsTabData={getItems} tabHeaderId={item.ID} cusomtTabName={item.SPTitle} tabDisplayName={item.Name} isHeaderVisible={item.isVisible} />
                            if (selectedTab === item.SPTitle) {
                                sendToAppInsights('Custom Experts');
                            }
                            break;
                        }
                        case "OurTeamMentorsTabTitle": {
                            tabComponent = (
                                <Mentors mentorsSearchResponse={mentorsSearchResponse} queryVal={queryVal} mentorsLoading={mentorsLoading} tabDisplayName={item.Name} userEmail={props.userEmail} />
                            );
                            if (selectedTab === item.SPTitle) {
                                sendToAppInsights('Mentors');
                            }
                            break;
                        }
                        case "OurTeamAllDisciplineMembersTabTitle": {
                            tabComponent = (
                                <AllDisciplineMembers allDisciplineMembersSearchResponse={allDisciplineMembersSearchResponse} allDisciplineLoading={allDisciplineLoading} tabDisplayName={item.Name} />
                            );
                            if (selectedTab === item.SPTitle) {
                                sendToAppInsights('All Discipline Members');
                            }
                            break;
                        }
                        case "OurTeamOtherDisciplineRolesTabTitle": {
                            tabComponent = <Leadership editMode={editMode} leaderCategories={otherRoleCategories} allLeaders={otherRoleLeaders} getItems={getItems} addTo={'Other Role'} loading={loading} tabDisplayName={item.Name} excelLeadershipOtherRolesData={excelGroupedOtherRolesData} />;
                            if (selectedTab === item.SPTitle) {
                                sendToAppInsights('Other Discipline Roles');
                            }
                            break;
                        }
                        default: {
                            tabComponent = <CustomExperts editMode={editMode} allExperts={allExperts} expertHeaders={item.headers} getManualExpertsTabData={getItems} tabHeaderId={item.ID} cusomtTabName={item.SPTitle} tabMode={item.tabMode} efmlColumns={item.efmlColumns} efmlFilter={item.queryFilter} tabDisplayName={item.Name} isHeaderVisible={item.isVisible} />
                            if (selectedTab === item.SPTitle) {
                                sendToAppInsights('Custom Tab');
                            }
                            break;
                        }
                    }
                    if (item.showTab || !item.isDefault) {
                        return (
                            <Tab key={index} eventKey={item.SPTitle} title={item.Name}>
                                {tabComponent}
                            </Tab>
                        )
                    }

                })}
            </Tabs>
        );
    }

    //Load the Mentors data based on the configuration
    function getMentorsfromSearch() {

        helpers.getSearchresult("Discipline Configuration", ((items: Array<{
            SpoAConfigValue: string
        }>) => {
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let disc_configured_query = items[i].SpoAConfigValue;
                    if (disc_configured_query !== "null" && disc_configured_query !== "" && disc_configured_query !== null) {
                        var searchUrl = helpers.appweburl + "/_api/search/query?querytext='" + disc_configured_query + " AND refinableboolean00:1'&sortlist='RefinableString42:ascending'&rowlimit=4999&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'";
                        var executor = new SP.RequestExecutor(helpers.appweburl);
                        executor.executeAsync(
                            {
                                url: searchUrl,
                                method: "GET",
                                headers: { "Accept": "application/json; odata=verbose" },
                                success: onGetSearchResultsSuccess,
                                error: onGetSearchResultsFail
                            }
                        );
                        setQueryVal(disc_configured_query);
                        //setMentorsQuery(disc_configured_query);
                    }
                    else {
                        setMentorsLoading(false);
                    }
                }
            } else {
                setMentorsLoading(false);
            }
        }), "Title,SpoAConfigValue", "Title eq 'OurTeamMentorsQuery'");

    }

    function onGetSearchResultsSuccess(data) {
        let jsonObject = JSON.parse(data.body);
        let items = [];
        let user_ask_me_about = '';
        let user_skills = '';
        let user_intersets = '';
        let user_schools = '';
        let user_members = '';
        let User_AboutMe_popup = '';
        let User_Display_Name = '';
        let User_Job_Title = '';
        let User_Department = '';
        let User_EmailID = '';
        let User_Pic_URL = '';
        let User_Profile_Link = '';
        let results = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        if (results.length > 0 && results !== null) {
            for (let i = 0; i < results.length; i++) {
                user_ask_me_about = '';
                user_skills = '';
                user_intersets = '';
                user_schools = '';
                user_members = '';
                User_AboutMe_popup = '';
                User_Display_Name = '';
                User_Job_Title = '';
                User_Department = '';
                User_EmailID = '';
                User_Pic_URL = '';
                User_Profile_Link = '';
                for (var j = 0; j < results[i].Cells.results.length; j++) {
                    if (results[i].Cells.results[j].Key === "Responsibilities") {
                        if (results[i].Cells.results[j].Value === "" && results[i].Cells.results[j].Value !== null) {
                            if (results[i].Cells.results[j].Key === "Skills") {
                                if (results[i].Cells.results[j].Value !== "") {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Skills: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                            else if (results[i].Cells.results[j].Key === "Interests") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Interests: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                            else if (results[i].Cells.results[j].Key === "Schools") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Schools: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                            else if (results[i].Cells.results[j].Key === "PastProjects") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Past Projects: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Responsibilities") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_ask_me_about = results[i].Cells.results[j].Value;
                            user_ask_me_about = "Ask me about: " + user_ask_me_about.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Skills") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_skills = results[i].Cells.results[j].Value;
                            user_skills = user_skills.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Interests") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            console.log("Intersets: " + results[i].Cells.results[j].Value);
                            user_intersets = results[i].Cells.results[j].Value;
                            user_intersets = user_intersets.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Schools") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_schools = results[i].Cells.results[j].Value;
                            user_schools = user_schools.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Memberships") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_members = results[i].Cells.results[j].Value;
                            user_members = user_members.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Responsibilities") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_AboutMe_popup = results[i].Cells.results[j].Value;
                            User_AboutMe_popup = User_AboutMe_popup.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "PreferredName") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            console.log("User Name: " + results[i].Cells.results[j].Value);
                            User_Display_Name = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "JobTitle") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Job_Title = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Department") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Department = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "WorkEmail") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_EmailID = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "PictureURL") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Pic_URL = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Path") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Profile_Link = results[i].Cells.results[j].Value;
                        }
                    }
                }
                items.push({
                    User_DisplayName: User_Display_Name,
                    User_JobTitle: User_Job_Title,
                    User_Department: User_Department,
                    User_Email: User_EmailID,
                    User_AboutMe: user_ask_me_about,
                    User_AboutMe_popup: User_AboutMe_popup,
                    User_Skills: user_skills,
                    User_Intersets: user_intersets,
                    User_Schools: user_schools,
                    User_Members: user_members,
                    User_PicURL: User_Pic_URL,
                    User_ProfileLink: User_Profile_Link,
                });
            }
            setMentorsSearchResponse(items);
            setMentorsLoading(false);
        }
        else {
            setMentorsLoading(false);
        }
    }

    //Load the All discipline members data based on the configuration
    function getAllDisciplineMembersfromSearch() {

        helpers.getSearchresult("Discipline Configuration", ((items: Array<{
            SpoAConfigValue: string
        }>) => {
            console.log(items);
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let disc_configured_query = items[i].SpoAConfigValue;
                    if (disc_configured_query !== "null" && disc_configured_query !== "" && disc_configured_query !== null) {
                        console.log(items[i].SpoAConfigValue);
                        var searchUrl = helpers.appweburl + "/_api/search/query?querytext='" + disc_configured_query + "'&sortlist='RefinableString42:ascending'&rowlimit=4999&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'";
                        var executor = new SP.RequestExecutor(helpers.appweburl);
                        executor.executeAsync(
                            {
                                url: searchUrl,
                                method: "GET",
                                headers: { "Accept": "application/json; odata=verbose" },
                                success: onGetalldiscplineSearchResultsSuccess,
                                error: onGetSearchResultsFail
                            }
                        );
                    }
                    else {
                        setAllDisciplineLoading(false);
                    }
                }
            } else {
                setAllDisciplineLoading(false);
            }
        }), "Title,SpoAConfigValue", "Title eq 'OurTeamAllDisciplineMembersQuery'");
    }

    function onGetalldiscplineSearchResultsSuccess(data) {
        let jsonObject = JSON.parse(data.body);
        let items = [];
        let user_ask_me_about = '';
        let user_skills = '';
        let user_intersets = '';
        let user_schools = '';
        let user_members = '';
        let User_AboutMe_popup = '';
        let User_Display_Name = '';
        let User_Job_Title = '';
        let User_Department = '';
        let User_EmailID = '';
        let User_Pic_URL = '';
        let User_Profile_Link = '';
        let results = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        if (results.length > 0 && results !== null) {
            for (let i = 0; i < results.length; i++) {
                user_ask_me_about = '';
                user_skills = '';
                user_intersets = '';
                user_schools = '';
                user_members = '';
                User_AboutMe_popup = '';
                User_Display_Name = '';
                User_Job_Title = '';
                User_Department = '';
                User_EmailID = '';
                User_Pic_URL = '';
                User_Profile_Link = '';
                for (var j = 0; j < results[i].Cells.results.length; j++) {
                    if (results[i].Cells.results[j].Key === "Responsibilities") {
                        if (results[i].Cells.results[j].Value === "" && results[i].Cells.results[j].Value !== null) {
                            if (results[i].Cells.results[j].Key === "Skills") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Skills: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                            else if (results[i].Cells.results[j].Key === "Interests") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Interests: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                            else if (results[i].Cells.results[j].Key === "Schools") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Schools: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                            else if (results[i].Cells.results[j].Key === "PastProjects") {
                                if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                                    user_ask_me_about = results[i].Cells.results[j].Value;
                                    user_ask_me_about = "Past Projects: " + user_ask_me_about.replace(/;/g, "; ");
                                }
                            }
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Responsibilities") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_ask_me_about = results[i].Cells.results[j].Value;
                            user_ask_me_about = "Ask me about: " + user_ask_me_about.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Skills") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_skills = results[i].Cells.results[j].Value;
                            user_skills = user_skills.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Interests") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_intersets = results[i].Cells.results[j].Value;
                            user_intersets = user_intersets.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Schools") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_schools = results[i].Cells.results[j].Value;
                            user_schools = user_schools.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Memberships") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            user_members = results[i].Cells.results[j].Value;
                            user_members = user_members.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Responsibilities") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_AboutMe_popup = results[i].Cells.results[j].Value;
                            User_AboutMe_popup = User_AboutMe_popup.replace(/;/g, "; ");
                        }
                    }
                    if (results[i].Cells.results[j].Key === "PreferredName") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Display_Name = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "JobTitle") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Job_Title = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Department") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Department = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "WorkEmail") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_EmailID = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "PictureURL") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Pic_URL = results[i].Cells.results[j].Value;
                        }
                    }
                    if (results[i].Cells.results[j].Key === "Path") {
                        if (results[i].Cells.results[j].Value !== "" && results[i].Cells.results[j].Value !== null) {
                            User_Profile_Link = results[i].Cells.results[j].Value;
                        }
                    }
                }
                items.push({
                    User_DisplayName: User_Display_Name,
                    User_JobTitle: User_Job_Title,
                    User_Department: User_Department,
                    User_Email: User_EmailID,
                    User_AboutMe: user_ask_me_about,
                    User_AboutMe_popup: User_AboutMe_popup,
                    User_Skills: user_skills,
                    User_Intersets: user_intersets,
                    User_Schools: user_schools,
                    User_Members: user_members,
                    User_PicURL: User_Pic_URL,
                    User_ProfileLink: User_Profile_Link,
                });
            }
            setAllDisciplineMembersSearchResponse(items);
            setAllDisciplineLoading(false);
        }
        else {
            setAllDisciplineLoading(false);
        }
    }

    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }

    function sendToAppInsights(webpartname: string) {
        props.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: webpartname + ' Web Part',
            WebPartHeader: webpartname,
            UserEmail: props.userEmail
        });
        props.AppInsights.flush();
    }

};

export default LeadershipTabs;
