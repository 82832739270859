import React, { useState } from "react";
import "./leadershipTabs.scss";
import { Button, Modal, Form } from "react-bootstrap";
import helpers from "../helpers";
import { FaArrowLeft } from "react-icons/fa";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import Sortable from 'sortablejs';

const EditTabsModal = (props: any) => {
    const { show, onHide, allTabs, getItems, allExperts } = props;
    const [itemID, setItemID] = useState('');
    const [itemName, setItemName] = useState("");
    const [editTab, setEditTab] = useState(false);
    const [displayTab, setDisplayTab] = useState(true);
    const [isDefaultTab, setIsDefaultTab] = useState(false);
    const [addTab, setAddTab] = useState(false);
    const [deleteTab, setDeleteTab] = useState(false);
    const [tabEdits, setTabEdits] = useState(false);
    const [tabLength, setTabLength] = useState(0);
    const [showProcessing, setShowProcessing] = useState(<span>Save Tab</span>);
    const [duplicateTabName, setDuplicateTabName] = useState(false);
    const [noTabName, setNoTabName] = useState(false);
    const [validated, setValidated] = useState(false);
    const [customTabExperts, setCustomTabExperts] = useState([]);
    const [dragWait, setDragWait] = useState<JSX.Element>(null);
    const [closeButton, setCloseButton] = useState<JSX.Element>(<span>Close</span>);
    const [isSaving, setIsSaving] = useState(false);


    let tabEditMode = editTab || addTab || deleteTab;
    let editTabsHeaderLabel = "Edit Tabs";
    const CUSTOM_TAB_DEFAULT_HEADERS = "Expert Name;Column 1;Column 2;Column4;Column5;Column6;Column7";
    const CUSTOM_TAB_DEFAULT_HEADERS_VISIBLE = "true;true;true;true;true;true;true";
    editTabsHeaderLabel = (addTab ? "Add New Tab" : deleteTab ? "Delete Tab" : "Edit Tab");


    setTimeout(() => {
        let menuElements = document.getElementsByClassName('onhandle-tabs') as any;
        menuElements.forEach((z: any) => {
            Sortable.create(z, {
                animation: 500,
                ghostClass: 'blue-background-class',
                onEnd: function (evt: any) {
                    setDragWait(<i className="fa fa-spinner fa-pulse fa-fw"></i>)
                },
                handle: '.edit-tabs-wrapper-before',
                dragClass: 'dragging',
                chosenClass: 'dragging',
                store: {
                    get: function (sortable) {
                        return [];
                    },
                    set: function (sortable) {
                        let temp = sortable.toArray();
                        let itemsToUpdate = [];
                        temp.forEach((item: any, linkIndex: number) => {
                            allTabs.map((menuItem: any) => {
                                if (menuItem.ID == item) {
                                    itemsToUpdate.push({
                                        itemID: menuItem.ID,
                                        itemOrder: linkIndex
                                    })
                                }
                            })
                        })
                        setIsSaving(true);
                        setCloseButton(<span>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                        
                        itemsToUpdate.forEach((menuItem: any) => {
                            helpers.updateData('Discipline Configuration', { SpoAOrder: menuItem.itemOrder }, menuItem.itemID, function () {
                                setIsSaving(false);
                                setCloseButton(<span>Close</span>);
                            })
                        })
                        setTabEdits(true);
                    }
                }
            });
        });
    }, 1000);


    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="edit-tabs"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <div id="modify-tab-wrapper" className={tabEditMode ? "active-edit-tab" : ""} >
                <Modal.Header closeButton>
                    {tabEditMode ? (
                        <FaArrowLeft
                            onClick={() => {
                                cancelOperation();
                            }}
                            className="arrow-back-category"
                        />
                    ) : null}
                    <Modal.Title>
                        {editTabsHeaderLabel}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="edit-tab-modal">
                    {deleteTab ? <DeleteTabDialog /> :
                        <Form noValidate validated={validated}>
                            <Form.Group>
                                <Form.Label>Tab Name</Form.Label>
                                <span className="star">*</span>
                                <Form.Control
                                    placeholder="Enter new tab name here"
                                    pattern="^[a-zA-Z1-9].*"
                                    type="input"
                                    value={itemName}
                                    onChange={(e: any) => {
                                        setItemName(e.currentTarget.value);
                                        setTabLength(e.currentTarget.value.length);
                                    }}
                                    maxLength={50}
                                    required
                                    autoFocus
                                ></Form.Control>
                                {noTabName ?
                                    <Form.Text className="tabname-validate">
                                        Please provide a valid tab name</Form.Text> : ''}
                                {duplicateTabName ?
                                    <Form.Text className="tabname-validate">
                                        Tab already exists. Kindly try with a different tab name</Form.Text> : ''}
                                <Form.Text className="text-muted">
                                    {tabLength
                                        ? "" + (50 - tabLength) + " characters remaining"
                                        : "50 characters max"}
                                </Form.Text>
                            </Form.Group>
                            {(isDefaultTab && editTab) ? <Form.Check
                                type="switch"
                                id="custom-switch-2"
                                label="Show Tab"
                                title=""
                                checked={displayTab}
                                onClick={() => { displayTab ? setDisplayTab(false) : setDisplayTab(true) }}
                            /> : null}
                        </Form>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="button-cancel-menu"
                        variant="secondary"
                        onClick={() => cancelOperation()}
                    >
                        Cancel
                    </Button>
                    {tabEditMode ? (
                        <Button
                            variant="primary"
                            className={deleteTab ? "btn-danger" : "proceed-button"}
                            onClick={() => {
                                editTab
                                    ? updateTabName()
                                    : deleteTab
                                        ? removeTab(itemID)
                                        : addTabName(itemName);
                            }}
                        >
                            {showProcessing}
                        </Button>
                    ) : null}
                </Modal.Footer>
            </div>

            <div id="edit-tabs-wrapper" className={tabEditMode ? "not-active-tabs" : ""}>
                <Modal.Header closeButton>
                    {tabEditMode ? (
                        <FaArrowLeft
                            onClick={() => {
                                setEditTab(false);
                                setAddTab(false);
                                setDeleteTab(false);
                            }}
                            className="arrow-back-category"
                        />
                    ) : null}
                    <Modal.Title>
                        Edit Tabs
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button
                        className="add-link-btn"
                        variant="link"
                        onClick={() => handleAddTab()}
                        disabled={allTabs.length > 7}
                    >
                        <MdAddCircleOutline /> Add New Tab
                    </Button>
                    <br />
                    <div className='onhandle-tabs' >
                        {allTabs.map((item: any, index: number) => {
                            return (
                                <div className='tab-handler-edit' data-id={item.ID} key={index}>
                                    <div className="edit-tabs-wrapper-before"></div>
                                    <tr key={index} className="tab-row">
                                        <td className={(item.isDefault && !item.showTab) && "hidden-tab"}>{item.Name}</td>
                                        <td>
                                            {<span className="default-text">{item.isDefault ? 'Default' : ' '}</span>}
                                            <MdCreate
                                                className="edit-link-icon"
                                                onClick={() => handleEditTab(item.Name, item.ID, item.isDefault, item.showTab)}
                                            />
                                            {!item.isDefault ? (
                                                <GiTrashCan
                                                    className="delete-link-icon"
                                                    onClick={() => handleDeleteTab(item.Name, item.ID, item.SPTitle)}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="button-cancel-menu"
                        variant="secondary"
                        onClick={() => handleClose()}
                    >
                        {closeButton}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );


    function DeleteTabDialog() {
        return (
            <div>
                <div id="delete-tab-conf">
                    Are you sure you want to remove the below tab, which will remove the
                    experts mapped to this tab?
                </div>
                <div className="delete-tab-title">{itemName}</div>
                <div className="delete-tab-experts">
                    {customTabExperts ?
                        customTabExperts.map((expert: any, index: number) => {
                            return (
                                <div key={index} className={`expert-title-` + index}>{expert.Expert_x0020_Name.Title}</div>
                            )
                        })
                        : ''
                    }
                </div>
            </div>
        );
    }

    async function updateTabName() {
        setNoTabName(false);
        setDuplicateTabName(false);
        let exists = false;
        allTabs.filter(x => x.ID != itemID).map(item => {
            if (item.Name === itemName.trim()) {
                exists = true;
            }
        })
        if (itemName.trim() === '') {
            setValidated(true);
            setNoTabName(true);
        }
        else if (exists) {
            setDuplicateTabName(true);
        }
        else {
            setNoTabName(false);
            setDuplicateTabName(false);
            setShowProcessing(
                <span>
                    Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            );
            let toUpdate = { SpoAConfigValue: itemName.trim(), Required: displayTab };
            let list = window['SP_Propertyweb'].lists.getByTitle("Discipline Configuration");
            const items = await list.items.get();
            let prevName = items.find(t => t.Id === itemID).SpoAConfigValue;
            helpers.updateData("Discipline Configuration", toUpdate, itemID, async function (
                iar: any
            ) {
                if (!isDefaultTab) {
                    let configItemID = items.find(t => t.Title === prevName + "Mode").Id;
                    const i = await list.items.getById(configItemID).update({ Title: itemName + "Mode" });
                }
                setShowProcessing(<span>Save Tab</span>);
                let tabToUpdate = allTabs.find(t => t.ID === itemID);
                tabToUpdate.Name = itemName.trim();
                tabToUpdate.showTab = displayTab;
                setTabEdits(true);
                setEditTab(false);
            });
        }
    }

    function cancelOperation() {
        setEditTab(false);
        setAddTab(false);
        setDeleteTab(false);
        setNoTabName(false);
        setDuplicateTabName(false);
        setValidated(false);
    }

    function addTabName(name: string) {
        setNoTabName(false);
        setDuplicateTabName(false);
        setValidated(false);
        let exists = false;
        allTabs.map(item => {
            if (item.Name === itemName.trim()) {
                exists = true;
            }
        })

        if (itemName.trim() === '') {
            setValidated(true);
            setNoTabName(true);
        }
        else if (exists) {
            setDuplicateTabName(true);
        }
        else {
            setNoTabName(false);
            setDuplicateTabName(false);
            let TabTitle = "OurTeamCustomTab_" + name.replace(/ /g, "") + "Title";
            let toAdd = {
                Title: TabTitle,
                SpoAConfigValue: itemName.trim(),
                SPoALongConfigValue: CUSTOM_TAB_DEFAULT_HEADERS,
                SPoATableHeaderVisible: CUSTOM_TAB_DEFAULT_HEADERS_VISIBLE,
                Required: displayTab,
            };
            setShowProcessing(
                <span>
                    Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            );

            helpers.addData("Discipline Configuration", toAdd, function (iar: any) {
                const listQuery = "select:ExpertName,EFMLRole,EFMLDiscipline,TermPath,PTEArea,SMEArea,EmailID;filter:((EFMLDiscipline eq 'Conduct Assurance' or EFMLDiscipline eq 'Supplier and Contract Management') and (EFMLRole eq 'Competence Owner' or EFMLRole eq 'CP SME'))"
                helpers.addData("Discipline Configuration", { Title: name + "Mode", SpoAConfigValue: "Manual", SPoALongConfigValue: listQuery }, function () {
                    setShowProcessing(<span>Save Tab</span>);
                    allTabs.push({
                        SPTitle: TabTitle,
                        ID: iar.data.Id,
                        Name: name.trim(),
                        isDefault: false,
                        showTab: true,
                        headers: null,
                        tabMode: null,
                        efmlColumns: "",
                        queryFilter: "",
                    });
                    setTabEdits(true);
                    setAddTab(false);
                })
            });
        }
    }

    function handleClose() {
        onHide();
        setEditTab(false);
        setAddTab(false);
        setDeleteTab(false);
        setNoTabName(false);
        setDuplicateTabName(false);
        setValidated(false);
        if (tabEdits) {
            getItems();
        }
    }

    function handleEditTab(name: string, id: string, isdefault: boolean, showtab: boolean) {
        setShowProcessing(<span>Save Tab</span>);
        setItemID(id);
        setItemName(name);
        setTabLength(name.length);
        setIsDefaultTab(isdefault);
        setDisplayTab(showtab);
        setEditTab(true);
    }

    function handleDeleteTab(name: string, id: string, sptitle: string) {
        setShowProcessing(<span>Delete Tab</span>);
        setCustomTabExperts(allExperts.filter(t => t.Tab_x0020_Name == sptitle));
        setItemID(id);
        setItemName(name);
        setDeleteTab(true);
    }

    function handleAddTab() {
        setShowProcessing(<span>Save Tab</span>);
        setItemName("");
        setTabLength(0);
        setAddTab(true);
    }

    function removeTab(id: string) {
        setShowProcessing(
            <span>
                Deleting... <i className="fa fa-spinner fa-pulse fa-fw"></i>
            </span>
        );
        // delete all the experts under this from the Manual Experts list
        if (customTabExperts.length > 0) {
            customTabExperts.map((expert: any) => {
                helpers.deleteItemByID('Manual Experts', expert.Id, function () { });
            })
        }
        // delete the tab config from Discipline Configuration list
        helpers.deleteItemByID("Discipline Configuration", id, async function (iar: any) {
            if (!isDefaultTab) {
                const efmlConfigTitle = itemName + "Mode";
                let list = window['SP_Propertyweb'].lists.getByTitle("Discipline Configuration");
                const items = await list.items.get();
                let configItemID = items.find(t => t.Title == efmlConfigTitle).Id;
                const recycleBinIdentifier = await list.items.getById(configItemID).recycle().then(() => {
                    var removeIndex = allTabs.findIndex(tab => tab.ID == itemID);
                    allTabs.splice(removeIndex, 1);
                    setTabEdits(true);
                });

            }

            setShowProcessing(<span>Save Tab</span>);
            setDeleteTab(false);
        });
    }
};

export default EditTabsModal;
