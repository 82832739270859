import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosInformationCircleOutline } from "react-icons/io";


export default function ToastMessage(props: any) {
    return (
        <div>            
            <Toast className="toast-message" onClose={props.onClose} autohide show={props.showToast} delay={4000}>
            {/* <Toast className="toast-message" autohide show={props.showToast} delay={1000}> */}
                <Toast.Body className="alert-success"> <IoIosCheckmarkCircleOutline className="toast-icon" /> Changes saved successfully! </Toast.Body>
            </Toast>
        </div>
    )
}