import React, { useState, useEffect } from 'react';
import './QuickLinks.scss';
import { GiTrashCan } from "react-icons/gi";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import AddQuickLink from './AddQuickLink';
import helpers from '../helpers';
import { AppInsights } from "applicationinsights-js";

const QuickLinks = (props: any) => {
    const { editMode, quickLinks } = props;
    const [addQuickLink, setAddQuickLink] = useState<boolean>(false);
    const [quickLinkAction, setQuickLinkAction] = useState("");
    const [selectedQuickLink, setSelectedQuickLink] = useState();

    useEffect(() => {
        /* Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key */
        AppInsights.downloadAndSetup({ instrumentationKey: helpers.appinsightsinstrumentationKey, maxBatchSizeInBytes: 0 });
    }, []);

    return (
        <div >
            <Dropdown drop={'down'}>
                <Dropdown.Toggle id="dropdown-basic" className="quick-links-dropdown" >
                    Quick Links
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        quickLinks.map((link: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <td className='quick-link-item'><Dropdown.Item onClick={() => { window.open(link.LinkURL); sendToAppInsights("Quick Links") }}>{link.Title}  </Dropdown.Item></td>
                                </tr>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>

        </div>
    )

    function sendToAppInsights(webpartname?: string) {
        AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Quick Links Web Part',
            WebPartHeader: webpartname,
            UserEmail: props.userEmail
        });
        AppInsights.flush();
    }


};

export default QuickLinks;
