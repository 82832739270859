import React from 'react';

const DocumentWidget = ({ o }) => {
    return (
        <div className="result type-d">
            <header>
                <div className="title" title={o.doctitle} onClick={() => {
                    window.open(o.filePath)
                }}>
                    {(o.doctitle && o.doctitle.length > 70) ?
                        o.doctitle.substr(0, 70) + "..." :
                        o.doctitle
                    } <span className="result-source-text"> (Knowledge Repository)</span>
                </div>
                <div className="help-text">
                    {o.docApprove}
                </div>
            </header>

            <footer>
                <div className="statistics">
                    <div>
                        <div>Function: {o.techFunction ? o.techFunction : " - "}</div>
                        <div>Discpline: {o.discipline ? o.discipline : " - "} </div>
                        <div>PTE Area: {o.pteArea ? o.pteArea : " - "}</div>
                    </div>
                    <div>
                        <div>Additional Expertise Area: {o.smeArea ? o.smeArea : " - "} </div>
                        <div>Valid untill: {o.validDoc ? o.validDoc : ' - '} </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}



export default DocumentWidget;