import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import helpers from '../helpers';

const EditSection = (props: any) => {

    const { onHide, sectionName, leaderCategories, allLeaders, addTo } = props;
    const [newSectionName, setNewSectionName] = useState(sectionName);
    const [sectionLength, setSectionLength] = useState(sectionName.length);
    const [validated, setvalidated] = useState<boolean>(false);
    const [noSectionName, setNoSectionName] = useState<boolean>(false);
    const [duplicateSectionName, setduplicateSectionName] = useState<boolean>(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Section</span>);

    return (
        <div>
            <Modal.Header closeButton >
                <FaArrowLeft onClick={() => { handleCancel() }} className="arrow-back-category" />
                <Modal.Title>Edit Section</Modal.Title></Modal.Header>
            <Modal.Body id="edit-section-modal">
                <Form noValidate validated={validated}>
                    <Form.Group>
                        <Form.Label>Section Name <span className="star">*</span></Form.Label>
                        <Form.Control
                            required
                            pattern="^[a-zA-Z1-9].*"
                            placeholder="Enter new section name here"
                            value={newSectionName}
                            type="input"
                            onChange={(e: any) => {
                                setNewSectionName(e.currentTarget.value);
                                setSectionLength(e.currentTarget.value.length);
                            }}
                            maxLength={80}
                            autoFocus
                        ></Form.Control>
                        {noSectionName ?
                            <Form.Text className="invalid-input">
                                Please provide a valid section name</Form.Text> : ''}
                        {duplicateSectionName ?
                            <Form.Text className="invalid-input">
                                Section already exists. Kindly try with a different section name</Form.Text> : ''}
                        <Form.Text className="text-muted">
                            {sectionLength
                                ? "" + (80 - sectionLength) + " characters remaining"
                                : "80 characters max"}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-cancel-menu" variant="secondary" onClick={() => { handleCancel(); setvalidated(false); }}> Cancel </Button>
                <Button variant="primary" className="proceed-button" onClick={() => updateSectionName()}>{showProcessing}</Button>

            </Modal.Footer>
        </div>
    )

    function handleCancel() {
        onHide();
    }

    function updateSectionName() {
        setNoSectionName(false);
        setduplicateSectionName(false);
        setvalidated(false);

        let exists = false;
        leaderCategories.map(item => {
            if (item.toLowerCase() === newSectionName.trim().toLowerCase()) {
                exists = true;
            }
        })

        if (newSectionName.trim() === '') {
            setNoSectionName(true);
            setvalidated(true);
        }

        else if (exists) {
            setduplicateSectionName(true);
        }

        else {
            setvalidated(true);
            setShowProcessing(
                <span>
                    Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            );


            // Update the choice field
            let field;
            if (addTo === "Leader")
                field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoALeaderGroup");
            else if (addTo === "Other Role")
                field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoAOtherDiscplineGroup");
            field.select('Choices')
                .get().then((fieldData) => {
                    let choices = fieldData.Choices.results || fieldData.Choices;
                    var index = choices.indexOf(sectionName);
                    if (index !== -1)
                        choices[index] = newSectionName;
                    field.update({
                        Choices: {
                            results: choices
                        }
                    }, 'SP.FieldChoice')
                })

            var index = leaderCategories.indexOf(sectionName);
            if (index !== -1)
                leaderCategories[index] = newSectionName;

            //update all items which have this choice 
            let leadersToUpdate;
            if (addTo === "Leader") {
                leadersToUpdate = allLeaders.filter(leader => (leader.SpoALeaderGroup && leader.SpoALeaderGroup.indexOf(sectionName) > -1));
                if (leadersToUpdate.length > 0) {
                    leadersToUpdate.map((leader: any) => {
                        var index = leader.SpoALeaderGroup.indexOf(sectionName);
                        if (index !== -1)
                            leader.SpoALeaderGroup.splice(index, 1);
                        let newSections = leader.SpoALeaderGroup.concat(newSectionName);
                        helpers.updateData('Experts', { SpoALeaderGroup: { results: newSections } }, leader.Id, function () {
                            setShowProcessing(<span>Save Section</span>);
                            setvalidated(false);
                            onHide(newSectionName, leadersToUpdate);
                        });
                    })
                }
                else {
                    onHide(newSectionName, leadersToUpdate);
                }
            }
            if (addTo === "Other Role") {
                leadersToUpdate = allLeaders.filter(leader => (leader.SpoAOtherDiscplineGroup && leader.SpoAOtherDiscplineGroup.indexOf(sectionName) > -1));
                if (leadersToUpdate.length > 0) {
                    leadersToUpdate.map((leader: any) => {
                        var index = leader.SpoAOtherDiscplineGroup.indexOf(sectionName);
                        if (index !== -1)
                            leader.SpoAOtherDiscplineGroup.splice(index, 1);
                        let newSections = leader.SpoAOtherDiscplineGroup.concat(newSectionName);
                        helpers.updateData('Experts', { SpoAOtherDiscplineGroup: { results: newSections } }, leader.Id, function () {
                            setShowProcessing(<span>Save Section</span>);
                            setvalidated(false);
                            onHide(newSectionName, leadersToUpdate);
                        });
                    })
                }
                else {
                    onHide(newSectionName, leadersToUpdate);
                }
            }

        }


    }
}
export default EditSection;