import React, { useState, useEffect } from 'react';
import './krEdit.scss';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';
import { Session } from '@pnp/sp-taxonomy';
import helpers from '../helpers';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';




const KrEdit = (props) => {

    const { showEditDialog, setShowEditDialog, getInitialDataLoad, setShowLoading, queryIDFromKR, queryFromKR, taxonomyDataFromKR } = props;
    const loaders = new Array(10).fill('');
    const steps = [{ name: "Step 1", desc: "Technical Function" }, { name: "Step 2", desc: "Discipline" }, { name: "Step 3", desc: "PTE" }
        // , { name: "Step 4", desc: "Additional Expertise Area" }
    ];

    const [activeStep, setActiveStep] = useState(0);
    const [taxonomyData, setTaxonomyData] = useState<any>(taxonomyDataFromKR);
    const [disciplineData, setDisciplineData] = useState([]);
    const [pteData, setPTEData] = useState([]);
    const [additionalEAData, setAdditionalEAData] = useState([]);
    const [technicalFunctionSelected, setTechnicalFunctionSelected] = useState(false);
    const [initalData, setInitialData] = useState<any>();
    const [showProcessing, setShowProcessing] = useState(<span>Save View</span>);



    useEffect(() => {
        createFormData();
    }, [activeStep])

    useEffect(() => {
        let checekd = false;
        Object.keys(taxonomyData).forEach((o) => {
            if (taxonomyData[o].checked) {
                checekd = true;
            }
        });

        setTechnicalFunctionSelected(checekd);

    }, [taxonomyData])

    return (
        <>
            <Modal
                show={showEditDialog}
                onShow={() => {
                    setTaxonomyData(JSON.parse(JSON.stringify(taxonomyDataFromKR)))
                }}
                onHide={() => {

                    setActiveStep(0);
                    setShowEditDialog(false);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                aria-labelledby="example-custom-modal-styling-title"
                size={"xl"}
                id="kr-edit-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Edit View
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sub-title">
                        {/* Select required Technical Function, Discipline, PTE and Additional Expertise Area based on which end-user view will be displayed. */}
                        Select required Technical Function, Discipline and PTE based on which end-user view will be displayed.
                </div>
                    <div className="step-indicator">
                        {
                            steps.map((o, i) => {
                                return (
                                    // <div key={i} className={i <= activeStep ? 'active step' : 'step'} onClick={() => setActiveStep(i)}>
                                    <div key={i} className={
                                        i < activeStep ? 'completed step' : i === activeStep ? 'active step' : 'step'
                                    } >
                                        <div className="step-title">{o.name}</div>
                                        <div className="step-desc">{o.desc}</div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {

                        Object.keys(taxonomyData).length ?
                            //taxonomyData.length ? 

                            <div className={"data-wrapper step-" + (activeStep + 1)}>
                                <Form className={"form-" + activeStep}>

                                    {/* {
                populateData(activeStep)  
              } */}

                                    {
                                        activeStep === 0 ?
                                            Object.keys(taxonomyData).map((key: any, i) => {

                                                return (
                                                    <div key={i}>
                                                        <Form.Group controlId={"/Guid(" + key + ")/"} >
                                                            <Form.Check type="checkbox" id={key} label={key} checked={taxonomyData[key].checked} onClick={() => {
                                                                const temp = { ...taxonomyData };
                                                                temp[key].checked = !temp[key].checked;
                                                                setTaxonomyData(temp);
                                                            }} />
                                                        </Form.Group>
                                                    </div>
                                                )
                                            }) : ""
                                    }

                                    {

                                        activeStep === 1 ?
                                            disciplineData.length === 0 ? "Please select a value in previous step" :
                                                disciplineData.map((obj: any, index: number) => {

                                                    return (
                                                        <div key={index}>
                                                            <div className="title">{obj.name}</div>
                                                            <div className="items">
                                                                {
                                                                    obj.items.items.length ?
                                                                        Object.keys(obj.items).map((innerKey: any, innerIndex: number) => {

                                                                            if (['ID', 'checked', 'items'].includes(innerKey) === false) {

                                                                                const innerObj = disciplineData[index].items[innerKey];
                                                                                return (
                                                                                    <div key={innerIndex}>
                                                                                        <Form.Group controlId={"/Guid(" + innerIndex + ")/"}>
                                                                                            <Form.Check type="checkbox" id={'discipline-' + "/Guid(" + innerIndex + ")/"} label={innerKey} checked={innerObj.checked} onClick={() => {
                                                                                                const temp = [...disciplineData];
                                                                                                temp[index].items[innerKey].checked = !temp[index].items[innerKey].checked;
                                                                                                setDisciplineData(temp);
                                                                                            }} />
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }) : "No disciplines found"
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            : ""

                                    }


                                    {
                                        activeStep === 2 ?
                                            pteData.length === 0 ? "Please select a value in previous step" :
                                                pteData.map((obj: any, index: number) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="title">{obj.name}</div>
                                                            <div className="items">
                                                                {
                                                                    Object.keys(obj.items).length > 3 ?
                                                                        Object.keys(obj.items).map((innerKey: any, innerIndex: number) => {
                                                                            if (['ID', 'checked', 'items'].includes(innerKey) === false) {
                                                                                const innerObj = pteData[index].items[innerKey];
                                                                                return (
                                                                                    <div key={innerIndex}>
                                                                                        <Form.Group controlId={"/Guid(" + innerIndex + ")/"}>
                                                                                            <Form.Check type="checkbox" id={'pte-' + "/Guid(" + innerIndex + ")/"} label={innerKey} checked={innerObj.checked} onClick={() => {
                                                                                                const temp = [...pteData];
                                                                                                temp[index].items[innerKey].checked = !temp[index].items[innerKey].checked;
                                                                                                setPTEData(temp);
                                                                                            }} />
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }) : "No PTE found"
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            : ""
                                    }

                                    {/* {
                activeStep === 3 ? 
                additionalEAData.length === 0 ? "Please select a value in previous step" : 
                additionalEAData.map( (obj:any, index:number) => {
                  return (
                    <div key={index}>
                      <Form.Check type="checkbox" id={'pte-' + obj.ID} label={obj.name} checked={obj.checked} onClick={() => {
                                const temp = [...additionalEAData];
                                temp[index].checked = !temp[index].checked;
                                setAdditionalEAData(temp);
                              }} />                   
                    </div>
                  )
                })
                : ""
              } */}

                                </Form>
                            </div>

                            :

                            <div className='step-loader'>
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">

                                    {
                                        loaders.map((o, i) => <Skeleton key={i} height={20} ></Skeleton>)
                                    }


                                </SkeletonTheme>
                            </div>

                    }



                </Modal.Body>
                <Modal.Footer className={activeStep === 0 ? "flex-end" : " "} >
                    {
                        activeStep !== 0 ?
                            <Button variant="outline-secondary" onClick={() => { setActiveStep(activeStep - 1) }}> <FaArrowLeft /> Back</Button>
                            : ""
                    }

                    {
                        activeStep === 2 ?
                            <Button variant="primary" onClick={() => buildQueryString()}> {showProcessing} </Button>
                            :
                            Object.keys(taxonomyData).length ?
                                <Button variant="outline-primary" title={!technicalFunctionSelected ? "Please select one Technical Function to Proceed" : "Proceed"} disabled={!technicalFunctionSelected} onClick={() => { setActiveStep(activeStep + 1) }}> Proceed <FaArrowRight /> </Button>
                                : ""
                    }

                </Modal.Footer>
            </Modal>
        </>
    )

    function buildQueryString() {
        let tempPteQuerySet = [];
        let tempDisciplineQuerySet = [];
        let tempTechFunctionQSet = [];
        setShowProcessing(<span>Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
        let refinablestring00 = ''
        let refinablestring01 = ''
        let refinablestring02 = ''
        let refinablestring03 = ''
        let tfCount = 0;
        let disciplineCount = 0;
        let pteCount = 0;

        Object.keys(taxonomyData).forEach((o) => {
            if (taxonomyData[o].checked) {
                tempTechFunctionQSet.push(o);
                tfCount++;
                refinablestring00 = refinablestring00 + 'refinablestring11:"' + taxonomyData[o].ID + '",'
            }
        });

        disciplineData.forEach((obj: any, index: number) => {
            Object.keys(obj.items).forEach((innerKey: any, innerIndex: number) => {
                if (['ID', 'checked', 'items'].includes(innerKey) === false) {
                    if (disciplineData[index].items[innerKey].checked) {
                        disciplineCount++;
                        tempDisciplineQuerySet.push(innerKey);
                        const innerObj = disciplineData[index].items[innerKey];
                        refinablestring01 = refinablestring01 + 'refinablestring00:"' + innerObj.items[0].Id + '",';
                    }
                }
            })
        })


        pteData.map((obj: any, index: number) => {
            if (obj.items) {

                Object.keys(obj.items).forEach((innerKey: any, innerIndex: number) => {
                    if (['ID', 'checked', 'items'].includes(innerKey) === false) {
                        if (pteData[index].items[innerKey].checked) {
                            tempPteQuerySet.push(innerKey);
                            pteCount++;
                            const innerObj = pteData[index].items[innerKey];
                            refinablestring02 = refinablestring02 + 'refinablestring01:"' + innerObj.items[0].Id + '",';
                        }
                    }
                })

            }
        })


        additionalEAData.forEach((obj: any, index: number) => {
            if (obj.checked) {
                refinablestring03 = refinablestring03 + 'refinablestring03:"' + obj.ID.split('/Guid(')[1].split(')/')[0] + '",'
            }
        })




        //let wrapper;
        ////if (tfCount === 1 && disciplineCount === 1 && pteCount === 1) {
        ////    wrapper = "'AND (";
        ////}
        ////else
        //if (tfCount >= 1 || disciplineCount >= 1 || pteCount >= 1) {
        //    wrapper = "'OR (";
        //}
        //else {
        //    wrapper = "'AND (";
        //}

        let finalQueryString;

        if (tfCount >= 1 && disciplineCount === 0 && pteCount === 0) {
            if (tfCount === 1) {
                finalQueryString = ("'" + refinablestring00.replace(/,\s*$/, "") + "'");
            }
            else {
                finalQueryString = "'OR (" + (refinablestring00).replace(/,\s*$/, "") + ")'";
            }
        }
        else if (tfCount >= 1 && disciplineCount >= 1 && pteCount === 0) {
            if (tfCount === 1 && disciplineCount === 1) {
                finalQueryString = "'AND (" + (refinablestring00 + refinablestring01).replace(/,\s*$/, "") + ")'";
            }
            else {
                finalQueryString = "'OR (" + (refinablestring01).replace(/,\s*$/, "") + ")'";
            }
        }
        else if (tfCount >= 1 && disciplineCount >= 1 && pteCount >= 1) {
            if (tfCount === 1 && disciplineCount === 1 && pteCount === 1) {
                finalQueryString = "'AND (" + (refinablestring00 + refinablestring01 + refinablestring02).replace(/,\s*$/, "") + ")'";
            }
            else {
                finalQueryString = "'OR (" + (refinablestring02).replace(/,\s*$/, "") + ")'";
            }
        }

        //if (tfCount === 1 && refinablestring01 === '' && refinablestring02 === '') {
        //    finalQueryString = ("'" + refinablestring00.replace(/,\s*$/, "") + "'");
        //}
        //else {
        //    finalQueryString = (wrapper + refinablestring00 + refinablestring01 + refinablestring02 + refinablestring03).replace(/,\s*$/, "") + ")'";
        //}


        finalQueryString = queryFromKR.split('refinementfilters=')[0] + 'refinementfilters=' + finalQueryString;

        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(queryIDFromKR).update({
            Query: finalQueryString, TechFunction: tempTechFunctionQSet.join(';'), Discipline: tempDisciplineQuerySet.join(';'), PTEVal: tempPteQuerySet.join(';')
        }).then((item: any) => {
            setShowLoading(true);
            getInitialDataLoad();
            setTaxonomyData(JSON.parse(JSON.stringify(taxonomyDataFromKR)));
            setActiveStep(0);
            setShowEditDialog(false);
            setShowProcessing(<span>Save View</span>);
        }).catch(e => {
            console.log('Update failed')
        });


    }

    function createFormData() {
        if (activeStep === 1) {
            let temp = [];

            Object.keys(taxonomyData).forEach((o) => {

                if (taxonomyData[o].checked) {

                    //taxonomyData[o].items.map(mapObj => {
                    //    if (taxonomyData[o][mapObj.Name] === undefined) {

                    //        taxonomyData[o][mapObj.Name] = {};
                    //        taxonomyData[o][mapObj.Name].ID = mapObj.Id;
                    //        taxonomyData[o][mapObj.Name].items = [];
                    //        taxonomyData[o][mapObj.Name].checked = false;
                    //    }
                    //});

                    temp.push({
                        name: o,
                        ID: taxonomyData[o].ID,
                        items: taxonomyData[o]
                    });
                }
            });

            setDisciplineData(temp);

        } else if (activeStep === 2) {


            let tempPTEData = [];

            Object.keys(disciplineData).forEach((z: any) => {


                Object.keys(disciplineData[z].items).forEach((o) => {


                    if (disciplineData[z].items[o].checked) {

                        //disciplineData[z].items[o].items.map(mapObj => {
                        //    if (disciplineData[z].items[o][mapObj.Name] === undefined) {

                        //        disciplineData[z].items[o][mapObj.Name] = {};
                        //        disciplineData[z].items[o][mapObj.Name].ID = mapObj.Id;
                        //        disciplineData[z].items[o][mapObj.Name].items = [];
                        //        disciplineData[z].items[o][mapObj.Name].checked = false;
                        //    }
                        //});


                        tempPTEData.push({
                            name: o,
                            ID: disciplineData[z].items[o].ID,
                            items: disciplineData[z].items[o]
                        })
                    }

                });

            });

            setPTEData(tempPTEData);
        }
        else if (activeStep === 3) {


            let tempAEAData = [];

            Object.keys(pteData).forEach((z: any) => {


                Object.keys(pteData[z].items).forEach((o) => {

                    if (pteData[z].items[o].checked) {

                        pteData[z].items[o].items.forEach(aeaObj => {
                            tempAEAData.push({
                                name: aeaObj.Name,
                                ID: aeaObj.Id,
                                checked: false
                            })
                        })

                    }

                });

            });

            setAdditionalEAData(tempAEAData);
        }
    }


    function getTermsetWithChildren(siteCollectionURL: string, termStoreName: string, termsetId: string) {
        return new Promise((resolve, reject) => {
            const taxonomy = new Session(siteCollectionURL);
            const store: any = taxonomy.termStores.getByName(termStoreName);
            store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()
                .then((data: any[]) => {
                    processResponse(data);
                    //resolve();
                }).catch(fail => {
                    console.warn(fail);
                    reject(fail);
                });
        });
    }

    function processResponse(data: any) {
        let results = {};
        data.map((o, i) => {

            if (o.Parent) {
                const categories = o.PathOfTerm.split(";");

                if (categories[0] && results[categories[0]] === undefined) {
                    results[categories[0]] = {}
                    results[categories[0]].checked = false;
                    results[categories[0]].items = []
                }

                if (categories[1] && results[categories[0]][categories[1]] === undefined) {
                    results[categories[0]][categories[1]] = {}
                    results[categories[0]][categories[1]].items = [];
                    results[categories[0]][categories[1]].checked = false;
                }

                if (categories[2] && results[categories[0]][categories[1]][categories[2]] === undefined) {
                    results[categories[0]][categories[1]][categories[2]] = {}
                    results[categories[0]][categories[1]][categories[2]].items = [];
                    results[categories[0]][categories[1]][categories[2]].checked = false;
                }

                if (categories.length && categories.length === 1) {
                    o.checked = false;
                    results[categories[0]].items.push(o);
                    results[categories[0]].ID = o.Parent;
                }

                else if (categories.length && categories.length === 2) {
                    o.checked = false;
                    results[categories[0]][categories[1]].items.push(o);
                    results[categories[0]][categories[1]].ID = o.Parent;
                }

                else if (categories.length && categories.length === 3) {
                    o.checked = false;
                    results[categories[0]][categories[1]][categories[2]].items.push(o);
                    results[categories[0]][categories[1]][categories[2]].ID = o.Parent;
                }
            }
            else {
                if (results[o.Name] === undefined) {
                    results[o.Name] = {}
                    results[o.Name].ID = o.Id;
                    results[o.Name].items = [];
                    results[o.Name].checked = false;
                }
                results[o.Name].ID = o.Id;
            }
        })


        setInitialData(JSON.parse(JSON.stringify(results)));
        setTaxonomyData(results);

        return results;
    }

    //return new SPFetchClient("https://shelldevelopment.sharepoint.com/sites/SPODA0019/CSO", "47fe1d65-cf51-446a-a70e-eab3f67cd857", "qQfRxnoYDSdUuvL1xEn6NxG1qDX2B6jc+O+HYjzpMf8=");

}

export default KrEdit;