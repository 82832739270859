import React, { useState, useEffect, useRef } from 'react';
import './CardWidget.scss';
import { FiFilter } from "react-icons/fi"
import { MdCreate } from "react-icons/md";
import { Button, Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import { sp as pnpsp } from '@pnp/sp';
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux'
import { AppInsights } from "applicationinsights-js";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiMoreVertical } from "react-icons/fi";
import Slider from "react-slick";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

function CustomArrow(props) {
    const { className, style, onClick, prev } = props;
    return (<div className={prev ? 'left-custom' : 'right-custom'} style={{ ...style }} onClick={onClick}> {prev ? <GoChevronLeft size={30} /> : <GoChevronRight size={30} />}</div>);
}

function CustomArrowLeft(props) {
    const { className, style, onClick, prev } = props;
    return (<div className={'right-custom'} style={{ ...style }} onClick={onClick}> <GoChevronLeft size={30} /> </div>);
}



const CardWidget = ({ config, response, editMode, updateConfigurationItems, loading, searchLFEURL, hideWebpart, isModerator, isHidden }) => {

    const [showModal, setShowModal] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [functionQuery, setFunctionQuery] = useState(true);
    const [keyWordQuery, setKeyWordQuery] = useState(true);
    const [keyWord, setKeyWord] = useState('');

    const [initalData, setInitialData] = useState<any>({});
    const [taxonomyData, setTaxonomyData] = useState<any>({});
    const [functionOptions, setFunctionOptions] = useState([]);
    const [disciplineOptions, setDisciplineOptions] = useState([]);
    const [disciplineData, setDisciplineData] = useState([]);
    const [PTEData, setPTEData] = useState([]);
    const [PTEOptions, setPTEOptions] = useState([]);
    const [SMEData, setSMEData] = useState([]);
    const [SMEOptions, setSMEOptions] = useState([]);
    const [signTechFunc, setSignTechFunc] = useState([]);
    const [signForumDetails, setSignForumDetails] = useState([]);
    const [maxCount, setMaxCount] = useState(false);
    const functionRef = useRef<any>(null);
    const disciplineRef = useRef<any>(null);
    const PTERef = useRef<any>(null);
    const SMERef = useRef<any>(null);
    const termStore = useSelector(state => state.termStore);

    const [defaultFunctionVal, setDefaultFunctionVal] = useState([]);
    const [defaultDisciplineVal, setDefaultDisciplineVal] = useState([]);
    const [defaultPteVal, setDefaultPteVal] = useState([]);
    const [defaultSmeVal, setDefaultSmeVal] = useState([]);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow prev={true} />
    };



    useEffect(() => {
        AppInsights.downloadAndSetup({ instrumentationKey: helpers.appinsightsinstrumentationKey, maxBatchSizeInBytes: 0 });
        let temp = [];
        if (disciplineRef.current !== null) {
            disciplineRef.current.select.clearValue();
        }
        if (PTERef.current !== null) {
            PTERef.current.select.clearValue();
        }
        if (SMERef.current !== null) {
            SMERef.current.select.clearValue();
        }

        Object.keys(taxonomyData).forEach((o) => {
            if (taxonomyData[o].checked) {
                taxonomyData[o].items.forEach(obj => {
                    Object.keys(taxonomyData[o]).forEach(key => {
                        if (key !== "checked" && key !== "items" && key !== "ID") {
                            temp.push({
                                key: taxonomyData[o][key].ID,
                                value: key,
                                label: key,
                                items: taxonomyData[o][key],
                                checked: false,
                            })
                        }
                    })
                });
            }
        });
        if (temp.length > 0) {
            temp = temp.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        }
        setDisciplineOptions(temp);
        setDisciplineData(temp);
    }, [taxonomyData])

    useEffect(() => {
        let temp = [];
        Object.keys(disciplineData).forEach((o) => {
            if (disciplineData[o].checked) {
                Object.keys(disciplineData[o].items).forEach(key => {
                    if (key !== "checked" && key !== "items" && key !== "ID") {
                        temp.push({
                            key: disciplineData[o].items[key].ID,
                            value: key,
                            label: key,
                            items: disciplineData[o].items[key],
                            checked: false,
                        })
                    }
                })
            }
        });
        setPTEOptions(temp);
        setPTEData(temp);
    }, [disciplineData]);

    useEffect(() => {
        let temp = [];
        Object.keys(PTEData).forEach((o) => {
            if (PTEData[o].checked) {
                Object.keys(PTEData[o].items).forEach(key => {
                    if (key !== "checked" && key !== "items" && key !== "ID") {
                        temp.push({
                            key: PTEData[o].items[key].ID,
                            value: key,
                            label: key,
                            items: PTEData[o].items[key],
                            checked: false,
                        })
                    }
                })
            }
        });
        setSMEOptions(temp);
        setSMEData(temp);
    }, [PTEData]);

    useEffect(() => {
        let temp = [];
        Object.keys(SMEData).forEach((o) => {
            if (SMEData[o].checked) {
                Object.keys(SMEData[o].items).forEach(key => {
                    if (key !== "checked" && key !== "items" && key !== "ID") {
                        temp.push({
                            key: SMEData[o].items[key].ID,
                            value: key,
                            label: key,
                            items: SMEData[o].items[key],
                            checked: false,
                        })
                    }
                })
            }
        });
    }, [SMEData]);

    useEffect(() => {
        if (keyWord !== "" && keyWord !== null) {
            setKeyWord('')
        }

    }, [functionQuery])

    useEffect(() => {
        if (functionRef.current !== null) {
            functionRef.current.select.clearValue();
        }
        if (disciplineRef.current !== null) {
            disciplineRef.current.select.clearValue();
        }
        if (PTERef.current !== null) {
            PTERef.current.select.clearValue();
        }
        if (SMERef.current !== null) {
            SMERef.current.select.clearValue();
        }
    }, [keyWordQuery])



    useEffect(() => {
        getSIGNForumDetails();
        // //PHA Setup
        // var executor = new SP.RequestExecutor(helpers.appweburl);
        // executor.executeAsync({
        //     url: helpers.taxonomyURLAppWebURL + "/_api/web/getFileByServerRelativeUrl('" + helpers.taxonomyURL + "')/$value?binaryStringResponseBody=true",
        //     // url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/lists/getbytitle('Calendar')/items(" + iar.data.ID + ")/AttachmentFiles/add(FileName='" + fileAttachmentText + "')?@target='" + helpers.hostweburl + "'",
        //     method: "GET",
        //     headers: {
        //         "Accept": "application/json; odata=verbose"
        //     },
        //     success: function (data: any) {
        //         const results: any = processResponse(JSON.parse(data.body));
        //     },
        //     error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
        //         console.log(thrownError);
        //     }
        // });
        termStore.length && processResponse(termStore);
    }, []);

    return (

        <div id="card-widget">
            <div className="widget-title">
                <h1> {config.title}
                    {
                        config.type === "LFI" &&
                        <span className="lfi-links">
                            <span onClick={() => {
                                window.open(config.GoalZero)
                            }}>Goal Zero</span>
                            <span onClick={() => {
                                window.open(config.Fountain)
                            }}>Fountain</span>
                        </span>
                    }
                </h1>
                {config.type === "LFE" ?
                    response.map((o, i) => {
                        return (
                            o.lferesult !== undefined && o.lferesult.length > 0 ?
                                <span className="lfeExpertiseAreaSpan">Note: Results shown are based on predefined {o.lferesult.map((d, i) => {
                                    return((i === 0) ?
                                        (d.lfeExpArea) : "")
                                })} </span> : ""
                        )
                    })
                    : ""}
                {/* {config.type === "LFE" && response !== null ? <span className="lfeExpertiseAreaSpan">Note: Results shown are based on predefined {response[0].lferesult[0].lfeExpArea} </span> : ""}*/}
                <div id="moderator-tools">
                    {
                        editMode && (!isHidden ?
                            <DropdownButton alignRight title={<FiMoreVertical />} id="dropdown-menu-align-right" >
                                <Dropdown.Item eventKey="1" onClick={() => setShowModal(true)} >Edit Query </Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={() => hideWebpart(true)} >Hide </Dropdown.Item>
                            </DropdownButton> :
                            <span onClick={() => hideWebpart(false)} className="unhide">Unhide </span>)}
                    {/*
                        config.type === "SIGN" &&
                        <>
                            <span className="sign-forum-filter" onClick={() => setShowFilters(!showFilters)}><FiFilter /> Forums</span>
                            {
                                showFilters &&
                                <div className="forum-filters">
                                    <Form.Group controlId="formBasicCheckbox">
                                        {
                                            response.map((o, i) => {
                                                return (
                                                    <Form.Check type="checkbox" key={i} id={"filter-chk-" + i} label={o.signForum} onClick={
                                                        () => { filterForumData(o.signForum) }} />
                                                )
                                            })

                                        }
                                    </Form.Group>
                                </div>
                            }
                        </>
                   */ }



                </div>
            </div>

            {
                loading ?
                    <div className="card-loading">
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={220} width={350}></Skeleton>
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={220} width={350}></Skeleton>
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={220} width={345}></Skeleton>
                        </SkeletonTheme>
                    </div>
                    :
                    <div className={isHidden ? "hidden-webpart widget-body" : "widget-body"} id={response.length > 3 && "not-flex-widget"}>
                        {
                            response.length === 0 && config.type === "SIGN" ?
                                <div className="no-results">No Discussions Available at this moment.</div>
                                :
                                response.length === 0 && config.type === "LFE" ?
                                    <div className="no-results">No Learnings Available at this moment.</div>
                                    :
                                    response.length === 0 && config.type === "Workday" ?
                                        <div className="no-results">No Learnings Available at this moment.</div>
                                        :
                                        response.length === 0 && config.type === "LFI" ?
                                            <div className="no-results">No Learnings Available at this moment.</div>
                                            : response.length === 0 && config.type === "EE" ?
                                                <div className="no-results">No Articles Available at this moment.</div>
                                                :
                                                response.length > 3 && config.type === "SIGN" ?
                                                    <Slider {...settings}>
                                                        {response.map(function (o: any, index: number) {
                                                            return (
                                                                <div key={index} className="info-card" >
                                                                    <h6 className="sign-forum-title" onClick={() => { window.open(o.forumUrl); }}>{o.forumName}</h6>
                                                                    {o.discussions.map((d, i) => (
                                                                        <div>
                                                                            <a className="sign-discussion-title" title={d.signTitle} target="_blank" href={d.signPath} onClick={() => { sendToAppInsights(config.type) }}>
                                                                                {(d.signTitle && d.signTitle.length > 36) ?
                                                                                    d.signTitle.substr(0, 36) + "..." :
                                                                                    d.signTitle
                                                                                } </a>
                                                                            <div className="discussion-info">Last modified: {d.submittedDate}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )
                                                        })}
                                                    </Slider> :
                                                    response.map((o, i) => {
                                                        return (
                                                            <>
                                                                {
                                                                    config.type === "SIGN" ?
                                                                        <>
                                                                            <div key={i} className="info-card" >
                                                                                <h6 className="sign-forum-title" onClick={() => { window.open(o.forumUrl); }}>{o.forumName}</h6>
                                                                                {o.discussions.map((d, i) => (
                                                                                    <div>
                                                                                        <a className="sign-discussion-title" title={d.signTitle} target="_blank" href={d.signPath} onClick={() => { sendToAppInsights(config.type) }}>
                                                                                            {(d.signTitle && d.signTitle.length > 36) ?
                                                                                                d.signTitle.substr(0, 36) + "..." :
                                                                                                d.signTitle
                                                                                            } </a>
                                                                                        <div className="discussion-info">Last modified: {d.submittedDate}</div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        config.type === "LFE" ?
                                                                            <>
                                                                                {
                                                                                    o.lferesult !== undefined ?
                                                                                        <div key={i} className="info-card" >
                                                                                            <h6 className="lfe-title">Latest Submissions</h6>
                                                                                            {
                                                                                                o.lferesult.length > 0 ?
                                                                                                    o.lferesult.map((d, i) => (
                                                                                                        <div>
                                                                                                            <a className="sign-discussion-title" title={d.lfeTitle} target="_blank" href={d.lfePath} onClick={() => { sendToAppInsights(config.type) }}>
                                                                                                                {(d.lfeTitle && d.lfeTitle.length > 32) ?
                                                                                                                    d.lfeTitle.substr(0, 32) + "..." :
                                                                                                                    d.lfeTitle
                                                                                                                } </a>
                                                                                                            <div className="discussion-info">Submitted on: {d.submittedDate} Learning Type: {d.lfeLearnType}</div>
                                                                                                        </div>
                                                                                                    )) : <div className="no-results-lfe">No Learnings Available at this moment.</div>
                                                                                            }

                                                                                        </div>
                                                                                        : ""}
                                                                                {o.lfiresult !== undefined ?
                                                                                    <div key={i} className="info-card" >
                                                                                        <h6 className="lfe-title">Latest LFI's</h6>
                                                                                        {
                                                                                            o.lfiresult.length > 0 ?
                                                                                                o.lfiresult.map((d, i) => (
                                                                                                    <div>
                                                                                                        <a className="sign-discussion-title" title={d.lfiTitle} target="_blank" href={d.lfiPath} onClick={() => { sendToAppInsights(config.type) }}>
                                                                                                            {(d.lfiTitle && d.lfiTitle.length > 32) ?
                                                                                                                d.lfiTitle.substr(0, 32) + "..." :
                                                                                                                d.lfiTitle
                                                                                                            } </a>
                                                                                                        {d.submittedDate !== "" && d.lfiApprovedBy !== "" ? <div className="discussion-info">Approved on: {d.submittedDate} by {(d.lfiApprovedBy && d.lfiApprovedBy.length > 17) ? d.lfiApprovedBy.substr(0, 17) + "..." : d.lfiApprovedBy}</div> : <div className="no-discussion-info"></div>}
                                                                                                    </div>
                                                                                                )) : <div className="no-results-lfe">No Incidents Available at this moment.</div>
                                                                                        }
                                                                                    </div>
                                                                                    : ""}
                                                                                {o.playlistResult !== undefined ?
                                                                                    <div key={i} className="info-card" >
                                                                                        <h6 className="lfe-title">Recent Playlists</h6>
                                                                                        {
                                                                                            o.playlistResult.length > 0 ?
                                                                                                o.playlistResult.map((d, i) => (
                                                                                                    <div>
                                                                                                        <a className="sign-discussion-title" title={d.playlistTitle} target="_blank" href={d.playlistPath} onClick={() => { sendToAppInsights(config.type) }}>
                                                                                                            {(d.playlistTitle && d.playlistTitle.length > 32) ?
                                                                                                                d.playlistTitle.substr(0, 32) + "..." :
                                                                                                                d.playlistTitle
                                                                                                            } </a>
                                                                                                        <div className="discussion-info">Modified on: {d.playListsubmittedDate}</div>
                                                                                                    </div>
                                                                                                )) : <div className="no-results-lfe">No Playlists Available at this moment.</div>
                                                                                        }
                                                                                    </div>
                                                                                    : ""}
                                                                                {/* <div className="info-card" key={i} onClick={() => {
                                                                            window.open(o.lfePath); sendToAppInsights(config.type)
                                                                        }}>
                                                                            <h1>{(o.lfeTitle && o.lfeTitle.length > 70) ?
                                                                                o.lfeTitle.substr(0, 70) + "..." :
                                                                                o.lfeTitle
                                                                            } ({o.lfeLearnType})</h1>
                                                                            <span className="exp-apprvd-container">
                                                                                
                                                                                {o.lfeStatus === "Expert Approved" ? <span className="exp-apprvd">{o.lfeStatus}</span> : <span className="info">{o.lfeStatus}</span>}
                                                                            </span>
                                                                            <span className="info">Submitted on: {o.submittedDate}</span>
                                                                        </div>*/}
                                                                            </>
                                                                            : config.type === "LFI" ?
                                                                                <>
                                                                                    <div className="info-card" key={i} onClick={() => {
                                                                                        window.open(o.lfiPath); sendToAppInsights(config.type)
                                                                                    }}>
                                                                                        <h1>{(o.lfiTitle && o.lfiTitle.length > 70) ?
                                                                                            o.lfiTitle.substr(0, 70) + "..." :
                                                                                            o.lfiTitle
                                                                                        }</h1>
                                                                                        <span className="exp-apprvd-container">
                                                                                            {o.lfiStatus === "Expert Approved" ? <span className="exp-apprvd">{o.lfiStatus}</span> : <span className="info">{o.lfiStatus}</span>}
                                                                                        </span>
                                                                                        <span className="info">Submitted on: {o.submittedDate}</span>
                                                                                    </div>
                                                                                </>
                                                                                : config.type === "EE" ?
                                                                                    <>
                                                                                        <div className="info-card" key={i} onClick={() => {
                                                                                            window.open(o.articlePath); sendToAppInsights(config.type)
                                                                                        }}>
                                                                                            <h1>{(o.articleTitle && o.articleTitle.length > 70) ?
                                                                                                o.articleTitle.substr(0, 70) + "..." :
                                                                                                o.articleTitle
                                                                                            }</h1>
                                                                                            <span className="exp-apprvd-container">
                                                                                                {o.articleStatus == "Expert Approved" || o.articleStatus == "Peer Reviewed" ? <span className="exp-apprvd">{o.articleStatus}</span> : <span className="info">{o.articleStatus}</span>}
                                                                                            </span>
                                                                                            <span className="info">Last modified: {o.lastModfied}</span>
                                                                                        </div>
                                                                                    </>
                                                                                    : config.type === "Workday" &&
                                                                                    <>
                                                                                        <div className="info-card-workday" key={i} onClick={() => {
                                                                                            window.open(o.workDayURL); sendToAppInsights(config.type)
                                                                                        }}>
                                                                                            <h1>{(o.workdayTitle && o.workdayTitle.length > 70) ?
                                                                                                o.workdayTitle.substr(0, 70) + "..." :
                                                                                                o.workdayTitle
                                                                                            }</h1>
                                                                                            <span className="workday-desc">
                                                                                                {(o.WorkdayDescription && o.WorkdayDescription.length > 170) ?
                                                                                                    o.WorkdayDescription.substr(0, 170) + "..." :
                                                                                                    o.WorkdayDescription}
                                                                                            </span>
                                                                                            {/*<span className="info">Effective Date: {o.workdayEffectiveDate}</span>*/}
                                                                                        </div>
                                                                                    </>

                                                                }
                                                            </>
                                                        )
                                                    })
                        }
                    </div>
            }

            {
                config.type === "EE" ?
                    <div className="view-more" onClick={() => {
                        window.open(config.searchUrl)
                    }}>View More ...</div>
                    : config.type === "Workday" ?
                        <div className="view-more" onClick={() => {
                            window.open(config.workdayURL)
                        }}>View More ...</div>
                        : config.type === "LFI" ?
                            <div className="view-more" onClick={() => {
                                window.open(searchLFEURL)
                            }}>View More ...</div>
                            : config.type === "LFE" ?
                                <div className="view-more" onClick={() => {
                                    window.open(searchLFEURL)
                                }}>View More ...</div>
                                : config.type === "SIGN" ?
                                    <div className="signviewmore">
                                        <Dropdown drop={'down'}>
                                            <Dropdown.Toggle id="signdropdown-basic" className="signviewmore-links-dropdown" >
                                                View Forums </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    searchLFEURL.map((link: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className='signviewmore-item'><Dropdown.Item onClick={() => window.open(link.URL)}>{link.Title}  </Dropdown.Item></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                    :
                                    <div className="view-more">View More ...</div>
            }

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
                size={"xl"}
                id="card-widget-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{config.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="card-wiget-modal-body">
                    {
                        config.type === "SIGN" ?
                            <div className="sign-checkbox-wrapper">
                                <div className="modal-desc">Select required SIGN Forums</div>
                                <Form.Group controlId="formBasicCheckbox">
                                    {
                                        signTechFunc.map((oTechFun, i) => {
                                            return (
                                                <div className="sign-forum-wrapper">
                                                    <span className="sign-tech-func">{oTechFun}</span>
                                                    {
                                                        signForumDetails.map((oForum, i) => {
                                                            if (oForum !== undefined && oForum !== null && oForum !== "null") {
                                                                if (oForum.tf === oTechFun) {
                                                                    return (
                                                                        <Form.Check type="checkbox" key={i} id={"chk-" + i} value={oForum.url} checked={oForum.checked} label={oForum.disc} onChange={(e) => {
                                                                            let temp = [...signForumDetails];
                                                                            if (e.currentTarget.checked === true) {
                                                                                if (temp.filter(o => o.checked).length === 5) {
                                                                                    console.log("You cannot select more than 5 forums");
                                                                                    setMaxCount(true);
                                                                                    return false;
                                                                                } else {
                                                                                    setMaxCount(false);
                                                                                    temp[i].checked = !temp[i].checked;
                                                                                    setSignForumDetails(temp);
                                                                                }
                                                                            } else {
                                                                                setMaxCount(false);
                                                                                temp[i].checked = !temp[i].checked;
                                                                                setSignForumDetails(temp);
                                                                            }
                                                                        }} />
                                                                    )
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Form.Group>
                            </div>
                            : config.type === "LFI" || config.type === "LFE" ?
                                <div className="common-checkbox-wrapper">

                                    <Form.Check
                                        type="radio"
                                        label="Function"
                                        name="query-type"
                                        id="function-query"
                                        onChange={(e) => {
                                            setKeyWordQuery(!e.currentTarget.checked);
                                            setFunctionQuery(e.currentTarget.checked);
                                        }}
                                    />

                                    <div className={keyWordQuery ? "query-form query-form-disabled" : "query-form"}>
                                        <label>Function</label>
                                        {
                                            <Select id="function-select" isDisabled={keyWordQuery} ref={functionRef} options={functionOptions} maxMenuHeight={120} isMulti={false} placeholder="Select..." isClearable={false} onChange={(newVal: any) => {
                                                const temp = { ...taxonomyData };
                                                Object.keys(temp).forEach(key => temp[key].checked = false); //Need to optimize
                                                if (newVal && newVal.key !== undefined) {
                                                    newVal && Object.keys(temp).map(key => newVal.key === key ? temp[key].checked = true : false);
                                                }
                                                setTaxonomyData(temp);
                                            }}></Select>
                                        }
                                    </div>
                                    <div className={keyWordQuery ? "query-form query-form-disabled" : "query-form"}>
                                        <label>Discipline/Community</label>
                                        {
                                            <Select id="discpline-select" isDisabled={keyWordQuery} ref={disciplineRef} options={disciplineOptions} isMulti={false} maxMenuHeight={120} placeholder="Select..." isClearable={false} onChange={(newVal: any) => {
                                                const temp = [...disciplineData];
                                                if (newVal && newVal.key !== undefined) {
                                                    newVal && temp.map(obj => {
                                                        if (newVal.key === obj.key) {
                                                            obj.checked = true
                                                        }
                                                        else {
                                                            obj.checked = false
                                                        }
                                                    });
                                                }
                                                setDisciplineData(temp);
                                            }}></Select>
                                        }
                                    </div>

                                    <div className={keyWordQuery ? "query-form query-form-disabled" : "query-form"}>
                                        <label>Principal Expertise Area</label>
                                        {
                                            <Select id="PTE-select" isDisabled={keyWordQuery} ref={PTERef} options={PTEOptions} isMulti={false} maxMenuHeight={120} placeholder="Select..." isClearable={false} onChange={(newVal: any) => {
                                                const temp = [...PTEData];
                                                if (newVal && newVal.key !== undefined) {
                                                    newVal && temp.map(obj => {
                                                        if (newVal.key === obj.key) {
                                                            obj.checked = true;
                                                        }
                                                        else {
                                                            obj.checked = false
                                                        }
                                                    });
                                                }
                                                setPTEData(temp);
                                            }}></Select>
                                        }

                                    </div>

                                    <Form.Check
                                        type="radio"
                                        label="Keyword"
                                        name="query-type"
                                        id="keyword-query"
                                        className="keyword-checkbox"
                                        onChange={(e) => {
                                            setFunctionQuery(!e.currentTarget.checked);
                                            setKeyWordQuery(e.currentTarget.checked);
                                        }}
                                    />

                                    <div className={functionQuery ? "query-form query-form-disabled" : "query-form"}>
                                        <label></label>
                                        <textarea rows={4} value={keyWord} onChange={(e) => setKeyWord(e.currentTarget.value)} disabled={functionQuery}> </textarea>
                                    </div>

                                </div>
                                : config.type === "EE" ?
                                    <div className="common-checkbox-wrapper">
                                        <div className="query-form">
                                            <label>Function</label>
                                            {
                                                <Select id="function-select" ref={functionRef} options={functionOptions} value={defaultFunctionVal} defaultValue={defaultFunctionVal} maxMenuHeight={120} placeholder="Select..." isMulti={false} isClearable={false} onChange={(newVal: any) => {
                                                    const temp = { ...taxonomyData };
                                                    Object.keys(temp).forEach(key => temp[key].checked = false); //Need to optimize
                                                    if (newVal && newVal.key !== undefined) {
                                                        newVal && Object.keys(temp).map(key => {
                                                            if (newVal.key === key) {
                                                                temp[key].checked = true;
                                                                setDefaultFunctionVal([{ value: newVal.label, label: newVal.label }]);
                                                                setDefaultDisciplineVal([{ value: "", label: "" }]);
                                                                setDefaultPteVal([{ value: "", label: "" }]);
                                                            }
                                                            else {
                                                                temp[key].checked = false;
                                                            }
                                                        });
                                                    }
                                                    setTaxonomyData(temp);
                                                }}></Select>
                                            }
                                        </div>
                                        <div className="query-form">
                                            <label>Discipline/Community</label>
                                            {
                                                <Select id="discpline-select" ref={disciplineRef} options={disciplineOptions} value={defaultDisciplineVal} defaultValue={defaultDisciplineVal} placeholder="Select..." isMulti={false} maxMenuHeight={100} isClearable={false} onChange={(newVal: any) => {
                                                    const temp = [...disciplineData];
                                                    if (newVal && newVal.key !== undefined) {
                                                        newVal && temp.map(obj => {
                                                            if (newVal.key === obj.key) {
                                                                obj.checked = true;
                                                                setDefaultDisciplineVal([{ value: newVal.label, label: newVal.label }]);
                                                            }
                                                            else {
                                                                obj.checked = false
                                                            }
                                                        });
                                                    }
                                                    setDisciplineData(temp);
                                                }}></Select>
                                            }

                                        </div>

                                        <div className="query-form">
                                            <label>Principal Expertise Area</label>
                                            {
                                                <Select id="PTE-select" ref={PTERef} options={PTEOptions} value={defaultPteVal} defaultValue={defaultPteVal} isMulti={false} maxMenuHeight={100} placeholder="Select..." isClearable={false} onChange={(newVal: any) => {
                                                    const temp = [...PTEData];
                                                    if (newVal && newVal.key !== undefined) {
                                                        newVal && temp.map(obj => {
                                                            if (newVal.key === obj.key) {
                                                                obj.checked = true;
                                                                setDefaultPteVal([{ value: newVal.label, label: newVal.label }]);
                                                            }
                                                            else {
                                                                obj.checked = false
                                                            }
                                                        });
                                                    }
                                                    setPTEData(temp);
                                                }}></Select>
                                            }

                                        </div>
                                    </div>
                                    : config.type === "Workday" &&
                                    <div className="common-checkbox-wrapper">
                                        <div className="query-form">
                                            <label>Function</label>
                                            {
                                                <Select id="function-select" ref={functionRef} options={functionOptions} value={defaultFunctionVal} defaultValue={defaultFunctionVal} placeholder="Select..." maxMenuHeight={120} isMulti={false} isClearable={false} onChange={(newVal: any) => {
                                                    const temp = { ...taxonomyData };
                                                    Object.keys(temp).forEach(key => temp[key].checked = false); //Need to optimize
                                                    if (newVal && newVal.key !== undefined) {
                                                        newVal && Object.keys(temp).map(key => {
                                                            if (newVal.key === key) {
                                                                temp[key].checked = true;
                                                                setDefaultFunctionVal([{ value: newVal.label, label: newVal.label }]);
                                                                setDefaultDisciplineVal([{ value: "", label: "" }]);
                                                                setDefaultPteVal([{ value: "", label: "" }]);
                                                                setDefaultSmeVal([{ value: "", label: "" }]);
                                                            }
                                                            else {
                                                                temp[key].checked = false;
                                                            }
                                                        });
                                                    }
                                                    setTaxonomyData(temp);
                                                }}></Select>
                                            }
                                        </div>
                                        <div className="query-form">
                                            <label>Discipline/Community</label>
                                            {
                                                <Select id="discpline-select" ref={disciplineRef} options={disciplineOptions} value={defaultDisciplineVal} defaultValue={defaultDisciplineVal} placeholder="Select..." isMulti={false} maxMenuHeight={100} isClearable={false} onChange={(newVal: any) => {
                                                    const temp = [...disciplineData];
                                                    if (newVal && newVal.key !== undefined) {
                                                        newVal && temp.map(obj => {
                                                            if (newVal.key === obj.key) {
                                                                obj.checked = true;
                                                                setDefaultDisciplineVal([{ value: newVal.label, label: newVal.label }]);
                                                            }
                                                            else {
                                                                obj.checked = false
                                                            }
                                                        });
                                                    }
                                                    setDisciplineData(temp);
                                                }}></Select>
                                            }

                                        </div>

                                        {/*<div className="query-form">*/}
                                        {/*    <label>Principal Expertise Area</label>*/}
                                        {/*    {*/}
                                        {/*        <Select id="PTE-select" ref={PTERef} options={PTEOptions} value={defaultPteVal} defaultValue={defaultPteVal}  isMulti={false} maxMenuHeight={100} placeholder="Select..." isClearable={false} onChange={(newVal: any) => {*/}
                                        {/*            const temp = [...PTEData];*/}
                                        {/*            if (newVal && newVal.key !== undefined) {*/}
                                        {/*                newVal && temp.map(obj => {*/}
                                        {/*                    if (newVal.key === obj.key) {*/}
                                        {/*                        obj.checked = true;*/}
                                        {/*                        setDefaultPteVal([{ value: newVal.label, label: newVal.label }]);*/}
                                        {/*                    }*/}
                                        {/*                    else {*/}
                                        {/*                        obj.checked = false*/}
                                        {/*                    }*/}
                                        {/*                });*/}
                                        {/*            }*/}
                                        {/*            setPTEData(temp);*/}
                                        {/*        }}></Select>*/}
                                        {/*    }*/}

                                        {/*</div>*/}

                                        {/*<div className="query-form">*/}
                                        {/*    <label>Sub Expertise Area</label>*/}
                                        {/*    {*/}
                                        {/*        <Select id="SME-select" ref={PTERef} options={SMEOptions} value={defaultSmeVal} defaultValue={defaultSmeVal} isMulti={false} maxMenuHeight={100} placeholder="Select..." isClearable={false} onChange={(newVal: any) => {*/}
                                        {/*            const temp = [...SMEData];*/}
                                        {/*            if (newVal && newVal.key !== undefined) {*/}
                                        {/*                newVal && temp.map(obj => {*/}
                                        {/*                    if (newVal.key === obj.key) {*/}
                                        {/*                        obj.checked = true;*/}
                                        {/*                        setDefaultSmeVal([{ value: newVal.label, label: newVal.label }]);*/}
                                        {/*                    }*/}
                                        {/*                    else {*/}
                                        {/*                        obj.checked = false*/}
                                        {/*                    }*/}
                                        {/*                });*/}
                                        {/*            }*/}
                                        {/*            setSMEData(temp);*/}
                                        {/*        }}></Select>*/}
                                        {/*    }*/}

                                        {/*</div>*/}
                                    </div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        maxCount === true ? <div className="forum-selection">You cannot select more than 5 forums</div> : ""
                    }
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => { setShowModal(false); setFunctionQuery(true); setKeyWordQuery(true); setMaxCount(false); }}> Cancel </Button>
                    <Button variant="primary" className="proceed-button" onClick={() => { updateConfigurationItems(taxonomyData, disciplineOptions, PTEOptions, SMEOptions, keyWord, signForumDetails); setShowModal(false); setFunctionQuery(true); setKeyWordQuery(true); setMaxCount(false) }}>Save Query</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )

    function filterForumData(forumName?: any) {
        console.log(forumName);
    }

    function getSIGNForumDetails() {
        sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("signForumNames").items.select("signTechFunc,signForums,ID").top(4999).orderBy('Modified').get().then((signResponse: any) => {
            console.log(signResponse);
            if (signResponse.length > 0) {
                setSignTechFunc(JSON.parse(signResponse[0].signTechFunc));
                setSignForumDetails(JSON.parse(signResponse[0].signForums));
            }

        });
    }

    function processResponse(data: any) {

        let results = {};
        data.map((o, i) => {

            if (o.Parent) {
                const categories = o.PathOfTerm.split(";");

                if (categories[0] && results[categories[0]] === undefined) {
                    results[categories[0]] = {}
                    results[categories[0]].checked = false;
                    results[categories[0]].items = []
                }

                if (categories[1] && results[categories[0]][categories[1]] === undefined) {
                    results[categories[0]][categories[1]] = {}
                    results[categories[0]][categories[1]].items = [];
                    results[categories[0]][categories[1]].checked = false;
                }

                if (categories[2] && results[categories[0]][categories[1]][categories[2]] === undefined) {
                    results[categories[0]][categories[1]][categories[2]] = {}
                    results[categories[0]][categories[1]][categories[2]].items = [];
                    results[categories[0]][categories[1]][categories[2]].checked = false;
                }

                if (categories[3] && results[categories[0]][categories[1]][categories[2]][categories[3]] === undefined) {
                    results[categories[0]][categories[1]][categories[2]][categories[3]] = {}
                    results[categories[0]][categories[1]][categories[2]][categories[3]].items = [];
                    results[categories[0]][categories[1]][categories[2]][categories[3]].checked = false;
                }

                if (categories.length && categories.length === 1) {
                    o.checked = false;
                    results[categories[0]].items.push(o);
                    results[categories[0]].ID = o.Id;
                }

                else if (categories.length && categories.length === 2) {
                    o.checked = false;
                    results[categories[0]][categories[1]].items.push(o);
                    results[categories[0]][categories[1]].ID = o.Id;
                }

                else if (categories.length && categories.length === 3) {
                    o.checked = false;
                    results[categories[0]][categories[1]][categories[2]].items.push(o);
                    results[categories[0]][categories[1]][categories[2]].ID = o.Id;
                }

                else if (categories.length && categories.length === 4) {
                    o.checked = false;
                    results[categories[0]][categories[1]][categories[2]][categories[3]].items.push(o);
                    results[categories[0]][categories[1]][categories[2]][categories[3]].ID = o.Id;
                }
            }
            else {
                if (results[o.Name] === undefined) {
                    results[o.Name] = {}
                    results[o.Name].ID = o.Id;
                    results[o.Name].items = [];
                    results[o.Name].checked = false;
                }
                results[o.Name].ID = o.Id;
            }
        })

        setInitialData(JSON.parse(JSON.stringify(results)));
        setTaxonomyData(results);
        let temp = [];
        Object.keys(results).forEach(key => {

            temp.push({
                key: key,
                value: key,
                label: key
            })
        })
        temp = temp.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        setFunctionOptions(temp);
        return results;
    }



    function sendToAppInsights(webpartname: string) {
        helpers.getUser((e: any) => {
            AppInsights.trackEvent('SPOA3.0', {
                PageUrl: window.location.href,
                PageTitle: document.title,
                EventType: 'WebPart click',
                WebPartType: webpartname + ' Web Part',
                WebPartHeader: webpartname,
                UserEmail: e.Email
            });
            AppInsights.flush();
        });
    }

};

export default CardWidget;