import React, { Component, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./menuModalAdd.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import IMenuModal from "../menuModal/MenuModal.interface";
import Alert from "react-bootstrap/Alert";
import { FaArrowLeft } from "react-icons/fa";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { sp as pnpsp } from "@pnp/sp";
import { SPFetchClient } from "@pnp/nodejs";
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";
import { objectDefinedNotNull } from "@pnp/common";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import helpers from '../helpers';
import { convertFromHTML, convertToRaw, EditorState, Modifier, ContentState, AtomicBlockUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const MenuModalAdd = (props: any) => {

    const { showDialog, handleClose, categories, dialogType, links, getData, setShowLoading, getLinksForHeader } = props;

    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [allLinks, setAllLinks] = useState(links);

    const [mainCategories, setMainCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    const [subCategories, setSubCategories] = useState([]);

    const [menuCaption, setmenuCaption] = useState("");
    const [menuURL, setmenuURL] = useState("");
    const [toggleView, setToggleView] = useState("");
    const [showAddMainLink, setshowAddMainLink] = useState(false);
    const [showDescriptionLinks, setshowDescriptionLinks] = useState(true);
    const [showMainLinksDescription, setshowMainLinksDescription] = useState(false);
    const [mainLinkDescription, setMainLinkDescription] = useState("");
    const [showNewCategoryError, setShowNewCategoryError] = useState(false);
    const [showNewLinkError, setShowNewLinkError] = useState(false);
    const [showNewSubCategoryError, setShowNewSubCategoryError] = useState(false);
    const [showLinkOnHomePage, setShowLinkOnHomePage] = useState(false);
    const [newCategoryLength, setNewCategoryLength] = useState(0);
    const [newSubLinkCategoryLength, setNewSubLinkCategoryLength] = useState(0);
    const [linkTitleLength, setLinkTitleLength] = useState(0);
    const [linkDescLength, setLinkDescLength] = useState(0);
    const [mainLinkDescLength, setMainLinkDescLength] = useState(0);
    const [validated, setValidated] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    type TFile = {
        showError?: boolean,
        type?: any,
        message?: string
    }
    const [filecheck, setFileCheck] = useState({} as TFile);
    const [previewSrc, setPreviewSrc] = useState("");
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [loading, setLoading] = useState(false);
    useEffect(() => {
    }, [editorState])

    let desc_options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: decodeURIComponent(data.url),
                        target: '_blank'
                    },
                };
            }
        },
    };
    return (
        <div id="menu-modal-add">
            <Modal
                centered={true}
                backdrop="static"
                show={showDialog}
                onShow={() => onDialogOpen()}
                onHide={() => onDialogClose(false)}
            >
                <div
                    id="category-wrapper"
                    className={toggleView === "AddCategory" ? "active" : ""}
                >
                    <div>
                        <Modal.Header closeButton>
                            <FaArrowLeft
                                onClick={() => {

                                    let txtbox = document.getElementById("textbox-new-sub-link-category") as HTMLInputElement;
                                    txtbox.classList.remove("is-valid");
                                    txtbox.classList.remove("is-invalid");
                                    txtbox.value = "";

                                    setToggleView("");
                                }}
                                className="arrow-back-category"
                            />{" "}
                            <Modal.Title>Add New Link Category</Modal.Title>
                        </Modal.Header>
                    </div>

                    <Modal.Body className="modal-body-category">

                        <Form className="form-controls" onSubmit={e => { e.preventDefault(); }}>
                            <Form.Group>
                                <Form.Label>Link Category Name <span className='mandatory'>*</span> </Form.Label>
                                <Form.Control
                                    type="input"
                                    placeholder="Ex: CSO Leaders and Experts"
                                    id="textbox-new-sub-link-category"
                                    maxLength={100}
                                    onChange={(e) => {

                                        if (e.currentTarget.value === "" || /^ *$/.test(e.currentTarget.value)) {
                                            e.currentTarget.classList.remove("is-valid");
                                            e.currentTarget.classList.add("is-invalid");
                                        }
                                        else {
                                            e.currentTarget.classList.remove("is-invalid");
                                            e.currentTarget.classList.add("is-valid");
                                        }
                                        setNewSubLinkCategoryLength(e.currentTarget.value.length);

                                    }}

                                    onBlur={(e) => {
                                        if (e.currentTarget.value === "") {
                                            e.currentTarget.classList.remove("is-valid");
                                            e.currentTarget.classList.add("is-invalid");
                                        }
                                        else {
                                            e.currentTarget.classList.remove("is-invalid");
                                            e.currentTarget.classList.add("is-valid");
                                        }
                                        setNewSubLinkCategoryLength(e.currentTarget.value.length);
                                    }
                                    }

                                />
                                <Form.Text className="text-muted">
                                    {newSubLinkCategoryLength ? "" + (100 - newSubLinkCategoryLength) + " characters remaining" : "100 characters max"}
                                </Form.Text>
                            </Form.Group>
                            {showNewSubCategoryError ? <Alert variant="warning"> Link Category with same name exists, please provide other name. </Alert> : null}
                        </Form>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer-category">
                        <Button
                            className="button-cancel-menu"
                            variant="secondary"
                            onClick={() => setToggleView("")}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => saveNewSubCategory()}>
                            Save Category Name
                        </Button>
                    </Modal.Footer>
                </div>

                <div
                    id="category-wrapper"
                    className={toggleView === "AddMainCategory" ? "active" : ""}
                >
                    <div>
                        <Modal.Header closeButton>
                            <FaArrowLeft
                                onClick={() => {
                                    let txtbox = document.getElementById("textbox-new-main-link-category") as HTMLInputElement;
                                    txtbox.classList.remove("is-valid");
                                    txtbox.classList.remove("is-invalid");
                                    txtbox.value = "";
                                    setToggleView("");
                                }}
                                className="arrow-back-category"
                            />{" "}
                            <Modal.Title>Add New Main Link Category</Modal.Title>
                        </Modal.Header>
                    </div>
                    <Modal.Body className="modal-body-category">

                        <Form className="form-controls" onSubmit={e => { e.preventDefault(); }} id="add-new-main-link-category-form-container">
                            <Form.Group>
                                <Form.Label>Main Link Category Name <span className='mandatory'>*</span> </Form.Label>
                                <Form.Control
                                    type="input"
                                    id="textbox-new-main-link-category"
                                    placeholder="Ex: Knowledge Management"
                                    maxLength={50}
                                    onChange={(e) => {
                                        if (e.currentTarget.value === "" || /^ *$/.test(e.currentTarget.value)) {
                                            e.currentTarget.classList.remove("is-valid");
                                            e.currentTarget.classList.add("is-invalid");
                                        }
                                        else {
                                            e.currentTarget.classList.remove("is-invalid");
                                            e.currentTarget.classList.add("is-valid");
                                        }
                                        setNewCategoryLength(e.currentTarget.value.length);
                                    }
                                    }

                                    onBlur={(e) => {
                                        if (e.currentTarget.value === "" || /^ *$/.test(e.currentTarget.value)) {
                                            e.currentTarget.classList.remove("is-valid");
                                            e.currentTarget.classList.add("is-invalid");
                                        }
                                        else {
                                            e.currentTarget.classList.remove("is-invalid");
                                            e.currentTarget.classList.add("is-valid");
                                        }
                                        setNewCategoryLength(e.currentTarget.value.length);
                                    }
                                    }


                                />
                                <Form.Text className="text-muted">
                                    {newCategoryLength ? "" + (50 - newCategoryLength) + " characters remaining" : "50 characters max"}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group id="new-main-category-show-on-homepage">
                                <Form.Check type="checkbox" label="Add to Important Links section" />
                                {showNewCategoryError ? <Alert variant="warning"> Category with same name exists, please provide other name. </Alert> : null}
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer-category">
                        <Button
                            className="button-cancel-menu"
                            variant="secondary"
                            onClick={() => setToggleView("")}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => {

                            const form = document.getElementById("add-new-main-link-category-form-container") as HTMLFormElement;
                            if (form.checkValidity() === false) {
                                //to handle when validity fails
                            }
                            else {
                                saveNewMainLinkCategory()
                            }
                        }

                        }>
                            Save Category Name
                        </Button>
                    </Modal.Footer>
                </div>

                <div
                    id="add-new-menu"
                    className={
                        toggleView == "AddCategory" || toggleView == "AddMainCategory"
                            ? "activeMenu"
                            : ""
                    }
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add link to category</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-body-add">






                        {/* <Form noValidate id="main-form"> */}
                        {/* <Form noValidate onSubmit={handleSubmit}> */}
                        <Form noValidate id="main-form" validated={validated}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Select Main Link Category <span className='mandatory'>*</span></Form.Label>
                                <span className="add-new-category">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a onClick={() => {
                                        let homepage = document.querySelector("#new-main-category-show-on-homepage input") as HTMLInputElement;
                                        homepage.checked = false;
                                        setToggleView("AddMainCategory")
                                    }}>
                                        + Add New Main Link Category
                                    </a>
                                </span>

                                <Form.Control as="select" required value={selectedCategory} onChange={(e: any) => {

                                    setSelectedCategory(
                                        //e.currentTarget.selectedOptions[0].getAttribute("id") //keeping commented code for future reference
                                        e.currentTarget.options[e.currentTarget.selectedIndex].id
                                    )

                                    if (e.currentTarget.options[e.currentTarget.selectedIndex].innerText.trim() !== "Click to Select..") {
                                        setSubCategories(mainCategories[e.currentTarget.options[e.currentTarget.selectedIndex].innerText.trim()].subCategories);
                                        //setMainLinkDescription(mainCategories[e.currentTarget.options[e.currentTarget.selectedIndex].innerText.trim()].SpoADescription.replace(/(<([^>]+)>)/ig, ""));
                                        var div = document.createElement("div");
                                        div.innerHTML = mainCategories[e.currentTarget.options[e.currentTarget.selectedIndex].innerText.trim()].SpoADescription;
                                        var convertedText = div.textContent || div.innerText || "";
                                        let spoaMainLinkDescription = '';
                                        if (convertedText != "") {
                                            spoaMainLinkDescription = convertedText;
                                        } else {
                                            spoaMainLinkDescription = "";
                                        }
                                        setMainLinkDescription(spoaMainLinkDescription);
                                        setshowMainLinksDescription(
                                            mainCategories[e.currentTarget.options[e.currentTarget.selectedIndex].innerText.trim()].SpoADescription.length ?
                                                true : false
                                        )
                                    }

                                    else {
                                        setSubCategories([]);
                                        setMainLinkDescription("");
                                        setshowMainLinksDescription(false);
                                    }



                                }
                                }
                                >
                                    <option value="">Click to Select..</option>
                                    {// categories.map( (o:any) => <option key={`category` + o.id} id={o.id}> { o.category } </option>)
                                        Object.keys(mainCategories).map((o: any, index: any) => (
                                            <option key={`m-category-` + index} value={mainCategories[o].ID} id={mainCategories[o].ID}>
                                                {" "}
                                                {o}{" "}
                                            </option>
                                        ))}

                                </Form.Control>

                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Main Link Category Description</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-1"
                                    className="custom-switch-1"
                                    checked={showMainLinksDescription}
                                    label=""
                                    title=""
                                    onChange={() => setshowMainLinksDescription(!showMainLinksDescription)}
                                />
                            </Form.Group>

                            <Form.Group controlId="main-category-description" hidden={!showMainLinksDescription}>
                                <Form.Control as="textarea" value={mainLinkDescription} rows={3} maxLength={250} onChange={(e: any) => {
                                    setMainLinkDescription(e.currentTarget.value)
                                    setMainLinkDescLength(e.currentTarget.value.length);
                                }}

                                    onBlur={(e) => {
                                        setMainLinkDescLength(e.currentTarget.value.length);
                                    }}

                                />

                                <Form.Text className="text-muted">
                                    {setMainLinkDescLength ? "" + (250 - mainLinkDescLength) + " characters remaining" : "250 characters max"}
                                </Form.Text>


                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Select Link Category <span className='mandatory'>*</span></Form.Label>
                                <span className="add-new-category">
                                    {/* {
                  
                    selectedCategory === "" ? 
                      <a href="#"> + Add New Link Category (Select a Main Link Category)</a>
                     : <a href="#" onClick={() => setToggleView("AddCategory")}> + Add New Link Category</a>
                  
                  } */}

                                    <a onClick={() => setToggleView("AddCategory")}> + Add New Link Category</a>
                                </span>
                                <Form.Control as="select"
                                    id="selected-sub-category"
                                    required
                                    value={selectedSubCategory}
                                    onChange={(e: any) => {

                                        setSelectedSubCategory(
                                            e.currentTarget.options[e.currentTarget.selectedIndex].id
                                        )
                                    }}
                                >
                                    <option value="">Click to Select..</option>
                                    {// categories.map( (o:any) => <option key={`category` + o.id} id={o.id}> { o.category } </option>)
                                        Object.values(subCategories).map((o: any, index: any) => (
                                            <option key={`m-category-` + index} value={o.ID} id={o.ID}>
                                                {" "}
                                                {o.Title}{" "}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Link Title <span className='mandatory'>*</span></Form.Label>
                                <Form.Control
                                    type="input"
                                    required
                                    pattern="^[a-zA-Z1-9].*"
                                    value={menuCaption}
                                    maxLength={150}
                                    placeholder="Ex: Knowledge Management"
                                    onChange={(e: any) => {
                                        setmenuCaption(e.target.value);
                                        setLinkTitleLength(e.currentTarget.value.length);
                                    }}

                                    onBlur={(e) => {
                                        setLinkTitleLength(e.currentTarget.value.length);
                                    }}

                                />
                                <Form.Text className="text-muted">
                                    {linkTitleLength ? "" + (150 - linkTitleLength) + " characters remaining" : "150 characters max"}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Target Link <span className='mandatory'>*</span></Form.Label>
                                <Form.Control
                                    type="url"
                                    required
                                    pattern="https?://.+"
                                    placeholder="Ex: https://eu001-sp/shellcom/sites/AAAAA91/default.aspx"
                                    onChange={(e: any) => setmenuURL(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Link Description</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-2"
                                    label=""
                                    title="New Link on Top"
                                    checked={showDescriptionLinks}
                                    onChange={() => setshowDescriptionLinks(!showDescriptionLinks)}
                                />

                            </Form.Group>
                            <Form.Group
                                controlId="link-description"
                                hidden={!showDescriptionLinks}
                            >
                                <Editor editorState={editorState}
                                    placeholder="Add Text"
                                    onEditorStateChange={e => { setEditorState(e) }}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class" />
                                {/*<Form.Control as="textarea" rows={3} maxLength={350}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        setLinkDescLength(e.currentTarget.value.length);*/}
                                {/*    }}*/}

                                {/*    onBlur={(e) => {*/}
                                {/*        setLinkDescLength(e.currentTarget.value.length);*/}
                                {/*    }}*/}

                                {/*/>*/}

                                {/*<Form.Text className="text-muted">*/}
                                {/*    {linkDescLength ? "" + (350 - linkDescLength) + " characters remaining" : "350 characters max"}*/}
                                {/*</Form.Text>*/}
                                <Form.Group controlId="formImage">
                                    <Form.Label className="img-txt">Select image from computer (Supported formats - jpg, jpeg, png, bmp, jfif) </Form.Label>
                                    {filecheck.showError ? <Alert variant={filecheck.type}> {filecheck.message} </Alert> : null}
                                    <Form.Control className="img-upload-button" disabled={loading} type="file" id="imageuploadbanner" onChange={(e: any) => showPreview(e)} />
                                    {loading ? <div className="lds-ring"><div></div><div></div><div></div><div></div> <span className='loading-text'>Uploading image, please wait... </span> </div> : ""}

                                </Form.Group>
                            </Form.Group>

                            <Form.Group>
                                <Form.Check checked={showLinkOnHomePage} onChange={(e) => setShowLinkOnHomePage(e.target.checked)} type="checkbox" label="Add to Important Links section" />
                                {showNewLinkError ? <Alert variant="warning" className="cusotm-alert-box"> Link with same name exists, please provide other name. </Alert> : null}
                                {errorMsg ? <Alert variant="warning" className="cusotm-alert-box"> Please fill all the required fields !. </Alert> : null}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button
                        className="button-cancel-menu"
                        variant="secondary"
                        onClick={() => { onDialogClose(true); setEditorState(EditorState.createEmpty()); }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="button-save-menu"
                        variant="primary"
                        onClick={() => handleSubmit(links)}
                    >
                        Save Link
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );


    function handleSubmit(links: any) {

        const form = document.getElementById("main-form") as HTMLFormElement;
        if (form.checkValidity() === false) {
            //to handle when validity fails
            setErrorMsg(true)
            setTimeout(() => { setErrorMsg(false); }, 4000)
        }
        else {

            setErrorMsg(false)
            let newCategory: any = {};
            let newSubCategory: any = {};
            let mainDescription: any = "";
            let SpoADescription: string = "";

            mainDescription = document.getElementById("main-category-description") as HTMLInputElement;
            SpoADescription = mainDescription.value;

            Object.keys(mainCategories).forEach((o: any) => {
                if (mainCategories[o].newCategory) {
                    newCategory["Title"] = o;
                    newCategory["SpoAShowOnLanding"] = mainCategories[o].showOnHomePage;
                    newCategory["SpoADescription"] = mainCategories[o].SpoADescription;
                }
            });

            //Check if user added new category
            if (Object.keys(newCategory).length) {

                let linkDesc: any = "";

                if (showDescriptionLinks) {
                    linkDesc = stateToHTML(editorState.getCurrentContent(), desc_options);
                    //linkDesc = document.getElementById("link-description") as HTMLInputElement;
                    //linkDesc = linkDesc.value;
                }

                setShowLoading(true);
                onDialogClose(true);

                if (showMainLinksDescription) {
                    newCategory["SpoADescription"] = mainDescription.value;
                }

                window['SP_Propertyweb'].lists.getByTitle("Main Link Categories").items.add(newCategory).then((mainResp: any) => {

                    let newSubCategory = {
                        Title: mainCategories[newCategory.Title].subCategories[0].Title,
                        SpoAMainSubjectId: mainResp.data.Id,
                        SpoAOrder: 0
                    }

                    window['SP_Propertyweb'].lists.getByTitle("Link Categories").items.add(newSubCategory).then((resp: any) => {

                        let newLink = {
                            MainLinkCategoryId: mainResp.data.Id,
                            SpoASubjectId: resp.data.Id,
                            Title: menuCaption,
                            SpoADescription: linkDesc,
                            ShowOnLanding: showLinkOnHomePage,
                            SpoALinkType: showLinkOnHomePage ? "ShowOnHomePage" : "Normal",
                            SpoAHidden: false,
                            LinkURL: menuURL,
                            //URL: {
                            //  Url: menuURL,
                            //}                  
                        }

                        window['SP_Propertyweb'].lists.getByTitle("Links").items.add(newLink).then((resp: any) => {
                            getData();
                            getLinksForHeader();
                            setValidated(false);
                            setMainLinkDescription("");
                            setmenuCaption("");
                            setSelectedCategory(" ");
                            setSubCategories([]);
                            setshowMainLinksDescription(false);
                            setEditorState(EditorState.createEmpty());
                        });

                    });

                });

            }
            //User is adding links to exisitng category and sub cat
            else {

                let selectedCatName = "";
                Object.keys(mainCategories).forEach((o) => {
                    if (mainCategories[o].ID === parseInt(selectedCategory,10)) {
                        selectedCatName = o;
                    }
                });


                let categooryDetails = {};
                categooryDetails["SpoADescription"] = mainDescription.value;

                window['SP_Propertyweb'].lists.getByTitle("Main Link Categories").items.getById(parseInt(selectedCategory,10)).update(categooryDetails).then(i => {




                    let subCat = document.getElementById("selected-sub-category") as any;
                    let selectedSubCat = subCat[subCat.selectedIndex].id;

                    //If user added new sub cat
                    let newSubCat: any = "";
                    mainCategories[selectedCatName].subCategories.map((o: any) => {
                        if (o.Title === selectedSubCat && o.New !== undefined) {
                            newSubCat = o;
                        }
                    })

                    if (newSubCat) {

                        let newSubCategory = {
                            Title: newSubCat.Title,
                            SpoAMainSubjectId: mainCategories[selectedCatName].ID,
                            SpoAOrder: 0
                        }



                        window['SP_Propertyweb'].lists.getByTitle("Link Categories").items.add(newSubCategory).then((resp: any) => {
                            createLink(resp.data.ID);
                        });

                    }

                    else {

                        let linkDesc: any = "";
                        let linkExists = false;

                        links[selectedCatName][subCat.options[subCat.selectedIndex].text].map((o) => {

                            if (o.Title === menuCaption) {
                                linkExists = true;
                            }

                        });

                        if (linkExists) {
                            setShowNewLinkError(true);
                            setmenuCaption("");
                            setTimeout(() => { setShowNewLinkError(false); }, 4000)
                        }
                        else {
                            createLink(selectedSubCat);
                        }
                    }


                });


            }
        }
        setValidated(true);


    }

    function createLink(selectedSubCat: any) {

        let linkDesc: any = "";
        setShowLoading(true);
        onDialogClose(true);

        if (showDescriptionLinks) {
            //linkDesc = document.getElementById("link-description") as HTMLInputElement;
            //linkDesc = linkDesc.value;
            linkDesc = stateToHTML(editorState.getCurrentContent(), desc_options);
        }

        let newLink = {
            MainLinkCategoryId: selectedCategory,
            SpoASubjectId: selectedSubCat,
            Title: menuCaption,
            SpoADescription: linkDesc,
            ShowOnLanding: showLinkOnHomePage,
            SpoALinkType: showLinkOnHomePage ? "ShowOnHomePage" : "Normal",
            SpoAHidden: false,
            LinkURL: menuURL,
            //URL: {
            //    Url: menuURL,
            //}
        }

        window['SP_Propertyweb'].lists.getByTitle("Links").items.add(newLink).then((resp: any) => {
            getData();
            setValidated(false);
            getLinksForHeader();
            setMainLinkDescription("");
            setmenuCaption("");
            setSelectedCategory(" ");
            setSubCategories([]);
            setshowMainLinksDescription(false);
            setEditorState(EditorState.createEmpty());
        });
    }

    function onDialogOpen() {
        setMainCategories(sortMainLinkCategories(categories));
        setShowLinkOnHomePage(false);
    }

    function onDialogClose(passData: boolean) {
        passData
            ? props.handleClose({
                category: selectedCategory,
                caption: menuCaption,
                menuURL: menuURL
            })
            : props.handleClose();
        setToggleView("");
        setMainCategories([]);

        setValidated(false);
        setMainLinkDescription("");
        setmenuCaption("");
        setSelectedCategory(" ");
        setSubCategories([]);
        setEditorState(EditorState.createEmpty());
    }

    function saveNewMainLinkCategory() {

        let txtbox = document.getElementById("textbox-new-main-link-category") as HTMLInputElement;

        if (txtbox.value === "" || /^ *$/.test(txtbox.value)) {
            txtbox.classList.add("is-invalid");
        }
        else {
            txtbox.value = txtbox.value.trim();
            txtbox.classList.remove("is-valid");

            let homepage = document.querySelector("#new-main-category-show-on-homepage input") as HTMLInputElement;

            if (mainCategories[txtbox.value] === undefined) { //if the category doesnt exists then add it to the list.
                let tempMainCategories = mainCategories;

                tempMainCategories[txtbox.value] = {
                    subCategories: [],
                    newCategory: true,
                    showOnHomePage: homepage.checked,
                    ID: txtbox.value.toString(),
                    SpoADescription: ""
                }

                setMainCategories(tempMainCategories);
                //setSelectedCategory(txtbox.value);
                setTimeout(() => {
                    setSelectedCategory(txtbox.value);
                    txtbox.value = "";
                }, 200);

                setToggleView("");
                setSubCategories([]);
                setMainLinkDescription("");
                setshowMainLinksDescription(false);

            }
            else {
                setShowNewCategoryError(true);
                setTimeout(() => {
                    setShowNewCategoryError(false);
                }, 4000);
            }

        }


    }

    function saveNewSubCategory() {

        let txtbox = document.getElementById("textbox-new-sub-link-category") as HTMLInputElement;

        if (txtbox.value === "") {
            txtbox.classList.add("is-invalid");
        }
        else {

            txtbox.classList.remove("is-valid");

            let subCatExists = false;
            let selectedCatName: any = "";



            Object.keys(mainCategories).forEach(o => {

                let tempSelectedCatName: any = selectedCategory;

                if (typeof selectedCategory === "string" && !isNaN(parseInt(selectedCategory,10))) {
                    tempSelectedCatName = parseInt(selectedCategory,10);
                }

                if (tempSelectedCatName === mainCategories[o].ID) {
                    selectedCatName = o;
                }

            });

            mainCategories[selectedCatName]["subCategories"].map(o => {

                if (o.Title === txtbox.value) {
                    subCatExists = true;
                }

            });

            if (subCatExists) {
                setShowNewSubCategoryError(true);
                setTimeout(() => {
                    setShowNewSubCategoryError(false);
                }, 4000);
            }
            else {
                let tempMainCategories = mainCategories;

                // tempMainCategories[selectedCategory].subCategories.push({Title:txtbox.value, ID:txtbox.value});
                // setMainCategories(tempMainCategories);
                let selectedCatIndex: any = "";
                Object.values(tempMainCategories).forEach((o: any, i: number) => {
                    let tmp: any = selectedCategory;
                    if (typeof tmp === "string" && !isNaN(parseInt(tmp,10))) {
                        tmp = parseInt(tmp,10);
                    }

                    if (tmp === o.ID) {
                        selectedCatIndex = i;
                        o.subCategories.push({ Title: txtbox.value, ID: txtbox.value, New: true });
                    }
                });

                //setMainCategories(tempMainCategories);
                setSubCategories(tempMainCategories[Object.keys(tempMainCategories)[selectedCatIndex]].subCategories);
                //tempMainCategories[selectedCategory].subCategories.push({Title:txtbox.value, ID:txtbox.value});
                setTimeout(() => {
                    setSelectedSubCategory(txtbox.value);
                    txtbox.value = "";
                }, 200);

                setToggleView("");
            }

        }
    }

    function sortMainLinkCategories(temp: any) {
        let obj: any = temp
        var keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
            if (k1.toLocaleLowerCase() < k2.toLocaleLowerCase()) return -1;
            else if (k1.toLocaleLowerCase() > k2.toLocaleLowerCase()) return +1;
            else return 0;
        });

        var i, after = {};
        for (i = 0; i < keys.length; i++) {
            after[keys[i]] = obj[keys[i]];
            delete obj[keys[i]];
        }

        for (i = 0; i < keys.length; i++) {
            obj[keys[i]] = after[keys[i]];
        }

        Object.keys(obj).forEach((o: any) => {
            obj[o].subCategories.sort((a: any, b: any) => {
                if (a.Title.toLocaleLowerCase() < b.Title.toLocaleLowerCase()) { return -1; }
                if (a.Title.toLocaleLowerCase() > b.Title.toLocaleLowerCase()) { return 1; }
                return 0;
            })
        })

        return obj;
    }

    function onEditorStateChange(editorState) {
        setEditorState(editorState)
    };

    function showPreview(e: any) {
        let filetype;
        if (e.target.files.length > 0) {
            const selectedFile = e;
            if (e.target.files[0] != undefined || e.target.files[0] != null) {
                filetype = e.target.files[0].type;
                if (["image/png", "image/jpeg", "image/jpg", "image/bmp"].includes(filetype) === false) {
                    setFileCheck({ showError: true, type: "warning", message: "Not a valid file type" });
                    const filePicker = document.getElementById('imageuploadbanner') as HTMLInputElement;
                    setPreviewSrc("");
                }
                else {
                    setFileCheck({ showError: false, type: "warning", message: "" });
                    setLoading(true)
                    initUploadFile(e.target.files[0]);
                }
            }
        }
        else {
            setFileCheck({ showError: true, type: "warning", message: "Please select a file to Upload" });
        }
    }


    function initUploadFile(file) {
        let newDigest: any;
        //Image upload
        if (file !== null) {
            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                window['SP_Propertyweb'].getFolderByServerRelativeUrl(helpers.siteSiteAssetsURL).files.addChunked(file.name, file, data => {
                }, true).then(resp => {
                    return resp
                });
            }
            else {
                getFileBuffer(file).then(function (arrayBuffer) {
                    arrayBuffer = arrayBuffer.result;
                    var bytes = new Uint8Array(arrayBuffer);
                    var binary = '';
                    for (var b = 0; b < bytes.length; b++) {
                        binary += String.fromCharCode(bytes[b]);
                    }

                    let listServiceUrl = helpers.appweburl + "/_api/contextinfo";
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync({
                        url: listServiceUrl,
                        method: "POST",
                        headers: {
                            "Accept": "application/json; odata=verbose",
                            "Content-Type": "application/json; odata=verbose"
                        },
                        success: function (data) {
                            var jsonObject = JSON.parse(data.body.toString());
                            newDigest = jsonObject.d.GetContextWebInformation.FormDigestValue;
                            var requestHeaders = {
                                "Accept": "application/json;odata=verbose",
                                "X-RequestDigest": newDigest,
                                "Content-Type": "application/json; odata=verbose"
                            }
                            executor.executeAsync({
                                url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/GetFolderByServerRelativeUrl('" + helpers.siteSiteAssetsURL + "')/Files/Add(url='" + file.name + "',overwrite=true)?@target='" + helpers.hostweburl + "'",
                                method: "POST",
                                headers: requestHeaders,
                                binaryStringRequestBody: true,
                                body: binary,
                                success: function (data) {
                                    var jsonObject = JSON.parse(data.body.toString());
                                    let imgURL = jsonObject.d.ServerRelativeUrl;
                                    if (imgURL.startsWith("/sites")) {
                                        imgURL = helpers.siteOrigin + imgURL;
                                    }
                                    else {
                                        imgURL = jsonObject.d.ServerRelativeUrl;
                                    }
                                    const newEditorState = insertImage(editorState, imgURL);
                                    setEditorState(newEditorState);
                                    setLoading(false);
                                    setFileCheck({ showError: true, type: "success", message: "File uploaded successfully" });

                                    setTimeout(() => {
                                        setFileCheck({ showError: false });
                                    }, 3000)
                                },
                                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                    //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                },
                                state: "Update"
                            });
                        },
                        error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                            //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                        }
                    });
                });
            }
        }
    }


    function insertImage(editorState, base64) {

        const entityData = { src: base64, width: "auto", height: "200px" };
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            entityData
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity },
        );
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    }

    function getFileBuffer(file) {
        var deferred = $.Deferred();
        var reader = new FileReader();
        reader.onload = function (reader) {
            deferred.resolve(reader.target);
        }
        reader.onerror = function (e) {
            deferred.reject(e);
        }
        reader.readAsArrayBuffer(file);
        return deferred.promise();
    }


};

export default MenuModalAdd;
