import React, { useState, useEffect } from 'react';
import './Events.scss';
import { FiDownload, FiCalendar } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { sp as pnpsp } from "@pnp/sp";
import { MdAddCircleOutline } from "react-icons/md";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FiMoreVertical } from "react-icons/fi";
import { MdRotateRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";
import helpers from '../helpers';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import moment from 'moment-timezone';
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function Events(params: any) {

    const { editMode } = params;
    const [show, setShow] = useState(false);
    const [events, setEvents] = useState([]);
    const [isHidden, setIsHidden] = useState(params.hidden);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const handleClose = () => setShow(false);

    let location = useLocation();
    const Url_Paramater = location.pathname.split("/")[1];

    useEffect(() => {
        getItems();
    }, []);


    return (
        <div id="events">

            <div className="header">
                <h1>Upcoming Events</h1>
                {editMode &&
                    (!isHidden ? <span className="hide-unhide" onClick={() => hideWebpart(true)} >Hide </span>
                        :
                        <span className="hide-unhide" onClick={() => hideWebpart(false)} >Unhide </span>
                    )
                }
                {(!isHidden && events.length) ? <Link to={'/' + Url_Paramater + "/events"}> View All </Link> : ""}
            </div>

            <div className={isHidden ? "hidden-webpart events-wrapper" : "events-wrapper"}>

                {
                    !loading ?
                        events.length ?
                            events.map((event: any, index: any) => {

                                return (
                                    <div key={index} className="events" onClick={() => {
                                        setSelectedEvent(index);
                                        setShow(true);
                                        sendToAppInsights('Upcoming Events');
                                    }}>
                                        <div className="cal-box">
                                            {event.fRecurrence && <div className='recurring-info'><div className='recurring-indicator'> <MdRotateRight /> Recurring Event  </div><div className="recurring-frequency">{event.RecurrenceData.indexOf("<repeat><daily") > 0 ? "Daily" : (event.RecurrenceData.indexOf("<repeat><weekly") > 0) ? "Weekly" : "Monthly"}</div></div>}
                                            <div className="month"> {monthNames[new Date(event.EventDate).getUTCMonth()]} {new Date(event.EventDate).getUTCDate()}, {new Date(event.EventDate).getUTCHours() + ":" +
                                                (new Date(event.EventDate).getUTCMinutes() < 10 ? "0" + new Date(event.EventDate).getUTCMinutes() + " GMT" :
                                                    new Date(event.EventDate).getUTCMinutes() + " GMT")}, {moment(moment.utc(event.EventDate).toDate()).local().format('HH:mm')} Local Time  </div>
                                        </div>
                                        <div className="details">
                                            <div className="event-title"> {event.Title.length > 32 ? event.Title.substr(0, 32) + "..." : event.Title} </div>
                                            <div className="event-location"> {event.Location ? event.Location : ""} </div>
                                            {event.Attachments && <div className="download-invite"><a onClick={(e: any) => e.stopPropagation()} target="_blank" rel="noopener noreferrer" href={helpers.siteOrigin + event.AttachmentFiles[0].ServerRelativeUrl} > <FiDownload /> <span>Download Invite</span> </a></div>}

                                        </div>
                                    </div>
                                )
                            })
                            : <div className="no-events">
                                There are no upcoming events recorded for this community. Please contact your moderator to Add An Event.
                                {/* To be handled after implementing authentication
                        {/* { isModerator ? <div className="add-event"> <MdAddCircleOutline /> Add Events</div> : "" } */}
                                {/* <div className="add-event"> <MdAddCircleOutline /> Add Events</div> */}
                            </div>
                        :
                        <div className="events-loading">
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">

                                <Skeleton height={86}></Skeleton>

                                <Skeleton height={86}></Skeleton>

                                <Skeleton height={86}></Skeleton>

                            </SkeletonTheme>
                        </div>
                }

            </div>

            <Modal id="events-dialog" show={show} onHide={handleClose} dialogClassName="modal-90w" backdrop='static' centered size='lg'>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body >

                    {
                        selectedEvent !== null ?



                            <div className="events dialog-event">
                                <div className="cal-box">
                                    <div className="month"> {monthNames[new Date(events[selectedEvent].EventDate).getMonth()]} </div>
                                    <div className="date"> {new Date(events[selectedEvent].EventDate).getDate()} </div>
                                    <div className="time"> {new Date(events[selectedEvent].EventDate).getUTCHours() + ":" +
                                        (new Date(events[selectedEvent].EventDate).getUTCMinutes() < 10 ? "0" + new Date(events[selectedEvent].EventDate).getUTCMinutes() + " GMT" :
                                            new Date(events[selectedEvent].EventDate).getUTCMinutes() + " GMT")} </div>
                                    <div className="local-time">{moment(moment.utc(events[selectedEvent].EventDate).toDate()).local().format('HH:mm')} Local Time</div>
                                </div>
                                <div className="details">
                                    <div className="event-title"> {events[selectedEvent].Title} </div>
                                    <div className="event-location"> {events[selectedEvent].Location ? events[selectedEvent].Location : ""}  </div>
                                    <div className="event-description" dangerouslySetInnerHTML={events[selectedEvent].Description ? helpers.createMarkup(events[selectedEvent].Description.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection)) : helpers.createMarkup("")}>

                                    </div>

                                    <div className="event-file">

                                        {

                                            events[selectedEvent].Attachments ?
                                                <span>
                                                    <FiCalendar /> {events[selectedEvent].AttachmentFiles[0].ServerRelativeUrl.split("/")[events[selectedEvent].AttachmentFiles[0].ServerRelativeUrl.split("/").length - 1]}
                                                    <a onClick={(e: any) => e.stopPropagation()} target="_blank" rel="noopener noreferrer" href={helpers.siteOrigin + events[selectedEvent].AttachmentFiles[0].ServerRelativeUrl} ><FiDownload /> Download Invite </a>
                                                </span>
                                                :
                                                ""
                                        }

                                    </div>
                                    <div className="event-notes"> {events[selectedEvent].EventNotes ? events[selectedEvent].EventNotes : ""}  </div>
                                </div>
                            </div>


                            :

                            ""

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    )


    function getItems() {

        let selectedStartDate = new Date();
        let selectedEndDate = new Date(new Date().setDate(selectedStartDate.getDate() + 3650));

        selectedStartDate.setHours(0, 0, 0, 0);
        selectedEndDate.setHours(11, 59, 0, 0);

        let s = new Date(selectedStartDate.toString());
        let e = new Date(selectedEndDate.toString());

        s.setUTCMonth(selectedStartDate.getMonth(), selectedStartDate.getDate());
        s.setUTCHours(0, 0, 0, 0);

        e.setUTCMonth(e.getMonth(), e.getDate());
        e.setUTCHours(11, 59, 0, 0);

        window['SP_Propertyweb'].lists.getByTitle("Calendar").items.select("Title, Attachments, AttachmentFiles, Description, AttachmentFiles/ServerRelativeUrl, EventDate, Title, Location, EventNotes,fRecurrence,RecurrenceData,EndDate").expand("AttachmentFiles").filter('EndDate ge datetime' + "'" + s.toISOString() + "'" + ' and EventDate le datetime' + "'" + e.toISOString() + "'").top(5).orderBy('EventDate').get().then((response: any) => {
            setEvents(response);
            setLoading(false);
        });


    }
    function sendToAppInsights(webpartname: string) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Upcoming Events Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.userEmail
        });
        params.AppInsights.flush();
    }

    function hideWebpart(toHide: boolean) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Application eq 'Upcoming Events'").get().then(res => {
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(res[0].Id, 10)).update({ IsHidden: toHide }).then(i => {
                setIsHidden(toHide);
                getItems();
            }).catch(e => {
                console.log("error updating Upcomming Events Webpart!", e)
            });
        }).catch(e => {
            console.log("error updating Upcomming Events Webpart!", e)
        });
    }

}
