import React, { useState, useEffect } from 'react';
import './AllEvents.scss';
import { FiDownload, FiCalendar } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { sp as pnpsp } from "@pnp/sp";
import EditEvents from '../EditEvents/EditEvents'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FiMoreVertical } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination'
import { MdAddCircleOutline, MdCreate, MdRotateRight, MdRefresh } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import AddEvents from '../addEvents/AddEvents';
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";
import helpers from '../helpers';
import moment from 'moment-timezone';

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function AllEvents(params: any) {

    const recordsPerPage = 30; //This will decide the pagination count.
    const handleCloseDialog = () => setShow(false);
    const { editMode } = params;
    const [show, setShow] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [eventType, setEventType] = useState("upcoming");
    const [minStartDate, setMinStartDate] = useState<Date>(new Date());
    const [maxStartDate, setMaxStartDate] = useState<Date>();
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date(new Date().setDate(selectedStartDate.getDate() + 3650)));
    const [minEndDate, setMinEndDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() + 1)));
    const [maxEndDate, setMaxEndDate] = useState<Date>();
    const [pageCount, setPageCount] = useState(5);
    const [activePage, setaActivePage] = useState(1);
    const [eventsToShow, setEventsToShow] = useState({});
    const [needRest, setNeedRest] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [data, setData] = useState();
    const [showRefresh, setShowRefresh] = useState(false);
    const addNewLink = helpers.sitecollectionurl;
    const SPoAName = window.location.hash.split("/")[1];

    useEffect(() => {
        getItems();
    }, [eventType, selectedEndDate]);

    useEffect(() => {
        //params.AppInsights.trackPageView(
        //    document.title, 
        //    window.location.href, 
        //    { userEmail: params.userEmail }, 
        //    null,
        //);    
        setTimeout(() => {
            sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoASiteName").items.select("Title,SiteURL").top(4999).get().then((response: any) => {
                response.map(o => {
                    if (o.SiteURL.Url.toLowerCase() === helpers.hostweburl.toLowerCase()) {
                        helpers.getUser((e: any) => {
                            console.log("Entries added in App Insights - Events page");
                            params.AppInsights.trackPageView(
                                o.Title,
                                window.location.href,
                                { userEmail: e.Email },
                                null,
                            );
                        });
                    }
                });
            });
        }, 3000);
    }, []);

    return (
        <div id="all-events">
            <div className="container">
                <div className="event-types">
                    <span className={eventType === 'upcoming' ? 'nav-link active' : 'nav-link'} onClick={() => loading ? false : onUpcomingEvents()} >Upcoming Events</span>
                    <span className={eventType === 'past' ? 'nav-link active' : 'nav-link'} onClick={() => loading ? false : onPastEvents()} >Past Events</span>

                    {
                        (editMode && !loading && !showRefresh) &&
                        <div className="add-events-toolbar">
                            <a className="add-link-btn" onClick={() => { addSpoaEvent() }}><MdAddCircleOutline /> Add Event </a>
                            <a className="add-link-btn" onClick={onRCEventAdd}><MdRotateRight /> Add Recurring Event </a>
                        </div>
                    }
                    {
                        (editMode && !loading && showRefresh) &&
                        <a className="link refresh" onClick={getItems}> <MdRefresh /> Refresh to see updated content</a>
                    }


                </div>
                <div className="date-filter">
                    Filter :
                    <DatePicker
                        id="all-events-date-picker-from"
                        dateFormat="dd/MM/yyyy"
                        selected={selectedStartDate}
                        onChange={date => {
                            if (date) {
                                date.setHours(0, 0, 0, 0);
                                let d = date.toString();
                                d = new Date(d);
                                setSelectedStartDate(date);
                                setSelectedEndDate(null);
                                setMinEndDate(d.addDays(1));
                                setNeedRest(true);
                            }
                        }}
                        minDate={minStartDate}
                        maxDate={maxStartDate}
                    />
                    <i className="fa fa-calendar all-events-from-icon" aria-hidden="true" onClick={() => document.getElementById('all-events-date-picker-from').click()} ></i>
                    <span className="to">to</span>
                    <DatePicker
                        id="all-events-date-picker-to"
                        dateFormat="dd/MM/yyyy"
                        autocomplete="false"
                        selected={selectedEndDate}
                        onChange={date => {
                            date.setHours(11, 59, 0, 0);
                            setSelectedEndDate(date);
                            setNeedRest(true);
                        }}
                        minDate={minEndDate}
                        maxDate={maxEndDate}
                    />
                    <i className="fa fa-calendar all-events-to-icon" aria-hidden="true" onClick={() => document.getElementById('all-events-date-picker-to').click()} ></i>
                    {needRest ? <span className="clear-filter" onClick={() => resetFilters()}> clear filter </span> : ""}
                </div>

                {showAdd ? <AddEvents setShowAdd={showAdd} handleClose={handleClose} getItems={getItems} /> : null}
                {showEdit ? <EditEvents setShowEdit={showEdit} handleClose={handleClose} data={data} getItems={getItems} /> : null}


                <div className="events-wrapper">
                    {
                        !loading ?
                            events.length ?
                                Object.values(eventsToShow).map((evnetObj: any, eventIndex: number) => {
                                    return (
                                        <div key={eventIndex}>
                                            <div className="year"> {evnetObj.year} </div>
                                            {evnetObj.events.map((event: any, index: any) => {
                                                return (
                                                    <div key={index} className="events">
                                                        <div className="cal-box" onClick={() => {
                                                            setSelectedEvent(event);
                                                            setShow(true);
                                                            sendToAppInsights('Events');
                                                        }}>
                                                            <div className="month"> {monthNames[new Date(event.EventDate).getUTCMonth()]} </div>
                                                            <div className="date"> {new Date(event.EventDate).getUTCDate()} </div>
                                                            <div className="time"> {new Date(event.EventDate).getUTCHours() + ":" +
                                                                (new Date(event.EventDate).getUTCMinutes() < 10 ? "0" + new Date(event.EventDate).getUTCMinutes() + " GMT" :
                                                                    new Date(event.EventDate).getUTCMinutes() + " GMT")} </div>
                                                            <div className="local-time">{moment(moment.utc(event.EventDate).toDate()).local().format('HH:mm')} Local Time</div>
                                                        </div>
                                                        <div className="details" onClick={() => {
                                                            setSelectedEvent(event);
                                                            setShow(true);
                                                            sendToAppInsights('Events');
                                                        }}>
                                                            <div className="event-title"> {event.Title ? event.Title.length > 255 ? event.Title.substr(0, 255) + "..." : event.Title : ""} </div>
                                                            <div className='event-tools'>
                                                                {event.fRecurrence && <div className='recurring-indicator'> <MdRotateRight /> Recurring Event </div>}
                                                                {event.RecurrenceData && <div className="recurring-frequency">{event.RecurrenceData.indexOf("<repeat><daily") > 0 ? "Daily" : (event.RecurrenceData.indexOf("<repeat><weekly") > 0) ? "Weekly" : "Monthly"}</div>}
                                                                {event.Attachments ? <a onClick={(e: any) => e.stopPropagation()} target="_blank" rel="noopener noreferrer" className="download-invite" href={helpers.siteOrigin + event.AttachmentFiles[0].ServerRelativeUrl} > <FiDownload /><span>Download Invite </span></a> : ""}
                                                            </div>
                                                            <div className="event-location">
                                                                <span> {event.Location ? event.Location : ""} </span>
                                                            </div>
                                                        </div>

                                                        {params.editMode ?

                                                            <DropdownButton
                                                                alignRight
                                                                drop='left'
                                                                title={<FiMoreVertical />}
                                                                className={params.editMode ? "edit-dots active" : "edit-dots"}
                                                                id={`main-link-category-title-` + index}
                                                            >
                                                                <Dropdown.Item eventKey="1" onClick={() => { event.fRecurrence ? onEditRCEvent(event.ID) : editFunction(event) }}><MdCreate className='editlinkicon' /> Edit Event </Dropdown.Item>
                                                                <Dropdown.Item eventKey="1" onClick={() => {
                                                                    setShowDeleteDialog(true);
                                                                    setSelectedEvent(event);
                                                                }} ><GiTrashCan className='deletelinkicon' /> Delete Event</Dropdown.Item>
                                                            </DropdownButton>
                                                            : ""
                                                        }

                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })
                                : <div className="no-events">

                                    {
                                        eventType === "upcoming" ?
                                            <div>
                                                There are no upcoming events recorded for this community.
                                                <br />
                                                Please contact the moderator for the updates.
                                                <br />
                                                {/* <div onClick={() => addSpoaEvent() } className="add-event"> <MdAddCircleOutline /> Add Events</div>*/}


                                            </div> :
                                            'There are no past events.'
                                    }

                                    {/* To be handled after implementing authentication
                        {/* { isModerator ? <div className="add-event"> <MdAddCircleOutline /> Add Events</div> : "" } */}

                                </div>
                            :
                            <div className="events-loading">
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={86}></Skeleton>
                                    <Skeleton height={86}></Skeleton>
                                    <Skeleton height={86}></Skeleton>
                                    <Skeleton height={86}></Skeleton>
                                    <Skeleton height={86}></Skeleton>
                                    <Skeleton height={86}></Skeleton>
                                    <div className="pagination-loader">
                                        <Skeleton height={20} width={500}></Skeleton>
                                    </div>
                                </SkeletonTheme>
                            </div>
                    }
                    <Pagination>
                        {
                            (!loading && events.length) ?
                                [...Array(pageCount)].map((x, i) => {
                                    return (
                                        <Pagination.Item key={i + 1} active={i + 1 === activePage} onClick={() => onPaginationClick(i)}> {i + 1} </Pagination.Item>
                                    )
                                })
                                : ""
                        }
                    </Pagination>
                </div>

                <Modal centered size="lg" id="all-events-dialog" show={show} onHide={handleCloseDialog} dialogClassName="modal-90w" backdrop='static'>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {
                            selectedEvent !== null ?
                                <div className="events dialog-event">
                                    <div className="cal-box">
                                        <div className="month"> {monthNames[new Date(selectedEvent.EventDate).getMonth()]} </div>
                                        <div className="date"> {new Date(selectedEvent.EventDate).getUTCDate()} </div>
                                        <div className="time"> {new Date(selectedEvent.EventDate).getUTCHours() + ":" +
                                            (new Date(selectedEvent.EventDate).getUTCMinutes() < 10 ? "0" + new Date(selectedEvent.EventDate).getUTCMinutes() + " GMT" :
                                                new Date(selectedEvent.EventDate).getUTCMinutes() + " GMT")} </div>
                                        <div className="local-time">{moment(moment.utc(selectedEvent.EventDate).toDate()).local().format('HH:mm')} Local Time</div>
                                    </div>
                                    <div className="details">
                                        <div className="event-title"> {selectedEvent.Title} </div>
                                        <div className="event-location"> {selectedEvent.Location ? selectedEvent.Location : ""}  </div>
                                        <div className="event-description" dangerouslySetInnerHTML={selectedEvent.Description ? helpers.createMarkup(selectedEvent.Description.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection)) : helpers.createMarkup("")}>
                                        </div>
                                        <div className="event-file">

                                            {
                                                selectedEvent.Attachments ?
                                                    <span>
                                                        <FiCalendar /> {selectedEvent.AttachmentFiles[0].ServerRelativeUrl.split("/")[selectedEvent.AttachmentFiles[0].ServerRelativeUrl.split("/").length - 1]}
                                                        <a onClick={(e: any) => e.stopPropagation()} target="_blank" rel="noopener noreferrer" href={helpers.siteOrigin + selectedEvent.AttachmentFiles[0].ServerRelativeUrl} ><FiDownload /> Download Invite </a>
                                                    </span>
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div className="event-notes"> {selectedEvent.EventNotes ? selectedEvent.EventNotes : ""}  </div>
                                    </div>
                                </div>
                                : ""
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={handleCloseDialog}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal id="delete-events-dialog" size='lg' show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)} dialogClassName="modal-90w" backdrop='static'>
                    <Modal.Header closeButton>Delete Event</Modal.Header>
                    <Modal.Body>
                        {
                            selectedEvent !== null ?
                                <div className="delete-event-body">
                                    <div className="warning">Are you sure you want to delete below event?</div>
                                    <div className="delete-event-title">{selectedEvent.Title} </div>
                                    <div className="delete-event-info">
                                        {/* Day of the week */}

                                        {

                                        }
                                        <div className="delete-event-day">
                                            {
                                                new Date(selectedEvent.EventDate).getUTCDate() + "/" +
                                                (new Date(selectedEvent.EventDate).getUTCMonth() + 1) + "/" +
                                                new Date(selectedEvent.EventDate).getUTCFullYear()

                                                //new Date(selectedEvent.EventDate).toLocaleDateString() + " - " + new Date(selectedEvent.EventDate).toLocaleDateString(undefined, { weekday: 'long' })
                                            }
                                        </div>
                                        -
                                        {/* { new Date(selectedEvent.EventDate).getDate() }  */}
                                        {/* Time */}
                                        <div className="delete-event-time">
                                            {

                                                new Date(selectedEvent.EventDate).getUTCHours() + ":" +
                                                (new Date(selectedEvent.EventDate).getUTCMinutes() < 10 ? "0" + new Date(selectedEvent.EventDate).getUTCMinutes() :
                                                    new Date(selectedEvent.EventDate).getUTCMinutes())
                                            }
                                        </div>
                                        <span className="gmt">GMT</span>
                                        {/* Location */}
                                        {selectedEvent.Location ? <span> -  {selectedEvent.Location} </span> : ""}
                                        {/* Description */}
                                        <div className="delete-event-description" dangerouslySetInnerHTML={selectedEvent.Description ? helpers.createMarkup(selectedEvent.Description.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection)) : helpers.createMarkup("")}></div>
                                        <div className="event-notes"> {selectedEvent.EventNotes ? selectedEvent.EventNotes : ""}  </div>
                                    </div>


                                </div>
                                : ""
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={() => setShowDeleteDialog(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => deleteEvent()}>
                            Delete Event
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    )


    function onRCEventAdd() {
        window.open(addNewLink + SPoAName + '/Lists/SpoACalendar/NewForm.aspx');
        setShowRefresh(true);
    }

    function onEditRCEvent(ID) {
        window.open(addNewLink + SPoAName + '/Lists/SpoACalendar/EditForm.aspx?ID=' + ID);
        setShowRefresh(true);
    }
    function deleteEvent() {

        setShowDeleteDialog(false);
        setLoading(true);

        window['SP_Propertyweb'].lists.getByTitle("Calendar").items.getById(selectedEvent.Id).recycle().then((response) => {
            getItems();
        });

    }

    function editFunction(event: any) {
        setData(event);
        setShowEdit(true);

    }


    function getItems() {
        setShowRefresh(false);

        if (selectedStartDate && selectedEndDate) {
            setLoading(true);
            let orderBy: boolean;
            if (eventType === "upcoming") {
                orderBy = true;
            }
            else if (eventType === "past") {
                orderBy = false;
            }

            selectedStartDate.setHours(0, 0, 0, 0);
            selectedEndDate.setHours(11, 59, 0, 0);

            let s = new Date(selectedStartDate.toString());
            let e = new Date(selectedEndDate.toString());

            s.setUTCMonth(selectedStartDate.getMonth(), selectedStartDate.getDate());
            s.setUTCHours(0, 0, 0, 0);

            e.setUTCMonth(e.getMonth(), e.getDate());
            e.setUTCHours(11, 59, 0, 0);

            window['SP_Propertyweb'].lists.getByTitle("Calendar").items.select("Title, ID, Attachments, AttachmentFiles, Description, AttachmentFiles/ServerRelativeUrl,  AttachmentFiles/Title, AttachmentFiles/ID, EventDate, Title, Location, EndDate, fAllDayEvent, EventNotes,fRecurrence,RecurrenceData,RecurrenceID").expand("AttachmentFiles").filter("EndDate ge datetime'" + s.toISOString() + "' and EventDate le datetime'" + e.toISOString() + "'").top(4999).orderBy('EventDate', orderBy).get().then((response: any) => {
                setEvents(response);
                setPageCount(Math.ceil(response.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
                setEventsToShow(processData(response.slice(0, recordsPerPage)));
                setaActivePage(1);
                setLoading(false);
            });
        }
    }
    /**
     * 
     * @param page 
     */
    function filterData(page: number) {
        setEventsToShow([]);
        setEventsToShow(processData(events.slice(recordsPerPage * page - recordsPerPage, recordsPerPage * page)))
    }

    function onUpcomingEvents() {
        setEventType("upcoming");
        let dt = new Date();
        dt.setHours(0, 0, 0, 0);
        setSelectedStartDate(dt);
        setSelectedEndDate(new Date(new Date().setDate(new Date().getDate() + 3650)));
        setMaxStartDate(null);
        setMinStartDate(new Date());
        setMinEndDate(new Date(new Date().setDate(new Date().getDate() + 1)));
        setMaxEndDate(null);
    }

    function onPastEvents() {
        setEventType("past");
        setSelectedStartDate(new Date("01/01/2010"));
        setMinStartDate(new Date("01/01/2010"));
        setMaxStartDate(new Date(new Date().setDate(new Date().getDate() - 1)));
        setSelectedEndDate(new Date(new Date().setDate(new Date().getDate() - 1)));
        setMaxEndDate(new Date(new Date().setDate(new Date().getDate() - 1)));
        setMinEndDate(new Date("01/02/2010"));
    }

    function resetFilters() {
        eventType === "upcoming" ? onUpcomingEvents() : onPastEvents()
        setNeedRest(false);
    }

    function processData(response: Array<{}>) {
        let temp = {};
        response.forEach((o: any) => {
            const year = o.EventDate.split("-")[0];
            if (!temp[year]) {
                temp[year] = {};
                temp[year].year = year;
                temp[year].events = [];
            }
            temp[year].events.push(o);
        });

        if (eventType === "past") { //If the events are for the past, we need to show it in descending order.            
            var keys = Object.keys(temp);
            var obj = [];
            // Sort the keys in descending order
            keys.sort(function (a: any, b: any) { return b - a });
            // Iterate through the array of keys and access the corresponding object properties
            for (var i = 0; i < keys.length; i++) {
                obj.push(
                    temp[keys[i]]
                );
            }
            temp = obj;
        }
        return temp;
    }

    function onPaginationClick(i: any) {
        setaActivePage(i + 1);
        filterData(i + 1);
        window.scrollTo(0, 0)
    }


    function addSpoaEvent() {
        setShowAdd(true);
    }

    function handleClose() {
        setShowAdd(false);
        setShowEdit(false);
    }

    function sendToAppInsights(webpartname: string) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Events Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.userEmail
        });
        params.AppInsights.flush();
    }
}
