import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Form from 'react-bootstrap/Form';
import './EditBannerForm.scss';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import helpers from '../helpers';
import * as $ from "jquery";

const EditBannerForm = forwardRef((props: any, ref) => {

    type TFile = {
        showError?: boolean,
        type?: any,
        message?: string
    }


    let filesread = document.getElementById('imageuploadbanner') as HTMLInputElement
    let archived = false;
    const { onUpdate, tabName, showWarning, data, setDisablesubmit, uploadFileFromControl } = props;

    const [selectFile, setselectFile] = useState<boolean>(data.Image ? true : false);
    //const [title, setTitle] = useState<string>(data.Title);
    const [title, setTitle] = useState<string>(data.Archived ? data.Title : data.NewTab ? "" : data.Title);

    const [description, setDescription] = useState<string>(data.Description ? data.Description : "");
    const [headingLength, setheadingLength] = useState(title.length);
    const [descriptionLength, setDescriptionLength] = useState(description.length);
    const [link, setLink] = useState<string>(data.Link ? data.Link : "");
    const [previewSrc, setPreviewSrc] = useState(data.Image);
    const [bgColor, setBgColor] = useState(data.Background);

    const [filecheck, setFileCheck] = useState({} as TFile);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (data.NewTab !== undefined) {
            setTimeout(() => {
                // if(document.getElementById(tabName +"-formHeading") !== null){
                document.getElementById(tabName + "-formHeading")!.focus()
                // }
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="edit-banner-form">

            <div className="container mt-3">
                <div className="row">
                    <div className="col-6">

                        <Form className="banner-form">
                            <Form.Group controlId={tabName + "-formHeading"}>
                                <Form.Label>Heading <i>*</i></Form.Label>

                                <Form.Control type="input" value={title} placeholder="Enter Heading" maxLength={60} required onChange={
                                    (e: any) => {
                                        sendValues([tabName, e.currentTarget.id, e.currentTarget.value, e]);
                                        setheadingLength(e.currentTarget.value.length);
                                    }

                                }
                                    // If the user adds a new tab, then by default the textbox will be empty, tapping on save carousel will not highlight the textbox
                                    // with red border. 
                                    onBlur={
                                        (e: any) => {
                                            sendValues([tabName, e.currentTarget.id, e.currentTarget.value, e]);
                                            setheadingLength(e.currentTarget.value.length);
                                        }
                                    }
                                />
                                <Form.Text className="text-muted">
                                    {headingLength ? "" + (60 - headingLength) + " characters remaining" : "60 characters max"}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId={tabName + "-formDesc"}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" value={description} rows={5} maxLength={180} placeholder="Enter Description" onChange={(e: any) => {

                                    sendValues([tabName, e.currentTarget.id, e.currentTarget.value]);
                                    setDescriptionLength(e.currentTarget.value.length);

                                }
                                } />
                                <Form.Text className="text-muted">
                                    {descriptionLength ? "" + (180 - descriptionLength) + " characters remaining" : "180 characters max"}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId={tabName + "-formLink"}>
                                <Form.Label>Target Link</Form.Label>
                                <Form.Control type="input" value={link} pattern="https?://.+" placeholder="Ex: https://eu001-sp/shellcom/sites/AAAAA91/default.aspx" onChange={(e: any) => sendValues([tabName, e.currentTarget.id, e.currentTarget.value])} />
                                <Form.Text className="text-muted">
                                    On clicking banner this link will be opened
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId={tabName + "-formImageRequired"}>
                                <Form.Label>Insert Image</Form.Label>
                                <Form.Check type="switch" checked={selectFile} label="" onChange={(e: any) => {
                                    setselectFile(e.currentTarget.checked);
                                    e.currentTarget.checked ? sendValues(tabName, data.Image) : sendValues(tabName, ""); //sendvalue will add image url if second parameter is passed
                                }
                                } />

                            </Form.Group>

                            {
                                selectFile ?
                                    <div>
                                        <Form.Group controlId={tabName + "-formImage"}>
                                            <Form.Label>Select image from computer</Form.Label>
                                            {filecheck.showError ? <Alert variant={filecheck.type}> {filecheck.message} </Alert> : null}

                                            <Form.Control disabled={loading} type="file" id={tabName + "-imageuploadbanner"} onChange={(e: any) => showPreview(e)} />

                                            {loading ? <div className="lds-ring"><div></div><div></div><div></div><div></div> <span className='loading-text'>Uploading image, please wait... </span> </div> : ""}

                                        </Form.Group>

                                        <Form.Group controlId={tabName + "-formImageBgColor"}>
                                            <Form.Label>Text Background Color</Form.Label>
                                            <ButtonToolbar>
                                                <ToggleButtonGroup type="radio" name="options" defaultValue={bgColor} onChange={(e: any) => {
                                                    sendValues([tabName, tabName + "-formImageBgColor", e]);
                                                    setBgColor(e)
                                                }}>
                                                    <ToggleButton variant="light" checked={!bgColor} value={false}>White</ToggleButton>
                                                    <ToggleButton variant="dark" checked={bgColor} value={true}>Black</ToggleButton>
                                                </ToggleButtonGroup>
                                            </ButtonToolbar>
                                        </Form.Group>
                                    </div>
                                    :
                                    ""
                            }

                        </Form>

                    </div>
                    <div className="col-6">
                        <Form>
                            <Form.Group controlId={tabName + "-formBasicEmail"}>
                                <Form.Label>Banner Preview</Form.Label>
                                <div className={(bgColor) ? "banner-review" : "banner-review white"} style={{ backgroundImage: selectFile ? "url(" + previewSrc + ")" : "" }} >

                                    {
                                        (title.length || description.length) ?

                                            <div>
                                                <div className="banner-review-title">
                                                    {title}
                                                </div>
                                                <div className="banner-review-description">
                                                    {description}
                                                </div>
                                                <span className="author">
                                                {/* This code is breaking IE 11, commenting for now - TBD */}
                                                {/* {
                                                    new Date().getDate() + " " + new Date().toLocaleString('default', { month: 'short' }) + " " + new Date().getFullYear()
                                                } */}
                                                </span>
                                            </div>

                                            :

                                            <div className="no-preview"> No Preview Available </div>
                                    }
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <Button className="btn-remove-banner" variant="outline-danger" onClick={(e: any) => showWarning(tabName)}>
                    Remove this Banner
                </Button>
            </div>

        </div>
    )


    function updateFromArchive() {
        console.log('called from parent')
    }


    function showPreview(e: any) {
        let filetype;
        
        if(e.target.files.length > 0)
        {
            const selectedFile = e;

            if (e.target.files[0] != undefined || e.target.files[0] != null) {
                filetype = e.target.files[0].type;

                if (["image/png", "image/jpeg", "image/jpg", "image/tiff"].includes(filetype) === false) {
                    setFileCheck({ showError: true, type: "warning", message: "Not a valid file type" });

                    const filePicker = document.getElementById(tabName + '-imageuploadbanner') as HTMLInputElement;
                   // filePicker.value = ""; //Need to check with UX if this needs to handled
                    setPreviewSrc("");
                }
                else {

                    setFileCheck({ showError: false, type: "warning", message: "" });

                    let filesread = document.getElementById(tabName + '-imageuploadbanner') as HTMLInputElement

                    var reader = new FileReader();
                    // when image is loaded, set the src of the image where you want to display it




                    if (filesread.files !== null) {
                        reader.readAsDataURL(filesread.files[0]);
                    }



                    reader.onload = function (e: any) {

                        var image = new Image();

                        image.src = e.srcElement.result;

                        image.onload = function (image: any) {
                            //console.log(image.width);

                            if (image.target.naturalWidth >= 730 && image.target.naturalHeight >= 410) {
                                setPreviewSrc(e.srcElement.result);
                                setLoading(true);
                                setDisablesubmit(true);
                                initUploadFile(selectedFile);
                            }
                            else {
                                setFileCheck({ showError: true, type: "warning", message: "Above selected image does not meet required resolution. Kindly update image that has minimum 730 x 410 pixels width and height." });
                                const filePicker = document.getElementById(tabName + '-imageuploadbanner') as HTMLInputElement;
                                //filePicker.value = "";  //Need to check with UX if this needs to handled
                                setPreviewSrc("");
                            }

                        };



                    };
                }
            }
        }
        else {
            setFileCheck({ showError: true, type: "warning", message: "Please select a file to Upload" });
        }
    }


    function sendValues(e: any, imgUrl?: string) {

        let data;
        let isValidated = true;

        if (imgUrl !== undefined) {
            data = {
                tab: e,
                controlID: e + "-image",
                value: imgUrl
            };
        }

        else {
            if (e[1].includes('formHeading')) {
                if (e[2] === "") {
                    e[3].currentTarget.classList.add("is-invalid");
                    setTitle(e[2]);
                    return false;
                }
                else {
                    e[3].currentTarget.classList.remove("is-invalid");
                }
                setTitle(e[2]);

            }
            else if (e[1].includes('formDesc')) {
                setDescription(e[2]);
            }
            else if (e[1].includes('formLink')) {
                setLink(e[2]);
            }


            data = {
                tab: e[0],
                controlID: e[1],
                value: e[2],
                isValidated: isValidated
            };
        }

        onUpdate(data);
    }



    function initUploadFile(e: any) {
        uploadFileFromControl(e, tabName, (resp: any) => {
            setLoading(false);
            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                sendValues(tabName, helpers.siteOrigin + encodeURI(resp.data.ServerRelativeUrl));
            }
            else {
                sendValues(tabName, helpers.siteOrigin + encodeURI(resp.d.ServerRelativeUrl));
            }
            setDisablesubmit(false);
            setFileCheck({ showError: true, type: "success", message: "File uploaded successfully" });

            setTimeout(() => {
                setFileCheck({ showError: false });
            }, 3000)

        })
    }
})

export default EditBannerForm;