import React, { useState, useEffect } from 'react';
import './Experts.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';
import { sp as pnpsp } from '@pnp/sp';
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";



// Setup sp for subsite


let sitecollectionurl = helpers.sitecollectionurl;

export default function Experts(params: any) {


    const { editMode, setActive } = params;
    const [experts, setExperts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cardClass, setCardClass] = useState('');
    const [isHidden, setIsHidden] = useState(params.hidden);

    let location = useLocation();
    const Url_Paramater = location.pathname.split("/")[1];
    let expertsPath = '/' + Url_Paramater + '/leadership-and-team';

    useEffect(() => {

        window['SP_Propertyweb'].lists.getByTitle("Discipline Configuration").items.select('SpoAConfigValue, Title').filter("Title eq 'ExpertsListMode'").get().then((items: any) => {

            if (items[0].SpoAConfigValue === "Manual") {

                helpers.getAllData("Manual Experts", ((experts: []) => {
                    setExperts(shuffleManualExperts(experts));
                    setLoading(false);
                }), ["Id", "Expert_x0020_Name/Title", "Expert_x0020_Name/EMail", "Expert_x0020_Name/Id", "Column_x0020_1", "Column_x0020_2", "Column_x0020_3", "Column_x0020_4", "Column_x0020_5", "Column_x0020_6", "Tab_x0020_Name"], "Expert_x0020_Name/Id", "ID", "Tab_x0020_Name eq 'OurTeamExpertsTabTitle'");
            }
            else {
                setCardClass('efml-experts');
                getExpertsConfigurationItems();
            }
        })

    }, []);

    return (
        <div id="experts">

            <div className="experts-header">
                <header className="exprerts-header">
                    <div className='section-title'>Experts</div>
                    {
                        editMode &&
                        (!isHidden ? <div className="hide-unhide"><span className="hide" onClick={() => hideWebpart(true)} >Hide </span></div>
                            :
                            <div className="hide-unhide"><span onClick={() => hideWebpart(false)} >Unhide</span> </div>)

                    }
                    {/* <a className='experts-viewall' href={helpers.sitecollectionurl + '/pages/ourteam.aspx#experts'} target="_blank">View All</a> */}
                    {!isHidden && <Link to={{
                        pathname: expertsPath,
                        state: { tabname: 'experts' }
                    }}>View All</Link>}
                </header>
                <div className={isHidden ? "hidden-webpart exprerts-container" : "exprerts-container"}>
                    {
                        !loading ?
                            experts.length ?
                                experts.map((expert: any, index: number) => {
                                    return (
                                        <div className={'expert-details ' + cardClass} key={index} onClick={() => { window.open('https://my.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c' + `${expert.EmailID ? expert.EmailID : expert.Expert_x0020_Name.EMail}`, '_blank'); sendToAppInsights('Experts'); }} >
                                            <img className='userIMG' src={`${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${expert.EmailID ? expert.EmailID : expert.Expert_x0020_Name.EMail}`} alt={expert.EmailID ? expert.EmailID : expert.Expert_x0020_Name.EMail}></img>
                                            {/*<div className='expert-star-icon'></div>*/}
                                            <div className='expert-title' >{expert.ExpertName ? expert.ExpertName : expert.Expert_x0020_Name.Title}</div>
                                            {
                                                expert.EFMLRole == "PTE" ? <div className='expert-description'>{expert.PTEArea}</div> : <div className='expert-description'>{expert.SMEArea}</div>
                                            }
                                            {
                                                expert.Duplicate === "Found" ? <div className="expert-role-count"> and {expert.ExpertRoleCount} more</div> : <div className="expert-roles-count">and {expert.ExpertRoleCount} more</div>
                                            }
                                            {
                                                expert.EFMLRole ? <div className='expert-role'>{expert.EFMLRole}</div> : ""
                                            }
                                        </div>
                                    )
                                })
                                : <div className="no-experts">
                                    There are no Experts recorded for this Discipline.
                                </div>
                            :
                            <div className="experts-loading">
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={300} width={200}></Skeleton>
                                </SkeletonTheme>
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={300} width={200}></Skeleton>
                                </SkeletonTheme>
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={300} width={200}></Skeleton>
                                </SkeletonTheme>
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={300} width={200}></Skeleton>
                                </SkeletonTheme>
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={300} width={200}></Skeleton>
                                </SkeletonTheme>
                            </div>
                    }
                </div>
            </div>
        </div>
    )



    function getExpertsConfigurationItems() {


        const caml: any = {
            ViewXml: "<View><Query></Query></View>",
        };
        window['SP_Propertyweb'].lists.getByTitle("ExpertsConfiguration").getItemsByCAMLQuery(caml).then((response: any) => {
            const expertsconfigurationresponse = response

            if (response.length > 0) {
                getSPoAEFMLMasterItems(expertsconfigurationresponse);
            }
            else {
                setLoading(false);
            }

        });
    }


    function getSPoAEFMLMasterItems(expertsconfigurationresponse) {

        let EFMLDisciplines = '';
        let EFMLDiscipliness = '';

        for (var j = 0; j < expertsconfigurationresponse.length; j++) {
            let Discipline = expertsconfigurationresponse[j].ExpertiseArea.Label.replace(/:/g, ';');
            let EFMLDisciplines1 = "substringof('" + Discipline + "', TermPath)";

            if (j == expertsconfigurationresponse.length - 1) {
                EFMLDisciplines = EFMLDisciplines + EFMLDisciplines1;
            }
            else {
                EFMLDisciplines = EFMLDisciplines + EFMLDisciplines1 + ' or ';
            }

        }

        EFMLDiscipliness = "(" + EFMLDisciplines + ")";


        //let filterstring =  EFMLDiscipliness + " and (EFMLRole eq 'PTE' or EFMLRole eq 'SME' or EFMLRole eq 'Associate SME')"
        if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
            getDataformainlink(EFMLDiscipliness);
        }
        else { getData(EFMLDiscipliness); }

    }

    function getDataformainlink(EFMLDiscipliness) {
        sp.site.rootWeb.lists.getByTitle("SPoAEFMLMaster").items.select("ExpertName,EFMLRole,EFMLDiscipline,TermPath,PTEArea,SMEArea,EmailID,ID").filter(EFMLDiscipliness + "and (EFMLRole eq 'PTE' or EFMLRole eq 'SME' or EFMLRole eq 'Associate SME')").top(4999).orderBy('PTEArea').get().then((response: any) => {
            //Added logic to display the experts in random manner whenever user refresh the sites
            let no_of_values = 5;
            var shuffled_expertvalues = response.sort(function () { return .5 - window.crypto.getRandomValues(new Uint32Array(1))[0] / 0xFFFFFFFF * 0.9 });
            var selected_expertvalues = shuffled_expertvalues.slice(0, no_of_values);
            setExperts(selected_expertvalues);
            setLoading(false);
        });
    }
    function getData(EFMLDiscipliness) {
        sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoAEFMLMaster").items.select("ExpertName,EFMLRole,EFMLDiscipline,TermPath,PTEArea,SMEArea,EmailID,ID").filter(EFMLDiscipliness + "and (EFMLRole eq 'PTE' or EFMLRole eq 'SME' or EFMLRole eq 'Associate SME')").top(4999).orderBy('PTEArea').get().then((response: any) => {
            setExperts(shuffle(response));
            setLoading(false);
        });
    }

    function shuffle(expertLeaders) {
        let no_of_values = 5;
        let group = expertLeaders.reduce((r, a) => {
            console.log("a", a);
            console.log('r', r);
            r[a.ExpertName] = [...r[a.ExpertName] || [], a];
            return r;
        }, {});
        let tempexperts = [];
        for (var key in group) {
            if (group.hasOwnProperty(key)) {
            var arr = group[key];
            for (var i = 0; i < arr.length; i++) {
                var obj = arr[i];
                if (arr.length > 1) {
                    tempexperts.push({
                        ExpertName: obj.ExpertName,
                        ID: obj.ID,
                        EmailID: obj.EmailID,
                        EFMLRole: obj.EFMLRole,
                        EFMLDiscipline: obj.EFMLDiscipline,
                        TermPath: obj.TermPath,
                        PTEArea: obj.PTEArea,
                        SMEArea: obj.SMEArea,
                        Duplicate: "Found",
                        ExpertRoleCount: arr.length - 1
                    });
                } else {
                    tempexperts.push({
                        ExpertName: obj.ExpertName,
                        ID: obj.ID,
                        EmailID: obj.EmailID,
                        EFMLRole: obj.EFMLRole,
                        EFMLDiscipline: obj.EFMLDiscipline,
                        TermPath: obj.TermPath,
                        PTEArea: obj.PTEArea,
                        SMEArea: obj.SMEArea,
                        Duplicate: "Not Found",
                        ExpertRoleCount: arr.length - 1
                    })
                }
            }
        }
        }
        console.log(tempexperts);
        tempexperts = tempexperts.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.ExpertName === value.ExpertName && t.EmailID === value.EmailID
            ))
        );

        var shuffled_expertvalues = tempexperts.sort(function () { return .5 - window.crypto.getRandomValues(new Uint32Array(1))[0] / 0xFFFFFFFF * 0.9 });
        shuffled_expertvalues = shuffled_expertvalues.slice(0, no_of_values);
        return shuffled_expertvalues;
    }

    function shuffleManualExperts(expertLeaders) {
        let no_of_values = 5;
        var shuffled_expertvalues = expertLeaders.sort(function () { return .5 - window.crypto.getRandomValues(new Uint32Array(1))[0] / 0xFFFFFFFF * 0.9 });
        shuffled_expertvalues = shuffled_expertvalues.slice(0, no_of_values);
        return shuffled_expertvalues;
    }

    function sendToAppInsights(webpartname: string) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Experts Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.userEmail
        });
        params.AppInsights.flush();
    }

    function hideWebpart(toHide: boolean) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Application eq 'Experts'").get().then(res => {
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(res[0].Id, 10)).update({ IsHidden: toHide }).then(i => {
                setIsHidden(toHide);
                //getExpertsConfigurationItems();
                setLoading(false);
            }).catch(e => {
                console.log("error updating Experts Webpart!", e)
            });
        }).catch(e => {
            console.log("error updating Experts Webpart!", e)
        });
    }

}