import React, { useState, useEffect } from 'react';
import CardWidget from '../cardWidget/CardWidget';
import { sp as pnpsp } from '@pnp/sp';
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";
import { MdCreate } from "react-icons/md";
import { Button, Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import * as $ from "jquery";

const SignWidget = ({ editMode, isModerator }) => {
    const [loading, setLoading] = useState(true);
    const [signId, setSignId] = useState('');
    let signForumNames = [];

    const config = {
        title: "Shell International Global Networks (SIGN)",
        type: "SIGN",
        modalTitle: "Add / Remove SIGN Forums",
        signSearchURL: "https://eu001-sp.shell.com/sites/AAAAA5493/SitePages/home.aspx"
    }
    const [response, setResponse] = useState([]);
    const [searchLFEURL, setsearchLFEURL] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    useEffect(() => {
        getSIGNConfigurationItems();
    }, []);

    return (
        <div id="sign-widget">
            <>
                <CardWidget config={config} response={response} editMode={editMode} updateConfigurationItems={updateConfigurationItems} loading={loading} searchLFEURL={searchLFEURL} hideWebpart={hideWebpart} isModerator={isModerator} isHidden={isHidden} />
            </>
        </div>
    )



    function updateConfigurationItems(Tfunctions?: any, Tdiscipline?: any, Tpte?: any, Tsme?: any, Tkeyword?: any, TsignForums?: any) {
        setLoading(true)
        let items = [];
        setsearchLFEURL([]);
        let signURLs = '';
        TsignForums.map((o, i) => {
            if (o.checked === true) {
                items.push({
                    url: o.url
                });
            }
        })
        console.log("Selected length:" + items.length);
        if (items !== null && items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                signURLs = signURLs + items[i].url + " OR path:";
            }
            if (signURLs !== "") {
                signURLs = signURLs.substring(0, signURLs.length - 9);
            }
            let finalQueryString = "'path:" + signURLs + " NOT fileextension:\"aspx\"+ContentClass:STS_ListItem_DiscussionBoard'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=100&selectproperties='HitHighlightedSummary,DiscussionLastUpdatedOWSDATE,Path,Title,ReplyCount,RefinableString195'&sortlist='RefinableDate07:descending'";
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(signId).update({
                Query: finalQueryString
            }).then((item: any) => {
                console.log('Updated')
                getSIGNConfigurationItems();
            }).catch(e => {
                console.log('Update failed')
            });

        } else {
            signURLs = "https://eu001-sp.shell.com/sites/AAAAA5493/SDB00000/";
            let finalQueryString = "'path:" + signURLs + " NOT fileextension:\"aspx\"+ContentClass:STS_ListItem_DiscussionBoard'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=100&selectproperties='HitHighlightedSummary,DiscussionLastUpdatedOWSDATE,Path,Title,ReplyCount,RefinableString195'&sortlist='RefinableDate07:descending'";
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(signId).update({
                Query: finalQueryString
            }).then((item: any) => {
                console.log('Updated')
                getSIGNConfigurationItems();
            }).catch(e => {
                console.log('Update failed')
            });
        }
    }

    //Promise returning request
    function executeRequest(fieldConfig) {

        return function () {
            return new Promise(function (resolve, reject) {
                var requestUrl = helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/lists/GetByTitle('SIGNSiteName')/items?$select=Title,signurl&$filter=signurl eq '" + fieldConfig + "'&@target='" + helpers.sitecollectionurl + "'";
                var spExecutor = new SP.RequestExecutor(helpers.appweburl);
                spExecutor.executeAsync({
                    url: requestUrl,
                    method: "GET",
                    headers: { "Accept": "application/json; odata=verbose" },
                    success: function (data) {
                        var jsonObject = JSON.parse(data.body.toString());
                        var results = jsonObject.d.results;
                        if (results.length > 0) {
                            signForumNames.push({
                                Title: results[0].Title,
                                URL: results[0].signurl
                            })
                        }
                        resolve(signForumNames);
                    },
                    error: function (error) {
                        console.log("error");
                    }
                });
            });
        };
    }

    function getSIGNConfigurationItems() {
        setResponse([]);
        setsearchLFEURL([]);
        signForumNames = [];
        helpers.getKRListView("SpoAPageData", ((items: Array<{
            Query: string
            ID: string
            IsHidden: boolean
        }>) => {
            setIsHidden(items[0].IsHidden);
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let signQuery = items[i].Query;
                    let splitSignQuery = signQuery.split('NOT');
                    let splitSignQueryOR = splitSignQuery[0].split('OR');
                    let pushval = [];

                    for (var j = 0; j < splitSignQueryOR.length; j++) {
                        let queryvalue = splitSignQueryOR[j].trim();
                        let removepathfromquery = '';
                        if (j === 0) {
                            removepathfromquery = queryvalue.replace(/'path:/g, '');
                        }
                        else {
                            removepathfromquery = queryvalue.replace(/path:/g, '');
                        }
                        pushval.push({
                            URL: removepathfromquery
                        })
                    }


                    //Prepare empty/resolved promise to iterate later
                    var promise = Promise.resolve(null);
                    //Loop through every field 
                    $.each(pushval, function (i) {
                        promise = promise.then(executeRequest(pushval[i].URL));
                    });
                    //execute callback when all fields are created
                    promise.then(() => {
                        setsearchLFEURL(signForumNames);
                        setSignId(items[i].ID);
                        var searchUrl = helpers.appweburl + "/_api/search/query?querytext=" + signQuery + "";
                        var executor = new SP.RequestExecutor(helpers.appweburl);
                        executor.executeAsync(
                            {
                                url: searchUrl,
                                method: "GET",
                                headers: { "Accept": "application/json; odata=verbose" },
                                success: onGetSIGNSuccess,
                                error: onError
                            }
                        );
                    });
                }

            } else {
                setResponse([])
                setLoading(false)
            }
        }), "ID,Title,Query,Link,Section,Application,IsHidden", "Application eq 'SIGN3.0'");
    }

    function onGetSIGNSuccess(data) {
        let items = [];
        let submittedDate = '';
        let signTitle = '';
        let formattedValidDate = '';
        let signForum = '';
        let signPath = '';
        let signSourceType = '';
        let jsonObject = JSON.parse(data.body);
        if (jsonObject.d.query.PrimaryQueryResult.RelevantResults !== null) {
            let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
            console.log(jsonresults);
            const monthNames = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if (jsonresults !== null && jsonresults.length > 0) {
                for (let i = 0; i < jsonresults.length; i++) {
                    submittedDate = '';
                    signTitle = '';
                    formattedValidDate = '';
                    signForum = '';
                    signPath = '';
                    signSourceType = '';
                    for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                        if (jsonresults[i].Cells.results[j].Key === "DiscussionLastUpdatedOWSDATE") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                submittedDate = jsonresults[i].Cells.results[j].Value;
                                if (submittedDate) {
                                    let validDate = new Date(submittedDate);
                                    let validDay = validDate.getUTCDate();
                                    let validMonth = validDate.getUTCMonth();
                                    let validYear = validDate.getUTCFullYear();
                                    formattedValidDate = validDay + " " + monthNames[validMonth] + " " + validYear;
                                }
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Title") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                signTitle = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Path") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                signPath = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "RefinableString195") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                signForum = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                    }

                    items.push({
                        signTitle: signTitle,
                        signForum: signForum,
                        submittedDate: formattedValidDate,
                        signPath: signPath,
                    });
                }
                let groupedbyForumName = [];
                signForumNames.forEach((o, i) => {
                    groupedbyForumName.push({
                        forumName: o.Title,
                        forumUrl: o.URL,
                        discussions: items.filter(i => i.signForum == o.Title).slice(0, 3)
                    })
                })

                setResponse(groupedbyForumName);
                setLoading(false)
            }
            else {
                setResponse([])
                setsearchLFEURL([])
                setLoading(false)
            }
        } else {
            setResponse([])
            setsearchLFEURL([])
            setLoading(false)
        }
    }

    function onError(e) {
        console.error("Error fetching SIGN", e)
    }

    function hideWebpart(toHide: boolean) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(signId, 10)).update({ IsHidden: toHide }).then(i => {
            setIsHidden(toHide);
            setLoading(false);
        }).catch(e => {
            console.log("error updating CustomWebpart!", e)
        });

    }
};

export default SignWidget;