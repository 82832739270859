import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import Draft from "react-wysiwyg-typescript";
import { stateToHTML } from "draft-js-export-html";
import helpers from "../helpers";
import Form from "react-bootstrap/Form";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import { ContentState, EditorState, Modifier } from "draft-js";

const AboutEdit = (props: any) => {
    const { htmlData, onHide, getItems } = props;
    const processedHTML = DraftPasteProcessor.processHTML(htmlData);
    const contentState = ContentState.createFromBlockArray(processedHTML);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(contentState)
    );
    const [showProcessing, setShowProcessing] = useState(<p>Save</p>);
    const [aboutDescriptionLength, setaboutDescriptionLength] = useState(
        editorState ? editorState.getCurrentContent().getPlainText().length : 0
    );

    let desc_options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: data.url,
                        target: '_blank'
                    },
                };
            }
        },
    };

    return (
        <div>
            <div>
                <Draft
                    placeholder="Add Text"
                    handleReturn={e => {
                        const textLength = editorState.getCurrentContent().getPlainText().length;
                        if (e.keyCode === 13 && textLength >= 2500) {
                            return 'handled';
                        }
                    }}
                    handleBeforeInput={val => {
                        const textLength = editorState.getCurrentContent().getPlainText().length;
                        if (val && textLength >= 2500) {
                            return 'handled';
                        }
                        return 'not-handled';
                    }}
                    handlePastedText={val => {
                        const textLength = editorState.getCurrentContent().getPlainText().length;
                        if ((val.length + textLength) >= 2500) {
                            let availableLen = 2500 - textLength;
                            let modifiedVal = val.substr(0, availableLen);
                            const newContent = Modifier.insertText(
                                editorState.getCurrentContent(),
                                editorState.getSelection(),
                                modifiedVal
                            );
                            let newEditorState = EditorState.push(
                                editorState,
                                newContent,
                                'insert-characters'
                            );
                            setEditorState(newEditorState);
                            setaboutDescriptionLength(newEditorState.getCurrentContent().getPlainText().length);
                        }
                        return ((val.length + textLength) >= 2500);
                    }}
                    editorState={editorState}
                    onEditorStateChange={editorState => {
                        setEditorState(editorState);
                        setaboutDescriptionLength(editorState.getCurrentContent().getPlainText().length);
                    }}
                />
                <Form.Text className="text-muted">
                    {aboutDescriptionLength
                        ? "" + (2500 - aboutDescriptionLength) + " characters remaining"
                        : "2500 characters max"}
                </Form.Text>
            </div>
            <Modal.Footer>
                <Button
                    className="button-cancel-menu"
                    variant="secondary"
                    onClick={onHide}
                >
                    Cancel
        </Button>

                <Button
                    className="proceed-button"
                    variant="primary"
                    onClick={() => {
                        updateWelcomeDescription();
                    }}
                >
                    {showProcessing}
                </Button>
            </Modal.Footer>
        </div>
    );
    function updateWelcomeDescription() {
        let welcomeDesc = stateToHTML(editorState.getCurrentContent(), desc_options);
        setShowProcessing(
            <p>
                Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
            </p>
        );
        helpers.updateData(
            "SpoaWelcomeList",
            { SPoAWelcomeDescription: welcomeDesc },
            "1",
            function () {
                getItems();
                onHide();
                setShowProcessing(<span>Save</span>);
            }
        );
    }
};

export default AboutEdit;
