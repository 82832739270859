import React from 'react';
import helpers from '../helpers';

const DiscussionWidget = ({ o }) => {
    return (
        <div className="result type-b">
            <header>
                <div className="title" title={o.dTitle} onClick={() => {
                    window.open(o.dPath)
                }}>
                    {(o.dTitle && o.dTitle.length > 70) ?
                        o.dTitle.substr(0, 70) + "..." :
                        o.dTitle
                    }
                    <span className="forum-name">({o.dForumName} - SIGN)</span>
                </div>
                <div className="help-text">
                    {(o.dConclude - 0) ? "Concluded" : ''}
                </div>
            </header>

            <div className="result-description" title={o.dBody} 
                dangerouslySetInnerHTML={o.dBody && o.dBody.length > 350 ? helpers.createMarkup(o.dBody.substr(0, 350) + "...") : helpers.createMarkup(o.dBody)}>
            </div>

            <footer>
                <div className="statistics">
                    {o.dReply ? o.dReply : '0'} <span> {'Replies'} </span>
                    {/* { 'To Do' } <span> { 'Rating' } </span> */}
                    {/* Rating not possible */}
                    {o.dView ? o.dView : '0'} <span> {'Views'} </span>
                </div>
            </footer>
        </div>
    )
}



export default DiscussionWidget;