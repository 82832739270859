import React, { useEffect, useState, useRef } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiMoreVertical } from "react-icons/fi";
import './Announcement.scss'
import Skeleton from 'react-loading-skeleton';
import Pagination from 'react-bootstrap/Pagination';
import { MdAddCircleOutline, MdRefresh } from "react-icons/md";
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";

const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
}

const skeletonHeight = 120;
const addNewLink = helpers.sitecollectionurl;
const SPoAName = window.location.hash.split("/")[1];

const Announcement = (props) => {

    const { editMode, isModerator } = props;

    const [announcementItems, setAnnouncementItems] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [showRefresh, setShowRefresh] = useState(false);
    const [activeIndex, setActiveIndex] = useState(1);
    const [fitleredData, setFilteredData] = useState(announcementItems.slice(0, 10));
    const [paginationSize, setPaginationSize] = useState(parseInt((announcementItems.length / 10).toFixed(), 10));
    const [paginationItems, setPaginationItems] = useState(Array(paginationSize).fill(paginationSize));

    const isModRef = useRef(isModerator);
    isModRef.current = isModerator;

    useEffect(() => {
        getData();
    }, [isModerator])

    useEffect(() => {
        //props.AppInsights.trackPageView(
        //    document.title, 
        //    window.location.href, 
        //    { userEmail: props.userEmail }, 
        //    null,
        //);
        setTimeout(() => {
            sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoASiteName").items.select("Title,SiteURL").top(4999).get().then((response: any) => {
                response.map(o => {
                    if (o.SiteURL.Url.toLowerCase() === helpers.hostweburl.toLowerCase()) {
                        helpers.getUser((e: any) => {
                            console.log("Entries added in App Insights - Announcement page");
                            props.AppInsights.trackPageView(
                                o.Title,
                                window.location.href,
                                { userEmail: e.Email },
                                null,
                            );
                        });
                    }
                });
            });
        }, 3000);
    }, [])

    useEffect(() => {
        const paginationSize = Math.ceil((announcementItems.length / 10));
        setPaginationSize(paginationSize);
        setPaginationItems(Array(paginationSize).fill(1));
        const to = activeIndex * 10;
        const frm = activeIndex * 10 - 10;
        const temp = announcementItems.slice(frm, to);
        setFilteredData(temp);
        window.scrollTo(0, 0);
    }, [announcementItems]);

    return (
        <div id="announcement" className="container">

            <div className="announcement-cards">
                {
                    isLoading ?
                        <div style={{ lineHeight: 3 }}>
                            <Skeleton count={4} height={skeletonHeight}></Skeleton>
                            <div className="pagination-loader">
                                <Skeleton height={20} width={500}></Skeleton>
                            </div>
                        </div>
                        :
                        <>
                            {
                                (editMode && !isLoading && !showRefresh) &&
                                <a className="link" onClick={onAddAnnouncment}><MdAddCircleOutline /> Add New Announcement </a>
                            }

                            {
                                (editMode && !isLoading && showRefresh) &&
                                <a className="link refresh" onClick={getData}> <MdRefresh /> Refresh to see updated content</a>
                            }

                            {
                                announcementItems.length ? fitleredData.map((o, i) => {
                                    const cssClasses: any = o.isHidden ? isModerator ? "announcement-container hidden-announcement" : false : "announcement-container";
                                    return (
                                        <div key={i}>
                                            {
                                                cssClasses &&
                                                <div>
                                                    {(o.isHidden && isModerator) && <span onClick={() => hideAnnouncement(false, o.ID)} className="unhide">Unhide Announcement</span>}
                                                    <div className={cssClasses}>
                                                        <div id="edit" className={editMode ? " active" : ""}>
                                                            {
                                                                !o.isHidden &&
                                                                <DropdownButton alignRight title={<FiMoreVertical />} id="dropdown-menu-align-right" >
                                                                    <Dropdown.Item eventKey="1" onClick={() => onEdit(o.ID)} >Edit / Delete Announcement</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="2" onClick={() => hideAnnouncement(true, o.ID)} >Hide Announcement</Dropdown.Item>
                                                                </DropdownButton>
                                                            }

                                                        </div>
                                                        <h1 onClick={() => { window.open(addNewLink + SPoAName + '/Lists/SpoAAnnouncements/DispForm.aspx?ID=' + o.ID); sendToAppInsights('Announcement'); }}> {o.Title} </h1>
                                                        <div className="summary"> {(o.SpoASummary && o.SpoASummary.length > 350) ? o.SpoASummary.substr(0, 350) + "  ..." : o.SpoASummary} </div>
                                                        <div className="date"> {o.ArticleStartDate ? new Date(o.ArticleStartDate).toLocaleDateString("en-GB", dateOptions).replace(/ /g, ' ') : new Date(o.ArticleStartDate1).toLocaleDateString("en-GB", dateOptions).replace(/ /g, ' ')} </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    )

                                }) : <div className="no-announcments-message">No announcement available</div>
                            }

                            <Pagination>
                                {

                                    paginationItems.map((o: any, i: any) => (
                                        <Pagination.Item key={i} active={(i + 1) === activeIndex} onClick={(e: any) => onPagination(e)}>
                                            {i + 1}
                                        </Pagination.Item>
                                    ))
                                }
                            </Pagination>

                        </>
                }
            </div>
        </div>
    )

    function onPagination(e?) {
        setLoading(true);
        setActiveIndex(parseInt(e.target.innerText, 10));
        const to = e.target.innerText === "1" ? 10 : parseInt(e.target.innerText, 10) * 10;
        const frm = to === 1 ? 0 : to - 10;
        const temp = announcementItems.slice(frm, to);

        setFilteredData(temp);

        setTimeout(() => {
            setLoading(false);
        }, 250);
    }

    function onAddAnnouncment() {
        window.open(addNewLink + SPoAName + '/Lists/SpoAAnnouncements/NewForm.aspx');
        setShowRefresh(true);
    }

    function onEdit(ID) {
        window.open(addNewLink + SPoAName + '/Lists/SpoAAnnouncements/EditForm.aspx?ID=' + ID);
        setShowRefresh(true);
    }

    function getData() {
        setLoading(true);
        setShowRefresh(false);
        window['SP_Propertyweb'].lists.getByTitle('Announcements').items.top(4999).get().then((items) => {

            if (items.length) {
                let sortBy = items[0].ArticleStartDate1 ? "ArticleStartDate1" : "ArticleStartDate";
                items = items.sort((a: any, b: any) => {
                    const aDate: any = new Date(a[sortBy]);
                    const bDate: any = new Date(b[sortBy]);
                    return bDate - aDate;
                });
                if (isModRef.current === false) {
                    setAnnouncementItems(items.filter(o => !o.isHidden));
                } else {
                    setAnnouncementItems(items);
                }
            }
            //keeping commented code for testing in local of non moderator users
            //let t = []
            //items.map(o => t.push({ ...o, isHidden : true}))
            //items.map(o => t.push({ ...o}))
            setLoading(false);
        }).catch(e => {
            console.error('Fetching query failed!');
            setLoading(false);
        });
    }

    function hideAnnouncement(toHide: boolean, id) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('Announcements').items.getById(parseInt(id, 10)).update({ isHidden: toHide }).then(i => {
            getData();
        }).catch(e => {
            console.log("error updating announcement!", e)
        });


    }

    function sendToAppInsights(webpartname: string) {
        props.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Announcement Web Part',
            WebPartHeader: webpartname,
            UserEmail: props.userEmail
        });
        props.AppInsights.flush();
    }
}

export default Announcement;