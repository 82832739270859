import React, { useEffect, useState, useCallback } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from "react-bootstrap/Alert";
import Form, { FormRow } from 'react-bootstrap/Form';
import { FaArrowLeft } from 'react-icons/fa';
import 'react-icons/fa'
import helpers from '../helpers';
import ModalBody from 'react-bootstrap/ModalBody';
import { useDropzone } from 'react-dropzone';
import DatePicker from "react-datepicker";
import TimePicker from 'react-bootstrap-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import './EditEvents.scss'
import { link } from 'fs';
import * as $ from "jquery";
import { timeFromInt } from 'time-number';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToRaw, EditorState, Modifier, ContentState, AtomicBlockUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

export interface IEventsState {
    title: string | null;
    desc?: string | null;
    notes?: string | null;
    start?: Date | null;
    end?: Date | null;
    startTime?: Date | null;
    endTime?: Date | null;
    location?: string | null;
    allDayEvent: boolean | null;
}

const EditEvents = (props: any) => {
    const { setShowEdit, handleClose, data, getItems } = props;

    const [showModal, setShowModal] = useState(setShowEdit);
    const [showFields, setShowFields] = useState(false);
    const [isFile, setIsFile] = useState(data.Attachments);
    const [showConf, setShowConf] = useState(false);
    const [fileTitle, setFileTitle] = useState(data.Attachments ? data.AttachmentFiles[0].ServerRelativeUrl.slice(data.AttachmentFiles[0].ServerRelativeUrl.lastIndexOf('/')).slice(1) : '');
    const [eventDetails, seteventDetails] = useState<IEventsState>({ title: data.Title, desc: data.Description, notes: data.EventNotes, location: data.Location, start: new Date(data.EventDate.split('T')[0]), allDayEvent: data.fAllDayEvent, startTime: data.EventDate.split('T')[1].slice(0, -1), end: new Date(data.EndDate.split('T')[0]), endTime: data.EndDate.split('T')[1].slice(0, -1) });
    const [allDayEvent, setAllDayEvent] = useState(data.fAllDayEvent);
    const [validated, setValidated] = useState(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Event</span>);
    const [fileAttachment, setFileAttachment] = useState(isFile);
    const [noStartDate, setnoStartDate] = useState(false);
    const [noEndDate, setnoEndDate] = useState(false);
    const [longTitle, setlongTitle] = useState(false);
    const [longDesc, setlongDesc] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [fileAttachmentText, setFileAttachmentText] = useState('');
    const [correctFile, setCorrectFile] = useState(false);
    const [dateErrorPrev, setDateErrorPrev] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [descriptionLength, setDescriptionLength] = useState(data.Description ? data.Description.length : 0);
    const [titleLength, setTitleLength] = useState(data.Title ? data.Title.length : 0);
    const [notesLength, setNotesLength] = useState(data.EventNotes ? data.EventNotes.length : 0);
    const [endMinDate, setendMinDate] = useState(false);

    const contentState = stateFromHTML(eventDetails.desc);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(contentState)
    );

    let desc_options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: decodeURIComponent(data.url),
                        target: '_blank'
                    },
                };
            }
        },
    };


    return (
        <div>
            <Modal size='lg' show={showModal} onHide={() => onDialogClose(false)} centered backdrop='static'>

                <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form className='add-event' id='edit-event' noValidate validated={validated}>
                        <Form.Group>
                            <Form.Label>Event Title <span className="star">*</span></Form.Label>
                            <Form.Control type='input' value={eventDetails ? eventDetails.title : ''} onChange={(e: any) => { updateEvents(e.currentTarget.value, 'title'); setTitleLength(e.currentTarget.value.length) }} maxLength={255} required disabled={fileAttachment ? true : false}></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Event Title
                            </Form.Control.Feedback>
                            {titleLength ? <span className='info-max'> {(255 - titleLength)} characters remaining</span> : <span className='info-max'>255 characters max</span>}

                        </Form.Group>
                        {longTitle ? <span className='error-new'>
                            Title length cannot exceed 255 characters
                        </span> : ''}
                        {titleError ? <span className='error-new'>
                            Please provide a valid Event Title
                        </span> : ''}
                        <div className='checkbox-all-day'>
                            <Form.Check
                                type="checkbox"
                                id="custom-checkbox"
                                disabled={fileAttachment ? true : false}
                                label="All day event"
                                checked={allDayEvent}
                                onClick={() => allDayEvent ? setAllDayEvent(false) : setAllDayEvent(true)}
                            />
                        </div>
                        <Form.Group className='date-picker-row'>
                            <Form.Label>Starts By <span className="star">*</span></Form.Label>
                            <DatePicker id='date-picker-1' placeholderText='dd/mm/yyyy' dateFormat="dd/MM/yyyy" selected={eventDetails ? eventDetails.start : null} minDate={new Date()} onChange={date => updateEvents(date, 'start')} disabled={fileAttachment ? true : false}></DatePicker>
                            <a className='calendar-icon' onClick={(event) => triggerCal(event)}><i className="fa fa-calendar" aria-hidden="true"></i></a>
                            <span className='time-picker-span'> <TimePicker className='time-picker' start="00:00" end="23:59" step={15} format={24} value={eventDetails ? eventDetails.startTime : ''} onChange={(e: any) => { updateEvents(e, 'startTime'); eventDetails.start == eventDetails.end ? setendMinDate(true) : setendMinDate(false) }} disabled={fileAttachment ? true : allDayEvent} /></span>
                        </Form.Group>
                        {noStartDate ? <span className='error-new'>
                            Please provide a valid Start Date
                        </span> : ''}
                        <Form.Group className='date-picker-row'>
                            <Form.Label>Ends By <span className="star">*</span></Form.Label>
                            <DatePicker id='date-picker-2' placeholderText='dd/mm/yyyy' dateFormat="dd/MM/yyyy" minDate={eventDetails ? eventDetails.start : new Date()} selected={eventDetails ? eventDetails.end : null} onChange={date => updateEvents(date, 'end')} disabled={fileAttachment ? true : false} required></DatePicker>
                            <span className='calendar-icon' onClick={() => document.getElementById('date-picker-2').click()}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                            <span className='time-picker-span'> <TimePicker className='time-picker' start={eventDetails ? eventDetails.startTime : "00:00"} end="23:59" step={15} format={24} value={eventDetails ? eventDetails.endTime : ''} onChange={(e: any) => updateEvents(e, 'endTime')} initialValue={'23:59'} disabled={fileAttachment ? true : allDayEvent} /></span>
                        </Form.Group>
                        {noEndDate ? <span className='error-new'>
                            Please provide a valid End Date
                        </span> : ''}
                        {dateError ? <span className='error-new'>
                            Start Date & Time is before Today's Date & Time
                        </span> : ''}

                        {eventDetails ?
                            eventDetails.start ?
                                <Form.Group>
                                    <Form.Label>TimeZone: GMT+00000(Greenwich Mean Time)</Form.Label>
                                </Form.Group> : '' : ''
                        }
                        <Form.Group>
                            <Form.Label>About this event</Form.Label>
                            {/*<Form.Control as="textarea" rows={5} value={eventDetails ? eventDetails.desc ? eventDetails.desc : '' : '' } onChange={(e:any) => {updateEvents(e.currentTarget.value,'desc'); setDescriptionLength(e.currentTarget.value.length)}} maxLength={2000} disabled={fileAttachment ? true : false}/>*/}
                            <Editor editorState={editorState}
                                readOnly={fileAttachment ? true : false}
                                placeholder="Add Text"
                                onEditorStateChange={e => { setEditorState(e) }}
                                wrapperClassName="wrapper-class"
                                editorClassName={fileAttachment ? "disabled-editor editor-class" : "editor-class"}
                                toolbarClassName="toolbar-class" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Location</Form.Label>
                            <Form.Control type='input' className='location-input' value={eventDetails ? eventDetails.location ? eventDetails.location : '' : ''} onChange={(e: any) => updateEvents(e.currentTarget.value, 'location')} disabled={fileAttachment ? true : false}></Form.Control>
                        </Form.Group>


                        {isFile ?
                            <Form.Group className='on-file-upload'>
                                <span><Form.Label>Outlook calendar invite</Form.Label></span> <br />
                                <span><i className="fa fa-calendar" aria-hidden="true"></i> {fileTitle} &nbsp; &nbsp;{/* <span className ='remove-ics' onClick={() => {setIsFile(false);setFileTitle(''); setFileAttachment(null);setShowFields(false);seteventDetails(undefined);}}><a>Remove file</a></span> */}</span>
                                {// <input {...getInputProps()} id='choose-file-new' hidden /><br/>}
                                }<br /><span> <Button className="browse-outlook-invite" variant="secondary" disabled={true}>
                                    Browse Outlook Invite
                                </Button></span>
                            </Form.Group> :
                            ''}
                        <Form.Group>
                            <Form.Label>Additional Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} value={eventDetails ? eventDetails.notes : ''} onChange={(e: any) => { updateEvents(e.currentTarget.value, 'notes'); setNotesLength(e.currentTarget.value.length) }} maxLength={350} />
                            <span className='info-max'>{notesLength ? "" + (350 - notesLength) + " characters remaining" : '350 characters max'}</span>
                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer className='add-events-footer'>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => onDialogClose(false)} >
                        Cancel
                    </Button>
                    <Button variant="primary" className='proceed-button' onClick={() => { setModal(true) }}>
                        {showProcessing}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showConf} backdrop='static' centered>
                <Modal.Body className='modal-conf'>   <span className='conf-message'>Your Event has been saved succesfully. <br /> Would you like to book a meeting room for the same?</span> </Modal.Body>
                <Modal.Footer className='modal-footer-conf'>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => onModalClose(false)} >
                        Close
                    </Button>
                    <Button variant="primary" className='proceed-button' onClick={() => { window.open('https://shell.condecosoftware.com/index.html', "WindowName", "noopener"); onModalClose(false) }}>
                        Yes, Open Condeco &nbsp; <span><i className="fa fa-external-link" aria-hidden="true"></i></span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    function onDialogClose(passData: boolean) {
        handleClose();

    }
    function onModalClose(passData: boolean) {
        setShowConf(false);
        handleClose();
        getItems();
    }

    function updateTime(e: any) {
        console.log(e);
    }

    function triggerCal(e: any) {
        e.preventDefault();
        document.getElementById('date-picker-1').click();
    }
    function setModal(flag: boolean) {
        setValidated(false);
        setnoStartDate(false);
        setnoEndDate(false);
        if (eventDetails == undefined) {
            setValidated(true);
            setnoStartDate(true);
            setnoEndDate(true);
        }
        else if (eventDetails.title == null) {
            setValidated(true);
        }
        else if (eventDetails.title.trim() == '') {
            setTitleError(true);
        }
        else if (eventDetails.start == null) {
            setnoStartDate(true);
        }
        else if (eventDetails.end == null) {
            setnoEndDate(true);
        }
        else if (eventDetails.title.trim().length > 255) {
            setlongTitle(true);
        }
        //else if (eventDetails.desc && eventDetails.desc.length > 2000) {
        //    setlongDesc(true);
        //}
        else if (eventDetails.end < eventDetails.start) {
            setDateError(true);
        }
        else if (isFile) {
            setShowProcessing(<span>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
            let obj = {
                EventNotes: eventDetails.notes
            }
            helpers.updateData('Calendar', obj, data.Id, function (iar: any) {
                console.log('success!');
                setShowProcessing(<span>Save Event</span>);
                setShowConf(true);
                setShowModal(false);
            });

        }

        else {
            if (flag) {
                setValidated(false);
                setDateError(false);
                setlongTitle(false);
                setlongDesc(false);
                setnoStartDate(false);
                setShowProcessing(<span>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                let obj1: any = { ...eventDetails };

                if (!eventDetails.startTime) {
                    obj1.startTime = '00:00';
                    seteventDetails(obj1);
                }
                if (!eventDetails.endTime) {
                    obj1.endTime = '00:00';
                    seteventDetails(obj1);
                }
                let eventStartDate = eventDetails.start;
                let eventEndDate = eventDetails.end;
                if (eventDetails.startTime) {
                    if (eventDetails.startTime.toString().slice(-2) == 'PM') {
                        eventStartDate.setHours(parseInt(eventDetails.startTime.toString().split(':')[0], 10) + 12, parseInt(eventDetails.startTime.toString().split(':')[1], 10));
                        eventStartDate = new Date(eventStartDate.toUTCString());
                    }
                    else {
                        eventStartDate.setHours(parseInt(eventDetails.startTime.toString().split(':')[0], 10), parseInt(eventDetails.startTime.toString().split(':')[1], 10));
                        eventStartDate = new Date(eventStartDate.toUTCString());
                    }
                }

                if (eventDetails.endTime) {
                    if (eventDetails.endTime.toString().slice(-2) == 'PM') {
                        eventEndDate.setHours(parseInt(eventDetails.endTime.toString().split(':')[0], 10) + 12, parseInt(eventDetails.endTime.toString().split(':')[1], 10));
                        eventEndDate = new Date(eventEndDate.toUTCString());
                    }
                    else {
                        eventEndDate.setHours(parseInt(eventDetails.endTime.toString().split(':')[0], 10), parseInt(eventDetails.endTime.toString().split(':')[1], 10));
                        eventEndDate = new Date(eventEndDate.toUTCString());
                    }
                }
                // eventDetails.start.addHours(5)
                // eventDetails.end.addHours(5);
                //  eventDetails.start.setTime(eventDetails.start.getTime() - eventDetails.start.getTimezoneOffset()*60*1000)
                //  eventDetails.end.setTime(eventDetails.end.getTime() - eventDetails.end.getTimezoneOffset()*60*1000)

                eventStartDate.setTime(eventDetails.start.getTime() - eventDetails.start.getTimezoneOffset() * 60 * 1000)
                eventEndDate.setTime(eventDetails.end.getTime() - eventDetails.end.getTimezoneOffset() * 60 * 1000)
                //Validating the Time issue for date selected  
                let current_date = new Date();
                let selected_Date = new Date(eventStartDate);
                if (selected_Date <= current_date) {
                    setDateError(true);
                    setShowProcessing(<span>Save</span>);
                }
                else {
                    let event_description = '';
                    if (editorState.getCurrentContent().getPlainText().length !== 0) {
                        event_description = stateToHTML(editorState.getCurrentContent(), desc_options);
                    }
                    let obj = {
                        Title: eventDetails.title,
                        Location: eventDetails.location ? eventDetails.location : '',
                        EventDate: eventStartDate,
                        EndDate: eventEndDate,
                        EventNotes: eventDetails.notes,
                        fAllDayEvent: allDayEvent,
                        Description: event_description ? event_description.replace(/(\r\n|\n|\r)/gm, "") : ''

                    }
                    helpers.updateData('Calendar', obj, data.Id, function (iar: any) {
                        console.log('success!');
                        if (fileAttachment) {
                            // iar.item.attachmentFiles.add(fileAttachmentText, fileAttachment);
                            let file: any;
                            let newDigest: any;
                            if (fileAttachment !== null) {
                                file = fileAttachment;
                                getFileBuffer(file).then(function (arrayBuffer) {
                                    arrayBuffer = arrayBuffer.result;
                                    var bytes = new Uint8Array(arrayBuffer);
                                    var binary = '';
                                    for (var b = 0; b < bytes.length; b++) {
                                        binary += String.fromCharCode(bytes[b]);
                                    }

                                    let listServiceUrl = helpers.appweburl + "/_api/contextinfo";
                                    var executor = new SP.RequestExecutor(helpers.appweburl);
                                    executor.executeAsync({
                                        url: listServiceUrl,
                                        method: "POST",
                                        headers: {
                                            "Accept": "application/json; odata=verbose",
                                            "Content-Type": "application/json; odata=verbose"
                                        },
                                        success: function (data) {
                                            var jsonObject = JSON.parse(data.body.toString());
                                            newDigest = jsonObject.d.GetContextWebInformation.FormDigestValue;
                                            var requestHeaders = {
                                                "Accept": "application/json;odata=verbose",
                                                "X-RequestDigest": newDigest,
                                                "Content-Type": "application/json; odata=verbose"
                                            }
                                            executor.executeAsync({
                                                //url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/GetFolderByServerRelativeUrl('" + helpers.siteSiteAssetsURL + "')/Files/Add(url='" + fileAttachmentText + "',overwrite=true)?@target='" + helpers.hostweburl + "'",
                                                url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/lists/getbytitle('Calendar')/items(" + iar.data.ID + ")/AttachmentFiles/add(FileName='" + fileAttachmentText + "')?@target='" + helpers.hostweburl + "'",
                                                method: "POST",
                                                headers: requestHeaders,
                                                binaryStringRequestBody: true,
                                                body: binary,
                                                success: function (data) {
                                                    console.log('Attachment added!');
                                                },
                                                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                                    //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                                },
                                                state: "Update"
                                            });
                                        },
                                        error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                            //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                        }
                                    });
                                });
                            }
                        }
                        setShowProcessing(<span>Save Event</span>);
                        setShowConf(true);
                        setValidated(false);
                        setDateError(false);
                        setShowModal(false);
                        setlongTitle(false);
                        setlongDesc(false);
                        setnoStartDate(false);
                    })
                }
            }
        }
    }

    function getFileBuffer(file) {
        var deferred = $.Deferred();
        var reader = new FileReader();
        reader.onload = function (reader) {
            deferred.resolve(reader.target);
        }
        reader.onerror = function (e) {
            deferred.reject(e);
        }
        reader.readAsArrayBuffer(file);
        return deferred.promise();
    }

    function updateEvents(e: any, field: any) {
        let obj: any = { ...eventDetails };
        if (field == 'startTime' || field == 'endTime') {
            obj[field] = timeFromInt(e);
            seteventDetails(obj);
        }
        else if (field == 'desc') {
            let desc = stateToHTML(e.getCurrentContent(), desc_options);
            obj[field] = desc;
            seteventDetails(obj);
        }
        else {
            obj[field] = e;
            seteventDetails(obj);
        }
    }
}

export default EditEvents