import React, { useState, useEffect, useRef } from 'react';
import './customWebpartWidget.scss'
import { Modal, Form } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import helpers from '../helpers';
import { MdCreate } from "react-icons/md";
import EditCustomWebpart from '../customWebpart/editCustomWebpart';
import DeleteCustomWebpart from '../customWebpart/deleteCustomWebpart';
import CustomWebpart from '../customWebpart/CustomWebpart';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiMoreVertical } from "react-icons/fi";
import { Link, useLocation } from 'react-router-dom';
const CustomWebpartWidget = (props) => {

    const { editMode, isModerator, response, showLoading, getCustomWebpart, setShowLoading, AppInsights, userEmail } = props;

    const [editWebpart, setEditWebpart] = useState<boolean>(false);
    const [deleteWebpart, setDeleteWebpart] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [archivedCustomWebpart, setArchivedCustomWebpart] = useState([]);
    const [customWebpartResponse, setCustomWebpartResponse] = useState([]);
    //const [showLoading, setShowLoading] = useState(true);
    const cssClasses: any = response.IsVisible ? isModerator ? "announcement-container hidden-announcement" : false : "announcement-container";

    //useEffect(() => {
    //    //getCustomWebpart();
    //}, [isModerator]);

    return (
        <div className="customwebpart-widget">
            <div className="customwebpart-cards">
                {
                    showLoading ?

                        <div style={{ lineHeight: 3 }}>
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                <Skeleton height={300} width={1060}></Skeleton>
                            </SkeletonTheme>

                        </div>
                        :
                        <>

                            {

                                <div>
                                    {
                                        cssClasses &&
                                        <div>

                                            <div id='webpart-edit' className={editWebpart ? "active-content" : ""}><EditCustomWebpart response={response} show={editWebpart} getCustomWebpart={getCustomWebpart} onHide={() => setEditWebpart(false)} /></div>
                                            <div id='webpart-edit' className={deleteWebpart ? "active-content" : ""}><DeleteCustomWebpart response={response} show={deleteWebpart} getCustomWebpart={getCustomWebpart} setShowLoading={setShowLoading} onHide={() => setDeleteWebpart(false)} /></div>
                                            <div className="webpart-header" onClick={() => sendToAppInsights("custom")}>
                                                {response.LinkWebpage !== null ?
                                                    <a className="webpart-header-link" href={response.LinkWebpage} target="_blank">{response.Title}</a> : <span>{response.Title}</span>}
                                                {(response.IsVisible && isModerator) && <span onClick={() => hideWebpart(false, response.ID)} className="unhide">Unhide </span>}
                                                <div id="edit" className={editMode ? " active" : ""}>
                                                    {
                                                        !response.IsVisible &&

                                                        <DropdownButton alignRight title={<FiMoreVertical />} id="dropdown-menu-align-right" >
                                                            <Dropdown.Item eventKey="1" onClick={() => setEditWebpart(true)} >Edit </Dropdown.Item>
                                                            <Dropdown.Item eventKey="2" onClick={() => hideWebpart(true, response.ID)}  >Hide </Dropdown.Item>
                                                            <Dropdown.Item eventKey="3" onClick={() => setDeleteWebpart(true)}>Delete </Dropdown.Item>
                                                        </DropdownButton>
                                                    }

                                                </div>
                                            </div>
                                            <div className={cssClasses}>
                                                <div className='webpart-description' dangerouslySetInnerHTML={{ __html: response.Body }}></div>

                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                        </>
                }
            </div>


        </div>
    )


    function hideWebpart(toHide: boolean, id) {
        setShowLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('CustomWebpart').items.getById(parseInt(id, 10)).update({ IsVisible: toHide }).then(i => {
            getCustomWebpart();
        }).catch(e => {
            console.log("error updating CustomWebpart!", e)
        });


    }



    function getCustomWebparts() {
        helpers.getAllData("CustomWebpart", ((items: Array<{
            Title: string,
            Body: string,
            LinkWebpage: string,
            IsVisible: boolean,
            ID: boolean

        }>) => {
            let setItems = [];
            for (let i = 0; i < items.length; i++) {
                setItems.push({
                    Title: items[i].Title,
                    Body: items[i].Body,
                    LinkWebpage: items[i].LinkWebpage,
                    IsVisible: items[i].IsVisible,
                    ID: items[i].ID,
                })
            }
            setCustomWebpartResponse(setItems);
        }), ["Title", "ID", "Body", "LinkWebpage", "IsVisible"], "", "ID", "");

    }


    function sendToAppInsights(webpartname) {
        AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Custom Web Part',
            WebPartHeader: webpartname,
            UserEmail: userEmail
        });
        AppInsights.flush();
    }


};

export default CustomWebpartWidget;