import React, { useState } from "react";
import Select from 'react-select';
import helpers from '../helpers';
import './addCustomExperts.scss';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";


const AddCustomExperts = (props: any) => {
    let timer;
    const { showDialog, setDialog, action, getManualExpertsTabData, allExperts, selectedExpert, expertHeaders, cusomtTabName, isHeaderVisible } = props;
    const [loadingContact, setLoadingContact] = useState(false);
    const [options, setOptions] = useState([]);
    const [contactId, setContactId] = useState();
    const [userContactEmail, setUserContactEmail] = useState();
    const [noContactId, setNoContactId] = useState(false);
    const [duplicateExpert, setDuplicateExpert] = useState(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Expert</span>);

    const [expertValue, setExpertValue] = useState('');
    const [column1Value, setColumn1Value] = useState('');
    const [column2Value, setColumn2Value] = useState('');
    const [column3Value, setColumn3Value] = useState('');
    const [column4Value, setColumn4Value] = useState('');
    const [column5Value, setColumn5Value] = useState('');
    const [column6Value, setColumn6Value] = useState('');

    const [column1Length, setColumn1Length] = useState(column1Value ? column1Value.length : 0);
    const [column2Length, setColumn2Length] = useState(column2Value ? column2Value.length : 0);
    const [column3Length, setColumn3Length] = useState(column3Value ? column3Value.length : 0);
    const [column4Length, setColumn4Length] = useState(column4Value ? column4Value.length : 0);
    const [column5Length, setColumn5Length] = useState(column5Value ? column5Value.length : 0);
    const [column6Length, setColumn6Length] = useState(column6Value ? column6Value.length : 0);

    const [validated, setvalidated] = useState(false);

    const [header2VisibleValue, setHeader2VisisbleValue] = useState(isHeaderVisible[1] === "true" ? true : false);
    const [header3VisibleValue, setHeader3VisisbleValue] = useState(isHeaderVisible[2] === "true" ? true : false);
    const [header4VisibleValue, setHeader4VisisbleValue] = useState(isHeaderVisible[3] === "true" ? true : false);
    const [header5VisibleValue, setHeader5VisisbleValue] = useState(isHeaderVisible[4] === "true" ? true : false);
    const [header6VisibleValue, setHeader6VisisbleValue] = useState(isHeaderVisible[5] === "true" ? true : false);
    const [header7VisibleValue, setHeader7VisisbleValue] = useState(isHeaderVisible[6] === "true" ? true : false);


    function assignValues() {
        if (action === 'edit') {
            setExpertValue(selectedExpert.Expert_x0020_Name.Title);
            setOptions(
                [
                    { value: selectedExpert.Expert_x0020_Name.Title, label: selectedExpert.Expert_x0020_Name.Title }
                ]
            );

            setColumn1Value(selectedExpert.Column_x0020_1);
            if (selectedExpert.Column_x0020_1 !== null) {
                setColumn1Length(selectedExpert.Column_x0020_1.length);
            }
            setColumn2Value(selectedExpert.Column_x0020_2);
            if (selectedExpert.Column_x0020_2 !== null) {
                setColumn2Length(selectedExpert.Column_x0020_2.length);
            }
            setColumn3Value(selectedExpert.Column_x0020_3);
            if (selectedExpert.Column_x0020_3 !== null) {
                setColumn3Length(selectedExpert.Column_x0020_3.length);
            }
            setColumn4Value(selectedExpert.Column_x0020_4);
            if (selectedExpert.Column_x0020_4 !== null) {
                setColumn4Length(selectedExpert.Column_x0020_4.length);
            }
            setColumn5Value(selectedExpert.Column_x0020_5);
            if (selectedExpert.Column_x0020_5 !== null) {
                setColumn5Length(selectedExpert.Column_x0020_5.length);
            }
            setColumn6Value(selectedExpert.Column_x0020_6);
            if (selectedExpert.Column_x0020_6 !== null) {
                setColumn6Length(selectedExpert.Column_x0020_6.length);
            }
        }
    }


    return (
        <Modal id="add-custom-experts-modal" aria-labelledby="contained-modal-title-vcenter"
            centered show={showDialog} onHide={() => handleCancel()} onShow={() => assignValues()} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{action === "add" ? "Add New Expert" : "Edit Expert"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated}>
                    
                    {header2VisibleValue === true ? <Form.Group>
                        <Form.Label> {expertHeaders[1] ? expertHeaders[1] : 'Column 2'} </Form.Label>
                        <Form.Control
                            placeholder={expertHeaders[1] ? 'Enter ' + expertHeaders[1] + ' here' : 'Enter Column 2 here'}
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            maxLength={255}
                            value={column1Value}
                            autoFocus
                            onChange={(e) => { setColumn1Value(e.currentTarget.value); setColumn1Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {column1Length ? "" + (255 - column1Length) + " characters remaining" : "255 characters max"}
                        </Form.Text>
                    </Form.Group> : ""}
                    {header3VisibleValue === true ? <Form.Group>
                        <Form.Label> {expertHeaders[2] ? expertHeaders[2] : 'Column 3'} </Form.Label>
                        <Form.Control
                            placeholder={expertHeaders[2] ? 'Enter ' + expertHeaders[2] + ' here' : 'Enter Column 3 here'}
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            maxLength={255}
                            autoFocus
                            value={column2Value}
                            onChange={(e) => { setColumn2Value(e.currentTarget.value); setColumn2Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {column2Length ? "" + (255 - column2Length) + " characters remaining" : "255 characters max"}
                        </Form.Text>
                    </Form.Group> : ""}
                    <Form.Group className='add-field'>
                        {action === "add" ? <Form.Label> {expertHeaders[0] ? expertHeaders[0] + ' (LastName, FirstName)' : 'Expert Name (LastName, FirstName)'} <span className="star">*</span></Form.Label> : ''}
                        {action === "add" ? <Select isLoading={loadingContact} options={options} maxMenuHeight={100} onInputChange={(val) => {
                            clearTimeout(timer);
                            timer = setTimeout(() => {
                                getOptions(val);
                            }, 1000)

                        }} onChange={(newVal) => handleChangeOptions(newVal)} placeholder='Enter LastName, FirstName of the leader here' required></Select>
                            :
                            <Form.Label className="edit-expertName"> {expertValue ? expertValue : ''} </Form.Label>
                        }

                        {noContactId ?
                            <Form.Text className="text-nocontact">
                                Please provide a valid Name/Email id</Form.Text> : ''}
                        {duplicateExpert ?
                            <Form.Text className="text-nocontact">
                                Expert who you are trying to add already exists.</Form.Text> : ''}
                    </Form.Group>
                    {header4VisibleValue === true ? <Form.Group>
                        <Form.Label> {expertHeaders[3] ? expertHeaders[3] : 'Column 4'} </Form.Label>
                        <Form.Control
                            placeholder={expertHeaders[3] ? 'Enter ' + expertHeaders[3] + ' here' : 'Enter Column 4 here'}
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            maxLength={255}
                            value={column3Value}
                            autoFocus
                            onChange={(e) => { setColumn3Value(e.currentTarget.value); setColumn3Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {column3Length ? "" + (255 - column3Length) + " characters remaining" : "255 characters max"}
                        </Form.Text>
                    </Form.Group> : ""}

                    {header5VisibleValue === true ? <Form.Group>
                        <Form.Label> {expertHeaders[4] ? expertHeaders[4] : 'Column 5'} </Form.Label>
                        <Form.Control
                            placeholder={expertHeaders[4] ? 'Enter ' + expertHeaders[4] + ' here' : 'Enter Column 5 here'}
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            maxLength={255}
                            value={column4Value}
                            autoFocus
                            onChange={(e) => { setColumn4Value(e.currentTarget.value); setColumn4Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {column4Length ? "" + (255 - column4Length) + " characters remaining" : "255 characters max"}
                        </Form.Text>
                    </Form.Group> : ""}
                    {header6VisibleValue === true ? <Form.Group>
                        <Form.Label> {expertHeaders[5] ? expertHeaders[5] : 'Column 6'} </Form.Label>
                        <Form.Control
                            placeholder={expertHeaders[5] ? 'Enter ' + expertHeaders[5] + ' here' : 'Enter Column 6 here'}
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            maxLength={255}
                            value={column5Value}
                            autoFocus
                            onChange={(e) => { setColumn5Value(e.currentTarget.value); setColumn5Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {column5Length ? "" + (255 - column5Length) + " characters remaining" : "255 characters max"}
                        </Form.Text>
                    </Form.Group> : ""}
                    {header7VisibleValue === true ? <Form.Group>
                        <Form.Label> {expertHeaders[6] ? expertHeaders[6] : 'Column 7'} </Form.Label>
                        <Form.Control
                            placeholder={expertHeaders[6] ? 'Enter ' + expertHeaders[6] + ' here' : 'Enter Column 7 here'}
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            maxLength={255}
                            value={column6Value}
                            autoFocus
                            onChange={(e) => { setColumn6Value(e.currentTarget.value); setColumn6Length(e.currentTarget.value.length) }}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            {column6Length ? "" + (255 - column6Length) + " characters remaining" : "255 characters max"}
                        </Form.Text>
                    </Form.Group> : ""}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-cancel-menu" variant="secondary" onClick={() => { handleCancel() }}> Cancel </Button>
                <Button variant="primary" className="proceed-button" onClick={() => { saveExpert() }}>{showProcessing}</Button>

            </Modal.Footer>
        </Modal>
    );
    function handleCancel() {
        setDialog(false);
        setNoContactId(false);
        setDuplicateExpert(false);
        setvalidated(false);
        setExpertValue('');
        setColumn1Value('');
        setColumn2Value('');
        setColumn3Value('')
        setColumn4Value('');
        setColumn5Value('');
        setColumn6Value('');
    }

    function getOptions(name: string) {
        if (!name) return;
        setLoadingContact(true);
        const searchName = name.replace(/^.{1}/g, name[0].toUpperCase());
        let allOptions = [];
        var searchUrl = helpers.appweburl + "/_api/search/query?querytext='" + searchName + "'&rowlimit=100&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'";
        var executor = new SP.RequestExecutor(helpers.appweburl);
        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchResultsSuccess,
                error: onGetSearchResultsFail
            }
        );
        //helpers.getUserNames(function (data) {
        //    data.map((item: any) => {
        //        allOptions.push({
        //            key: item.Id,
        //            value: item.Title,
        //            label: <div><img src={item.UserDispImg} className='img-add' />&nbsp;{item.Title}</div>
        //        })
        //    });
        //    //Before setting the options, filtering already added contacts
        //    setOptions(allOptions);
        //    setLoadingContact(false);
        //}, searchName)
    }

    function onGetSearchResultsSuccess(data) {
        let userEmail = '';
        let value = '';
        let userAccname = '';
        let allOptions = [];
        let jsonObject = JSON.parse(data.body);
        let results = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        if (results.length > 0 && results !== null) {
            for (let i = 0; i < results.length; i++) {
                userEmail = '';
                value = '';
                userAccname = '';

                for (var j = 0; j < results[i].Cells.results.length; j++) {
                    if (results[i].Cells.results[j].Key === "PreferredName") {
                        value = results[i].Cells.results[j].Value;
                    }
                    if (results[i].Cells.results[j].Key === "AccountName") {
                        userAccname = results[i].Cells.results[j].Value;
                    }
                    if (results[i].Cells.results[j].Key === "WorkEmail") {
                        userEmail = results[i].Cells.results[j].Value;
                    }
                }

                allOptions.push({
                    key: value,
                    value: value,
                    label: <div><img src={userEmail} className='img-add' />&nbsp;{value}</div>,
                    userAccname: userAccname,
                })
            }
        }
        console.log(allOptions);
        //Before setting the options, filtering already added contacts
        setOptions(allOptions);
        setLoadingContact(false);
    }


    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }
    function handleChangeOptions(newVal: any) {
        setContactId(newVal.value);
        setUserContactEmail(newVal.userAccname);
        setDuplicateExpert(false);
        setNoContactId(false);
    }

    function saveExpert() {
        setNoContactId(false);
        setDuplicateExpert(false);
        setvalidated(false);

        let exists = false;
        allExperts.filter(e => e.Tab_x0020_Name === cusomtTabName).map(item => {
            if (item.Expert_x0020_Name.Title === contactId) {
                exists = true;
            }
        })


        if (action === "add" && (contactId == null || contactId == undefined)) {
            setvalidated(true);
            setNoContactId(true);
        }
        else if (exists) {
            setDuplicateExpert(true);
            setOptions([]);
        }
        else {
            setNoContactId(false);
            setDuplicateExpert(false);
            setvalidated(false);
            setShowProcessing(
                <span>
                    Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            );
            if (action === "add") {

                helpers.getWebEnsureUser(function (data) {
                    let toAdd = {
                        Expert_x0020_NameId: data.data.Id ? data.data.Id : selectedExpert.Expert_x0020_Name.Id,
                        Column_x0020_1: column1Value,
                        Column_x0020_2: column2Value,
                        Column_x0020_3: column3Value,
                        Column_x0020_4: column4Value,
                        Column_x0020_5: column5Value,
                        Column_x0020_6: column6Value,
                        Tab_x0020_Name: cusomtTabName,
                    };
                    helpers.addData("Manual Experts", toAdd, function (iar: any) {
                        setShowProcessing(<span>Save Expert</span>);
                        setDialog(false);
                        getManualExpertsTabData();
                    });
                }, userContactEmail);


            }
            else {
                let toAdd = {
                    Expert_x0020_NameId: selectedExpert.Expert_x0020_Name.Id,
                    Column_x0020_1: column1Value,
                    Column_x0020_2: column2Value,
                    Column_x0020_3: column3Value,
                    Column_x0020_4: column4Value,
                    Column_x0020_5: column5Value,
                    Column_x0020_6: column6Value,
                    Tab_x0020_Name: cusomtTabName,
                };
                helpers.updateCustomExpert("Manual Experts", toAdd, selectedExpert.ID, function (iar: any) {
                    setShowProcessing(<span>Save Expert</span>);
                    setDialog(false);
                    getManualExpertsTabData();
                });
            }
        }

    }
};

export default AddCustomExperts;