import React, { useState, useEffect, Suspense } from 'react';
import Slider from "react-slick";
import './Banner.scss';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiMoreVertical } from "react-icons/fi";
import helpers from '../helpers';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
//Loading components on demand
import EditBanner from '../editBanner/EditBanner';

function CustomArrow(props) {
    const { className, style, onClick, prev } = props;
    return (<div className={prev ? 'left-custom' : 'right-custom'} style={{ ...style }} onClick={onClick}> {prev ? <GoChevronLeft size={30} /> : <GoChevronRight size={30} />}</div>);
}

function CustomArrowLeft(props) {
    const { className, style, onClick, prev } = props;
    return (<div className={'right-custom'} style={{ ...style }} onClick={onClick}> <GoChevronLeft size={30} /> </div>);
}

const Banner = (params: any) => {

    //export default function Banner(params:any) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow prev={true} />
    };

    const [bannerResponse, setBannerResponse] = useState<{ Image: string, Id: number, Author: string, Title: string, Description: string, Link: string, Background: boolean }[]>([]);
    const [archivedBanners, setArchivedBanners] = useState([]);
    const [showEdit, setshowEdit] = useState(false);
    const [showBanners, setShowBanners] = useState(false);
    const [isHidden, setIsHidden] = useState(params.hidden);

    // Get Banner Data
    // Call the helper file
    // Pass the callback function as second parameter to process the data after data fetching is completed
    // Pass the columns to be selected and expanded as the 3rd and 4th option respectively as array of strings
    useEffect(() => {
        getBanners();
    }, [])

    return (

        <div id="banner-component">



            <EditBanner showDialog={showEdit} onClose={onEditBanerClose} getBanners={getBanners} banners={bannerResponse} archivedBanners={archivedBanners} />


            {showBanners ?
                <div id="edit" className={params.editMode && "active"} >
                {!isHidden ?
                    <DropdownButton
                        alignRight
                        title={<FiMoreVertical />}
                        id="dropdown-menu-align-right"
                    >
                        <Dropdown.Item eventKey="1" onClick={() => setshowEdit(true)} >Add / Modify / Remove</Dropdown.Item>
                        <Dropdown.Item eventKey="1" onClick={() => hideWebpart(true)} >Hide</Dropdown.Item>                
                    </DropdownButton>
                    :
                    <span className="unhide" onClick={() => hideWebpart(false)} >Unhide</span>
                    }
                </div>
                : ""}

            {showBanners ? <Slider {...settings}> {bannerResponse.map(function (slide: any, index: number) {
                return (
                    <div key={index}>
                        {slide.Image != null ?
                            <div key={slide.ID}>

                                <div title={slide.Link && "On clicking the banner, you will be taken to the respective content tagged by your moderator."} className={slide.Link ? 'slide-z' : 'slide-z no-link'} style={{ backgroundImage: "url(" + slide.Image + ")" }} key={slide.ID} onClick={() => slide.Link ? onBannerClick(slide.Link) : false}>
                                    {/* <img src={slide.img} ></img> */}
                                    <div className={slide.Background ? "content-black" : "content-white"} >
                                        <h3 className="slide-title">{slide.Title}</h3>
                                        <div className='slide-body'>
                                            {slide.Description}
                                        </div>
                                    </div>
                                </div>

                            </div> :
                            <div key={slide.id}>
                                <div title={slide.Link && "On clicking the banner, you will be taken to the respective content tagged by your moderator."} className={slide.Link ? 'slide-z' : 'slide-z no-link'} key={slide.ID} onClick={() => slide.Link ? onBannerClick(slide.Link) : false}>
                                    <div className='slide-noimage'>
                                        {
                                            <div className='slide-imagelessdiv'>
                                                <h3 className="slide-noimagetitle">{slide.Title}</h3>
                                                <div className='slide-noimagebody'>
                                                    {slide.Description}
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>}

                    </div>);
            })} </Slider> : <div className="loader-icon"> <SkeletonTheme color="#eeeeee" highlightColor="#ffffff"><Skeleton height={380}></Skeleton></SkeletonTheme><div className="loader-shimmer"><SkeletonTheme color="#dddddd" highlightColor='#dddddd'><Skeleton height={26} /><div className='loader-shimmer-2'><Skeleton height={26} /></div></SkeletonTheme></div></div>}

        </div>)

    function onEditBanerClose(showLoading?: boolean) {
        setshowEdit(false);
        showLoading ? setShowBanners(false) : setShowBanners(true)
    }

    function getBanners() {

        setShowBanners(false);

        helpers.getAllDatasortdesc("SPoABannerCarousel", ((archivedItems: []) => {
            setArchivedBanners(archivedItems);
        }), [], "", ["Modified"], "Archived eq 1", 6);

        helpers.getAllData("SPoABannerCarousel", ((items: Array<{
            Image: string,
            ID: number,
            Title: string,
            Editor: {
                Title: string
            },
            Description: string,
            Link: string,
            Background: boolean

        }>) => {
            let setBanner = [];
            for (let i = 0; i < items.length; i++) {
                setBanner.push({
                    Image: items[i].Image,
                    Id: items[i].ID,
                    Author: 'by ' + items[i].Editor.Title,
                    Title: items[i].Title,
                    Description: items[i].Description,
                    Link: items[i].Link,
                    Background: items[i].Background,
                })
            }
            setBannerResponse(setBanner);
            setShowBanners(true);
        }), ["Image", "Link", "ID", "Title", "Description", "Archived", "Background", "Editor/Title", "SpoAOrder"], ["Editor"], "SpoAOrder", "Archived eq 0");



    }
    function onBannerClick(link: any) {
        window.open(link, '_blank');
        sendToAppInsights('Banner');
    }

    function sendToAppInsights(webpartname: string) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Banner Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.userEmail
        });
        params.AppInsights.flush();
    }

    function hideWebpart(toHide: boolean) {
        setShowBanners(false);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Application eq 'Carousel'").get().then((res) => {
            let Id = res[0].Id;
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(Id).update({ IsHidden: toHide }).then(i => {
                setIsHidden(toHide);
                setShowBanners(true);
            }).catch(e => {
                console.log("error updating Banner!", e)
            });
        });

    }


};

export default Banner;