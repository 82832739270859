import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import helpers from "../helpers";
import './EditMainMenu.scss';

const EditMenuItem = (params: any) => {

    const [validated, setValidated] = useState(false);
    const [itemID, setItemID] = useState(params.selectedMenuItem.ID);
    const [itemName, setItemName] = useState(params.selectedMenuItem.Value);
    const [tabLength, setTabLength] = useState(params.selectedMenuItem.Value.length);
    const [displayTab, setDisplayTab] = useState(params.selectedMenuItem.Visible);
    const [noTabName, setNoTabName] = useState(false);
    const [duplicateTabName, setDuplicateTabName] = useState(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Tab</span>);



    return (
        <Modal id="edit-menu-item" onHide={params.onHide}
            backdrop="static"
            show={params.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Menu Item
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated}>
                    <Form.Group>
                        <Form.Label>Menu Title</Form.Label>
                        <span className="star">*</span>
                        <Form.Control
                            placeholder="Enter new menu name here"
                            pattern="^[a-zA-Z1-9].*"
                            type="input"
                            value={itemName}
                            onChange={(e: any) => {
                                setItemName(e.currentTarget.value);
                                setTabLength(e.currentTarget.value.length);
                            }}
                            maxLength={50}
                            required
                            autoFocus
                        ></Form.Control>
                        {noTabName ?
                            <Form.Text className="tabname-validate">
                                Please provide a valid menu name</Form.Text> : ''}
                        {duplicateTabName ?
                            <Form.Text className="tabname-validate">
                                Menu already exists. Kindly try with a different menu name</Form.Text> : ''}
                        <Form.Text className="text-muted">
                            {tabLength
                                ? "" + (50 - tabLength) + " characters remaining"
                                : "50 characters max"}
                        </Form.Text>
                    </Form.Group>
                    {params.selectedMenuItem.Title != "Home" && 
                    <Form.Check
                        type="switch"
                        id="custom-switch-2"
                        label="Show this menu item"
                        title=""
                        checked={displayTab}
                        onClick={() => { displayTab ? setDisplayTab(false) : setDisplayTab(true) }}
                    />}
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button className="button-cancel-menu" variant="secondary" onClick={() => { params.onHide(); }}>Cancel</Button>
                <Button variant="primary" className="proceed-button" onClick={() => { updateTabName(); }}>{showProcessing}</Button>

            </Modal.Footer>
        </Modal>
    );


    function updateTabName() {
        setNoTabName(false);
        setDuplicateTabName(false);
        let exists = false;
        params.defaultMenuItems.filter(x => x.ID != itemID).map(item => {
            if (item.Value === itemName.trim()) {
                exists = true;
            }
        })
        if (itemName.trim() === '') {
            setValidated(true);
            setNoTabName(true);
        }
        else if (exists) {
            setDuplicateTabName(true);
        }
        else {
            setNoTabName(false);
            setDuplicateTabName(false);
            setShowProcessing(
                <span>
                    Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            );
            let toUpdate = { Value: itemName.trim(), isVisible: displayTab, isDefault: true };
            helpers.updateData("Quick Links", toUpdate, itemID, function (
                iar: any
            ) {
                setShowProcessing(<span>Save Tab</span>);
                params.getDefaultMenuItems();
                params.onHide();
            });
        }
    }

}
export default EditMenuItem;