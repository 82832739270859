import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import "./editLeader.scss";
import EditSection from "./editSection";
import AddSection from "../addLeader/addSection";
import helpers from '../helpers';
import Sortable from 'sortablejs';

export interface ILeaderState {
    id: number | null;
    name: string | null;
    title: string | null;
    desc?: string | null;
    leaderGroups: string[] | null;
    order?: number | null;

}

const EditLeader = (props: any) => {
    const { show, onHide, allLeadersProp, getItems, addTo } = props;
    const [leaderCategories, setLeaderCategories] = useState(props.leaderCategories);
    const [details, setDetails] = useState<ILeaderState>();
    const [editLeader, setEditLeader] = useState<boolean>(false);       
    const[edited, setEdited] = useState(false);
    const [validated, setvalidated] = useState<boolean>(false);
    const [leaderTitle, setLeaderTitle] = useState('');
    const [leaderDesc, setLeaderDesc] = useState('');
    const [titleLength, setTitleLength] = useState(0);
    const [descLength, setDescLength] = useState(0);
    const [noSectionSelected, setNoSectionSelected] = useState<boolean>(false);
    const [leaderSections, setLeaderSections] = useState([]);
    const [leaderGroups, setLeaderGroups] = useState([]);
    const [noTitle, setNoTitle] = useState<boolean>(false);
    const [noDesc, setNoDesc] = useState<boolean>(false);
    const [editSection, setEditSection] = useState<boolean>(false);
    const [sectionName, setSectionName] = useState('');
    const [addSection, setAddSection] = useState<boolean>(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Leader</span>);
    const [dragWait, setDragWait] = useState<JSX.Element>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [closeButton, setCloseButton] = useState<JSX.Element>(<span>Close</span>);
    const [accordianActive, setAccordianActive] = useState(0);
    const [allLeaders, setAllleaders] = useState(allLeadersProp);

    setTimeout(() => {
        let leaderElements = document.getElementsByClassName('onhandle') as any;
        leaderElements.forEach((z: any) => {
            Sortable.create(z, {
                animation: 500,
                ghostClass: 'blue-background-class',
                onEnd: function (evt: any) {
                    setDragWait(<i className="fa fa-spinner fa-pulse fa-fw"></i>)
                },
                handle: '.leader-wrapper-before',
                dragClass: 'dragging',
                chosenClass: 'dragging',
                store: {
                    get: function (sortable) {
                        return [];
                    },
                    set: function (sortable) {
                        let temp = sortable.toArray();
                        let leadersToUpdate = [];
                        temp.forEach((item: any, linkIndex: number) => {
                            allLeaders.map((leader: any) => {
                                if (leader.Id == item) {
                                    leadersToUpdate.push({
                                        leaderID: leader.Id,
                                        leaderOrder: linkIndex
                                    })
                                }
                            })
                        })
                        setIsSaving(true);
                        setCloseButton(<span>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                        leadersToUpdate.forEach((leader: any) => {
                            helpers.updateData('Experts', { SpoAOrder: leader.leaderOrder }, leader.leaderID, function () {
                                setIsSaving(false);
                                setEdited(true);
                                setCloseButton(<span>Close</span>);                                
                            })
                        })
                    }
                }
            });
        });

        let sectionElements = document.getElementsByClassName('accordion') as any;
        sectionElements.forEach((z: any) => {
            Sortable.create(z, {
                animation: 500,
                ghostClass: 'blue-background-class',
                onEnd: function (evt: any) {
                    setDragWait(<i className="fa fa-spinner fa-pulse fa-fw"></i>)
                },
                handle: '.card-wrapper-before',
                dragClass: 'dragging',
                chosenClass: 'dragging',
                store: {
                    get: function (sortable) {
                        return [];
                    },
                    set: function (sortable) {
                        let temp = sortable.toArray();

                        setIsSaving(true);
                        setCloseButton(<span>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                        let field;
                        if (addTo === "Leader") {
                            field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoALeaderGroup");
                        }
                        else if (addTo === "Other Role") {
                            field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoAOtherDiscplineGroup");
                        }
                        field.select('Choices')
                            .get().then((fieldData) => {
                                field.update({
                                    Choices: {
                                        results: temp
                                    }
                                }, 'SP.FieldChoice')
                                setIsSaving(false);
                                setEdited(true);
                                setCloseButton(<span>Close</span>);
                            })
                    }
                }
            });
        });
    }, 1000);

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="edit-leader-section"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <div id="edit-leader-wrapper" className={!addSection && editLeader ? "active" : "not-active"} >
                <Modal.Header closeButton>
                    <FaArrowLeft onClick={() => { handleCancel() }} className="arrow-back-category" />
                    <Modal.Title>Edit Leader</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Form.Label><strong>{details ? details.name : ''}</strong></Form.Label>
                        <Form.Group>
                            <Form.Label>Leader Title <span className="star">*</span></Form.Label>
                            <Form.Control
                                required
                                type="input"
                                value={leaderTitle}
                                placeholder="Enter title for this leader here"
                                pattern="^[a-zA-Z1-9].*"
                                maxLength={255}
                                autoFocus
                                onChange={(e) => { setLeaderTitle(e.currentTarget.value); setTitleLength(e.currentTarget.value.length) }}
                            ></Form.Control>
                            {noTitle ? <Form.Text className="invalid-input"> Please provide a valid leader title</Form.Text> : ''}
                            <Form.Text className="text-muted">
                                {titleLength ? "" + (255 - titleLength) + " characters remaining" : "255 characters max"}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Leader Description <span className="star">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                required
                                type="input"
                                value={leaderDesc}
                                placeholder="Describe the leader title here"
                                pattern="^[a-zA-Z1-9].*"
                                maxLength={350}
                                autoFocus
                                onChange={(e) => { setLeaderDesc(e.currentTarget.value); setDescLength(e.currentTarget.value.length) }}
                            ></Form.Control>
                            {noDesc ? <Form.Text className="invalid-input"> Please provide a valid leader description</Form.Text> : ''}
                            <Form.Text className="text-muted">
                                {descLength ? "" + (350 - descLength) + " characters remaining" : "350 characters max"}
                            </Form.Text>
                        </Form.Group>
                        <Form.Label>Display in Leadership sections <span className="star">*</span></Form.Label>
                        <Button className="add-link-btn" variant="link" onClick={() => setAddSection(true)}>
                            <MdAddCircleOutline /> Add New Section</Button>
                        <Form.Group>
                            {leaderSections ? leaderSections.map((item: any, index: any) => {
                                return (
                                    <Form.Check
                                        custom
                                        key={index}
                                        type='checkbox'
                                        id={`leader-category ${index}-1`}
                                        label={item.Title}
                                        defaultChecked={item.isChecked}
                                        onChange={(e) => { handleCheck(e.target.checked, item.Title); }}
                                    />
                                )
                            }) : null}
                            {noSectionSelected ?
                                <Form.Text className="invalid-input">
                                    Please select at least one leadership section</Form.Text> : ''}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => { handleCancel() }}> Cancel </Button>
                    <Button variant="primary" className="proceed-button" onClick={() => { updateLeader(details.id.toString()) }}>{showProcessing}</Button>
                </Modal.Footer>
            </div>

            <div id="edit-section-wrapper" className={editSection ? "active" : "not-active"}>
                {editSection ? <EditSection onHide={(newSectionName?,leadersToUpdate?) => {
                    setEditSection(false);
                    setEdited(true);
                    if (newSectionName) {
                        let tempLeaders = [...allLeaders];
                        tempLeaders.forEach(o => {
                            if (addTo == "Leader") {
                                if (leadersToUpdate.includes(o) && !o.SpoALeaderGroup.includes(newSectionName)) {
                                    o.SpoALeaderGroup.push(newSectionName);
                                }
                            }
                            else if (addTo == "Other Role") {
                                if (leadersToUpdate.includes(o) && !o.SpoAOtherDiscplineGroup.includes(newSectionName)) {
                                    o.SpoAOtherDiscplineGroup.push(newSectionName);
                                }
                            }
                        });
                        setAllleaders(tempLeaders);
                    }


                }} sectionName={sectionName} leaderCategories={leaderCategories} allLeaders={allLeaders} addTo={addTo} /> : ''}
            </div>
            <div id="add-section-wrapper" className={addSection ? "active" : "not-active"}>
                {addSection ? <AddSection onHide={(sections) => {
                    setAddSection(false);
                    var op = sections.map(o => o.Title)
                    setLeaderCategories([...op]);
                }} leaderSections={leaderSections} leaderGroups={leaderGroups} addTo={addTo} /> : ''}
            </div>

            <div id="show-leaders-wrapper" className={(editLeader || editSection || addSection) ? "not-active" : ""} >
                <Modal.Header closeButton><Modal.Title>Edit Leaders / Sections</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Accordion defaultActiveKey="0">
                        {leaderCategories.map((cat: any, i: number) => {
                            let leadersByCategory;
                            if (addTo === "Leader"){
                                leadersByCategory = allLeaders.filter(leader => (leader.SpoALeaderGroup && leader.SpoALeaderGroup.indexOf(cat) > -1));
                            }                                
                            else if (addTo === "Other Role"){
                                leadersByCategory = allLeaders.filter(leader => ( leader.SpoAOtherDiscplineGroup && leader.SpoAOtherDiscplineGroup.indexOf(cat) > -1));
                            }                                
                            return (

                                <Card key={`accord-card` + i.toString()} data-id={cat}>
                                    <Accordion.Toggle as={Card.Header} className={i === 0 ? "active" : ''} eventKey={i.toString()} onClick={() => { handleArrows(i); setAccordianActive(i) }} >
                                        <div className='edit-header'>{cat}
                                            <span className='leader-edit-category-span'><MdCreate className='edit-link-icon' onClick={() => { setAccordianActive(i); setEditSection(true); setSectionName(cat); }} /> </span>
                                        </div>
                                    </Accordion.Toggle>
                                    <div className="card-wrapper-before"></div>
                                    <Accordion.Collapse eventKey={i.toString()}>
                                        <Card.Body className="leaders-wrapper" data-id={cat}>
                                            <div className='onhandle' >
                                                {
                                                    leadersByCategory.map((leader: any, index: number) => {
                                                        return (
                                                            <div className="edit-leader" data-id={leader.Id} key={index}>
                                                                <div className='handler-edit'>
                                                                    <div className="leader-wrapper-before"></div>
                                                                    <div id={`leader-title-` + i + index} className='handler-edit-div'> {leader.SpoAUser.Title}</div>
                                                                    <span className='leader-edit-category'><MdCreate className='edit-link-icon' onClick={() => { getLeaderData(leader, 'EditLeader') }} /> </span>
                                                                </div>

                                                                <div className="leader-wrapper" id={`leader-edit-` + i + index}></div>
                                                            </div>
                                                        )
                                                    })

                                                }
                                            </div>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })
                        }
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" disabled={isSaving} onClick={() => handleClose()}> {closeButton} </Button>
                </Modal.Footer>
            </div>
        </Modal >
    )

    function handleArrows(i: number) {
        document.querySelectorAll("#edit-leader-section .card-header").forEach((o, i) => {
            o.classList.remove('active')
        });

        if (!document.querySelectorAll("#edit-leader-section .collapse")[i].classList.contains("show")) {
            document.querySelectorAll("#edit-leader-section .card-header")[i].classList.add("active")
        }

    }

    function handleClose() {
        onHide();
        edited && getItems();        
    }

    function handleCancel() {
        setEditLeader(false);
        setvalidated(false);
        setLeaderSections(null);
        setNoTitle(false);
        setNoDesc(false);
        setNoSectionSelected(false);
    }

    function getLeaderData(leader, type: string) {
        let groups = [];
        if (addTo === "Leader")
            groups = leader.SpoALeaderGroup
        else if (addTo === "Other Role")
            groups = leader.SpoAOtherDiscplineGroup
        setDetails({ id: leader.Id, name: leader.SpoAUser.Title, title: leader.SpoALeaderType, desc: leader.SpoADescription, leaderGroups: groups });
        let sections = [];
        leaderCategories.map((item: any) => {
            sections.push({
                Title: item,
                isChecked: groups.indexOf(item) > -1 ? true : false,
            });
        })
        setLeaderSections(sections);
        setLeaderGroups(groups);
        setEditLeader(true);
        setLeaderTitle(leader.SpoALeaderType);
        setTitleLength(leader.SpoALeaderType.length);
        setLeaderDesc(leader.SpoADescription);
        setDescLength(leader.SpoADescription.length);
    }


    function handleCheck(flag: boolean, group: string) {
        setNoSectionSelected(false);
        if (flag) {
            leaderGroups.push(group);
        }
        else {
            var index = leaderGroups.indexOf(group);
            if (index !== -1)
                leaderGroups.splice(index, 1);
        }
    }

    function updateLeader(id: string) {
        setNoTitle(false);
        setNoDesc(false);
        setvalidated(false);
        setNoSectionSelected(false);

        if (leaderTitle.trim() === '') {
            setvalidated(true);
            setNoTitle(true);
        }

        else if (leaderDesc.trim() === '') {
            setvalidated(true);
            setNoDesc(true);
        }
        else if (leaderGroups.length === 0) {
            setNoSectionSelected(true);
        }

        else {
            let toUpdate;
            if (addTo === "Leader") {
                toUpdate = {
                    SpoALeaderType: leaderTitle,
                    SpoADescription: leaderDesc,
                    SpoALeaderGroup: {
                        results: leaderGroups
                    },
                };
            }
            else if (addTo === "Other Role") {
                toUpdate = {
                    SpoALeaderType: leaderTitle,
                    SpoADescription: leaderDesc,
                    SpoAOtherDiscplineGroup: {
                        results: leaderGroups
                    },
                };
            }

            setShowProcessing(<span>Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
            helpers.updateData('Experts', toUpdate, id, function () {
                setShowProcessing(<span>Save Leader</span>);
                setEdited(true);
                handleCancel();
            });

        }
    }


}

export default EditLeader;