import React, { useState, useEffect } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';
import './customExperts.scss';
import helpers from "../helpers";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCustomExperts from '../addCustomExperts/addCustomExperts';
import EditCustomExpertsHeader from '../editCustomExpertsHeader/editCustomExpertsHeader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { sp } from "sp-pnp-js";
import Pagination from 'react-bootstrap/Pagination';
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import ReactExport from "react-export-excel-fixed-xlsx";

const CustomExperts = (props: any) => {
    const { editMode, allExperts, expertHeaders, getManualExpertsTabData, tabHeaderId, cusomtTabName, tabMode, efmlColumns, efmlFilter, tabDisplayName, isHeaderVisible } = props;
    const [showEditExpert, setshowEditExpert] = useState(false);
    const [showEditHeader, setshowEditHeader] = useState(false);
    const [showDelete, setshowDelete] = useState(false);
    const [dialogType, setDialogType] = useState("");
    const [selectedExpert, setSelectedExpert] = useState<any>("");
    const [deleteButton, setDeleteButton] = useState(<span>Delete Expert</span>);
    const [efmlExperts, setEfmlExperts] = useState([]);
    const [loading, setLoading] = useState(true);
    const recordsPerPage = 10; //This will decide the pagination count.
    const [pageCount, setPageCount] = useState(5);
    const [expertsToShow, setExpertsToShow] = useState([]);
    const [activePage, setaActivePage] = useState(1);
    const [efmlSelectColumns, setEfmlSelectColumns] = useState(efmlColumns ? efmlColumns.split(",") : null);
    const EF_URL = 'https://my.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c';
    const [rows, setRows] = useState([]);
    const [efmlRows, setEfmlRows] = useState([]);

    const selectors = Data.Selectors;
    const {
        NumericFilter,
        AutoCompleteFilter,
        MultiSelectFilter,
        SingleSelectFilter
    } = Filters;

    const defaultColumnProperties = {
        filterable: true,
        sortable: true,
        resizable: true,

    };

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    let currentDateTime = new Date();

    const tempExcelFile = [];
    tempExcelFile.push({
        ExpertName: "No Data Available",
        Column1: "",
        Column2: "",
        Column3: "",
        Column4: "",
        Column5: "",
        Column6: "",
        Column7: "",
        Column8: ""
    });


    const ExpertNameFormatter = ({ row }) => {
        if (editMode && tabMode != "EFML")
            return (
                <div className="expert-name-cell" >
                    <img className="expert-img" src={`${helpers.sitecollectionurl}_layouts/15/userphoto.aspx?size=L&accountname=${row.Column8}`} alt={row.Column8} />
                    <a className="expert-name" href={EF_URL + `${row.Column8}`} target="_blank">{row.ExpertName}</a>
                </div>
            )
        else
            return (
                <div className="expert-name-cell" >
                    <img className="expert-img" src={`${helpers.sitecollectionurl}_layouts/15/userphoto.aspx?size=L&accountname=${row.Column8}`} alt={row.Column8} />
                    <a className="expert-name-efml" href={EF_URL + `${row.Column8}`} target="_blank">{row.ExpertName}</a>
                </div>
            )
    };

    const Column1Formatter = ({ row }) => {
        if (editMode && tabMode != "EFML")
            return (
                <div className="expert-name-cell" >
                    <div>
                        <MdCreate className='editlinktableicon' onClick={() => { setshowEditExpert(true); setDialogType("edit"); setSelectedExpert(allExperts.find(e => e.Id === row.Column7)); }} />
                        <GiTrashCan className='deletelinktableicon' onClick={() => { setshowDelete(true); setSelectedExpert(allExperts.find(e => e.Id === row.Column7)); }} />
                    </div>
                    <div className="expert-column1" title={row.Column1}>{row.Column1}</div>
                </div>
            )
        else
            return (
                <div className="expert-name-cell" >
                    <div className="expert-column1-efml" title={row.Column1}>{row.Column1}</div>
                </div>
            )
    };

    const initalColumns = [

        { key: 'Column1', name: expertHeaders[1], isVisible: isHeaderVisible[1], filterRenderer: MultiSelectFilter, formatter: Column1Formatter },
        { key: 'Column2', name: expertHeaders[2], isVisible: isHeaderVisible[2], filterRenderer: MultiSelectFilter },
        { key: 'ExpertName', name: expertHeaders[0], isVisible: isHeaderVisible[0], filterRenderer: MultiSelectFilter, formatter: ExpertNameFormatter },
        { key: 'Column3', name: expertHeaders[3], isVisible: isHeaderVisible[3], filterRenderer: MultiSelectFilter },
        { key: 'Column4', name: expertHeaders[4], isVisible: isHeaderVisible[4], filterRenderer: MultiSelectFilter },
        { key: 'Column5', name: expertHeaders[5], isVisible: isHeaderVisible[5], filterRenderer: MultiSelectFilter },
        { key: 'Column6', name: expertHeaders[6], isVisible: isHeaderVisible[6], filterRenderer: MultiSelectFilter }].map(c => ({ ...c, ...defaultColumnProperties }));

    let columns = [];
    columns = [...initalColumns];
    columns = columns.filter(o => o.isVisible === "true");

    const [filters, setFilters] = useState({});
    const initialRows = (tabMode == "EFML") ? efmlRows : rows;
    const filteredRows = (tabMode == "EFML") ? getRows(efmlRows, filters) : getRows(rows, filters);


    const handleFilterChange = filter => filters => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    };

    const sortRows = (initialRows, sortColumn, sortDirection) => initialRows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...initialRows].sort(comparer);
    };

    const EmptyRowsView = () => {
        const message = "No Data Available";
        return (
            <div className="no-data-available">{message}</div>
        );
    };

    const RowRenderer = ({ renderBaseRow, ...props }) => {
        return <div className="expert-row">{renderBaseRow(props)}</div>;
    };



    useEffect(() => {
        setLoading(true);
        if (tabMode == "EFML") {
            getEFMLData();
        }
        else {
            getSetManualExperts();
        }
    }, []);

    return (
        <div id="custom-experts">
            <AddCustomExperts showDialog={showEditExpert} setDialog={setshowEditExpert} action={dialogType} getManualExpertsTabData={getManualExpertsTabData} allExperts={allExperts} selectedExpert={selectedExpert} expertHeaders={expertHeaders} cusomtTabName={cusomtTabName} isHeaderVisible={isHeaderVisible} />
            <EditCustomExpertsHeader showDialog={showEditHeader} setDialog={setshowEditHeader} expertHeaders={expertHeaders} getManualExpertsTabData={getManualExpertsTabData} tabHeaderId={tabHeaderId} isHeaderVisible={isHeaderVisible} />

            <Modal id="delete-expert-click-modal"
                backdrop="static"
                show={showDelete}
                onHide={() => setshowDelete(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div>Delete Expert</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(selectedExpert).length ?
                        <div className="expert-delete">
                            <div className="expert-delete-txtinfo">Are you sure, you want to delete below Expert record?</div>
                            {
                                expertHeaders.map((o, i) => {
                                    let defaultColumnHeader;
                                    i == 0 ? defaultColumnHeader = 'Expert Name' : defaultColumnHeader = 'Column ' + `${i + 1}`;
                                    return (
                                        <div className="expert-name" key={i + '-manual-expert-key'}>
                                            <div> <strong>{expertHeaders[i] ? expertHeaders[i] : defaultColumnHeader}</strong></div> <div className="expert-name-div"> {i === 0 ? selectedExpert.Expert_x0020_Name.Title : selectedExpert['Column_x0020_' + i]} </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : ""
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => { setshowDelete(false) }}> Close </Button>
                    <Button variant="primary" className="proceed-button-delete" onClick={() => {
                        setDeleteButton(<span> Deleting... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                        helpers.deleteCustomExpert("Manual Experts", selectedExpert.ID, function (iar: any) {
                            setshowDelete(false);
                            setDeleteButton(<span>Delete Expert</span>);
                            getManualExpertsTabData();
                        });

                    }}> {deleteButton} </Button>

                </Modal.Footer>
            </Modal>

            <div className="edit-section">
                {editMode ? (
                    <DropdownButton
                        alignRight
                        title={<FiMoreVertical />}
                        id="dropdown-menu-align-right">
                        {tabMode != "EFML" ? <Dropdown.Item eventKey="1" onClick={() => { setshowEditExpert(true); setDialogType("add"); }} ><MdAddCircleOutline className='addlinkicon' /> Add Expert</Dropdown.Item> : ""}
                        <Dropdown.Item eventKey="1" onClick={() => { setshowEditHeader(true) }} ><MdCreate className='editlinkicon' /> Edit Table Header</Dropdown.Item>
                    </DropdownButton>
                ) : null}
            </div>
            <div className="table-custom-experts">
                {!loading && filteredRows.length > 0 ? <div className="export-excel">
                    <ExcelFile element={<button className="btn btn-info">Export to Excel</button>} filename={tabDisplayName + "_" + currentDateTime.toUTCString()}>
                        <ExcelSheet data={filteredRows} name={tabDisplayName}>
                            <ExcelColumn label={expertHeaders[1]} value="Column1" />
                            <ExcelColumn label={expertHeaders[2]} value="Column2" />
                            <ExcelColumn label={expertHeaders[0]} value="ExpertName" />
                            <ExcelColumn label={expertHeaders[3]} value="Column3" />
                            <ExcelColumn label={expertHeaders[4]} value="Column4" />
                            <ExcelColumn label={expertHeaders[5]} value="Column5" />
                            <ExcelColumn label={expertHeaders[6]} value="Column6" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
                    : !loading && filteredRows.length === 0 ?
                        <div className="export-excel">
                            <ExcelFile element={<button className="btn btn-info">Export to Excel</button>} filename={tabDisplayName + "_" + currentDateTime.toUTCString()}>
                                <ExcelSheet data={tempExcelFile} name={tabDisplayName}>
                                    <ExcelColumn label={expertHeaders[1]} value="Column1" />
                                    <ExcelColumn label={expertHeaders[2]} value="Column2" />
                                    <ExcelColumn label={expertHeaders[0]} value="ExpertName" />
                                    <ExcelColumn label={expertHeaders[3]} value="Column3" />
                                    <ExcelColumn label={expertHeaders[4]} value="Column4" />
                                    <ExcelColumn label={expertHeaders[5]} value="Column5" />
                                    <ExcelColumn label={expertHeaders[6]} value="Column6" />
                                </ExcelSheet>
                            </ExcelFile>
                        </div> : ""
                }
                {!loading ?
                    <ReactDataGrid minColumnWidth={275}
                        columns={columns}
                        rowGetter={i => filteredRows[i]}
                        rowsCount={filteredRows.length}
                        minHeight={filteredRows.length > 0 ? 700 : 120}
                        toolbar={<Toolbar enableFilter={filteredRows.length > 0 ? true : false} />}
                        onAddFilter={filter => setFilters(handleFilterChange(filter))}
                        onClearFilters={() => setFilters({})}
                        getValidFilterValues={columnKey => getValidFilterValues(initialRows, columnKey)}
                        onGridSort={(sortColumn, sortDirection) =>
                            (tabMode == "EFML") ? setEfmlRows(sortRows(initialRows, sortColumn, sortDirection)) :
                                setRows(sortRows(initialRows, sortColumn, sortDirection))
                        }
                        rowHeight={50}
                        headerRowHeight={60}
                        rowRenderer={RowRenderer}
                        emptyRowsView={EmptyRowsView}
                        enableCellAutoFocus={false}
                    /> :
                    <div className='skeleton-loading'>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={50} width={1050}></Skeleton>
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={50} width={1050}></Skeleton>
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={50} width={1050}></Skeleton>
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={50} width={1050}></Skeleton>
                        </SkeletonTheme>
                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                            <Skeleton height={50} width={1050}></Skeleton>
                        </SkeletonTheme>
                    </div>
                }
                {
                    /*tabMode == "EFML" ?  rowHeight={55}
                       
                        <div className="pagination-div">
                            <Pagination>
                                {
                                    (!loading && efmlExperts.length) ?
                                        [...Array(pageCount)].map((x, i) => {
                                            return (
                                                <Pagination.Item key={i + 1} active={i + 1 === activePage} onClick={() => onPaginationClick(i)}> {i + 1} </Pagination.Item>
                                            )
                                        })
                                        : ""
                                }
                            </Pagination></div> : "" */
                }
            </div>
        </div>
    );

    async function getEFMLData() {
        await sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoAEFMLMaster").items.select(efmlColumns + ",EmailID").filter(efmlFilter).top(4999).get().then((response: any) => {
            //sp.site.rootWeb.lists.getByTitle("SPoAEFMLMaster").items.select(efmlColumns + ",EmailID").filter(efmlFilter).top(4999).get().then((response: any) => {
            setEfmlExperts(response);
            if (response.length > 0) {
                setPageCount(Math.ceil(response.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
                setExpertsToShow(response.slice(0, recordsPerPage));
                setaActivePage(1);
                let temp = [];
                response.map((expert: any) => {
                    temp.push({
                        ExpertName: expert.ExpertName,
                        Column1: expert[efmlSelectColumns[1]] ? expert[efmlSelectColumns[1]] : "",
                        Column2: expert[efmlSelectColumns[2]] ? expert[efmlSelectColumns[2]] : "",
                        Column3: expert[efmlSelectColumns[3]] ? expert[efmlSelectColumns[3]] : "",
                        Column4: expert[efmlSelectColumns[4]] ? expert[efmlSelectColumns[4]] : "",
                        Column5: expert[efmlSelectColumns[5]] ? expert[efmlSelectColumns[5]] : "",
                        Column6: expert[efmlSelectColumns[6]] ? expert[efmlSelectColumns[6]] : "",
                        Column7: 0,
                        Column8: expert.EmailID
                    })
                })
                setEfmlRows(temp);
            }
            setLoading(false);
        });
    }

    function onPaginationClick(i: any) {
        setaActivePage(i + 1);
        filterData(i + 1);
        window.scrollTo(0, 0)
    }

    function filterData(page: number) {
        setExpertsToShow([]);
        setExpertsToShow(efmlExperts.slice(recordsPerPage * page - recordsPerPage, recordsPerPage * page));
    }

    function getValidFilterValues(rows, columnId) {
        return rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
    }

    function getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }



    async function getSetManualExperts() {
        let temp = [];
        let currentTabExperts = allExperts.filter(e => e.Tab_x0020_Name === cusomtTabName);
        await currentTabExperts.map((expert: any) => {
            temp.push({
                ExpertName: expert.Expert_x0020_Name.Title,
                Column1: expert.Column_x0020_1 ? expert.Column_x0020_1 : "",
                Column2: expert.Column_x0020_2 ? expert.Column_x0020_2 : "",
                Column3: expert.Column_x0020_3 ? expert.Column_x0020_3 : "",
                Column4: expert.Column_x0020_4 ? expert.Column_x0020_4 : "",
                Column5: expert.Column_x0020_5 ? expert.Column_x0020_5 : "",
                Column6: expert.Column_x0020_6 ? expert.Column_x0020_6 : "",
                Column7: expert.Id,
                Column8: expert.Expert_x0020_Name.EMail
            });
        })
        setRows(temp);
        setLoading(false);
    }

};

export default CustomExperts;