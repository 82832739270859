import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import './menuModalEdit.scss'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from "react-bootstrap/Alert";
import Sortable from 'sortablejs';
import ReactHtmlParser from 'react-html-parser';
import Form from 'react-bootstrap/Form';
import { FaArrowLeft } from 'react-icons/fa';
import 'react-icons/fa'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Draft, { htmlToDraft, draftToHtml, EmptyState, rawToDraft, draftToRaw, draftStateToHTML } from 'react-wysiwyg-typescript';
import { Editor } from 'react-draft-wysiwyg';
import helpers from '../helpers';
import { convertFromHTML, convertToRaw, EditorState, Modifier, ContentState, AtomicBlockUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { sp as pnpsp } from "@pnp/sp";
import { SPFetchClient } from "@pnp/nodejs";
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";


export interface ILinks {
    title: string | null;
    id: string | null;
    order: number | null;
    subcategory: [{
        title: string | null;
        id: string | null;
        order: number | null;
        links: [{
            title: string | null;
            id: string | null;
            order: number | null;
        }]
    }]
}

export interface ILinksState {
    title: string | null;
    desc?: string | null;
    id: string | null;
    order?: number | null;
    targetLink?: string | null;
    mainLink?: string | null;
    linkCategory?: string | null;
    showOnLanding?: boolean | null;
    mainlinkcategoryid?: string | null;
    linkCategoryId?: string | null;
}

const MenuModalEdit = (props: any) => {
    var onSort = function () {
        //console.log("sortedList");
    }
    const html = 'testing this will work';

    let linksResponse1: any[] = [];
    const { currMainLink, links, showDialog, categories, getData, setShowLoading, handleClose, getLinksForHeader } = props;

    categories.map((item: any, i: number) => {
        linksResponse1.push({
            title: item.MainLinkCategory,
            id: item.Mainlinkcategoryid,
            desc: item.MainLinkDesc,
            order: item.MainlinkcategoryOrder,
            subcategory: [],
            showOnLanding: item.Mainlinkshowonlanding
        })

        let count = 0;
        links.map((itemsSub: any, j: number) => {
            if (itemsSub.MainLinkCategory.Title == item.MainLinkCategory) {
                var present = false;
                linksResponse1[i].subcategory.map((presentItems: any) => {
                    if (presentItems.title == itemsSub.Subject.Title) {
                        present = true;
                        return;
                    }
                })
                if (!present) {
                    linksResponse1[i].subcategory.push({ title: itemsSub.Subject.Title, id: itemsSub.SubjectId, LinkCategoryOrder: itemsSub.Subject.SpoAOrder, links: [] })
                    links.map((itemsLink: any, k: number) => {
                        if (itemsLink.Subject.Title == itemsSub.Subject.Title) {

                            if (itemsLink.URL === undefined || itemsLink.URL === null) {
                                //itemsLink.URL = {};
                                //itemsLink.URL.Url = '';
                                itemsLink.URL = '';
                            }
                            //This check will make sure if same link category and link name exists, it will add based on main link category id.
                            if (linksResponse1[i].id === itemsLink.MainLinkCategoryId) {
                                linksResponse1[i].subcategory[count].links.push({
                                    title: itemsLink.title,
                                    id: itemsLink.id,
                                    order: itemsLink.SPoAOrder == null ? itemsLink.id : itemsLink.SPoAOrder,
                                    desc: itemsLink.desc,
                                    url: itemsLink.URL,
                                    Mainlinkcategoryid: itemsLink.Mainlinkcategoryid,
                                    LinkCategoryId: itemsLink.SubjectId,
                                    showOnLanding: itemsLink.ShowOnLanding
                                })
                            }

                        }

                    })
                    linksResponse1[i].subcategory[count].links.sort((a, b) => a.order > b.order ? 1 : -1);
                    count++;
                }
            }
        })
        if (linksResponse1[i].subcategory.length > 1) {
            linksResponse1[i].subcategory.sort((a, b) => a.LinkCategoryOrder > b.LinkCategoryOrder ? 1 : -1);
        }
    })

    type TFile = {
        showError?: boolean,
        type?: any,
        message?: string
    }
    const [filecheck, setFileCheck] = useState({} as TFile);
    const [loading, setLoading] = useState(false);
    const [previewSrc, setPreviewSrc] = useState("");

    const [linksEdit, setLinks] = useState(links);
    const [toggleView, setToggleView] = useState("");
    const [showDescriptionLinks, setshowDescriptionLinks] = useState(true);
    const [showMainDescriptionLinks, setshowMainDescriptionLinks] = useState(true);
    const [currTitle, setCurrTitle] = useState('');
    const [details, setDetails] = useState<ILinksState>();
    const [selectedMain, setSelected] = useState('');
    const [selectedLink, setSelectedLink] = useState('');
    const [saveButton, setSaveButton] = useState<JSX.Element>(<p>Save Edits</p>);
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [editorStateMain, setEditorStateMain] = useState(htmlToDraft('Your html contents'));
    const [linksResponse, setLinksResponse] = useState(linksResponse1);
    const [categoryChanged, setCategoryChanged] = useState(false);
    const [expandTitle, setExpandTitle] = useState('');
    const [showNewCategoryError, setShowNewCategoryError] = useState(false);
    const [showNewLinkError, setShowNewLinkError] = useState(false);
    const [showNewSubCategoryError, setShowNewSubCategoryError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [dragWait, setDragWait] = useState<JSX.Element>(null);
    const [edited, setEdited] = useState(false);
    const [closeButton, setCloseButton] = useState<JSX.Element>(<p>Close</p>);
    const [isSaving, setIsSaving] = useState(false);
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [mainLinkLength, setmainLinkLength] = useState(0);
    const [linkCategoryLength, setLinkCategoryLength] = useState(0);
    const [linkTitleLength, setLinkTitleLength] = useState(0);
    const [linkDescriptionLength, setLinkDescriptionLength] = useState(0);

    let expandTitle1: any = '0'
    //Adding target blank for all links in description field
    let desc_options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: data.url,
                        target: '_blank'
                    },
                };
            }
        },
    };

    //console.log(linksResponse);

    setTimeout(() => {

        let crd = document.getElementsByClassName('accordion') as any;
        crd.forEach((z: any) => {


            Sortable.create(z, {
                animation: 500,
                ghostClass: 'blue-background-class',
                onEnd: function (/**Event*/evt: any) {
                    setDragWait(<i className="fa fa-spinner fa-pulse fa-fw"></i>)
                },
                handle: '.links-wrapper-before',
                dragClass: 'dragging',
                chosenClass: 'dragging',
                store: {
                    get: function (sortable) {
                        //console.log(sortable);
                        return [];
                    },
                    set: function (sortable) {
                        // console.log(sortable.toArray());

                        // let menus = linksResponse1;

                        let temp = sortable.toArray();
                        temp.forEach((item: any, linkIndex: number) => {
                            linksResponse1.forEach((itemMenu: any) => {
                                if (itemMenu.id == item) {
                                    itemMenu.order = linkIndex;
                                }
                            })
                        })
                        linksResponse1.forEach((itemMenu: any) => {
                            setIsSaving(true);
                            setCloseButton(<p>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></p>);
                            helpers.updateData('Main Link Categories', { SpoAOrder: itemMenu.order }, itemMenu.id, function () {
                                setDragWait(null);
                                setIsSaving(false);
                                setCloseButton(<p>Close</p>);
                                setEdited(true)
                                getLinksForHeader();
                                getData();
                            })
                        })
                        setLinksResponse(linksResponse1);

                    }

                }
            });
        })

        let el = document.getElementsByClassName('onhandle') as any;
        el.forEach((z: any) => {


            Sortable.create(z, {
                animation: 500,
                ghostClass: 'blue-background-class',
                handle: '.card-header-before',
                dragClass: 'dragging',
                store: {
                    get: function (sortable) {
                        //console.log(sortable);
                        return [];
                    },
                    set: function (sortable) {
                        // console.log(sortable.toArray());

                        // let menus = linksResponse1;

                        let temp = sortable.toArray();
                        temp.forEach((item: any, linkIndex: number) => {
                            linksResponse1.forEach((itemMenu: any) => {
                                itemMenu.subcategory.map((itemsSub: any) => {
                                    itemsSub.links.map((itemsLink: any) => {
                                        if (itemsLink.id == item) {
                                            itemsLink.order = linkIndex;
                                        }
                                    })
                                })

                            })
                        })
                        linksResponse1.forEach((itemMenu: any) => {
                            itemMenu.subcategory.map((itemsSub: any) => {
                                itemsSub.links.map((itemsLink: any) => {
                                    temp.forEach((item: any, linkIndex: number) => {
                                        if (itemsLink.id == item) {
                                            setIsSaving(true);
                                            setCloseButton(<p>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></p>);
                                            helpers.updateData('Links', { SpoAOrder: itemsLink.order }, itemsLink.id, function () {
                                                setDragWait(null);
                                                setIsSaving(false);
                                                setCloseButton(<p>Close</p>);
                                                setEdited(true)
                                                getLinksForHeader();
                                                getData();
                                            })
                                        }
                                    })
                                })
                            })


                        })

                        setLinksResponse(linksResponse1);

                    }

                }
            })
        });

    }, 1000);




    linksResponse.map((item: any, i: number) => {
        if (item.title == currMainLink) {
            expandTitle1 = '' + i + '';
        }
    })

    return (
        <div>

            <Modal size="lg" id="menu-modal-edit" show={showDialog} onHide={() => { onDialogClose(false); setToggleView('') }} onShow={() => { setLinksResponse(linksResponse1); }} backdrop='static'>
                <div id="category-wrapper" className={toggleView == "EditLinks" ? "active" : ""}>
                    <div>
                        <Modal.Header closeButton>
                            <FaArrowLeft onClick={() => { setToggleView(''); setShowNewLinkError(false) }} className="arrow-back-category" />  <Modal.Title>Edit Links </Modal.Title>
                        </Modal.Header></div>
                    <Modal.Body className="modal-body-category-edit">
                        <Form className="form-controls-edit" id="form-controls-edit" noValidate validated={validated}>
                            <Form.Group className='label-title'>
                                <Form.Label className='title-label'>{currTitle}</Form.Label>

                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Link Title <span className='mandatory'>*</span></Form.Label>
                                <Form.Control maxLength={150} pattern="^[a-zA-Z1-9].*" type="input" value={details ? details.title : ''} onChange={(e: any) => { updateItems(e.currentTarget.value, 'title'); setLinkTitleLength(e.currentTarget.value.length) }} required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Link Title
                                </Form.Control.Feedback>
                                {linkTitleLength ? <span className='info-max'> {(150 - linkTitleLength)} characters remaining</span> : <span className='info-max'>150 characters max</span>}
                                {showNewLinkError ? <Alert variant="warning"> Link with same name exists, please provide other name. </Alert> : null}

                            </Form.Group>


                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Target Link <span className='mandatory'>*</span> </Form.Label>
                                <Form.Control type="input" value={details ? details.targetLink : ''} onChange={(e: any) => updateItems(e.currentTarget.value, 'targetLink')} required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Target Link
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1" >
                                <Form.Label>Link Description</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-2"
                                    label=""
                                    title=""
                                    checked={showDescriptionLinks}
                                    onClick={() => { showDescriptionLinks ? setshowDescriptionLinks(false) : setshowDescriptionLinks(true) }}
                                />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1" hidden={!showDescriptionLinks}>
                                <Editor editorState={editorState}
                                    placeholder="Add Text"
                                    onEditorStateChange={e => { setEditorState(e) }}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                                {/*<Draft*/}
                                {/*    stripPastedStyles={true}*/}
                                {/*    maxLength={350}*/}
                                {/*    handleReturn={e => {*/}
                                {/*        const textLength = editorState.getCurrentContent().getPlainText().length;*/}
                                {/*        if (e.keyCode === 13 && textLength >= 350) {*/}
                                {/*            return 'handled';*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    handleBeforeInput={val => {*/}
                                {/*        const textLength = editorState.getCurrentContent().getPlainText().length;*/}
                                {/*        if (val && textLength >= 350) {*/}
                                {/*            return 'handled';*/}
                                {/*        }*/}
                                {/*        return 'not-handled';*/}
                                {/*    }}*/}
                                {/*    handlePastedText={val => {*/}
                                {/*        const textLength = editorState.getCurrentContent().getPlainText().length;*/}
                                {/*        if ((val.length + textLength) >= 350) {*/}
                                {/*            let availableLen = 350 - textLength;*/}
                                {/*            let modifiedVal = val.substr(0, availableLen);*/}
                                {/*            const newContent = Modifier.insertText(*/}
                                {/*                editorState.getCurrentContent(),*/}
                                {/*                editorState.getSelection(),*/}
                                {/*                modifiedVal*/}
                                {/*            );*/}
                                {/*            let newEditorState = EditorState.push(*/}
                                {/*                editorState,*/}
                                {/*                newContent,*/}
                                {/*                'insert-characters'*/}
                                {/*            );*/}
                                {/*            setEditorState(newEditorState);*/}
                                {/*            setLinkDescriptionLength(newEditorState.getCurrentContent().getPlainText().length);*/}
                                {/*        }*/}
                                {/*        return ((val.length + textLength) >= 350);*/}
                                {/*    }}*/}
                                {/*    editorState={editorState}*/}
                                {/*    onEditorStateChange={(editorState) => {*/}
                                {/*        setEditorState(editorState); setLinkDescriptionLength(editorState.getCurrentContent().getPlainText().length)*/}
                                {/*    }}*/}
                                {/*/>*/}

                                {/*{linkDescriptionLength ? <span className='info-max'> {(350 - linkDescriptionLength)} characters remaining</span> : <span className='info-max'>350 characters max</span>}*/}
                                <Form.Group controlId="formImage">
                                    <Form.Label className="img-txt">Select image from computer (Supported formats - jpg, jpeg, png, bmp, jfif) </Form.Label>
                                    {filecheck.showError ? <Alert variant={filecheck.type}> {filecheck.message} </Alert> : null}
                                    <Form.Control className="img-upload-button" disabled={loading} type="file" id="imageuploadbanner" onChange={(e: any) => showPreview(e)} />
                                    {loading ? <div className="lds-ring"><div></div><div></div><div></div><div></div> <span className='loading-text'>Uploading image, please wait... </span> </div> : ""}

                                </Form.Group>
                            </Form.Group>

                            <Form.Group id="formGridCheckbox">
                                <Form.Check type="checkbox" label="Add to Important Links section" checked={details ? details.showOnLanding : false} onChange={(e: any) => updateItems(details.showOnLanding ? false : true, 'showOnLanding')} />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Move this Link to different category</Form.Label>
                                <div className='dropdown-edit'>
                                    <div className='dropdown-edit-1'>
                                        <Form.Label >1. Main Link Category</Form.Label>
                                        <Form.Control as="select" value={selectedMain ? selectedMain : ''} onChange={(e: any) => {
                                            setSelected(e.currentTarget.options[e.currentTarget.selectedIndex].id);
                                            updateItems(e.currentTarget.options[e.currentTarget.selectedIndex].id, 'mainlinkcategoryid', e.currentTarget.options[e.currentTarget.selectedIndex].text);
                                            setCategoryChanged(true)
                                        }}>
                                            {
                                                linksResponse.map((o: any) => <option key={`category` + o.id} id={o.id} value={o.id}> {o.title} </option>)
                                            }
                                        </Form.Control>
                                    </div>
                                    <div className='dropdown-edit-2'>
                                        <Form.Label>2. Link Category</Form.Label>
                                        <Form.Control as="select" value={selectedLink ? selectedLink : ''} onChange={(e: any) => {
                                            setSelectedLink(e.currentTarget.options[e.currentTarget.selectedIndex].id);
                                            updateItems(e.currentTarget.options[e.currentTarget.selectedIndex].id, 'linkCategoryId', e.currentTarget.options[e.currentTarget.selectedIndex].text);
                                            setCategoryChanged(true)
                                        }} required>
                                            {
                                                linksResponse.map((o: any) => {
                                                    if (selectedMain == o.id) {
                                                        return (
                                                            o.subcategory.map((s: any, index: any) => { return (<option key={`linkcategory` + s.title} id={s.id} value={s.id}> {s.title} </option>) })
                                                        )
                                                    }
                                                })
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Link Category
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-category">
                        <Button className="button-cancel-menu" variant="secondary" onClick={() => { setToggleView(''); setShowNewLinkError(false) }}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => {

                            const form = document.getElementById("form-controls-edit") as HTMLFormElement;
                            if (form.checkValidity() === false) {
                                //To handle when validation fails
                            }
                            else {
                                updateDataLinks(categoryChanged);
                            }


                        }}>
                            {saveButton}
                        </Button>
                    </Modal.Footer>
                </div>

                <div id="category-wrapper-link" className={toggleView == "EditSub" ? "activeSub" : ""}>
                    <div>
                        <Modal.Header closeButton>
                            <FaArrowLeft onClick={() => { setToggleView(''); setShowNewSubCategoryError(false) }} className="arrow-back-category" />  <Modal.Title>Edit Link Category</Modal.Title>
                        </Modal.Header></div>
                    <Modal.Body className='modal-body-links'>
                        <Form className="form-controls" id="link-category-title-form-container" noValidate validated={validated}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Link Category Title <span className='mandatory'>*</span> </Form.Label>
                                <Form.Control maxLength={100} pattern="^[a-zA-Z1-9].*"
                                    type="input" value={details ? details.title : ''} onChange={(e: any) => {
                                        updateItems(e.currentTarget.value, 'title'); setLinkCategoryLength(e.currentTarget.value.length)
                                    }} required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Link Category Title
                                </Form.Control.Feedback>
                                {linkCategoryLength ? <span className='info-max'> {(100 - linkCategoryLength)} characters remaining</span> : <span className='info-max'>100 characters max</span>}
                                {showNewSubCategoryError ? <Alert variant="warning">Link with same name exists, please provide other name. </Alert> : null}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer-category-links">
                        <Button className="button-cancel-menu" variant="secondary" onClick={() => { setToggleView(''); setShowNewSubCategoryError(false) }}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => {
                            const form = document.getElementById("link-category-title-form-container") as HTMLFormElement;
                            if (form.checkValidity() === false) {
                                //to handle when validity fails
                            }
                            else {
                                updateDataSubLinks();
                            }

                        }}>
                            {saveButton}
                        </Button>
                    </Modal.Footer>
                </div>

                <div id="category-wrapper-main" className={toggleView == "EditMain" ? "activeMain" : ""}>
                    <div>
                        <Modal.Header closeButton>
                            <FaArrowLeft onClick={() => { setToggleView(''); setShowNewCategoryError(false) }} className="arrow-back-category" />  <Modal.Title>Edit Main Link Category</Modal.Title>
                        </Modal.Header></div>
                    <Modal.Body className='modal-body-main'>
                        <Form className="form-controls" noValidate validated={validated} id='form-main'>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Main Link Category Title <span className='mandatory'>*</span> </Form.Label>
                                <Form.Control maxLength={50} pattern="^[a-zA-Z1-9].*" type="input" value={details ? details.title : ''} onChange={(e: any) => { updateItems(e.currentTarget.value, 'title'); setmainLinkLength(e.currentTarget.value.length) }} required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Main Link Category Title
                                </Form.Control.Feedback>
                                {mainLinkLength ? <span className='info-max'> {(50 - mainLinkLength)} characters remaining</span> : <span className='info-max'>50 characters max</span>}
                                {showNewCategoryError ? <Alert variant="warning"> Main Link Category with same name exists, please provide other name. </Alert> : null}
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Description</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-3"
                                    label=""
                                    checked={showMainDescriptionLinks}
                                    onClick={() => showMainDescriptionLinks ? setshowMainDescriptionLinks(false) : setshowMainDescriptionLinks(true)}
                                />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1" hidden={!showMainDescriptionLinks}>
                                <Draft
                                    toolbar={{
                                        fontFamily: {
                                            options: ['Arial Regular', 'ShellBook', 'ShellBold', 'ShellLight', 'ShellBookMedium'],
                                        },
                                    }}
                                    editorState={editorStateMain}
                                    onEditorStateChange={(editorStateMain) => { setEditorStateMain(editorStateMain) }}
                                />
                            </Form.Group>
                            <Form.Group id="new-main-category-show-on-homepage">
                                <Form.Check type="checkbox" label="Add to Important Links section" checked={details ? details.showOnLanding : false} onChange={(e: any) => updateItems(details.showOnLanding ? false : true, 'showOnLanding')} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer-category-main">
                        <Button className="button-cancel-menu" variant="secondary" onClick={() => { setToggleView(''); setShowNewCategoryError(false) }}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={(e) => {
                            const form = document.getElementById("form-main") as HTMLFormElement;
                            if (form.checkValidity() === false) {
                                //to handle when validity fails
                            }
                            else {
                                updateDataMainLinks(e);
                            }
                        }}>
                            {saveButton}
                        </Button>
                    </Modal.Footer>
                </div>



                <Modal.Header closeButton>
                    <Modal.Title>Edit Links/Categories</Modal.Title>
                </Modal.Header>
                <div id='modal-body-edit' className={toggleView == 'EditLinks' ? 'activeLink' : toggleView == 'EditMain' ? 'activeMain' : toggleView == 'EditSub' ? 'activeSub' : ''}>
                    <Modal.Body>
                        <span className="important-links-help">You can choose a max of 5 links for each Main Link Category to show on Important Links section</span>

                        {
                            isExpandAll ? <div className="exapand-all" onClick={() => collapseAllAccordians()}>Collapse All</div> :
                                <div className="exapand-all" onClick={() => expandAllAccordians()}>Expand All</div>
                        }

                        <Accordion defaultActiveKey={expandTitle1}>
                            {linksResponse.map((o: any, i: number) => {
                                return (
                                    <Card key={`accord-card` + i.toString()} data-id={o.id}>
                                        <Accordion.Toggle as={Card.Header} eventKey={i.toString()} onClick={() => handleArrows(i)}>
                                            <div className='edit-header' onDrag={onSort}>{o.title}
                                                <span className='link-edit-category-main'><a onClick={() => { getData1(o.title, o.id, 'EditMain', o.desc, null, null, null, o.showOnLanding) }} >Edit</a></span></div>
                                        </Accordion.Toggle>
                                        <div className="links-wrapper-before"></div>
                                        <Accordion.Collapse eventKey={i.toString()}>

                                            <Card.Body className="links-wrapper" data-id={o.id}>
                                                {
                                                    o.subcategory.map((link: any, linkIndex: number) => {
                                                        return (
                                                            <div>
                                                                <div key={linkIndex} className=''>
                                                                    <div id={`link-title-` + i + linkIndex} className='link-category-edit'> {link.title}
                                                                        <span className='link-edit-category-span'><a onClick={() => { getData1(link.title, link.id, 'EditSub') }} >Edit</a></span>
                                                                    </div>
                                                                    {
                                                                        <div className="link-wrapper" id={`link-edit-` + i + linkIndex}></div>
                                                                    }
                                                                    <div className='onhandle' >
                                                                        {link.links.map((sublink: any, sublinkIndex: number) => {
                                                                            return (
                                                                                <div className="edit-sublink" data-id={sublink.id} key={'sublink-key-' + sublinkIndex}>


                                                                                    <div className='handler-edit'>
                                                                                        {link.links.length > 1 ? <div className="card-header-before"></div> : ''}
                                                                                        <div id={`link-title-` + i + sublinkIndex} className='handler-edit-div'><a href={sublink.url} target='_blank'> {sublink.title}</a> {sublink.showOnLanding && <div className="display-homepage-check">(important-link)</div>}</div>
                                                                                    </div>
                                                                                    <span className='edit-subcategory'><a onClick={() => { getData1(sublink.title, sublink.id, 'EditLinks', sublink.desc, sublink.url, o.id, link.title, sublink.showOnLanding, o.id, link.id) }}>Edit</a></span>
                                                                                    {
                                                                                        <div className="link-edit-wrapper" id={`link-edit-` + i + sublinkIndex}></div>
                                                                                    }

                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>



                                                            </div>

                                                        )
                                                    })
                                                    // o.links.map((link: any, linkIndex: number) => {
                                                    //     return (                                                          
                                                    //         <div key={ linkIndex } data-id ={link.id}> 
                                                    //         <div className="card-header-before"></div>
                                                    //             <div id={ `link-title-` + i + linkIndex }> { link.title } </div>
                                                    //             <span onClick={() => enableEdit(i, linkIndex)}>Edit</span>
                                                    //             {
                                                    //                 <div className="link-edit-wrapper" id={ `link-edit-` + i + linkIndex }>
                                                    //                     <input type="text" value={ link.title } />
                                                    //                     <input type="text" value={ link.url } />
                                                    //                 </div>
                                                    //             }
                                                    //         </div>
                                                    //     )
                                                    // })
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })

                            }

                        </Accordion>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" disabled={isSaving} onClick={() => onDialogClose(true)}>
                        {closeButton}
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>
    )


    function enableEdit(categoryIndex: number, linkIndex: number) {

        const editId = "link-edit-" + categoryIndex + linkIndex;
        const titleId = "link-title-" + categoryIndex + linkIndex;

        let editEle = document.getElementById(editId);
        let titleEle = document.getElementById(titleId);

        titleEle!.style.display = 'none';
        editEle!.style.display = 'block';

        titleEle!.classList.add('before-none');
        editEle!.classList.add('before-none');


    }
    function getData1(title: string, index: string, type: string, desc?: string, url?: string, mainLink?: string, linkCategory?: string, showOnLanding?: boolean, mainlinkcategoryid?: string, linkCategoryid?: string) {
        if (type == 'EditMain') {
            setDetails({ title: title, id: index, desc: '', showOnLanding: showOnLanding });
            setmainLinkLength(title.length);
            if (desc) {
                setshowMainDescriptionLinks(true);
            }
            else {
                setshowMainDescriptionLinks(false);
            }
        }
        if (type == 'EditLinks') {
            setDetails({ title: title, id: index, order: 0, desc: '', targetLink: url, mainLink: mainLink, linkCategory: linkCategory, showOnLanding: showOnLanding, mainlinkcategoryid: mainlinkcategoryid.toString(), linkCategoryId: linkCategoryid.toString() });
            setLinkTitleLength(title.length);
            if (desc) {
                setshowDescriptionLinks(true);
                setLinkDescriptionLength(desc.length);
            }
            else {
                setshowDescriptionLinks(false);
            }
        }
        if (type == 'EditSub') {
            setDetails({ title: title, id: index });
            setLinkCategoryLength(title.length);

        }
        setSelected(mainlinkcategoryid);
        setSelectedLink(linkCategoryid);
        let linksDescription = '';
        if (type == 'EditMain')
            setEditorStateMain(desc ? htmlToDraft(desc) : htmlToDraft(''));
        if (type == 'EditLinks')
        setEditorState(desc ? EditorState.createWithContent(stateFromHTML(desc)) : EditorState.createWithContent(stateFromHTML('')));
        setCurrTitle(title);
        setToggleView(type);
        setValidated(false);

    }

    function handleArrows(i: number) {
        document.querySelectorAll("#menu-modal-edit .card-header").forEach((o, i) => {
            o.classList.remove('active')
        });

        if (!document.querySelectorAll("#menu-modal-edit .collapse")[i].classList.contains("show")) {
            document.querySelectorAll("#menu-modal-edit .card-header")[i].classList.add("active")
        }

    }

    function updateDataLinks(flag: boolean) {
        let testVal = false;
        let checkDuplicate = false;
        linksResponse.map((items: any) => {
            if (items.id == details.mainlinkcategoryid) {
                if (items.subcategory.length == 0)
                    testVal = true
            }
        })
        linksResponse.map((items: any) => {
            items.subcategory.map((itemsSub: any) => {
                itemsSub.links.map((itemsLink: any) => {
                    if (itemsLink.title != null && itemsLink.id != details.id) {

                        if (itemsLink.title.toLowerCase() == details.title.toLowerCase()) {
                            checkDuplicate = true;
                        }
                    }
                })
            })
        })
        if (details.title.length == 0 || testVal || details.targetLink.length == 0) {
            setValidated(true)

        }
        else if (checkDuplicate) {
            setShowNewLinkError(true);
        }
        else {
            if (!showDescriptionLinks) {
                setEditorState(htmlToDraft(''));
            }
            setSaveButton(<p>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></p>);
            let desc = stateToHTML(editorState.getCurrentContent(), desc_options);
            if (!flag) {
                helpers.updateData('Links', { Title: details.title, LinkURL: details.targetLink, SpoADescription: showDescriptionLinks ? stateToHTML(editorState.getCurrentContent(), desc_options) : '', ShowOnLanding: details.showOnLanding, SpoALinkType: details.showOnLanding ? 'ShowOnHomePage' : 'Normal' }, details.id, function () {
                    linksResponse1.forEach((items: any) => {
                        items.subcategory.forEach((itemsSub: any) => {
                            itemsSub.links.forEach((itemsLink: any) => {
                                if (itemsLink.id == details.id) {
                                    itemsLink.title = details.title;
                                    itemsLink.desc = desc;
                                    itemsLink.url = details.targetLink;
                                    itemsLink.showOnLanding = details.showOnLanding;
                                }
                            })
                        })
                    })
                    setLinksResponse(linksResponse1)
                    setToggleView('');
                    setShowNewLinkError(false);
                    setSaveButton(<p>Save Edits</p>);
                    setCategoryChanged(false);
                    setEdited(true)
                    getLinksForHeader();
                    getData();
                })
            }
            if (flag) {
                helpers.updateData('Links', { Title: details.title, LinkURL: details.targetLink, SpoADescription: showDescriptionLinks ? stateToHTML(editorState.getCurrentContent(), desc_options) : '', ShowOnLanding: details.showOnLanding, MainLinkCategoryId: details.mainlinkcategoryid }, details.id, function () {
                    helpers.updateData('Links', { SpoASubjectId: selectedLink ? selectedLink : '' }, details.id, function () {
                        linksResponse1 = linksResponse;
                        linksResponse1.forEach((items: any) => {
                            items.subcategory.forEach((itemsSub: any) => {
                                itemsSub.links = itemsSub.links.filter((s: any) => s.id != details.id);
                            })
                        })

                        linksResponse1.forEach((items: any) => {
                            if (items.id == details.mainlinkcategoryid) (
                                items.subcategory.forEach((itemsSub: any) => {
                                    if (itemsSub.id == selectedLink) {
                                        itemsSub.links.push({

                                            title: details.title,
                                            id: details.id,
                                            order: details.order,
                                            desc: '',
                                            url: details.targetLink,
                                            showOnLanding: details.showOnLanding,
                                            mainCategory: details.mainLink,
                                            linkCategory: details.linkCategory,
                                            linkCategoryId: details.linkCategoryId,
                                            mainlinkcategoryid: details.mainlinkcategoryid
                                        })
                                    }
                                })
                            )
                        })


                        setLinksResponse(linksResponse1)

                        setToggleView('');
                        linksResponse1.forEach((item: any, i: number) => {
                            if (item.id == details.mainlinkcategoryid) {
                                document.querySelectorAll("#menu-modal-edit .card-header").forEach((o) => {
                                    o.classList.remove('active');
                                    o.parentElement.children[2].classList.remove("show");
                                });

                                if (!document.querySelectorAll("#menu-modal-edit .collapse")[i].classList.contains("show")) {
                                    document.querySelectorAll("#menu-modal-edit .card-header")[i].classList.add("active");
                                    document.querySelectorAll("#menu-modal-edit .card-header")[i].parentElement.children[2].classList.add("show");
                                }
                            }
                        });
                        setSaveButton(<p>Save Edits</p>);
                        setCategoryChanged(false);
                        setShowNewLinkError(false);
                        setValidated(true);
                        setEdited(true)
                        getLinksForHeader();
                        getData();
                    })
                })


                /*         linksResponse1.forEach((items:any) => {
                             if(items.id == details.mainlinkcategoryid) {
                                 items.subcategory.forEach((itemsSub:any) => {
                                         if(itemsSub.id = details.linkCategoryId){
                                             itemsSub.links.forEach((itemsLink:any) => { 
                                                 itemsLink.id = details.id
                                                 itemsLink.title = details.title;
                                                 itemsLink.desc = desc;
                                                 itemsLink.url = details.targetLink;
                                                 itemsLink.showOnLanding = details.showOnLanding;
                                                 itemsLink.mainCategory = details.mainLink;
                                                 itemsLink.linkCategory = details.linkCategory;
                                                 itemsLink.linkCategoryId = details.linkCategoryId;
                                                 itemsLink.mainlinkcategoryid = details.mainlinkcategoryid;
                                             })
                                         }                        
                                     })
                             } */
                //})

            }
        }

    }

    function updateDataSubLinks() {
        let checkDuplicate = false;
        linksResponse.map((items: any) => {
            items.subcategory.map((itemsSub: any) => {
                if (itemsSub.title != null && itemsSub.id != details.id) {

                    if (itemsSub.title.toLowerCase() == details.title.toLowerCase()) {
                        checkDuplicate = true;
                    }
                }
            })
        })
        if (details.title.length === 0 || details.targetLink && details.targetLink.length === 0) {
            setValidated(true);

        }
        else if (checkDuplicate) {
            setShowNewSubCategoryError(true);
        }
        else {
            setSaveButton(<i className="fa fa-spinner fa-pulse fa-fw"></i>);
            helpers.updateData('Link Categories', { Title: details.title }, details.id, function () {
                linksResponse1.forEach((items: any) => {
                    items.subcategory.forEach((itemsSub: any) => {
                        if (itemsSub.id == details.id) {
                            itemsSub.title = details.title
                        }
                    })
                })
                setLinksResponse(linksResponse1);
                setToggleView('');
                setShowNewSubCategoryError(false);
                setSaveButton(<p>Save Edits</p>);
                setValidated(false);
                setEdited(true)
                getLinksForHeader();
                getData();
            })

        }
    }

    function updateDataMainLinks(event) {
        const form = document.getElementById('form-main');
        let checkDuplicate = false;
        linksResponse.map((items: any) => {
            if (items.title != null && items.id != details.id) {
                if (items.title.toLowerCase() == details.title.toLowerCase()) {
                    checkDuplicate = true;
                }
            }
        })
        if (details.title.length == 0 || checkDuplicate) {
            setValidated(true);
            setShowNewCategoryError(true);
        }

        else {
            let temp = !showMainDescriptionLinks ? '' : stateToHTML(editorStateMain.getCurrentContent(), desc_options);
            setSaveButton(<i className="fa fa-spinner fa-pulse fa-fw"></i>);
            helpers.updateData('Main Link Categories', { Title: details.title, SpoADescription: temp, SpoAShowOnLanding: details.showOnLanding }, details.id, function () {
                linksResponse1.forEach((items: any) => {

                    if (items.id == details.id) {
                        items.title = details.title;
                        items.desc = stateToHTML(editorStateMain.getCurrentContent(), desc_options);
                        items.showOnLanding = details.showOnLanding;
                    }
                })
                setLinksResponse(linksResponse1);
                setToggleView('');
                setShowNewCategoryError(false);
                setSaveButton(<p>Save Edits</p>);
                setValidated(false);
                setEdited(true);
                getLinksForHeader();
                getData();
            })
        }


    }

    function expandAllAccordians() {
        setIsExpandAll(true);
        document.querySelectorAll("#menu-modal-edit .card-header").forEach((o) => { o.classList.add('active') });
        document.querySelectorAll("#menu-modal-edit .collapse").forEach((o) => { o.classList.add('show') });
    }

    function collapseAllAccordians() {
        setIsExpandAll(false);
        document.querySelectorAll("#menu-modal-edit .card-header").forEach((o) => { o.classList.remove('active') });
        document.querySelectorAll("#menu-modal-edit .collapse").forEach((o) => { o.classList.remove('show') });
    }

    function updateItems(e: any, field: any, text?: string) {
        let obj: any = { ...details };
        obj[field] = e;
        if (field == 'mainlinkcategoryid') {
            obj['mainLink'] = text

            linksResponse1.forEach((items: any) => {
                if (items.id == e) {
                    setSelectedLink(items.subcategory[0] ? items.subcategory[0].id : '')
                }
            })
        }
        if (field == 'linkCategoryId') {
            obj['linkCategory'] = text
        }
        setDetails(obj);
    }

    function onDialogClose(passData: boolean) {
        if (edited) {
            passData ? props.handleClose({
                links: linksEdit
            })
                : props.handleClose()
            getLinksForHeader();
            setShowLoading(true);
            getData();
            setToggleView('');
            setEdited(false);

        }
        else {
            passData ? props.handleClose({
                links: linksEdit
            })
                : props.handleClose()
            setEdited(false)
        }
    }

    /*Image Uploading code Started*/
    function showPreview(e: any) {
        let filetype;
        if (e.target.files.length > 0) {
            const selectedFile = e;
            if (e.target.files[0] != undefined || e.target.files[0] != null) {
                filetype = e.target.files[0].type;
                if (["image/png", "image/jpeg", "image/jpg", "image/bmp"].includes(filetype) === false) {
                    setFileCheck({ showError: true, type: "warning", message: "Not a valid file type" });
                    const filePicker = document.getElementById('imageuploadbanner') as HTMLInputElement;
                    setPreviewSrc("");
                }
                else {
                    setFileCheck({ showError: false, type: "warning", message: "" });
                    setLoading(true)
                    initUploadFile(e.target.files[0]);
                }
            }
        }
        else {
            setFileCheck({ showError: true, type: "warning", message: "Please select a file to Upload" });
        }
    }


    function initUploadFile(file) {
        let newDigest: any;
        //Image upload
        if (file !== null) {
            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                window['SP_Propertyweb'].getFolderByServerRelativeUrl(helpers.siteSiteAssetsURL).files.addChunked(file.name, file, data => {
                }, true).then(resp => {
                    return resp
                });
            }
            else {
                getFileBuffer(file).then(function (arrayBuffer) {
                    arrayBuffer = arrayBuffer.result;
                    var bytes = new Uint8Array(arrayBuffer);
                    var binary = '';
                    for (var b = 0; b < bytes.length; b++) {
                        binary += String.fromCharCode(bytes[b]);
                    }

                    let listServiceUrl = helpers.appweburl + "/_api/contextinfo";
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync({
                        url: listServiceUrl,
                        method: "POST",
                        headers: {
                            "Accept": "application/json; odata=verbose",
                            "Content-Type": "application/json; odata=verbose"
                        },
                        success: function (data) {
                            var jsonObject = JSON.parse(data.body.toString());
                            newDigest = jsonObject.d.GetContextWebInformation.FormDigestValue;
                            var requestHeaders = {
                                "Accept": "application/json;odata=verbose",
                                "X-RequestDigest": newDigest,
                                "Content-Type": "application/json; odata=verbose"
                            }
                            executor.executeAsync({
                                url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/GetFolderByServerRelativeUrl('" + helpers.siteSiteAssetsURL + "')/Files/Add(url='" + file.name + "',overwrite=true)?@target='" + helpers.hostweburl + "'",
                                method: "POST",
                                headers: requestHeaders,
                                binaryStringRequestBody: true,
                                body: binary,
                                success: function (data) {
                                    var jsonObject = JSON.parse(data.body.toString());
                                    let imgURL = jsonObject.d.ServerRelativeUrl;
                                    if (imgURL.startsWith("/sites")) {
                                        imgURL = helpers.siteOrigin + imgURL;
                                    }
                                    else {
                                        imgURL = jsonObject.d.ServerRelativeUrl;
                                    }
                                    const newEditorState = insertImage(editorState, imgURL);
                                    setEditorState(newEditorState);
                                    setLoading(false);
                                    setFileCheck({ showError: true, type: "success", message: "File uploaded successfully" });

                                    setTimeout(() => {
                                        setFileCheck({ showError: false });
                                    }, 3000)
                                },
                                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                    //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                },
                                state: "Update"
                            });
                        },
                        error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                            //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                        }
                    });
                });
            }
        }
    }


    function insertImage(editorState, base64) {
        const entityData = { src: base64, width: "auto", height: "400px" };
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            entityData
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity },
        );
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    }

    function getFileBuffer(file) {
        var deferred = $.Deferred();
        var reader = new FileReader();
        reader.onload = function (reader) {
            deferred.resolve(reader.target);
        }
        reader.onerror = function (e) {
            deferred.reject(e);
        }
        reader.readAsArrayBuffer(file);
        return deferred.promise();
    }
}

export default MenuModalEdit
