import React, { useState, useEffect } from 'react';
import './AllExperts.scss';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import _ from 'lodash';
import expert_icon from '../../assets/images/PersonPlaceholder.96x96x32.png';
import { sp as pnpsp } from '@pnp/sp';
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";
import ReactExport from "react-export-excel-fixed-xlsx";

let sitecollectionurl = helpers.sitecollectionurl;
//let allExperts = [];
export default function AllExperts(props: any) {


    const [experts, setExperts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [accordians, setAccordians] = useState([]);
    const [allExperts, setAllExperts] = useState([]);
    const [filterValues, setFilterValues] = useState(false);

    const [excelGroupedData, setExcelGroupedData] = useState([]);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const options = [
        { value: 'Dynamic Positioning', label: 'Dynamic Positioning' },
        { value: 'Global Design', label: 'Global Design' }
    ]
    // #####REMOVE AFTER BACKEND INTEGRATION 
    useEffect(() => {
        getExpertsConfigurationItems();
    }, []);

    /* let accordians = [
        {
            Title: "Civil Engineering",
            Experts: [
                {
                    Name: "Nick Van",
                    Category: "Commercial Excellence Mgt CP Upstrea, CP Commercial Excellence",
                    Descripton: "Main CP Contract for Integrated Australia Main CP Contact For",
                    Img: "https://i.pravatar.cc/150?img=60"
                    
                },
                {
                    Name: "Nick Van",
                    Category: "Commercial Excellence Mgt CP Upstrea, CP Commercial Excellence",
                    Descripton: "Main CP Contract for Integrated Australia Main CP Contact For",
                    Img: "https://i.pravatar.cc/150?img=60"
                },
                {
                    Name: "Nick Van",
                    Category: "Commercial Excellence Mgt CP Upstrea, CP Commercial Excellence",
                    Descripton: "Main CP Contract for Integrated Australia Main CP Contact For",
                    Img: "https://i.pravatar.cc/150?img=60"
                },
                {
                    Name: "Nick Van",
                    Category: "Commercial Excellence Mgt CP Upstrea, CP Commercial Excellence",
                    Descripton: "Main CP Contract for Integrated Australia Main CP Contact For",
                    Img: "https://i.pravatar.cc/150?img=60"
                }                                                
            ],
            SME: [
                {
                    Title: "Dynamic Positioning",
                    SMES: [
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        },
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        }                        
                    ]
                },
                {
                    Title: "Global Design",
                    SMES: [
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        },
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        }                        
                    ]
                }                
            ]              
        },
        {
            Title: "Metocean",
            Experts: [
                {
                    Name: "Nick Van",
                    Category: "Commercial Excellence Mgt CP Upstrea, CP Commercial Excellence",
                    Descripton: "Main CP Contract for Integrated Australia Main CP Contact For",
                    Img: "https://i.pravatar.cc/150?img=60"
                },
                {
                    Name: "Nick Van",
                    Category: "Commercial Excellence Mgt CP Upstrea, CP Commercial Excellence",
                    Descripton: "Main CP Contract for Integrated Australia Main CP Contact For",
                    Img: "https://i.pravatar.cc/150?img=60"
                }                
            ],
            SME: [
                {
                    Title: "Dynamic Positionings",
                    SMES: [
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        },
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        }                        
                    ]
                },
                {
                    Title: "Global Designs",
                    SMES: [
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        },
                        {
                            Name: "Nick Van",
                            Img: "https://i.pravatar.cc/150?img=60"
                        }                        
                    ]
                }                
            ]    
        }        
    ]
 */
    // #####REMOVE AFTER BACKEND INTEGRATION

    const { editMode, tabDisplayName } = props;
    const [activeHeader, setActiveHeader] = useState();
    const [showAll, setShowAll] = useState(false);
    const [displayExperts, setDisplayExperts] = useState(true);
    const [itemID, setItemID] = useState('');
    let currentDateTime = new Date();
    return (
        <div id="all-experts">
            <div className="container">

                {
                    !loading ?
                        experts.length ?
                            <div>
                                <div className="export-excel">
                                    {editMode ?
                                        <div className="expert-toggle-button">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch-8"
                                                label="Hide/Show blank PTE's"
                                                title=""
                                                checked={displayExperts}
                                                onChange={(e: any) => {
                                                    setDisplayExperts(e.currentTarget.checked);
                                                    updateExpertsConfiguration(e.currentTarget.checked);
                                                }}
                                            /> </div> : ""}
                                    <ExcelFile element={<button className="btn btn-info">Export to Excel</button>} filename={tabDisplayName + "_" + currentDateTime.toUTCString()}>
                                        <ExcelSheet data={excelGroupedData} name={tabDisplayName}>
                                            <ExcelColumn label="Function" value="TechnicalFunction" />
                                            <ExcelColumn label="Discipline" value="EFMLDiscipline" />
                                            <ExcelColumn label="Principal Expert Area" value="PTEArea" />
                                            <ExcelColumn label="Subject Matter Expertise Area" value="SMEArea" />
                                            <ExcelColumn label="Role" value="EFMLRole" />
                                            <ExcelColumn label="Expert Name" value="ExpertName" />
                                            <ExcelColumn label="Expert Email" value="EmailID" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                                <h6 className='experts-text'>Experts</h6>

                                <div className="utilities">
                                    {/* <div><Button variant="outline-secondary" className="export-excel">Export Excel</Button></div> */}
                                    <Button variant="link" className="expand-all" onClick={() => setShowAll(!showAll)}>{showAll ? "Collapse" : "Expand"} All</Button>
                                </div>
                                <Accordion defaultActiveKey="0" id="experts-accordion">
                                    {
                                        accordians.map((o: any, i: any) => {
                                            return (
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} className={activeHeader === i || showAll ? "active" : " "} eventKey={i} onClick={() => onAccordionClick(i)}>
                                                        {o.Title}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={i} className={activeHeader === i || showAll ? "show" : " "} >
                                                        <Card.Body>
                                                            {o.Experts.length > 0 ?
                                                                <div className="category-exeprts">
                                                                    {o.Experts ?
                                                                        o.Experts.map((z: any, i: any) => {
                                                                            {
                                                                                if (z.Name.toLowerCase() != 'to be appointed' && displayExperts == true) {
                                                                                    return (
                                                                                        <div key={i}>
                                                                                            <img src={z.Img} alt={z.Name} />
                                                                                            <div className="title">{z.Name ? z.Name.toLowerCase() == 'to be appointed' ? z.name : <a href={'https://my.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c' + `${z.EmailID}`} target='_blank'>{z.Name} </a> : null}  </div>
                                                                                            <div className="category"> {z.Category} </div>
                                                                                            <div className="description"> {z.Descripton} </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                else if (displayExperts == false) {
                                                                                    return (
                                                                                        <div key={i}>
                                                                                            <img src={z.Img} alt={z.Name} />
                                                                                            <div className="title">{z.Name ? z.Name.toLowerCase() == 'to be appointed' ? z.name : <a href={'https://my.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c' + `${z.EmailID}`} target='_blank'>{z.Name} </a> : null}  </div>
                                                                                            <div className="category"> {z.Category} </div>
                                                                                            <div className="description"> {z.Descripton} </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }

                                                                        }) : null
                                                                    }
                                                                </div>
                                                                : ""}
                                                            {o.SME.length > 0 ?
                                                                <div className="sme container">
                                                                    <div className="sme-header row">
                                                                        <div className="col-12 filter-select">
                                                                            <span>Subject Matter Expert (SME)</span>
                                                                            <Select options={getOptions(o)} isMulti={true} onChange={(newVal) => handleChangeOptions(newVal, o, i)} closeMenuOnSelect={false} placeholder={'Quick Filter'} maxMenuHeight={100} />
                                                                        </div>

                                                                    </div>

                                                                    {filterValues ?
                                                                        o.SME == [] ? null :
                                                                            o.SME.map((o: any, i: any) => {
                                                                                return (
                                                                                    <div className="row" id="expert-sme-id" key={i}>
                                                                                        <div className="col-4">
                                                                                            <span> {o.Title} </span>
                                                                                        </div>
                                                                                        <div className="col-8 sme-details-wrapper">
                                                                                            {
                                                                                                o.SMES.map((z: any, index: any) => {
                                                                                                    return (
                                                                                                        <div key={index} className="sme-details" onClick={() => z.EmailID ? window.open('https://profile.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c' + `${z.EmailID}`) : ''}>
                                                                                                            <span className="star-img"></span>
                                                                                                            <img src={z.Img} alt={z.Name} />
                                                                                                            <span className={`sme-name ${z.EmailID ? 'sme-underline' : ''}`}>
                                                                                                                {z.Name}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                        o.SME == [] ? null :
                                                                            o.SME.map((o: any, i: any) => {
                                                                                return (
                                                                                    <div className="row" key={i}>
                                                                                        <div className="col-4">
                                                                                            <span> {o.Title} </span>
                                                                                        </div>
                                                                                        <div className="col-8 sme-details-wrapper">
                                                                                            {
                                                                                                o.SMES.map((z: any, index: any) => {
                                                                                                    return (
                                                                                                        <div key={index} className="sme-details" onClick={() => z.EmailID ? window.open('https://profile.shell.com/PersonImmersive.aspx?accountname=i%3a0%23%2ef%7cmembership%7c' + `${z.EmailID}`) : ''}>
                                                                                                            <span className="star-img"></span>
                                                                                                            <img src={z.Img} alt={z.Name} />
                                                                                                            <span className={`sme-name ${z.EmailID ? 'sme-underline' : ''}`}>
                                                                                                                {z.Name}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                    }
                                                                </div>
                                                                : null}

                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                            : <div className="no-experts">
                                There are no Experts recorded for this Discipline.
                            </div>
                        :
                        <div className='skeleton-loading'>
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                <Skeleton height={50} width={1050}></Skeleton>
                            </SkeletonTheme>
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                <Skeleton height={50} width={1050}></Skeleton>
                            </SkeletonTheme>
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                <Skeleton height={50} width={1050}></Skeleton>
                            </SkeletonTheme>
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                <Skeleton height={50} width={1050}></Skeleton>
                            </SkeletonTheme>
                            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                <Skeleton height={50} width={1050}></Skeleton>
                            </SkeletonTheme>
                        </div>
                }





            </div>
        </div>
    )


    function updateExpertsConfiguration(expertsTabValue) {
        let toUpdate = { Required: expertsTabValue };
        helpers.updateData("ExpertsConfiguration", toUpdate, itemID, async function (iar: any) {
            console.log("Item Updated");
        });
    }
    function onAccordionClick(i: any) {
        if (showAll) {
            setShowAll(false);
        }
        i !== activeHeader ? setActiveHeader(i) : setActiveHeader(null)
        setFilterValues(null);
    }

    function handleChangeOptions(newVal: any, o: any, accordianIndex: number) {

        let temp = JSON.parse(JSON.stringify(allExperts));
        //let temp1 = JSON.parse(JSON.stringify(allExperts));
        if (newVal == null || newVal.length == 0) {
            setAccordians(allExperts);
            setFilterValues(false);
        }
        else {
            // for(let i=0; i<newVal.length; i++) {
            //     temp1.map((item:any, index:number) => {
            //       if(item.Title == o.Title) {
            //           temp[index].SME = item.SME.filter(items => String(items.Title).toLowerCase().indexOf(newVal[i].value.toLowerCase()) > -1)
            //       }
            //     })      
            //   }

            let selectedValues = [];
            newVal.map((o: any) => selectedValues.push(o.value))
            temp[accordianIndex].SME = temp[accordianIndex].SME.filter((o: any) => selectedValues.includes(o.Title));

            setAccordians(temp);
            setFilterValues(true);
        }
    }


    function getOptions(o: any) {

        let options = [];
        allExperts.map((item: any) => {
            if (item.Title == o.Title) {
                item.SME.map((i: any) => {
                    options.push({ value: i.Title, label: i.Title });
                })
            }
        })

        return options;
    }

    function getExpertsConfigurationItems() {
        window.scrollTo(0, 0);
        const caml: any = {
            ViewXml: "<View><Query></Query></View>",
        };
        window['SP_Propertyweb'].lists.getByTitle("ExpertsConfiguration").getItemsByCAMLQuery(caml).then((response: any) => {
            const expertsconfigurationresponse = response

            if (response.length > 0) {
                getSPoAEFMLMasterItems(expertsconfigurationresponse);
            }
            else {
                setLoading(false);
            }

        });
    }



    function getSPoAEFMLMasterItems(expertsconfigurationresponse) {

        let EFMLDisciplines = '';
        let EFMLDiscipliness = '';
        let expertsItemID = '';
        let expertsItemRequired;
        for (var j = 0; j < expertsconfigurationresponse.length; j++) {

            let Discipline = expertsconfigurationresponse[j].ExpertiseArea.Label.replace(/:/g, ';');
            let EFMLDisciplines1 = "substringof('" + Discipline + "', TermPath)";

            if (j == expertsconfigurationresponse.length - 1) {
                EFMLDisciplines = EFMLDisciplines + EFMLDisciplines1;
            }
            else {
                EFMLDisciplines = EFMLDisciplines + EFMLDisciplines1 + ' or ';
            }

            expertsItemID = expertsconfigurationresponse[j].ID;
            expertsItemRequired = expertsconfigurationresponse[j].Required;

        }
        setDisplayExperts(expertsItemRequired);
        setItemID(expertsItemID);
        EFMLDiscipliness = "(" + EFMLDisciplines + ")";


        //let filterstring =  EFMLDiscipliness + " and (EFMLRole eq 'PTE' or EFMLRole eq 'SME' or EFMLRole eq 'Associate SME')"
        if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
            getDataformainlink(EFMLDiscipliness);
        }
        else { getData(EFMLDiscipliness); }

    }


    function getDataformainlink(EFMLDiscipliness) {
        sp.site.rootWeb.lists.getByTitle("SPoAEFMLMaster").items.select("ExpertName,EFMLRole,EFMLDiscipline,TermPath,PTEArea,SMEArea,EmailID,ID,TechnicalFunction").filter(EFMLDiscipliness).orderBy('PTEArea').top(4999).get().then((response: any) => {
            setExperts(response);
            let expertsResponse = [];
            for (let i = 0; i < response.length; i++) {
                if (response[i].EFMLRole === "PTE" || response[i].EFMLRole === "SME" || response[i].EFMLRole === "Associate SME") {
                    expertsResponse.push(response[i]);
                }
            }
            setExcelGroupedData(expertsResponse);
            let groupedItems = _.groupBy(response, 'PTEArea');
            let groupedItems1 = _.groupBy(response, 'SMEArea');
            let temp = [];
            for (let i = 0; i < Object.keys(groupedItems).length; i++) {
                if (Object.keys(groupedItems)[i] == 'null') {
                    continue;
                }
                else {
                    let temp0 = [];
                    let temp2 = [];

                    groupedItems[Object.keys(groupedItems)[i]].map((e: any) => {

                        if (e.EFMLRole === 'PTE') {
                            temp0.push({
                                Name: e.ExpertName,
                                Category: "Principal Expert",
                                Descripton: "",
                                EmailID: e.EmailID,
                                Img: "https://i.pravatar.cc/150?img=60"
                            });
                        }
                    });
                    if (temp0.length == 0) {


                        temp0.push({
                            Name: 'To be Appointed',
                            Category: "Principal Expert",
                            Descripton: "",
                            Img: expert_icon
                        });

                    }
                    let groupedSme = _.groupBy(groupedItems[Object.keys(groupedItems)[i]], 'SMEArea')
                    for (let j = 0; j < Object.keys(groupedSme).length; j++) {
                        if (Object.keys(groupedSme)[j] != 'null') {

                            let temp1 = [];
                            groupedSme[Object.keys(groupedSme)[j]].map((e: any) => {
                                if (e.EFMLRole === 'SME') {
                                    temp1.push({
                                        Name: e.ExpertName + "(SME)",
                                        EmailID: e.EmailID,
                                        Img: e.ExpertName.toLowerCase() == 'to be appointed' ? expert_icon : "https://i.pravatar.cc/150?img=60",
                                        Role: e.EFMLRole,
                                    });

                                }
                                else if (e.ExpertName) {
                                    if (e.ExpertName.toLowerCase() == 'to be appointed') {
                                        temp1.push({
                                            Name: e.ExpertName,
                                            EmailID: e.EmailID,
                                            Img: e.ExpertName.toLowerCase() == 'to be appointed' ? expert_icon : "https://i.pravatar.cc/150?img=60",
                                            Role: e.EFMLRole,
                                        });
                                    }
                                }
                                else if (e.EFMLRole === 'Associate SME') {
                                    temp1.push({
                                        Name: e.ExpertName + "(Associate SME)",
                                        EmailID: e.EmailID,
                                        Img: e.ExpertName.toLowerCase() == 'to be appointed' ? expert_icon : "https://i.pravatar.cc/150?img=60",
                                        Role: e.EFMLRole,
                                    });

                                }
                            })
                            let SMEs = temp1.filter(s => s.Role == "SME");
                            let NSMEs = temp1.filter(s => s.Role != "SME");
                            let sortedTemp1 = SMEs.concat(NSMEs);
                            temp2.push({
                                Title: Object.keys(groupedSme)[j], SMES: sortedTemp1
                            });
                        }
                    }

                    temp.push({ Title: Object.keys(groupedItems)[i], Experts: temp0, SME: temp2 });

                }
            }
            setAccordians(temp);
            setAllExperts(temp);
            setLoading(false);
        });
    }
    function getData(EFMLDiscipliness) {
        sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoAEFMLMaster").items.select("ExpertName,EFMLRole,EFMLDiscipline,TermPath,PTEArea,SMEArea,EmailID,ID,TechnicalFunction").filter(EFMLDiscipliness).orderBy('PTEArea').top(4999).get().then((response: any) => {
            setExperts(response);
            let expertsResponse = [];
            for (let i = 0; i < response.length; i++) {
                if (response[i].EFMLRole === "PTE" || response[i].EFMLRole === "SME" || response[i].EFMLRole === "Associate SME") {
                    expertsResponse.push(response[i]);
                }
            }
            setExcelGroupedData(expertsResponse);
            let groupedItems = _.groupBy(response, 'PTEArea');
            let groupedItems1 = _.groupBy(response, 'SMEArea');
            let temp = [];
            for (let i = 0; i < Object.keys(groupedItems).length; i++) {
                if (Object.keys(groupedItems)[i] == 'null') {
                    continue;
                }
                else {
                    let temp0 = [];
                    let temp2 = [];

                    groupedItems[Object.keys(groupedItems)[i]].map((e: any) => {
                        console.log(e);
                        if (e.EFMLRole === 'PTE') {
                            temp0.push({
                                Name: e.ExpertName,
                                Category: "Principal Technical Expert (PTE)",
                                Descripton: "",
                                EmailID: e.EmailID,
                                Img: e.ExpertName.toLowerCase() == 'to be appointed' ? `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=null` : `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${e.EmailID}`
                            });
                        }
                    })
                    if (temp0.length == 0) {
                        temp0.push({
                            Name: 'To be Appointed',
                            Category: "Principal Technical Expert (PTE)",
                            Descripton: "",
                            Img: `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=null`
                        });
                    }
                    let groupedSme = _.groupBy(groupedItems[Object.keys(groupedItems)[i]], 'SMEArea')
                    for (let j = 0; j < Object.keys(groupedSme).length; j++) {
                        if (Object.keys(groupedSme)[j] != 'null') {

                            let temp1 = [];
                            groupedSme[Object.keys(groupedSme)[j]].map((e: any) => {
                                console.log(e);
                                if (e.EFMLRole === 'SME') {
                                    temp1.push({
                                        Name: e.ExpertName + "(SME)",
                                        EmailID: e.EmailID,
                                        Img: e.ExpertName.toLowerCase() == 'to be appointed' ? `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=null` : `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=M&accountname=${e.EmailID}`,
                                        Role: e.EFMLRole,
                                    });

                                }
                                else if (e.ExpertName) {
                                    //if (e.ExpertName.toLowerCase() == 'to be appointed') {
                                    //    temp1.push({
                                    //        Name: e.ExpertName,
                                    //        EmailID: e.EmailID,
                                    //        Img: e.ExpertName.toLowerCase() == 'to be appointed' ? expert_icon : "https://i.pravatar.cc/150?img=60",
                                    //        Role: e.EFMLRole,
                                    //    });
                                    //}
                                }
                                if (e.EFMLRole === 'Associate SME') {
                                    temp1.push({
                                        Name: e.ExpertName + "(Associate SME)",
                                        EmailID: e.EmailID,
                                        Img: e.ExpertName.toLowerCase() == 'to be appointed' ? `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=null` : `${sitecollectionurl}/_layouts/15/userphoto.aspx?size=M&accountname=${e.EmailID}`,
                                        Role: e.EFMLRole,
                                    });

                                }


                            })
                            let SMEs = temp1.filter(s => s.Role == "SME");
                            let NSMEs = temp1.filter(s => s.Role != "SME");
                            let sortedTemp1 = SMEs.concat(NSMEs);
                            temp2.push({
                                Title: Object.keys(groupedSme)[j], SMES: sortedTemp1
                            });
                        }
                    }
                    temp2 = temp2.sort((a, b) => a.Title.localeCompare(b.Title));
                    temp.push({ Title: Object.keys(groupedItems)[i], Experts: temp0, SME: temp2 });

                }
            }
            setAccordians(temp);
            setAllExperts(temp);
            console.log(groupedItems);
            setLoading(false);
        });
    }


}