import React, { useEffect, useState, useCallback } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from "react-bootstrap/Alert";
import Form, { FormRow } from 'react-bootstrap/Form';
import { FaArrowLeft } from 'react-icons/fa';
import 'react-icons/fa'
import helpers from '../helpers';
import ModalBody from 'react-bootstrap/ModalBody';
import { useDropzone } from 'react-dropzone';
import DatePicker from "react-datepicker";
import TimePicker from 'react-bootstrap-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import './AddEvents.scss'
import { link } from 'fs';
import * as $ from "jquery";
import { timeFromInt } from 'time-number';
import moment from 'moment-timezone';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToRaw, EditorState, Modifier, ContentState, AtomicBlockUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

export interface IEventsState {
    title: string | null;
    desc?: string | null;
    notes?: string | null;
    start?: Date | null;
    end?: Date | null;
    startTime?: Date | null;
    endTime?: Date | null;
    location?: string | null;
    allDayEvent: boolean | null;
    tz: string | null;
}

const windowsToIANA = {
    "Dateline Standard Time": "Etc/GMT+12",
    "UTC-11": "Etc/GMT+11",
    "Aleutian Standard Time": "America/Adak",
    "Hawaiian Standard Time": "Pacific/Honolulu",
    "Marquesas Standard Time": "Pacific/Marquesas",
    "Alaskan Standard Time": "America/Anchorage",
    "UTC-09": "Etc/GMT+9",
    "Pacific Standard Time (Mexico)": "America/Tijuana",
    "UTC-08": "Etc/GMT+8",
    "Pacific Standard Time": "America/Los_Angeles",
    "US Mountain Standard Time": "America/Phoenix",
    "Mountain Standard Time (Mexico)": "America/Chihuahua",
    "Mountain Standard Time": "America/Denver",
    "Central America Standard Time": "America/Guatemala",
    "Central Standard Time": "America/Chicago",
    "Easter Island Standard Time": "Pacific/Easter",
    "Central Standard Time (Mexico)": "America/Mexico_City",
    "Canada Central Standard Time": "America/Regina",
    "SA Pacific Standard Time": "America/Bogota",
    "Eastern Standard Time (Mexico)": "America/Cancun",
    "Eastern Standard Time": "America/New_York",
    "Haiti Standard Time": "America/Port-au-Prince",
    "Cuba Standard Time": "America/Havana",
    "US Eastern Standard Time": "America/Indianapolis",
    "Paraguay Standard Time": "America/Asuncion",
    "Atlantic Standard Time": "America/Halifax",
    "Venezuela Standard Time": "America/Caracas",
    "Central Brazilian Standard Time": "America/Cuiaba",
    "SA Western Standard Time": "America/La_Paz",
    "Pacific SA Standard Time": "America/Santiago",
    "Turks And Caicos Standard Time": "America/Grand_Turk",
    "Newfoundland Standard Time": "America/St_Johns",
    "Tocantins Standard Time": "America/Araguaina",
    "E. South America Standard Time": "America/Sao_Paulo",
    "SA Eastern Standard Time": "America/Cayenne",
    "Argentina Standard Time": "America/Buenos_Aires",
    "Greenland Standard Time": "America/Godthab",
    "Montevideo Standard Time": "America/Montevideo",
    "Magallanes Standard Time": "America/Punta_Arenas",
    "Saint Pierre Standard Time": "America/Miquelon",
    "Bahia Standard Time": "America/Bahia",
    "UTC-02": "Etc/GMT+2",
    "Azores Standard Time": "Atlantic/Azores",
    "Cape Verde Standard Time": "Atlantic/Cape_Verde",
    "UTC": "Etc/GMT",
    "Morocco Standard Time": "Africa/Casablanca",
    "GMT Standard Time": "Europe/London",
    "Greenwich Standard Time": "Atlantic/Reykjavik",
    "W. Europe Standard Time": "Europe/Berlin",
    "Central Europe Standard Time": "Europe/Budapest",
    "Romance Standard Time": "Europe/Paris",
    "Central European Standard Time": "Europe/Warsaw",
    "W. Central Africa Standard Time": "Africa/Lagos",
    "Jordan Standard Time": "Asia/Amman",
    "GTB Standard Time": "Europe/Bucharest",
    "Middle East Standard Time": "Asia/Beirut",
    "Egypt Standard Time": "Africa/Cairo",
    "E. Europe Standard Time": "Europe/Chisinau",
    "Syria Standard Time": "Asia/Damascus",
    "West Bank Standard Time": "Asia/Hebron",
    "South Africa Standard Time": "Africa/Johannesburg",
    "FLE Standard Time": "Europe/Kiev",
    "Israel Standard Time": "Asia/Jerusalem",
    "Kaliningrad Standard Time": "Europe/Kaliningrad",
    "Sudan Standard Time": "Africa/Khartoum",
    "Libya Standard Time": "Africa/Tripoli",
    "Namibia Standard Time": "Africa/Windhoek",
    "Arabic Standard Time": "Asia/Baghdad",
    "Turkey Standard Time": "Europe/Istanbul",
    "Arab Standard Time": "Asia/Riyadh",
    "Belarus Standard Time": "Europe/Minsk",
    "Russian Standard Time": "Europe/Moscow",
    "E. Africa Standard Time": "Africa/Nairobi",
    "Iran Standard Time": "Asia/Tehran",
    "Arabian Standard Time": "Asia/Dubai",
    "Astrakhan Standard Time": "Europe/Astrakhan",
    "Azerbaijan Standard Time": "Asia/Baku",
    "Russia Time Zone 3": "Europe/Samara",
    "Mauritius Standard Time": "Indian/Mauritius",
    "Saratov Standard Time": "Europe/Saratov",
    "Georgian Standard Time": "Asia/Tbilisi",
    "Caucasus Standard Time": "Asia/Yerevan",
    "Afghanistan Standard Time": "Asia/Kabul",
    "West Asia Standard Time": "Asia/Tashkent",
    "Ekaterinburg Standard Time": "Asia/Yekaterinburg",
    "Pakistan Standard Time": "Asia/Karachi",
    "India Standard Time": "Asia/Calcutta",
    "Sri Lanka Standard Time": "Asia/Colombo",
    "Nepal Standard Time": "Asia/Katmandu",
    "Central Asia Standard Time": "Asia/Almaty",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Omsk Standard Time": "Asia/Omsk",
    "Myanmar Standard Time": "Asia/Rangoon",
    "SE Asia Standard Time": "Asia/Bangkok",
    "Altai Standard Time": "Asia/Barnaul",
    "W. Mongolia Standard Time": "Asia/Hovd",
    "North Asia Standard Time": "Asia/Krasnoyarsk",
    "N. Central Asia Standard Time": "Asia/Novosibirsk",
    "Tomsk Standard Time": "Asia/Tomsk",
    "China Standard Time": "Asia/Shanghai",
    "North Asia East Standard Time": "Asia/Irkutsk",
    "Singapore Standard Time": "Asia/Singapore",
    "W. Australia Standard Time": "Australia/Perth",
    "Taipei Standard Time": "Asia/Taipei",
    "Ulaanbaatar Standard Time": "Asia/Ulaanbaatar",
    "Aus Central W. Standard Time": "Australia/Eucla",
    "Transbaikal Standard Time": "Asia/Chita",
    "Tokyo Standard Time": "Asia/Tokyo",
    "North Korea Standard Time": "Asia/Pyongyang",
    "Korea Standard Time": "Asia/Seoul",
    "Yakutsk Standard Time": "Asia/Yakutsk",
    "Cen. Australia Standard Time": "Australia/Adelaide",
    "AUS Central Standard Time": "Australia/Darwin",
    "E. Australia Standard Time": "Australia/Brisbane",
    "AUS Eastern Standard Time": "Australia/Sydney",
    "West Pacific Standard Time": "Pacific/Port_Moresby",
    "Tasmania Standard Time": "Australia/Hobart",
    "Vladivostok Standard Time": "Asia/Vladivostok",
    "Lord Howe Standard Time": "Australia/Lord_Howe",
    "Bougainville Standard Time": "Pacific/Bougainville",
    "Russia Time Zone 10": "Asia/Srednekolymsk",
    "Magadan Standard Time": "Asia/Magadan",
    "Norfolk Standard Time": "Pacific/Norfolk",
    "Sakhalin Standard Time": "Asia/Sakhalin",
    "Central Pacific Standard Time": "Pacific/Guadalcanal",
    "Russia Time Zone 11": "Asia/Kamchatka",
    "New Zealand Standard Time": "Pacific/Auckland",
    "UTC+12": "Etc/GMT-12",
    "Fiji Standard Time": "Pacific/Fiji",
    "Chatham Islands Standard Time": "Pacific/Chatham",
    "UTC+13": "Etc/GMT-13",
    "Tonga Standard Time": "Pacific/Tongatapu",
    "Samoa Standard Time": "Pacific/Apia",
    "Line Islands Standard Time": "Pacific/Kiritimati"
}

const AddEvents = (props: any) => {
    const { setShowAdd, handleClose, getItems } = props;
    const ical = require('node-ical');
    let fileRef = React.useRef(null);
    let emailID = '';
    let NowDate = new Date();
    useEffect(() => {
        helpers.getUser(function (item) {
            emailID = item.Email;
        })
    }, [])
    NowDate.setHours(0, 0, 0, 0);
    const onDrop = useCallback((acceptedFiles) => {
        if (showFields === false && eventDetails == undefined) {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                if (file.type != 'text/calendar') {
                    setCorrectFile(true);
                }
                else {
                    setFileTitle(file.name.length > 40 ? file.name.slice(0, 40).trim() + '...' : file.name);
                    setFileAttachmentText(file.name);
                    reader.onabort = () => console.log('file reading was aborted')
                    reader.onerror = () => console.log('file reading has failed')

                    reader.onload = () => {
                        // Do whatever you want with the file contents         
                        console.log(reader.result);
                        const directEvents = ical.sync.parseICS(reader.result);
                        console.log(directEvents);
                        //File upload issue code 
                        for (let i = 0; i < Object.keys(directEvents).length; i++) {
                            if (directEvents[Object.keys(directEvents)[i]].organizer !== undefined) {
                                /*if (emailID.toLowerCase() != directEvents[Object.keys(directEvents)[i]].organizer.val.replace('mailto:', '').toLowerCase()) {
                                   setInValidUser(true);
                               }*/
                                //else {
                                setIsFile(true);
                                var temp: any = { ...eventDetails };
                                if (Object.keys(directEvents).length > 1) {
                                    temp.title = directEvents[Object.keys(directEvents)[1]].summary != '' ? directEvents[Object.keys(directEvents)[1]].summary.val ? directEvents[Object.keys(directEvents)[1]].summary.val.slice(-255) : directEvents[Object.keys(directEvents)[1]].summary.slice(-255) : '';
                                    temp.desc = directEvents[Object.keys(directEvents)[1]]["ALT-DESC"].val.replaceAll("&nbsp;", '').replaceAll(/<img[^>]*>/g, "");
                                    temp.location = directEvents[Object.keys(directEvents)[1]].location;
                                    temp.start = new Date(directEvents[Object.keys(directEvents)[1]].start);
                                    temp.end = new Date(directEvents[Object.keys(directEvents)[1]].end);
                                    temp.endTime = new Date(temp.end).getHours() + ":" + new Date(temp.end).getMinutes() + ":" + new Date(temp.end).getSeconds();
                                    temp.startTime = new Date(temp.start).getHours() + ":" + new Date(temp.start).getMinutes() + ":" + new Date(temp.start).getSeconds();
                                    temp.tz = directEvents[Object.keys(directEvents)[1]].start.tz.toString();
                                    setAllDayEvent(false);
                                    let contentState = stateFromHTML(temp.desc);
                                    setEditorState(EditorState.createWithContent(contentState));
                                }
                                else {
                                    temp.title = directEvents[Object.keys(directEvents)[0]].summary != '' ? directEvents[Object.keys(directEvents)[0]].summary.val ? directEvents[Object.keys(directEvents)[0]].summary.val != '' ? directEvents[Object.keys(directEvents)[0]].summary.val.slice(-255) : '' : directEvents[Object.keys(directEvents)[0]].summary.slice(-255) : '';
                                    temp.desc = directEvents[Object.keys(directEvents)[1]]["ALT-DESC"].val;
                                    temp.location = directEvents[Object.keys(directEvents)[0]].location;
                                    temp.start = new Date(directEvents[Object.keys(directEvents)[0]].start);
                                    temp.end = new Date(directEvents[Object.keys(directEvents)[0]].end);
                                    temp.endTime = '00:00'
                                    temp.startTime = '00:00'
                                    temp.tz = directEvents[Object.keys(directEvents)[0]].start.toString();
                                    setAllDayEvent(true);
                                    let contentState = stateFromHTML(temp.desc);
                                    setEditorState(EditorState.createWithContent(contentState));
                                }

                                seteventDetails(temp);
                                setShowFields(true);
                                setCorrectFile(false);
                                setFileAttachment(file);
                                //}

                            }
                        }
                    }

                    reader.readAsText(file)
                }
            })

        }
        fileRef.current.value = null;
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    // const { getRootProps1, getInputProps1 } = useDropzone({onDrop1});
    const [showModal, setShowModal] = useState(setShowAdd);
    const [showFields, setShowFields] = useState(false);
    const [isFile, setIsFile] = useState(false);
    const [showConf, setShowConf] = useState(false);
    const [fileTitle, setFileTitle] = useState('');
    const [eventDetails, seteventDetails] = useState<IEventsState>();
    const [allDayEvent, setAllDayEvent] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Event</span>);
    const [fileAttachment, setFileAttachment] = useState<File>();
    const [noStartDate, setnoStartDate] = useState(false);
    const [noEndDate, setnoEndDate] = useState(false);
    const [longTitle, setlongTitle] = useState(false);
    const [longDesc, setlongDesc] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [fileAttachmentText, setFileAttachmentText] = useState('');
    const [correctFile, setCorrectFile] = useState(false);
    const [dateErrorPrev, setDateErrorPrev] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [inValidUser, setInValidUser] = useState(false);
    const [descLength, setDescLength] = useState(0);
    const [notesLength, setNotesLength] = useState(0);
    const [TitleLength, setTitleLength] = useState(0);
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    let desc_options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: decodeURIComponent(data.url),
                        target: '_blank'
                    },
                };
            }
        },
    };

    return (
        <div>
            <Modal show={showModal} onHide={() => { onDialogClose(false); setDateError(false); setDateErrorPrev(false); setInValidUser(false) }} centered size='lg' dialogClassName={showFields ? 'modal-new' : ''} backdrop='static'>

                <Modal.Header closeButton>
                    {showFields ? <FaArrowLeft onClick={() => {
                        setShowFields(false);
                        seteventDetails(undefined);
                        setFileAttachment(null);
                        setIsFile(false);
                        setDateErrorPrev(false);
                        setDateError(false);
                        setInValidUser(false);
                        setValidated(false);
                        setnoStartDate(false);
                        setnoEndDate(false);
                        setDateError(false);
                        setTitleError(false);
                    }} className="arrow-back-category" /> : ''}<Modal.Title>Add Event</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='add-event-dragndrop'>
                        <div id='dragndrop-section' className={showFields ? `not-active` : ''}>
                            <div {...getRootProps()}>
                                <Form.Label className='dragndrop-text'><span className='dragnrop-span'>Drag and drop your outlook<br /> calendar invite here</span><br /> or</Form.Label><br />
                            </div>
                            <input {...getInputProps()} id='choose-file-dnd' ref={fileRef} hidden />
                            <Button className="button-cancel-menu" variant="secondary" onClick={() => document.getElementById('choose-file-dnd').click()}>
                                Browse from Computer
                            </Button>
                            {correctFile ? <span className='error-file'><br />
                                Please upload a valid file (.ics)
                            </span> : ''}
                            {inValidUser ? <span className='error-file'><br />
                                Please upload an event created by you
                            </span> : ''}
                        </div>
                    </div>
                    <div id='add-event-fields' className={showFields ? `active` : ''}>
                        <Form className='add-event' noValidate validated={validated}>
                            <Form.Group>
                                <Form.Label>Event Title <span className="star">*</span></Form.Label>
                                <Form.Control type='input' pattern="^[a-zA-Z1-9].*" value={eventDetails ? eventDetails.title : ''} onChange={(e: any) => { updateEvents(e.currentTarget.value, 'title'); setTitleLength(e.currentTarget.value.length) }} maxLength={255} required disabled={fileAttachment ? true : false}>
                                </Form.Control>
                                <span className='info-max'>{TitleLength ? "" + (255 - TitleLength) + " characters remaining" : '255 characters max'}</span>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Event Title
                                </Form.Control.Feedback>
                            </Form.Group>
                            {longTitle ? <span className='error-new'>
                                Title length cannot exceed 255 characters
                            </span> : ''}
                            {titleError ? <span className='error-new'>
                                Please provide a valid Event Title
                            </span> : ''}
                            <div className='checkbox-all-day'>
                                <Form.Check
                                    type="checkbox"
                                    id="custom-checkbox"
                                    disabled={fileAttachment ? true : false}
                                    label="All day event"
                                    checked={allDayEvent}
                                    onChange={() => allDayEvent ? setAllDayEvent(false) : setAllDayEvent(true)}
                                />
                            </div>
                            <Form.Group className='date-picker-row'>
                                <Form.Label>Starts By <span className="star">*</span></Form.Label>
                                <DatePicker id='date-picker-1' placeholderText='dd/mm/yyyy' dateFormat="dd/MM/yyyy" minDate={new Date()} selected={eventDetails ? eventDetails.start : null} onChange={date => { updateEvents(date, 'start'); eventDetails ? eventDetails.start < NowDate ? setDateErrorPrev(true) : setDateErrorPrev(false) : setDateErrorPrev(false) }} disabled={fileAttachment ? true : false}></DatePicker>
                                <a className='calendar-icon' onClick={(event) => triggerCal(event)}><i className="fa fa-calendar" aria-hidden="true"></i></a>
                                <span className='time-picker-span'> <TimePicker className='time-picker' start="00:00" end="23:59" step={15} format={24} value={eventDetails ? eventDetails.startTime : ''} onChange={(e: any) => updateEvents(e, 'startTime')} disabled={fileAttachment ? true : allDayEvent} /></span>
                            </Form.Group>
                            {noStartDate ? <span className='error-new'>
                                Please provide a valid Start Date
                            </span> : ''}
                            <Form.Group className='date-picker-row'>
                                <Form.Label>Ends By <span className="star">*</span></Form.Label>
                                <DatePicker id='date-picker-2' placeholderText='dd/mm/yyyy' dateFormat="dd/MM/yyyy" minDate={eventDetails ? eventDetails.start : new Date()} selected={eventDetails ? eventDetails.end : null} onChange={date => updateEvents(date, 'end')} disabled={fileAttachment ? true : false} required></DatePicker>
                                <span className='calendar-icon' onClick={() => document.getElementById('date-picker-2').click()}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                <span className='time-picker-span'> <TimePicker className='time-picker' start={eventDetails ? eventDetails.startTime : "00:00"} end="23:59" step={15} format={24} value={eventDetails ? eventDetails.endTime : ''} onChange={(e: any) => updateEvents(e, 'endTime')} initialValue={'23:59'} disabled={fileAttachment ? true : allDayEvent} /></span>
                            </Form.Group>
                            {noEndDate ? <span className='error-new'>
                                Please provide a valid End Date
                            </span> : ''}
                            {dateError ? <span className='error-new'>
                                End Date is before Start Date
                            </span> : ''}
                            {dateErrorPrev ? <span className='error-new'>
                                Start Date & Time is before Today's Date & Time
                            </span> : ''}

                            {eventDetails ?
                                eventDetails.start ?
                                    <Form.Group>
                                        <Form.Label>TimeZone: {fileAttachment ?
                                            windowsToIANA[eventDetails.tz.slice(1, -1)]
                                            : new Date().toString().split('GMT')[1].slice(5).trim().slice(1, -1)}</Form.Label>
                                    </Form.Group> : '' : ''
                            }
                            <Form.Group className='add-field'>
                                <Form.Label>About this event</Form.Label>
                                {/*<Form.Control as="textarea" rows={5} value={eventDetails ? eventDetails.desc : ''} onChange={(e: any) => { updateEvents(e.currentTarget.value, 'desc'); setDescLength(e.currentTarget.value.length) }} disabled={fileAttachment ? true : false} />*/}
                                <Editor editorState={editorState}
                                    readOnly={fileAttachment ? true : false}
                                    placeholder="Add Text"
                                    onEditorStateChange={e => { setEditorState(e) }}
                                    wrapperClassName="wrapper-class"
                                    editorClassName={fileAttachment ? "disabled-editor editor-class" : "editor-class"}
                                    toolbarClassName="toolbar-class" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Location</Form.Label>
                                <Form.Control type='input' className='location-input' value={eventDetails ? eventDetails.location : ''} onChange={(e: any) => updateEvents(e.currentTarget.value, 'location')} disabled={fileAttachment ? true : false}></Form.Control>
                            </Form.Group>
                            {isFile ?
                                <Form.Group className='on-file-upload'>
                                    <span><Form.Label>Outlook calendar invite</Form.Label></span> <br />
                                    <span><i className="fa fa-calendar" aria-hidden="true"></i> {fileTitle} &nbsp; &nbsp;<span className='remove-ics' onClick={() => { setIsFile(false); setFileTitle(''); setFileAttachment(null); setShowFields(false); seteventDetails(undefined); setDateErrorPrev(false); setDateError(false); setInValidUser(false); }}><a>Remove file</a></span></span>
                                    {// <input {...getInputProps()} id='choose-file-new' hidden /><br/>}
                                    }<br /><span> <Button className="browse-outlook-invite" variant="secondary" disabled={true}>
                                        Browse Outlook Invite
                                    </Button></span>
                                </Form.Group> :
                                ''}
                            <Form.Group>
                                <Form.Label>Additional Notes</Form.Label>
                                <Form.Control as="textarea" rows={3} value={eventDetails ? eventDetails.notes : ''} onChange={(e: any) => { updateEvents(e.currentTarget.value, 'notes'); setNotesLength(e.currentTarget.value.length) }} maxLength={350} />
                                <span className='info-max'>{notesLength ? "" + (350 - notesLength) + " characters remaining" : '350 characters max'}</span>
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>

                <Modal.Footer className='add-events-footer'>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => onDialogClose(false)} >
                        Cancel
                    </Button>
                    <Button variant="primary" className='proceed-button' onClick={() => { showFields ? setModal(true) : setShowFields(true); setCorrectFile(false); initializeTime(); }}>
                        {showFields ? showProcessing : 'Proceed without Outlook invite'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showConf} backdrop='static' centered>
                <Modal.Body className='modal-conf'>   <span className='conf-message'>Your Event has been saved succesfully. <br /> Would you like to book a meeting room for the same?</span> </Modal.Body>
                <Modal.Footer className='modal-footer-conf'>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => onModalClose(false)} >
                        Close
                    </Button>
                    <Button variant="primary" className='proceed-button' onClick={() => { window.open('https://shell.condecosoftware.com/index.html', "WindowName", "noopener"); onModalClose(false) }}>
                        Yes, Open Condeco &nbsp; <span><i className="fa fa-external-link" aria-hidden="true"></i></span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    function onDialogClose(passData: boolean) {
        handleClose();

    }
    function onModalClose(passData: boolean) {
        setShowConf(false);
        setShowModal(setShowAdd);
        handleClose();
        getItems();
    }

    function updateTime(e: any) {
        console.log(e);
    }

    function triggerCal(e: any) {
        e.preventDefault();
        document.getElementById('date-picker-1').click();
    }
    function initializeTime() {
        let obj: any = { ...eventDetails };
        obj['startTime'] = '00:00';
        obj['endTime'] = '00:00';
    }

    function getTimeZone(d) {
        let date: any = new Date(d);
        if (!isNaN(date.getDate())) {
            date = new Date(d).toString().split('(')[1].slice(0, -1);
        } else {
            date = d.replace(/\"/g, "");
        }
        return date;
    }

    function setModal(flag: boolean) {
        setValidated(false);
        setnoStartDate(false);
        setnoEndDate(false);
        setDateError(false);
        setTitleError(false);
        let nowDate = new Date();
        nowDate.setHours(0, 0, 0, 0);
        setDateErrorPrev(false);
        if (eventDetails == undefined) {
            setValidated(true);
            setnoStartDate(true);
            setnoEndDate(true);
        }
        else if (eventDetails.title == null) {
            setValidated(true);
        }
        else if (eventDetails.title.trim() == '') {
            setTitleError(true);
        }
        else if (eventDetails.start == null) {
            setnoStartDate(true);
        }
        else if (eventDetails.end == null) {
            setnoEndDate(true);
        }
        else if (eventDetails.title.trim().length > 255) {
            setlongTitle(true);
        }
        else if (eventDetails.end < eventDetails.start) {
            setDateError(true);
        }
        else if (eventDetails.start < nowDate) {
            setDateErrorPrev(true);
        }

        else {
            if (flag) {
                setValidated(false);
                setDateError(false);
                setlongTitle(false);
                setlongDesc(false);
                setnoStartDate(false);
                setShowProcessing(<span>Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                let obj1: any = { ...eventDetails };

                if (!eventDetails.startTime) {
                    obj1.startTime = '00:00';
                    seteventDetails(obj1);
                }
                if (!eventDetails.endTime) {
                    obj1.endTime = '00:00';
                    seteventDetails(obj1);
                }
                if (eventDetails.startTime) {
                    if (eventDetails.startTime.toString().slice(-2) == 'PM') {
                        eventDetails.start.setHours(parseInt(eventDetails.startTime.toString().split(':')[0], 10) + 12, parseInt(eventDetails.startTime.toString().split(':')[1], 10));
                        eventDetails.start = new Date(eventDetails.start.toUTCString());
                    }
                    else {
                        eventDetails.start.setHours(parseInt(eventDetails.startTime.toString().split(':')[0], 10), parseInt(eventDetails.startTime.toString().split(':')[1], 10));
                        eventDetails.start = new Date(eventDetails.start.toUTCString());
                    }
                }

                if (eventDetails.endTime) {
                    if (eventDetails.endTime.toString().slice(-2) == 'PM') {
                        eventDetails.end.setHours(parseInt(eventDetails.endTime.toString().split(':')[0], 10) + 12, parseInt(eventDetails.endTime.toString().split(':')[1], 10));
                        eventDetails.end = new Date(eventDetails.end.toUTCString());
                    }
                    else {
                        eventDetails.end.setHours(parseInt(eventDetails.endTime.toString().split(':')[0], 10), parseInt(eventDetails.endTime.toString().split(':')[1], 10));
                        eventDetails.end = new Date(eventDetails.end.toUTCString());
                    }
                }
                //Validating the Time issue for date selected  
                let current_date = new Date();
                let selected_Date = new Date(eventDetails.start);
                if (selected_Date <= current_date) {
                    setDateErrorPrev(true);
                    setShowProcessing(<span>Save</span>);
                }
                else {
                    let utcStartDate;
                    let utcEndDate;
                    if (fileAttachment) {
                        let sYear = eventDetails.start.getFullYear();
                        let sMonth = (eventDetails.start.getMonth() + 1) < 10 ? `0${eventDetails.start.getMonth() + 1}` : (eventDetails.start.getMonth() + 1).toString();
                        let sDay = eventDetails.start.getDate() < 10 ? `0${eventDetails.start.getDate()}` : eventDetails.start.getDate().toString();
                        let sHours = eventDetails.start.getHours() < 10 ? `0${eventDetails.start.getHours()}` : eventDetails.start.getHours().toString();
                        let sMinutes = eventDetails.start.getMinutes() < 10 ? `0${eventDetails.start.getMinutes()}` : eventDetails.start.getMinutes().toString();
                        let sSeconds = eventDetails.start.getSeconds() < 10 ? `0${eventDetails.start.getSeconds()}` : eventDetails.start.getSeconds().toString();

                        let eYear = eventDetails.end.getFullYear();
                        let eMonth = (eventDetails.end.getMonth() + 1) < 10 ? `0${eventDetails.end.getMonth() + 1}` : (eventDetails.end.getMonth() + 1).toString();
                        let eDay = eventDetails.end.getDate() < 10 ? `0${eventDetails.end.getDate()}` : eventDetails.end.getDate().toString();
                        let eHours = eventDetails.end.getHours() < 10 ? `0${eventDetails.end.getHours()}` : eventDetails.end.getHours().toString();
                        let eMinutes = eventDetails.end.getMinutes() < 10 ? `0${eventDetails.end.getMinutes()}` : eventDetails.end.getMinutes().toString();
                        let eSeconds = eventDetails.end.getSeconds() < 10 ? `0${eventDetails.end.getSeconds()}` : eventDetails.end.getSeconds().toString();

                        let startDate = `${sYear}-${sMonth}-${sDay}T${sHours}:${sMinutes}:${sSeconds}`;
                        let endDate = `${eYear}-${eMonth}-${eDay}T${eHours}:${eMinutes}:${eSeconds}`;

                        const timeZone = windowsToIANA[eventDetails.tz.slice(1, -1)];
                        utcStartDate = moment.tz(startDate, timeZone).utc().format();
                        utcEndDate = moment.tz(endDate, timeZone).utc().format();
                    }
                    let event_description = '';
                    if (editorState.getCurrentContent().getPlainText().length !== 0) {
                        event_description = stateToHTML(editorState.getCurrentContent(), desc_options);
                    }
                    let obj = {
                        Title: eventDetails.title,
                        Location: eventDetails.location ? eventDetails.location : '',
                        EventDate: fileAttachment ? utcStartDate : eventDetails.start,
                        EndDate: fileAttachment ? utcEndDate : eventDetails.end,
                        EventNotes: eventDetails.notes,
                        fAllDayEvent: allDayEvent,
                        Description: event_description
                    }

                    helpers.addData('Calendar', obj, function (iar: any) {

                        if (fileAttachment) {
                            // iar.item.attachmentFiles.add(fileAttachmentText, fileAttachment);
                            let file: any;
                            let newDigest: any;
                            if (fileAttachment !== null) {
                                file = fileAttachment;
                                getFileBuffer(file).then(function (arrayBuffer) {
                                    arrayBuffer = arrayBuffer.result;
                                    var bytes = new Uint8Array(arrayBuffer);
                                    var binary = '';
                                    for (var b = 0; b < bytes.length; b++) {
                                        binary += String.fromCharCode(bytes[b]);
                                    }

                                    let listServiceUrl = helpers.appweburl + "/_api/contextinfo";
                                    var executor = new SP.RequestExecutor(helpers.appweburl);
                                    executor.executeAsync({
                                        url: listServiceUrl,
                                        method: "POST",
                                        headers: {
                                            "Accept": "application/json; odata=verbose",
                                            "Content-Type": "application/json; odata=verbose"
                                        },
                                        success: function (data) {
                                            var jsonObject = JSON.parse(data.body.toString());
                                            newDigest = jsonObject.d.GetContextWebInformation.FormDigestValue;
                                            var requestHeaders = {
                                                "Accept": "application/json;odata=verbose",
                                                "X-RequestDigest": newDigest,
                                                "Content-Type": "application/json; odata=verbose"
                                            }
                                            executor.executeAsync({
                                                //url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/GetFolderByServerRelativeUrl('" + helpers.siteSiteAssetsURL + "')/Files/Add(url='" + fileAttachmentText + "',overwrite=true)?@target='" + helpers.hostweburl + "'",
                                                url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/lists/getbytitle('Calendar')/items(" + iar.data.ID + ")/AttachmentFiles/add(FileName='" + fileAttachmentText + "')?@target='" + helpers.hostweburl + "'",
                                                method: "POST",
                                                headers: requestHeaders,
                                                binaryStringRequestBody: true,
                                                body: binary,
                                                success: function (data) {
                                                    onEventCreation();
                                                },
                                                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                                    //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                                },
                                                state: "Update"
                                            });
                                        },
                                        error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                            //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                        }
                                    });
                                });
                            }
                        }
                        else {
                            onEventCreation()
                        }


                    })
                }

            }
        }
    }

    function onEventCreation() {
        setShowProcessing(<span>Save Event</span>);
        setShowConf(true);
        setValidated(false);
        setShowModal(false);
        setDateError(false);
        setlongTitle(false);
        setlongDesc(false);
        setnoStartDate(false);
    }

    function getFileBuffer(file) {
        var deferred = $.Deferred();
        var reader = new FileReader();
        reader.onload = function (reader) {
            deferred.resolve(reader.target);
        }
        reader.onerror = function (e) {
            deferred.reject(e);
        }
        reader.readAsArrayBuffer(file);
        return deferred.promise();
    }

    function updateEvents(e: any, field: any) {
        let obj: any = { ...eventDetails };
        if (field == 'startTime' || field == 'endTime') {
            obj[field] = timeFromInt(e);
            seteventDetails(obj);
        }
        else if (field == 'desc') {
            let desc = stateToHTML(e.getCurrentContent(), desc_options);
            obj[field] = desc;
            seteventDetails(obj);
        }
        else {
            obj[field] = e;
            seteventDetails(obj);
        }
    }

}

export default AddEvents
