import React, { useState, useEffect } from 'react';
import CardWidget from '../cardWidget/CardWidget';
import './EeWidget.scss'
import { sp as pnpsp } from '@pnp/sp';
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const EeWidget = ({ editMode, isModerator }) => {
    const [articleSearchURL, setArticleSearchURL] = useState('');
    const config = {
        title: "Enterprise Encyclopedia (EE)",
        type: "EE",
        modalTitle: "Edit EE Query",
        searchUrl: articleSearchURL
    }
    const [response, setResponse] = useState([]);
    const [articleId, setArticleId] = useState('');
    const [articleQuery, setArticleQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchLFEURL, setsearchLFEURL] = useState('');
    const [isHidden, setIsHidden] = useState(false);


    useEffect(() => {
        getEEConfigurationItems();
    }, []);

    return (
        <div id="ee-widget">
            <>
                <CardWidget config={config} response={response} editMode={editMode} updateConfigurationItems={updateConfigurationItems} loading={loading} searchLFEURL={searchLFEURL} hideWebpart={hideWebpart} isModerator={isModerator} isHidden={isHidden} />
            </>
        </div>
    )
    function updateConfigurationItems(Tfunctions?: any, Tdiscipline?: any, Tpte?: any, Tsme?: any, Tkeyword?: any, TsignForums?: any) {
        setLoading(true)
        let selectedFunctionsLabel = []
        let TfunctionsLabel = '';
        let TDisciplineLabel = '';
        let TPteLabel = '';
        Object.keys(Tfunctions).forEach((o) => {
            if (Tfunctions[o].checked === true) {
                selectedFunctionsLabel.push(o);
            }
        });
        let selectedDisciplines = Tdiscipline.filter(o => o.checked);
        let selectedPTE = Tpte.filter(o => o.checked);
        for (let j = 0; j < selectedFunctionsLabel.length; j++) {
            TfunctionsLabel = TfunctionsLabel + selectedFunctionsLabel[j];
        }
        for (let j = 0; j < selectedDisciplines.length; j++) {
            TDisciplineLabel = TDisciplineLabel + selectedDisciplines[j].label;
        }
        for (let j = 0; j < selectedPTE.length; j++) {
            TPteLabel = TPteLabel + selectedPTE[j].label;
        }

        let queryFinalString = '';
        if (TfunctionsLabel !== "" && TDisciplineLabel !== "" && TPteLabel !== "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAAAB1732\"+AND+contentclass=\"STS_ListItem_850\"+AND+(refinablestring189:\"" + TfunctionsLabel + "\" AND refinablestring19:\"" + TDisciplineLabel + "\" AND refinablestring159:\"" + TPteLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='HitHighlightedSummary,LastModifiedTime,Path,Title,EEArticleStatusOWSCHCS,articlestartdateowsdate'&sortlist='LastModifiedTime:descending'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel !== "" && TPteLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAAAB1732\"+AND+contentclass=\"STS_ListItem_850\"+AND+(refinablestring189:\"" + TfunctionsLabel + "\" AND refinablestring19:\"" + TDisciplineLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='HitHighlightedSummary,LastModifiedTime,Path,Title,EEArticleStatusOWSCHCS,articlestartdateowsdate'&sortlist='LastModifiedTime:descending'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel === "" && TPteLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAAAB1732\"+AND+contentclass=\"STS_ListItem_850\"+AND+(refinablestring189:\"" + TfunctionsLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='HitHighlightedSummary,LastModifiedTime,Path,Title,EEArticleStatusOWSCHCS,articlestartdateowsdate'&sortlist='LastModifiedTime:descending'";
        }
        else if (TfunctionsLabel === "" && TDisciplineLabel === "" && TPteLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAAAB1732\"+AND+contentclass=\"STS_ListItem_850\"+AND+(refinablestring189:null AND refinablestring19:null)'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='HitHighlightedSummary,LastModifiedTime,Path,Title,EEArticleStatusOWSCHCS,articlestartdateowsdate'&sortlist='LastModifiedTime:descending'";
        }
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(articleId).update({
            Query: queryFinalString
        }).then((item: any) => {
            console.log('Updated')
            getEEConfigurationItems();
        }).catch(e => {
            console.log('Update failed')
        });

    }
    function getEEConfigurationItems() {
        setResponse([]);
        helpers.getKRListView("SpoAPageData", ((items: Array<{
            Query: string
            ID: string
            IsHidden: boolean
        }>) => {
            setIsHidden(items[0].IsHidden);
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let eeQuery = items[i].Query;
                    setArticleId(items[i].ID);
                    setArticleQuery(eeQuery);
                    if (eeQuery !== null) {
                        let articleSearchUrl = '';
                        if (eeQuery.indexOf('refinablestring19') !== -1) {
                            let splitFunction = eeQuery.split('refinablestring189:')[1];
                            let finalSplitFunction = splitFunction.split(' AND')[0];
                            finalSplitFunction = finalSplitFunction.replace(/"/g, '');
                            let splitDiscipline = eeQuery.split('refinablestring19:')[1];
                            let finalSplitDiscipline = splitDiscipline.split('")')[0];
                            finalSplitDiscipline = finalSplitDiscipline.replace(/"/g, '');
                            articleSearchUrl = "https://eu001-sp.shell.com/sites/AAAAB1732/Pages/Results.aspx?u=https://eu001-sp.shell.com/sites/AAAAB1732#Default={\"k\":\"RefinableString189:" + finalSplitFunction + " AND RefinableString19:" + finalSplitDiscipline + "\",\"o\": [{ \"p\": \"filename\" }]}";
                        }
                        else {
                            let splitFunction = eeQuery.split('refinablestring189:')[1];
                            let finalSplitFunction = splitFunction.split(')')[0];
                            finalSplitFunction = finalSplitFunction.replace(/"/g, '');
                            articleSearchUrl = "https://eu001-sp.shell.com/sites/AAAAB1732/Pages/Results.aspx?u=https://eu001-sp.shell.com/sites/AAAAB1732#Default={\"k\":\"RefinableString189:" + finalSplitFunction + "\",\"o\": [{ \"p\": \"filename\" }]}";
                        }
                        setArticleSearchURL(articleSearchUrl);
                    }
                    var searchUrl = helpers.appweburl + "/_api/search/query?querytext=" + eeQuery + "";
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync(
                        {
                            url: searchUrl,
                            method: "GET",
                            headers: { "Accept": "application/json; odata=verbose" },
                            success: onGetSearchResultsSuccess,
                            error: onGetSearchResultsFail
                        }
                    );
                }

            } else {
                setResponse([])
                setLoading(false)
            }
        }), "ID,Title,Query,Link,Section,Application,IsHidden", "Application eq 'EE3.0'");
    }

    function onGetSearchResultsSuccess(data) {
        let items = [];
        let lastModifiedDate = '';
        let articleTitle = '';
        let formattedValidDate = '';
        let articleStatus = '';
        let articlePath = '';
        let jsonObject = JSON.parse(data.body);
        if (jsonObject.d.query.PrimaryQueryResult.RelevantResults !== null) {
            let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
            console.log(jsonresults);
            const monthNames = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if (jsonresults !== null && jsonresults.length > 0) {
                for (let i = 0; i < jsonresults.length; i++) {
                    lastModifiedDate = '';
                    articleStatus = '';
                    articleTitle = '';
                    formattedValidDate = '';
                    articlePath = '';
                    for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                        if (jsonresults[i].Cells.results[j].Key === "LastModifiedTime") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lastModifiedDate = jsonresults[i].Cells.results[j].Value;
                                if (lastModifiedDate) {
                                    let validDate = new Date(lastModifiedDate);
                                    let validDay = validDate.getUTCDate();
                                    let validMonth = validDate.getUTCMonth();
                                    let validYear = validDate.getUTCFullYear();
                                    formattedValidDate = validDay + " " + monthNames[validMonth] + " " + validYear;
                                }
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Title") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                articleTitle = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Path") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                articlePath = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "EEArticleStatusOWSCHCS") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                articleStatus = jsonresults[i].Cells.results[j].Value;
                                if (articleStatus === "Principal Expert Approved") {
                                    articleStatus = "Expert Approved";
                                }
                            }
                        }
                    }
                    items.push({
                        articleTitle: articleTitle,
                        articleStatus: articleStatus,
                        lastModfied: formattedValidDate,
                        articlePath: articlePath,
                    });
                }
                setResponse(items);
                setLoading(false)
            }
            else {
                setResponse([])
                setLoading(false)
            }
        }
        else {
            setResponse([])
            setLoading(false)
        }
    }
    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }
    function hideWebpart(toHide: boolean) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(articleId, 10)).update({ IsHidden: toHide }).then(i => {
            setIsHidden(toHide);
            setLoading(false);
        }).catch(e => {
            console.log("error updating CustomWebpart!", e)
        });

    }
};

export default React.memo(EeWidget);