import React, { useState, useEffect } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';
import helpers from '../helpers';
import './mentors.scss';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Pagination from 'react-bootstrap/Pagination';
import ProgressiveImage from 'react-progressive-image';
import ReactExport from "react-export-excel-fixed-xlsx";
import { FormControl } from "react-bootstrap";

const Mentors = (props: any) => {

    const { mentorsSearchResponse, queryVal, mentorsLoading, tabDisplayName, userEmail } = props;
    const recordsPerPage = 10; //This will decide the pagination count.
    const [modalShow, setModalShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pageCount, setPageCount] = useState(5);
    const [mentorsToShow, setMentorsToShow] = useState([]);
    const [activePage, setaActivePage] = useState(1);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    let currentDateTime = new Date();
    let addMentorsLink = "https://profile.shell.com/?accountname=i:0%23.f%7cmembership%7c" + userEmail;
    useEffect(() => {
        setMentorsval();
    }, []);

    return (
        <div id="mentors" >
            {mentorsLoading ?
                <div className="mentors-loading">
                    <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                    </SkeletonTheme>
                </div> :
                <div>
                    {mentorsSearchResponse.length > 0 ? <div><FormControl type="text" placeholder="Search" className="mentors-search" onKeyUp={(e: any) => filtersearchdata(e.currentTarget.value)} /></div> : ""}
                    {
                        mentorsSearchResponse.length > 0 ?
                            <div className="mentors_search_link">
                                {queryVal ? <a href={helpers.siteAppSearchURL + "/Search/Pages/peopleresults.aspx?k=" + queryVal + " and refinableboolean00:1"} target="_blank" className="Mentors_Community">Show all mentors in this Discipline / Community</a> : null} <br />
                                <a href={helpers.siteAppSearchURL + "/Search/Pages/peopleresults.aspx?k=refinableboolean00:1"} target="_blank" className="Mentors_Community_shell">Show all mentors in all Discipline / Communities in Shell</a>
                            </div>
                            : null}

                    {mentorsSearchResponse.length > 0 ? <div className="addMentorsLink"> <p className="addMentorsptag"><a href={addMentorsLink} target="_blank">Click here</a> to add yourself as Mentor.</p></div> : ""}
                    {
                        mentorsSearchResponse.length > 0 ?
                            <div className="export-excel">
                                <ExcelFile element={<button className="btn btn-info">Export to Excel</button>} filename={tabDisplayName + "_" + currentDateTime.toUTCString()}>
                                    <ExcelSheet data={mentorsSearchResponse} name={tabDisplayName}>
                                        <ExcelColumn label="Display Name" value="User_DisplayName" />
                                        <ExcelColumn label="Job Title" value="User_JobTitle" />
                                        <ExcelColumn label="Department" value="User_Department" />
                                        <ExcelColumn label="Ask me About" value="User_AboutMe_popup" />
                                        <ExcelColumn label="Skills" value="User_Skills" />
                                        <ExcelColumn label="Interests" value="User_Intersets" />
                                        <ExcelColumn label="Schools" value="User_Schools" />
                                        <ExcelColumn label="Member Of" value="User_Members" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            : ""
                    }

                    <div className="mentors-cards">
                        {
                            mentorsSearchResponse.length > 0
                                ? mentorsToShow.map((item: any, index: any) => {
                                    return (

                                        <div className="mentors-experts" key={index} onClick={() => { setSelectedUser(item); setModalShow(true); }}>
                                            <div>
                                                <ProgressiveImage src={`${helpers.sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${item.User_Email}`} placeholder={`${helpers.sitecollectionurl}/PublishingImages/react_placeholder.jpg`}>
                                                    {src => <img src={`${helpers.sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${item.User_Email}`} alt={item.User_Email} />}
                                                </ProgressiveImage>
                                                <div className="title">{item.User_DisplayName}</div>
                                                <div className="category">{item.User_JobTitle}</div>
                                                <div className="description">{item.User_Department}</div>
                                                {item.User_AboutMe !== "" ? <div className="ask_me_about">{truncateString(item.User_AboutMe, 40)}</div> : null}
                                            </div>
                                        </div>

                                    );
                                }) : <div className="no-mentors">
                                    <div>
                                        There are no mentors available for this community.
                                    </div>
                                </div>
                        }

                    </div>
                    {
                        mentorsToShow.length > 0 ? "" : <div className="no-mentors">
                            <div>
                                No results found for the entered keyword.
                            </div>
                        </div>
                    }
                    <div className="pagination-div">
                        <Pagination>
                            {
                                (!mentorsLoading && mentorsSearchResponse.length) ?
                                    [...Array(pageCount)].map((x, i) => {
                                        return (
                                            <Pagination.Item key={i + 1} active={i + 1 === activePage} onClick={() => onPaginationClick(i)}> {i + 1} </Pagination.Item>
                                        )
                                    })
                                    : ""
                            }
                        </Pagination></div>
                    <Modal id="mentors_click_modal"
                        backdrop="static"
                        size="lg"
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    selectedUser !== null ?
                                        <div className="mentors_usr_info">
                                            <span className="mentors_usr_img"> <img src={`${helpers.sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${selectedUser.User_Email}`} alt={selectedUser.User_Email} /></span>
                                            <div className="mentors_usr_title"><a href={selectedUser.User_ProfileLink} target="_blank"> {selectedUser.User_DisplayName} </a></div>
                                            <div className="mentors_usr_category">{selectedUser.User_JobTitle}</div>
                                            <div className="mentors_usr_description">{selectedUser.User_Department}</div>
                                        </div>
                                        : ""}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                selectedUser !== null ?
                                    <div className="mentors_usr_details">
                                        <div className="mentors_left_div">
                                            {selectedUser.User_AboutMe_popup ?
                                                <div className="Ask_me_about"> <h3>Ask me about:</h3>
                                                    <p>{selectedUser.User_AboutMe_popup}</p>
                                                </div> : null}
                                            {selectedUser.User_Intersets ?
                                                <div className="intersets"> <h3>Interests:</h3>
                                                    <p>{selectedUser.User_Intersets}</p>
                                                </div> : null}
                                            {selectedUser.User_Members ?
                                                <div className="member_of">
                                                    <h3>Member of:</h3>
                                                    <p>{truncateString(selectedUser.User_Members, 140)}</p>
                                                </div> : null}
                                        </div>
                                        <div className="mentors_right_div">
                                            {selectedUser.User_Skills ?
                                                <div className="skills"> <h3>Skills:</h3>
                                                    <p>{truncateString(selectedUser.User_Skills, 140)}</p>
                                                </div> : null}
                                            {selectedUser.User_Schools ?
                                                <div className="schools"> <h3>Schools:</h3>
                                                    <p>{selectedUser.User_Schools}</p>
                                                </div>
                                                : null}
                                        </div></div>
                                    : ""}
                        </Modal.Body>
                        <Modal.Footer>
                            {selectedUser !== null ? <a href={selectedUser.User_ProfileLink} target="_blank">Explore more <i className="fa fa-external-link"></i> </a> : null}
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    );

    function filtersearchdata(e) {
        let temp = [];
        let filter2 = [...mentorsSearchResponse];
        for (var i = 0; i < filter2.length; i++) {
            if (filter2[i].User_JobTitle != "" && filter2[i].User_JobTitle != null) {
                if ((filter2[i].User_DisplayName.toUpperCase().indexOf(e.toUpperCase()) > -1) || (filter2[i].User_JobTitle.toUpperCase().indexOf(e.toUpperCase()) > -1)) {
                    temp.push(filter2[i]);
                } else {
                }
            }
            else {
                if (filter2[i].User_DisplayName.toUpperCase().indexOf(e.toUpperCase()) > -1) {
                    temp.push(filter2[i]);
                } else {
                }
            }
        }
        if (temp.length > 0) {
            console.log(temp);
            setPageCount(Math.ceil(temp.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
            setMentorsToShow(temp.slice(0, recordsPerPage));
            setaActivePage(1);
        }
        else {
            setMentorsToShow([]);
            setPageCount(Math.ceil(temp.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
            setMentorsToShow(temp.slice(0, recordsPerPage));
            setaActivePage(1);
        }
    }
    function filterData(page: number) {
        setMentorsToShow([]);
        setMentorsToShow(mentorsSearchResponse.slice(recordsPerPage * page - recordsPerPage, recordsPerPage * page));
    }

    function setMentorsval() {
        if (mentorsSearchResponse.length > 0) {
            setPageCount(Math.ceil(mentorsSearchResponse.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
            setMentorsToShow(mentorsSearchResponse.slice(0, recordsPerPage));
            setaActivePage(1);
        }
    }
    function onPaginationClick(i: any) {
        setaActivePage(i + 1);
        filterData(i + 1);
        window.scrollTo(0, 0)
    }


    function truncateString(str: string, num: number) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }

};

export default Mentors;