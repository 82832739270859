import React, { useState, useEffect } from 'react';
import helpers from '../helpers';
import { Modal, Form } from 'react-bootstrap';
import Button from "react-bootstrap/Button";

const YammerNative = (props: any) => {

    const { show, onHide } = props;
    const [showProcessing, setShowProcessing] = useState(<span>Save Webpart</span>);
    return (
        <div id="yammer-native">
            <Modal id="jodit-webpart-id" className="jodit-webpart-class" onHide={onHide}
                backdrop="static"
                show={show}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add Custom Webpart using JODIT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe width="100%" height="100%" src="https://web.yammer.com/embed/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3OTg0MDk0In0" />
                </Modal.Body>
                <Modal.Footer >
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => {
                        onHide();
                    }}>Cancel</Button>
                    <Button className="proceed-button" variant="primary">
                        {showProcessing}
                    </Button>
                </Modal.Footer>
            </Modal >
        </div>
    )
};

export default YammerNative;