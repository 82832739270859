import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { GiTrashCan } from "react-icons/gi";
import { FaArrowLeft } from "react-icons/fa";
import "./deleteLeader.scss";
import helpers from '../helpers';

const DeleteLeader = (props: any) => {
    const { show, onHide, allLeaders, getItems, currentSection, sectionLeaders, addTo } = props;
    const [deleteSection, setDeleteSection] = useState<boolean>(false);
    const [deleteLeader, setDeleteLeader] = useState<boolean>(false);    
    const [edited, setEdited] = useState(false);
    const [leaderToDelete, setLeaderToDelete] = useState({ Id: '', Name: '', Title: '', Cat: '' });
    const [sectionToDelete, setSectionToDelete] = useState({ SectionName: '', Leaders: [] });
    const [showProcessing, setShowProcessing] = useState(<span>Delete Leader</span>);
    const [currentSectionLeaders, setCurrentSectionLeaders] = useState(sectionLeaders);
    const DELETE_LEADER_CONF_MESSAGE = <div>Are you sure you want to delete the below leader <br />which will permanently remove the profile from this SPoA ?</div>
    const DELETE_SECTION_CONF_MESSAGE = <div>Are you sure you want to delete the below leadership section <br />which will permanently remove the below profiles from this SPoA ?</div>

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="delete-leaders-section"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <div id="delete-leader-wrapper" className={deleteLeader ? 'active' : 'not-active'} >
                <Modal.Header closeButton>
                    <FaArrowLeft className="arrow-back-category" onClick={() => { setDeleteLeader(false); }} />
                    <Modal.Title>Delete Leaders / Sections</Modal.Title></Modal.Header>
                <Modal.Body id="delete-leader-pop-up">
                    
                        {DELETE_LEADER_CONF_MESSAGE}
                        <br />
                        <div>
                            <div><b>{leaderToDelete.Name}</b></div>
                            <div>{leaderToDelete.Title}</div>
                        </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => { setDeleteLeader(false); }}> Cancel </Button>
                    <Button variant="primary" className="btn-danger" onClick={() => { updateLeader(); }}>{showProcessing}</Button>
                </Modal.Footer>
            </div>

            <div id="show-leaders-wrapper" className={deleteLeader || deleteSection ? 'not-active' : ''} >
                <Modal.Header closeButton><Modal.Title>Delete Leaders / Sections</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className='section-header'>{currentSection}
                        <span><GiTrashCan className='delete-icon' onClick={() => { setDeleteSection(true); setSectionToDelete({ SectionName: currentSection, Leaders: currentSectionLeaders }); setShowProcessing(<span>Delete Section</span>); }} /> </span>
                    </div>
                    <div className="delete-section-leaders">
                        {
                            currentSectionLeaders.map((leader: any, index: number) => {
                                return (
                                    <div key={index} className={`leader-title-` + index}>{leader.SpoAUser.Title}
                                        <span><GiTrashCan className='delete-icon' onClick={() => { setDeleteLeader(true); setLeaderToDelete({ Id: leader.Id, Name: leader.SpoAUser.Title, Title: leader.SpoALeaderType, Cat: currentSection }); setShowProcessing(<span>Delete Leader</span>); }} /> </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => { handleClose(); }} > Close </Button>
                </Modal.Footer>
            </div>
            <div id="delete-section-wrapper" className={deleteSection ? "active" : "not-active"}>
                <Modal.Header closeButton>
                    <FaArrowLeft className="arrow-back-category" onClick={() => { setDeleteSection(false); }} />
                    <Modal.Title>Delete Leaders / Sections</Modal.Title></Modal.Header>
                <Modal.Body>
                    {DELETE_SECTION_CONF_MESSAGE}
                    <br />
                    <b>{sectionToDelete.SectionName}</b>

                    <div className="delete-section-leaders">
                        {
                            sectionToDelete.Leaders.map((leader: any, index: number) => {
                                return (
                                    <div key={index} className={`leader-title-` + index}>{leader.SpoAUser.Title}</div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => { setDeleteSection(false); }}> Cancel </Button>
                    <Button variant="primary" className="btn-danger" onClick={() => { removeSection(); }}>{showProcessing}</Button>
                </Modal.Footer>
            </div>
        </Modal >
    )


    function handleClose() {
        onHide();        
        edited && getItems();
    }

    function updateLeader() {
        let sections;
        if (addTo === "Leader")
            sections = allLeaders.filter(leader => leader.Id == leaderToDelete.Id)[0].SpoALeaderGroup;
        else if (addTo === "Other Role")
            sections = allLeaders.filter(leader => leader.Id == leaderToDelete.Id)[0].SpoAOtherDiscplineGroup;
        var removeIndex = currentSectionLeaders.findIndex(leader => leader.Id == leaderToDelete.Id);
        currentSectionLeaders.splice(removeIndex, 1);
        if (sections.length === 1) {
            setShowProcessing(<span>Deleting... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
            helpers.deleteItemByID('Experts', leaderToDelete.Id, function () {
                setShowProcessing(<span>Delete Leader</span>);
                let isCurrentSectionLeaders = currentSectionLeaders;
                setCurrentSectionLeaders(isCurrentSectionLeaders);
                setDeleteLeader(false);
                setEdited(true);
            });
        }

        else {
            var index = sections.indexOf(leaderToDelete.Cat);
            if (index !== -1)
                sections.splice(index, 1);
            let toUpdate;
            if (addTo === "Leader") {
                toUpdate = {
                    SpoALeaderGroup: {
                        results: sections
                    },
                };
            }
            else if (addTo === "Other Role") {
                toUpdate = {
                    SpoAOtherDiscplineGroup: {
                        results: sections
                    },
                };
            }

            setShowProcessing(<span>Deleting... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
            helpers.updateData('Experts', toUpdate, leaderToDelete.Id, function () {
                setShowProcessing(<span>Delete Leader</span>);
                setDeleteLeader(false);
                setEdited(true);
            });

        }
    }

    function removeSection() {
        setShowProcessing(<span>Deleting... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
        // Update all leaders
        if (sectionToDelete.Leaders.length > 0) {
            sectionToDelete.Leaders.map((leader: any) => {
                let sections;
                if (addTo === "Leader")
                    sections = allLeaders.filter(item => item.Id == leader.Id)[0].SpoALeaderGroup;
                else if (addTo === "Other Role")
                    sections = allLeaders.filter(item => item.Id == leader.Id)[0].SpoAOtherDiscplineGroup;

                if (sections.length === 1) {
                    helpers.deleteItemByID('Experts', leader.Id, function () { });
                }
                else {
                    var exists = sections.indexOf(sectionToDelete.SectionName);
                    if (exists !== -1)
                        sections.splice(exists, 1);
                    let toUpdate;
                    if (addTo === "Leader") {
                        toUpdate = {
                            SpoALeaderGroup: {
                                results: sections
                            },
                        };
                    }
                    else if (addTo === "Other Role") {
                        toUpdate = {
                            SpoAOtherDiscplineGroup: {
                                results: sections
                            },
                        };
                    }
                    helpers.updateData('Experts', toUpdate, leader.Id, function () {
                        setEdited(true);
                    });
                }
            })

        }

        //Update choice column
        let field;
        if (addTo === "Leader")
            field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoALeaderGroup");
        else if (addTo === "Other Role")
            field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoAOtherDiscplineGroup");
            field.select('Choices')
            .get().then((fieldData) => {
                let choices = fieldData.Choices.results || fieldData.Choices;
                var index = choices.indexOf(sectionToDelete.SectionName);
                if (index !== -1)
                    choices.splice(index, 1);
                field.update({
                    Choices: {
                        results: choices
                    }
                }, 'SP.FieldChoice').then( () => {                    
                    getItems();
                    onHide();
                    setShowProcessing(<span>Delete Section</span>);
                })
                
            })
    }
}

export default DeleteLeader;