import React, { useState, useEffect } from 'react';
import helpers from '../helpers';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const SPoAWidget = ({ o }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    return (
        <div className="result type-b">
            <header>
                {o.oPath.toLowerCase().indexOf('spoaexperts') !== -1 ? <img className="expertsimg" src={`${helpers.sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${o.oEmail}`} alt={o.oEmail} /> : ""}
                {
                    o.oPath.toLowerCase().indexOf('spoaexperts') !== -1 ?
                        <div className="title" title={o.oUser} onClick={() => {
                            window.open(o.oUserProfileLink)
                        }}>
                            {(o.oUser && o.oUser.length > 170) ?
                                o.oUser.substr(0, 170) + "..." :
                                o.oUser
                            }
                            <span className="forum-name">(Leaders)</span>
                        </div> : ""
                }
                {o.oPath.toLowerCase().indexOf('spoalinks') !== -1 ? <div className="title" title={o.oTitle} onClick={() => {
                    window.open(o.oURL)
                }}>
                    {(o.oTitle && o.oTitle.length > 70) ?
                        o.oTitle.substr(0, 70) + "..." :
                        o.oTitle
                    }
                    <span className="forum-name">(Links)</span>
                </div> : ""
                }
                {o.oPath.toLowerCase().indexOf('spoaannouncements') !== -1 ? <div className="title" title={o.oTitle} onClick={() => {
                    window.open(o.oPath)
                }}>
                    {(o.oTitle && o.oTitle.length > 70) ?
                        o.oTitle.substr(0, 70) + "..." :
                        o.oTitle
                    }
                    <span className="forum-name">(Announcements)</span>
                </div> : ""
                }
                {o.oPath.toLowerCase().indexOf('spoacalendar') !== -1 ? <div className="title" title={o.oTitle} onClick={() => {
                    setSelectedEvent(o);
                    setShow(true);
                }}>
                    {(o.oTitle && o.oTitle.length > 70) ?
                        o.oTitle.substr(0, 70) + "..." :
                        o.oTitle + " | " + o.oEventUTCDate + o.oEventMonth + " - " + o.oEventUTCTime + " (GMT +05:30)"
                    }
                    <span className="forum-name">(Events)</span>
                </div> : ""
                }
                {o.oPath.toLowerCase().indexOf('manual%20experts') !== -1 ? <img className="expertsimg" src={`${helpers.sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${o.oEmail}`} alt={o.oEmail} /> : ""}
                {o.oPath.toLowerCase().indexOf('manual%20experts') !== -1 ? <div className="user" title={o.oExpertName} onClick={() => {
                    window.open(o.oUserProfileLink)
                }}>
                    {(o.oExpertName && o.oExpertName.length > 170) ?
                        o.oExpertName.substr(0, 170) + "..." :
                        o.oExpertName
                    }
                    <span className="forum-name">(Leaders)</span>
                </div> : ""
                }


            </header>

            {
                o.oPath.toLowerCase().indexOf('spoaexperts') !== -1 ?
                    <div className="result-experts">
                        <p>{o.oLeaderTitle}</p>
                        <p>{o.oDescription}</p>
                    </div> : ""
            }
            {o.oPath.toLowerCase().indexOf('spoalinks') !== -1 ?
                <div className="result-description" title={o.oDescription}
                    dangerouslySetInnerHTML={o.oDescription && o.oDescription.length > 350 ? helpers.createMarkup(o.oDescription.substr(0, 350) + "...") : helpers.createMarkup(o.oDescription)}>
                </div> : ""}
            {o.oPath.toLowerCase().indexOf('spoaannouncements') !== -1 ?
                <div className="result-description" title={o.oSummary}
                    dangerouslySetInnerHTML={o.oSummary && o.oSummary.length > 350 ? helpers.createMarkup(o.oSummary.substr(0, 350) + "...") : helpers.createMarkup(o.oSummary)}>
                </div> : ""}
            {o.oPath.toLowerCase().indexOf('manual%20experts') !== -1 ?
                <div className="result-experts">
                    <p>{o.oColumn1}</p>
                </div> : ""

            }
            <footer>
                {o.oPath.toLowerCase().indexOf('spoacalendar') !== -1 ?
                    <div className="statistics">
                        <span>{o.oEventLocation !== null ? o.oEventLocation + " | " : ""} </span>
                        <span>{o.oEventUTCDate !== null ? o.oEventUTCDate + " " + o.oEventMonth + " | " : ""} </span>
                        <span>{o.oEventUTCTime !== null ? o.oEventUTCTime + " | " : ""} </span>
                        <span>{o.oEventLocalTime}</span>
                    </div> : ""}
            </footer>
            <Modal id="events-dialog" show={show} onHide={handleClose} dialogClassName="modal-90w" backdrop='static' centered size='lg'>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body >
                    {
                        selectedEvent !== null ?
                            <div className="events dialog-event">
                                <div className="cal-box">
                                    <div className="month"> {o.oEventMonth} </div>
                                    <div className="date"> {o.oEventUTCDate} </div>
                                    <div className="time"> {o.oEventUTCTime} </div>
                                    <div className="local-time">{o.oEventLocalTime} Local Time</div>
                                </div>
                                <div className="details">
                                    <div className="event-title"> {o.oTitle} </div>
                                    <div className="event-location"> {o.oEventLocation ? o.oEventLocation : ""}  </div>
                                    <div className="event-description" dangerouslySetInnerHTML={o.oEventDesc ? helpers.createMarkup(o.oEventDesc) : helpers.createMarkup("")}></div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>


    )
}



export default SPoAWidget;