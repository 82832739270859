import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../../assets/images/logo.png';
import './PageLoader.scss';
import { useLocation, useHistory } from "react-router-dom";
const loadingMessages = ["Loading...","Fetching SPoA...","Authenticating...","Fetching layout...","Almost done..."];

const PageLoader = () => {

    const [message, setMessage] = useState<string>('Fetching SPoA');
    let location = useLocation();
    const history = useHistory();
    const isDashboard = location.pathname.includes("/dashboard");

    useEffect( () => {
        /*if (!location.pathname.includes("/dashboard")) { 
            history.push("dashboard");
        }*/
        if(isDashboard){
            startLoadingMessages();
        }
    }, []);
    

    return(
        <div>
           { isDashboard && 
            <div id="page-loader">
            <div>
                <img src={logo} />
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                <div className="message">
                    { message }
                </div>
            </div>
        </div>}
        </div>
    )

    function startLoadingMessages() {
        loadingMessages.forEach((o, i) => {
            setTimeout( () => {
                setMessage(loadingMessages[i])
            }, i === 0 ? 3000 : i * 3000);
        })
    }
    
}

export default PageLoader;