import React from 'react';
import helpers from '../helpers';

const ArticleWidget = ({ o }) => {
    return (
        <div className="result type-b">
            <header>
                <div className="title" title={o.articleTitle} onClick={() => {
                    window.open(o.articleFilePath)
                }}>
                    {(o.articleTitle && o.articleTitle.length > 70) ?
                        o.articleTitle.substr(0, 70) + "..." :
                        o.articleTitle
                    } <span className="result-source-text"> (Enterprise Encyclopedia)</span>
                </div>
                <div className="help-text">
                    {o.articleApproved}
                </div>
            </header>

            <div className="result-description" title={o.articleBody}
                dangerouslySetInnerHTML={o.articleBody && o.articleBody.length > 350 ? helpers.createMarkup(o.articleBody.substr(0, 350) + "...") : helpers.createMarkup(o.articleBody)}>
            </div>
        </div>
    )
}



export default ArticleWidget;