import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import AddQuickLink from '../quickLinks/AddQuickLink';
import EditMenuItem from './EditMenuItem';
import './EditMainMenu.scss';
import Sortable from 'sortablejs';
import helpers from '../helpers';

const EditMainMenu = (params: any) => {
    const { show, onHide, EditMode } = params;
    const [addQuickLink, setAddQuickLink] = useState<boolean>(false);
    const [quickLinkAction, setQuickLinkAction] = useState("");
    const [selectedQuickLink, setSelectedQuickLink] = useState();
    const [editMenuItem, setEditMenuItem] = useState<boolean>(false);
    const [selectedMenuItem, setselectedMenuItem] = useState();
    const [dragWait, setDragWait] = useState<JSX.Element>(null);
    const [closeButton, setCloseButton] = useState<JSX.Element>(<span>Close</span>);
    const [isSaving, setIsSaving] = useState(false);

    setTimeout(() => {
        let menuElements = document.getElementsByClassName('onhandle') as any;
        menuElements.forEach((z: any) => {
            Sortable.create(z, {
                animation: 500,
                ghostClass: 'blue-background-class',
                onEnd: function (evt: any) {
                    setDragWait(<i className="fa fa-spinner fa-pulse fa-fw"></i>)
                },
                handle: '.edit-menu-wrapper-before',
                dragClass: 'dragging',
                chosenClass: 'dragging',
                store: {
                    get: function (sortable) {
                        return [];
                    },
                    set: function (sortable) {
                        let temp = sortable.toArray();
                        let itemsToUpdate = [];
                        temp.forEach((item: any, linkIndex: number) => {
                            params.defaultMenuItems.map((menuItem: any) => {
                                if (menuItem.ID == item) {
                                    itemsToUpdate.push({
                                        itemID: menuItem.ID,
                                        itemOrder: linkIndex
                                    })
                                }
                            })
                        });
                        temp.forEach((item: any, linkIndex: number) => {
                            params.quickLinks.map((menuItem: any) => {
                                if (menuItem.ID == item) {
                                    itemsToUpdate.push({
                                        itemID: menuItem.ID,
                                        itemOrder: linkIndex
                                    })
                                }
                            })
                        });
                        setIsSaving(true);
                        setCloseButton(<span>Saving...<i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
                        itemsToUpdate.forEach((menuItem: any) => {
                            helpers.updateData('Quick Links', { SpoAOrder: menuItem.itemOrder }, menuItem.itemID, function () {
                                setIsSaving(false);
                                setCloseButton(<span>Close</span>);
                            })
                        })
                    }
                }
            });
        });

    }, 1000);


    return (
        <div className="Top-Links-Menu">
            <Modal aria-labelledby="contained-modal-title-vcenter"
                centered
                id="edit-main-menu"
                show={show}
                onHide={handleClose}
                backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Main Menu
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="topmenu-default-menu">Default Menu</span>
                    <div className='onhandle' >
                        {params.defaultMenuItems.map((item: any, index: number) => {
                            return (
                                <div className='handler-edit' data-id={item.ID} key={index}>
                                    <div className="edit-menu-wrapper-before"></div>
                                    <tr key={index} className="tab-row">
                                        <td className={!item.Visible && 'hidden-menu-item'}>{item.Value}</td>
                                        <td>
                                            <MdCreate
                                                className="edit-link-icon-topmenu"
                                                onClick={() => { setEditMenuItem(true); setselectedMenuItem(item); }}
                                            />
                                        </td>
                                    </tr>
                                </div>
                            );
                        })}
                    </div>

                    <br />
                    <div id="quick-links">
                        <div className="quick-links-header">
                            <span className="topmenu-default-menu">Quick Links</span>
                            <div className={params.quickLinks.length > 7 ? 'add-link-disabled' : 'add-quick-link'} onClick={() => { setAddQuickLink(true); setQuickLinkAction('Add') }} ><MdAddCircleOutline className='add-icon' /> Add Quick Link </div>

                        </div>
                        <div className='onhandle' >
                        {params.quickLinks.map((link: any, index: number) => {
                            return (
                                <div className='handler-edit' data-id={link.ID} key={index}>
                                    <div className="edit-menu-wrapper-before"></div>
                                    <tr key={index} className="tab-row">
                                        <td className={!link.isVisible && 'hidden-menu-item'}>{link.Title}</td>
                                        <td><MdCreate className="edit-link-icon-topmenu" onClick={() => { setAddQuickLink(true); setQuickLinkAction('Edit'); setSelectedQuickLink(link) }} /></td>
                                        <td><GiTrashCan className="delete-link-icon" onClick={() => { setAddQuickLink(true); setQuickLinkAction('Delete'); setSelectedQuickLink(link) }} /></td>
                                    </tr>
                                </div>
                            );
                        })}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" onClick={() => handleClose()} variant="secondary">{closeButton}</Button>
                </Modal.Footer>
            </Modal>
            {addQuickLink && <AddQuickLink show={addQuickLink} onHide={() => setAddQuickLink(false)} getAllQuickLinks={params.getAllQuickLinks} action={quickLinkAction} selectedQuickLink={selectedQuickLink} />}
            {editMenuItem && <EditMenuItem show={editMenuItem} onHide={() => setEditMenuItem(false)} getDefaultMenuItems={params.getDefaultMenuItems} defaultMenuItems={params.defaultMenuItems} selectedMenuItem={selectedMenuItem} />}
        </div>
    )



    function handleClose() {
        params.getAllQuickLinks();
        params.getDefaultMenuItems();
        onHide();
    }

}

export default EditMainMenu;