import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";

const AddSection = (props: any) => {

    const { onHide, leaderSections, leaderGroups, getItems, addTo } = props;
    const [sectionName, setSectionName] = useState('');
    const [noSectionName, setNoSectionName] = useState(false);
    const [duplicateSectionName, setduplicateSectionName] = useState(false);
    const [sectionLength, setSectionLength] = useState(0);
    const [validated, setvalidated] = useState(false);
    const [showProcessing, setShowProcessing] = useState(<span>Save Section</span>);

    return (
        <div>
            <Modal.Header closeButton>
                {leaderSections ? <FaArrowLeft onClick={() => { onHide(); }} className="arrow-back-category" /> : ''}
                <Modal.Title>Add New Section</Modal.Title>
            </Modal.Header>
            <Modal.Body id="add-section-modal">
                <Form noValidate validated={validated}>
                    <Form.Group>
                        <Form.Label>Section Name <span className="star">*</span></Form.Label>
                        <Form.Control
                            required
                            pattern="^[a-zA-Z1-9].*"
                            placeholder="Enter new section name here"
                            value={sectionName}
                            type="input"
                            onChange={(e: any) => {
                                setSectionName(e.currentTarget.value);
                                setSectionLength(e.currentTarget.value.length);
                            }}
                            maxLength={80}
                            autoFocus
                        ></Form.Control>
                        {noSectionName ?
                            <Form.Text className="text-nocontact">
                                Please provide a valid section name</Form.Text> : ''}
                        {duplicateSectionName ?
                            <Form.Text className="text-nocontact">
                                Section already exists. Kindly try with a different section name</Form.Text> : ''}
                        <Form.Text className="text-muted">
                            {sectionLength
                                ? "" + (80 - sectionLength) + " characters remaining"
                                : "80 characters max"}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-cancel-menu" variant="secondary" onClick={() => { onHide(); }}> Cancel </Button>
                <Button variant="primary" className="proceed-button" onClick={() => saveSection(sectionName)}>{showProcessing}</Button>
            </Modal.Footer>
        </div>
    );

    function saveSection(choice: string) {
        setNoSectionName(false);
        setduplicateSectionName(false);
        let exists = false;
        if (leaderSections) {
            leaderSections.map(item => {
                if (item.Title.toLowerCase() === choice.trim().toLowerCase()) {
                    exists = true;
                }
            })
        }

        if (sectionName.trim() === '') {
            setNoSectionName(true);
            setvalidated(true);
        }

        else if (exists) {
            setduplicateSectionName(true);
        }

        else {
            setvalidated(true);
            setShowProcessing(
                <span>
                    Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            );

            let field;
            if (addTo === "Leader") {
                field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoALeaderGroup");
            }
            else if (addTo === "Other Role") {
                field = window['SP_Propertyweb'].lists.getByTitle("Experts").fields.getByInternalNameOrTitle("SpoAOtherDiscplineGroup");
            }

            field.select('Choices')
                .get().then((fieldData) => {
                    let choices = fieldData.Choices.results || fieldData.Choices;
                    field
                        .update({
                            Choices: {
                                results: choices.concat(choice)
                            }
                        }, 'SP.FieldChoice').then( () => {
                            if (leaderSections) {
                                leaderSections.push({
                                    Title: choice,
                                    isChecked: true,
                                });
                            }
                            if (leaderGroups) {
                                leaderGroups.push(choice);
                            }
                            
                            leaderSections ? onHide(true,leaderSections) : getItems()
                            setvalidated(false);
                        })
                })
        }
    }

};

export default AddSection;