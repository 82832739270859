    import React, { useState, useEffect } from 'react';
import './links.scss';
import helpers from '../helpers';
import ReactHtmlParser from 'react-html-parser';
import { FiMoreVertical } from "react-icons/fi";
import { MdAddCircleOutline, MdCreate } from "react-icons/md";
import { GiTrashCan } from "react-icons/gi";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import MenuModal from '../menuModal/MenuModal';
import { sp as pnpsp } from "@pnp/sp";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";
import { animateScroll } from 'react-scroll';
import { createBrowserHistory } from 'history';
import { Link, useLocation } from 'react-router-dom';
import ScrollArrow from '../ScrollArrow/ScrollArrow';
export default function Links(params: any) {

    const myLinksResponse = [
        {
            category: "Leadership & Team",
            id: "l0",
            order: 0,
            links: [
                {
                    title: "Principal Technical Experts",
                    url: "https://www.PrincipalTechnicalExperts.com/",
                    id: "s0",
                    order: 0
                },
                {
                    title: "Experts",
                    url: "https://www.Experts.com/",
                    id: "s1",
                    order: 1
                },
                {
                    title: "Articles Experts",
                    url: "https://www.Articles Experts.com/",
                    id: "s2",
                    order: 2
                },
                {
                    title: "Standard and Guidelines",
                    url: "https://www.Articles Experts.com/",
                    id: "s3",
                    order: 3
                },
                {
                    title: "Tools",
                    url: "https://www.Articles Experts.com/",
                    id: "s4",
                    order: 4
                },
                {
                    title: "Knowledge Repository",
                    url: "https://www.Articles Experts.com/",
                    id: "s5",
                    order: 5
                },

            ]
        },
        {
            category: "Function & skill pool",
            id: "l1",
            order: 1,
            links: [
                {
                    title: "Principal Technical Experts",
                    url: "https://www.PrincipalTechnicalExperts.com/"
                },
                {
                    title: "Experts",
                    url: "https://www.Experts.com/"
                },
                {
                    title: "Articles Experts",
                    url: "https://www.Articles Experts.com/"
                }
            ]
        },
        {
            category: "Tools",
            id: "l2",
            order: 2,
            links: [
                {
                    title: "Principal Technical Experts",
                    url: "https://www.PrincipalTechnicalExperts.com/"
                },
                {
                    title: "Experts",
                    url: "https://www.Experts.com/"
                },
                {
                    title: "Articles Experts",
                    url: "https://www.Articles Experts.com/"
                }
            ]
        }
    ];
    const myLinksResponseDelete = [
        {
            category: "Metocean",
            id: "l0",
            order: 0,
            subcategory: [
                {
                    id: 'sub2',
                    title: 'Learning and Development',
                    links: [
                        {
                            title: "Principal Technical Experts",
                            url: "https://www.PrincipalTechnicalExperts.com/",
                            id: "s0",
                            order: 0
                        },

                    ]
                },
                {
                    id: 'sub1',
                    title: 'Knowledge Networks and Collaboration',
                    links: [
                        {
                            title: "Principal Technical Experts",
                            url: "https://www.PrincipalTechnicalExperts.com/",
                            id: "s0",
                            order: 0
                        },
                        {
                            title: "Experts",
                            url: "https://www.Experts.com/",
                            id: "s1",
                            order: 1
                        },
                        {
                            title: "Articles Experts",
                            url: "https://www.Articles Experts.com/",
                            id: "s2",
                            order: 2
                        },
                        {
                            title: "Standard and Guidelines",
                            url: "https://www.Articles Experts.com/",
                            id: "s3",
                            order: 3
                        },
                        {
                            title: "Tools",
                            url: "https://www.Articles Experts.com/",
                            id: "s4",
                            order: 4
                        },
                        {
                            title: "Knowledge Repository",
                            url: "https://www.Articles Experts.com/",
                            id: "s5",
                            order: 5
                        },

                    ]
                },

            ]
        },
    ];
    let categoriesFromMyLinks: any = [];
    let subCategoriesFromMyLinks: any = [];

    let categoryTemp: any;

    let scrollh: any;

    const [linksResponse, setlinksResponse] = useState([]);
    const [allLinks, setAllLinks] = useState({});
    const [MainLinkCategory, setMainLinkCategory] = useState([]);
    const [subLinkCategory, setsubLinkCategory] = useState([]);
    const [dialogAction, setDialogAction] = useState<string>('edit');
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showEdit, setshowEdit] = useState(false);
    const [myLinks, setMyLinks] = useState(myLinksResponse);
    const [myLinksCategories, setMyLinksCategories] = useState(categoriesFromMyLinks);
    const [categoryList, setCategoryList] = useState([]);
    const [showLoading, setShowLoading] = useState(true);
    const [currMainLink, setCurrMainLink] = useState('');
    const [linksDelete, setlinksDelete] = useState([]);
    const [MainlinkscatDelete, setMainlinkscatDelete] = useState([]);
    const [scrollheight, setscrollheight] = useState(0);
    const browserHistory = createBrowserHistory();
    let lastId: number;
    let SectionID: any;

    let location = useLocation();
    const Url_Paramater = location.pathname.split("/")[1];
    if (location.hash != '') {
        SectionID = location.hash.split("#")[1];
    }


    useEffect(() => {

        //params.AppInsights.trackPageView(
        //    document.title,
        //    window.location.href,
        //    { userEmail: params.userEmail },
        //    null,
        //);
        setTimeout(() => {
            sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoASiteName").items.select("Title,SiteURL").top(4999).get().then((response: any) => {
                response.map(o => {
                    if (o.SiteURL.Url.toLowerCase() === helpers.hostweburl.toLowerCase()) {
                        helpers.getUser((e: any) => {
                            console.log("Entries added in App Insights - Links page");
                            params.AppInsights.trackPageView(
                                o.Title,
                                window.location.href,
                                { userEmail: e.Email },
                                null,
                            );
                        });
                    }
                });
            });
        }, 3000);
        // let batch = sp.createBatch();
        //tems.inBatch(batch).add(updateObj
        getData();
        window.addEventListener('scroll', pagescroll);

        return (() => {
            window.removeEventListener('scroll', pagescroll);
            console.log("scroll event removed")
        });

    }, []);

    useEffect(() => {
        let topMenu = document.getElementById('linkspage-mainlinkssection');
        let topMenuHeight = topMenu.offsetHeight + 0;
        megaMenuScroll(SectionID, topMenu.offsetHeight);

        let elements = document.getElementsByClassName("more-links-mega");
        for (let i = 0; i < elements.length; i++) {
            let ele = elements[i] as HTMLAnchorElement;
            ele.onclick = function () {
                megaMenuScroll(ele.href.split("links#")[1], topMenu.offsetHeight);
            }
        }
    }, [showLoading]);



    /* html for page contnet display */
    return (
        <div id="all-links-page">
            <ScrollArrow />
            <div className="container">
                <div className="row">
                    <div className="col">

                        <div id='linkspage' >

                            <MenuModal currMainLink={currMainLink} showDialog={showDialog} handleClose={handleClose} dialogType={dialogAction} categories={categoryList} links={allLinks} categoriesEdit={MainLinkCategory} linksEdit={linksResponse} getData={getData} setShowLoading={setShowLoading} linksDelete={linksDelete} MainlinkscatDelete={MainlinkscatDelete} getLinksForHeader={params.getLinksForHeader} />
                            <div className='linkspage-mainlinkssection' id='linkspage-mainlinkssection'>
                                <div className="navigation-wrapper">
                                    {/* <div className = "mainCategories"> Main Categories</div> */}
                                    <nav className='mainlinknav'>
                                        <ul id="mainNav">


                                            {!showLoading ?

                                                Object.keys(allLinks).length === 0 ? "No links has been added to this Community." :

                                                    Object.keys(allLinks).map(function (MainLinkCategory: any, index: number) {
                                                        let ID = allLinks[MainLinkCategory].ID;
                                                        if (index === 0) {
                                                            return (
                                                                <li key={index} className="MainLinkCategorytitles active"><a id={"toplink" + ID} onClick={(e: any) => changeCategory(e)} href={"#/" + Url_Paramater + "/links#" + ID}>{MainLinkCategory}</a></li>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <li key={index} className="MainLinkCategorytitles"><a id={"toplink" + ID} onClick={(e: any) => changeCategory(e)} href={"#/" + Url_Paramater + "/links#" + ID}>{MainLinkCategory}</a></li>
                                                            )
                                                        }
                                                    }) : <div className="nav-loader">
                                                    <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                                        <Skeleton height={100}></Skeleton>
                                                    </SkeletonTheme>
                                                </div>
                                            }




                                        </ul>


                                        {
                                            (params.editMode && !showLoading) ?
                                                <a className="add-link-btn" onClick={() => { Customlinkpopupaction('add') }}><MdAddCircleOutline /> Add Link  </a>
                                                :
                                                ""
                                        }

                                    </nav>

                                </div>

                                <div className='grayline-mainlink'></div>
                            </div>
                            <div className='linkspage-linkssection'>




                                {


                                    !showLoading ?
                                        Object.keys(allLinks).map((o: any, index: number) => {
                                            return (
                                                <section key={index} id={allLinks[o].ID} className='Mainlinkcontentgrayline'>
                                                    <div className="mainlinkTitleDesc">
                                                        <h2 className="MainLinkCategory-title"> {o}

                                                            {params.editMode ?

                                                                <DropdownButton
                                                                    alignRight
                                                                    title={<FiMoreVertical />}
                                                                    className={params.editMode ? "edit-dots active" : "edit-dots"}
                                                                    id={`main-link-category-title-` + index}
                                                                >
                                                                    <Dropdown.Item eventKey="1" onClick={() => { Customlinkpopupaction('edit'); setCurrMainLink(o) }} ><MdCreate className='editlinkicon' /> Edit Links / categories</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="1" onClick={() => {
                                                                        //setdelete object
                                                                        let mainobj: any = {};
                                                                        mainobj.Title = o;
                                                                        setMainlinkscatDelete(mainobj);
                                                                        let obj: any = {};
                                                                        obj = allLinks[o];
                                                                        obj.Title = o;
                                                                        setlinksDelete(obj);
                                                                        Customlinkpopupaction('delete');
                                                                        setscrollheight(window.pageYOffset);
                                                                        //setscrollheight(currentscrollheight);
                                                                    }} ><GiTrashCan className='deletelinkicon' /> Delete Links / categories</Dropdown.Item>
                                                                </DropdownButton>

                                                                : ""

                                                            }

                                                        </h2>


                                                        {(allLinks[o].Description && allLinks[o].Description.length) ?
                                                            <div className="MainLinkCategory-dec" dangerouslySetInnerHTML={{ __html: allLinks[o].Description }}></div>
                                                            : ""
                                                        }

                                                    </div>

                                                    {

                                                        Object.keys(allLinks[o]).length != 3 ?
                                                            Object.keys(allLinks[o]).map((oCat: any, indexCat: number) => {

                                                                if (oCat !== "ID" && oCat !== "Description" && oCat !== "Title" && oCat !== "SpoAOrder") {
                                                                    return (
                                                                        <div className="Sublinkcontent" key={indexCat}>
                                                                            <div className="subLinkCategory-title"> {oCat} </div>
                                                                            {
                                                                                Object.keys(allLinks[o][oCat]).length > 1 ?
                                                                                    allLinks[o][oCat].map((oLink: any, indexLink: number) => {
                                                                                        return (
                                                                                            <div className="LinksContent" key={indexLink}>
                                                                                                <div className="Links-title">
                                                                                                    <a target="_blank" href={oLink.URL} onClick={() => sendToAppInsights('Links')}> {oLink.Title} </a>
                                                                                                </div>
                                                                                                {
                                                                                                    oLink.SpoADescription ?
                                                                                                        <div className="Links-desc" dangerouslySetInnerHTML={{ __html: oLink.SpoADescription }} ></div>
                                                                                                        : ""
                                                                                                }

                                                                                            </div>
                                                                                        )
                                                                                    }) :
                                                                                    <div className="no-links"> There are no links for this category. </div>

                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return ('')
                                                                }

                                                            })

                                                            :
                                                            <div className="no-links"> There are no link categories. </div>

                                                    }

                                                </section>
                                            )
                                        })

                                        :

                                        <div>
                                            <div className="main-category-shimmer">
                                                <Skeleton height={40}></Skeleton>
                                            </div>

                                            <div className="main-category-desc-shimmer">
                                                <Skeleton height={60}></Skeleton>
                                            </div>

                                            <div className="sub-category-shimmer">
                                                <Skeleton height={20}></Skeleton>
                                            </div>

                                            <div className="link-title-shimmer">
                                                <Skeleton height={20}></Skeleton>
                                            </div>

                                            <div className="link-desc-shimmer">
                                                <Skeleton height={60}></Skeleton>
                                            </div>

                                        </div>


                                }



                                <div className="clear"></div>
                            </div>
                            <div className='emptydivforspace'></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )


    function changeCategory(e: any) {

        let topMenu = document.getElementById('linkspage-mainlinkssection');
        let topMenu1 = document.getElementById('mainNav');
        let menuItems: any = topMenu1.getElementsByTagName("a");
        let topMenuHeight = topMenu.offsetHeight + 0;
        let href = e.target.href;
        let currURL = window.location.toString();
        let sectionID = href.split("links#")[1];
        currURL = currURL.split("links")[0] + "links#" + sectionID;
        window.history.pushState({}, null, currURL);

        var ele = document.getElementById(href.split("links#")[1])

        let offsetTop = href === "#" ? 0 : ele.offsetTop - topMenuHeight + 110;
        window.scrollTo(0, offsetTop);
        e.preventDefault();

    }

    /* On page scroll the Main Link Categories will be selected automatically */

    function pagescroll() {
        let firstliID = '';
        let topMenu = document.getElementById('linkspage-mainlinkssection');
        let topMenu1 = document.getElementById('mainNav');
        if (topMenu) {


            let menuItems: any = topMenu1.getElementsByTagName("a");
            let topMenuHeight = topMenu.offsetHeight + 60;
            let scrollItems: any = [];
            menuItems.forEach(function (e: any) {
                let item = document.getElementById(e.href.split("links#")[1]);
                if (item != null) {
                    scrollItems.push(item);
                    return item
                }

            });

            // Get container scroll position
            let fromTop = window.scrollY + topMenuHeight;
            // Get id of current scroll item

            if (scrollItems.length) {
                let cur: any = "";


                scrollItems.forEach(function (e: any) {


                    if (e.offsetTop < fromTop) {
                        cur = e
                    }


                });

                //cur = cur[cur.length-1];
                let id = cur.id

                let currURL = window.location.toString();
                currURL = currURL.split("links")[0] + "links#" + id;
                window.history.pushState({}, null, currURL);

                if (lastId !== id) {
                    lastId = id;

                    menuItems.forEach(function (e: any, index: number) {

                        if (e.parentElement !== undefined) {
                            if (index === 0) {
                                firstliID = e.href.split("links#")[1]
                                e.parentElement.classList.remove("active");
                            }
                            else {
                                e.parentElement.classList.remove("active");
                            }

                        }

                    });
                    if (document.querySelectorAll("a[id='toplink" + id + "']")[0] != undefined) {
                        document.querySelectorAll("a[id='toplink" + id + "']")[0].parentElement.classList.add("active");
                    }
                    else {
                        document.querySelectorAll("a[id='toplink" + firstliID + "']")[0].parentElement.classList.add("active");
                    }

                }

            }

        }

    }

    function Customlinkpopupaction(action: string) {
        setDialogAction(action);
        setShowDialog(true);
    }

    function handleClose(item: any) {
        if (item) {
            // let menus = links;
            // menus.splice(item.order - 1, 0, item);
            // setLinks(menus);

            let menus = myLinks;

            menus.map((e: any) => {

                if (e.id === item.category) {
                    e.links.push({ title: item.caption, url: item.menuURL })
                }

            });

            setMyLinks(menus);
        }

        setShowDialog(false);
    }

    function getData() {


        scrollh = scrollheight;
        helpers.getAllData("Main Link Categories", ((mainlinkitems: any[]) => {
            let mainlinkcat: any = [];

            /* Getting the Unique MainLinkCategorys and Description */
            for (let j = 0; j < mainlinkitems.length; j++) {
                mainlinkcat.push({
                    MainLinkCategory: mainlinkitems[j].Title,
                    MainLinkDesc: mainlinkitems[j].SpoADescription,
                    Mainlinkcategoryid: mainlinkitems[j].Id,
                    Mainlinkshowonlanding: mainlinkitems[j].SpoAShowOnLanding,
                })
            }
            setMainLinkCategory(mainlinkcat);
            categoriesFromMyLinks = mainlinkcat;

            helpers.getAllData("Link Categories", ((subItems: any[], mainlinkcat: any) => {
                let val: any = {};

                subCategoriesFromMyLinks = subItems;

                subItems.forEach((o: any) => {
                    if (o.SpoAMainSubject.Title !== null) {
                        if (!val[o.SpoAMainSubject.Title]) {
                            val[o.SpoAMainSubject.Title] = {};
                            val[o.SpoAMainSubject.Title].ID = o.SpoAMainSubject.ID;
                            val[o.SpoAMainSubject.Title].SpoADescription = o.SpoAMainSubject.SpoADescription;
                            val[o.SpoAMainSubject.Title].subCategories = [];
                        }
                        val[o.SpoAMainSubject.Title].subCategories.push(
                            {
                                Title: o.Title,
                                ID: o.ID
                            }
                        );
                    }
                });


                Object.keys(val).forEach((z: any) => {
                    categoriesFromMyLinks.map((o: any) => {
                        if (o.MainLinkCategory === z) {
                            if (o.MainLinkDesc === undefined || o.MainLinkDesc === null) {
                                o.MainLinkDesc = ""
                            }

                            val[z].SpoADescription = o.MainLinkDesc;
                        }
                    });
                });


                //Defect fix - Main cateogry is not showing up in add links if a main category does not have a sub category
                categoriesFromMyLinks.map(o => {
                    if (val[o.MainLinkCategory] === undefined) {
                        val[o.MainLinkCategory] = {};
                        val[o.MainLinkCategory].SpoADescription = o.MainLinkDesc ? o.MainLinkDesc : "";
                        val[o.MainLinkCategory].subCategories = []
                        val[o.MainLinkCategory].ID = o.Mainlinkcategoryid;
                    }
                })

                setCategoryList(val);


                helpers.getAllData("Links", ((linkItems: any[]) => {
                    let setlinks: any = [];

                    /* Getting the Unique SubLinks */
                    let sublink = linkItems.map(item => item.SpoASubject.Title)
                        .filter((value, index, self) => self.indexOf(value) === index)
                    setsubLinkCategory(sublink);

                    for (let i = 0; i < linkItems.length; i++) {
                        let SPoALinkType: any;
                        if (linkItems[i].SpoALinkType !== "Normal" || linkItems[i].ShowOnLanding === true) {
                            SPoALinkType = true;
                        }
                        else {
                            SPoALinkType = false;
                        }
                        var descLinks = '';
                        if (linkItems[i].SpoADescription !== null && linkItems[i].SpoADescription !== "" && linkItems[i].SpoADescription !== undefined) {
                            descLinks = linkItems[i].SpoADescription;
                            descLinks = descLinks.replace(/<img src="[/]*sites/g, '<img src="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;x&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;w&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;p&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;b&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            var desctag = document.createElement("p");
                            desctag.innerHTML = descLinks;
                            let eles = desctag.getElementsByTagName("img");
                            for (let a = 0; a < eles.length; a++) {
                                const newItem = document.createElement('figure');
                                newItem.innerHTML = eles[a].outerHTML
                                eles[a].parentNode.replaceChild(newItem, eles[a])
                            }
                            descLinks = desctag.innerHTML;
                        }
                        setlinks.push({
                            id: linkItems[i].Id,
                            title: linkItems[i].Title,
                            Order: linkItems[i].SpoAOrder == null ? linkItems[i].Id : linkItems[i].SpoAOrder,
                            //desc: linkItems[i].SpoADescription,
                            desc: descLinks,
                            //URL: linkItems[i].URL,
                            URL: linkItems[i].LinkURL,
                            MainLinkCategory: linkItems[i].MainLinkCategory,
                            MainLinkCategoryId: linkItems[i].MainLinkCategory.ID,
                            Subject: linkItems[i].SpoASubject,
                            SubjectId: linkItems[i].SpoASubject.ID,
                            SPoAOrder: linkItems[i].SpoAOrder,
                            ShowOnLanding: SPoALinkType,
                        })
                    }
                    setlinksResponse(setlinks);

                    let temp: any = {};

                    categoriesFromMyLinks.map((z: any) => {
                        let linkItemsbyML = linkItems.filter(m => m.MainLinkCategory.Title == z.MainLinkCategory).sort((a, b) => a.SpoASubject.SpoAOrder > b.SpoASubject.SpoAOrder ? 1 : -1)
                        linkItemsbyML.forEach(o => {
                            if (temp[o.MainLinkCategory.Title] === undefined) {
                                temp[o.MainLinkCategory.Title] = {};
                                temp[o.MainLinkCategory.Title].ID = o.MainLinkCategory.ID;
                                temp[o.MainLinkCategory.Title].SpoAOrder = o.MainLinkCategory.SpoAOrder;
                            }
                            if (temp[o.MainLinkCategory.Title][o.SpoASubject.Title] === undefined) {
                                temp[o.MainLinkCategory.Title][o.SpoASubject.Title] = [];
                                temp[o.MainLinkCategory.Title][o.SpoASubject.Title].ID = o.SpoASubject.ID;
                                temp[o.MainLinkCategory.Title][o.SpoASubject.Title].SpoAOrder = o.SpoASubject.SpoAOrder;

                            }

                        });
                    });

                    linkItems.forEach(o => {
                        //  if(o.URL === undefined || o.URL === null) {
                        //    o.URL = {};
                        //    o.URL.Url = '';
                        //}
                        let descLinks = '';
                        if (o.SpoADescription !== null && o.SpoADescription !== "" && o.SpoADescription !== undefined) {
                            descLinks = o.SpoADescription;
                            descLinks = descLinks.replace(/<img src="[/]*sites/g, '<img src="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;x&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;w&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;p&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            descLinks = descLinks.replace(/<a href="[/&#58;b&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                        }
                        temp[o.MainLinkCategory.Title][o.SpoASubject.Title].push({
                            Title: o.Title,
                            URL: o.LinkURL,
                            //URLDescription: o.URL.Description,
                            SpoADescription: descLinks,
                            ID: o.ID,
                            SPoAOrder: o.SpoAOrder == null ? o.ID : o.SpoAOrder
                        });
                    });


                    for (let o in temp) {
                        if (temp.hasOwnProperty(o)) {
                            categoriesFromMyLinks.map((z: any) => {
                                if (z.MainLinkCategory === o) {
                                    temp[o].Description = z.MainLinkDesc
                                }
                            });
                        }
                    }

                    //Add categories with no sub categories and links

                    categoriesFromMyLinks.map((o: any) => {

                        if (!temp[o.MainLinkCategory]) {
                            temp[o.MainLinkCategory] = {};
                            temp[o.MainLinkCategory].ID = o.Mainlinkcategoryid;
                            if (o.MainLinkDesc === undefined || o.MainLinkDesc === null) {
                                o.MainLinkDesc = "";
                            }
                            temp[o.MainLinkCategory].Description = o.MainLinkDesc;
                        }
                    });


                    Object.keys(val).forEach((o) => {
                        if (val[o].subCategories) {
                            val[o].subCategories.map(z => {
                                if (temp[o][z.Title] === undefined) {
                                    temp[o][z.Title] = [];
                                    temp[o][z.Title]["ID"] = z.ID;
                                }
                            });
                        }
                    });


                    Object.keys(temp).forEach(o => { if (temp[o].SpoAOrder === null || temp[o].SpoAOrder === undefined) { temp[o].SpoAOrder = 999 } })
                    for (let k = 0; k < Object.keys(temp).length; k++) {
                        let z = temp[Object.keys(temp)[k]];

                        for (let y = 0; y < Object.keys(z).length; y++) {
                            if (Array.isArray(z[Object.keys(z)[y]])) {
                                z[Object.keys(z)[y]].sort((a, b) => a.SPoAOrder > b.SPoAOrder ? 1 : -1);
                            }
                        }
                        temp[Object.keys(temp)[k]] = z;
                    }
                    setAllLinks(temp);
                    setShowLoading(false);

                    setShowLoading(false);
                    if (scrollheight) {
                        // window.scrollTo(0, scrollheight);
                        window.scrollTo({
                            top: scrollheight,
                            left: 0,
                            behavior: 'auto'
                        });
                        setscrollheight(0);
                    }
                }

                ), ["ID", "ShowOnLanding", "SpoALinkType", "Title", "SpoAOrder", "LinkURL", "URL", "SpoADescription", "MainLinkCategory/Title", "MainLinkCategory/ID", "MainLinkCategory/SpoAOrder", "SpoASubject/Title", "SpoASubject/ID", "SpoASubject/SpoAOrder"], ["MainLinkCategory", "SpoASubject"], ["MainLinkCategory/SpoAOrder"], ["SpoAHidden ne 1"], 4999);




            }), ["ID", "Title", "SpoAMainSubject/Title", "SpoAMainSubject/ID", "SpoAOrder"], ["SpoAMainSubject"], ["SpoAOrder"], [], "");


        }), [], [], ["SpoAOrder"], "");

    }

    function megaMenuScroll(SectionID, menuoffset) {

        if (SectionID) {
            let offset;
            const ele = document.getElementById(SectionID);
            if (ele) {
                offset = ele.offsetTop - menuoffset + 110;
                animateScroll.scrollMore(offset);
            }
        }
        else {
            animateScroll.scrollToTop();
        }
    }

    function sendToAppInsights(webpartname: string) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Links Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.userEmail
        });
        params.AppInsights.flush();
    }

}
