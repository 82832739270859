import React, { useState } from 'react'
import MenuModalEdit from '../menuModalEdit/menuModalEdit'
import MenuModalAdd from '../menuModalAdd/menuModalAdd'
import MenuModalDelete from '../menuModalDelete/menuModaldelete'

const MenuModal = (props:any) => {

    
        const { currMainLink,  showDialog, handleClose, categories, dialogType, links, linksDelete, getData, setShowLoading, categoriesEdit, linksEdit, MainlinkscatDelete, getLinksForHeader} = props;
        const [selectedCategory, setselectedCategory] = useState("");
        const [menuCaption, setmenuCaption] = useState('');
        const [menuURL, setmenuURL] = useState('');

        
        let actionComponent;

        if(dialogType === "add"){
            actionComponent = <MenuModalAdd showDialog={showDialog} handleClose={handleClose} dialogType={dialogType} categories={categories} links={links} getData={getData} setShowLoading={setShowLoading} getLinksForHeader={getLinksForHeader}/>
        }
        else if (dialogType === "delete") {
            actionComponent = <MenuModalDelete MainlinkscatDelete = {MainlinkscatDelete}  linksDelete={linksDelete} showDialog={showDialog} onDialogClose={onDialogClose} handleClose={handleClose} getData={getData} setShowLoading={setShowLoading} getLinksForHeader={getLinksForHeader}/>
        }
          else if (dialogType === "edit") {
             actionComponent = <MenuModalEdit currMainLink= {currMainLink} links={linksEdit} categories={categoriesEdit} handleClose={handleClose} showDialog={showDialog} onDialogClose={onDialogClose} getData={getData} setShowLoading={setShowLoading} getLinksForHeader={getLinksForHeader}/>
            }

        return (
            <div>
               {actionComponent}
            </div>
        )

  
        function onDialogClose(passData: boolean) {
            passData ? props.handleClose({
                category: selectedCategory,
                caption: menuCaption, 
                menuURL: menuURL
            }) : props.handleClose()
            
        }

}

export default MenuModal
