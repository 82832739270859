import React, { useState, useEffect } from 'react';
import './WorkdayLearning.scss';
import CardWidget from '../cardWidget/CardWidget';
import helpers from '../helpers';

const WorkdayLearning = ({ editMode, isModerator }) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    const [searchLFEURL, setsearchLFEURL] = useState([]);
    const [workdayID, setworkdayID] = useState('');
    let itemWorkDayID = '';
    const config = {
        title: "Workday Learnings",
        type: "Workday",
        modalTitle: "Edit Query",
        workdayURL: "https://wd3.myworkday.com/shell/d/inst/1422$1199/rel-task/3001$14.htmld?metadataEntryPoint=%2fshell%2flearning"
    }

    useEffect(() => {
        getWorkdayLearnings();
    }, []);

    return (
        <div id="workday-widget">
            <>
                <CardWidget config={config} response={response} editMode={editMode} updateConfigurationItems={updateConfigurationItems} loading={loading} searchLFEURL={searchLFEURL} hideWebpart={hideWebpart} isModerator={isModerator} isHidden={isHidden} />
            </>
        </div>
    )

    function updateConfigurationItems(Tfunctions?: any, Tdiscipline?: any, Tpte?: any, Tsme?: any, Tkeyword?: any, TsignForums?: any) {
        setLoading(true)
        let selectedFunctionsLabel = []
        let TfunctionsLabel = '';
        let TDisciplineLabel = '';
        let TPteLabel = '';
        let TSmeLabel = '';
        Object.keys(Tfunctions).forEach((o) => {
            if (Tfunctions[o].checked === true) {
                selectedFunctionsLabel.push(o);
            }
        });
        let selectedDisciplines = Tdiscipline.filter(o => o.checked);
        let selectedPTE = Tpte.filter(o => o.checked);
        let selectedSME = Tsme.filter(o => o.checked);
        for (let j = 0; j < selectedFunctionsLabel.length; j++) {
            TfunctionsLabel = TfunctionsLabel + selectedFunctionsLabel[j];
        }
        for (let j = 0; j < selectedDisciplines.length; j++) {
            TDisciplineLabel = TDisciplineLabel + selectedDisciplines[j].label;
        }
        for (let j = 0; j < selectedPTE.length; j++) {
            TPteLabel = TPteLabel + selectedPTE[j].label;
        }
        for (let j = 0; j < selectedSME.length; j++) {
            TSmeLabel = TSmeLabel + selectedSME[j].label;
        }

        let queryFinalString = '';

        if (TfunctionsLabel !== "" && TDisciplineLabel !== "" && TPteLabel !== "" && TSmeLabel !== "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA6975/Workday%20Learning%20Content%20for%20Enterprise%20Search\"+AND+contentclass:\"STS_ListItem_GenericList\"+AND+(Refinablestring44:\"" + TfunctionsLabel + "\" OR Refinablestring53:\"" + TDisciplineLabel + "\")+AND+(Refinablestring55:\"" + TPteLabel + "\" OR Refinablestring57:\"" + TSmeLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='WorkdayLC,WorkdayLLC,WorkdayDesc,WorkdayEffDate,Refinablestring44,Refinablestring53,Refinablestring55,Refinablestring57'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel !== "" && TPteLabel !== "" && TSmeLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA6975/Workday%20Learning%20Content%20for%20Enterprise%20Search\"+AND+contentclass:\"STS_ListItem_GenericList\"+AND+(Refinablestring44:\"" + TfunctionsLabel + "\" OR Refinablestring53:\"" + TDisciplineLabel + "\")+AND+(Refinablestring55:\"" + TPteLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='WorkdayLC,WorkdayLLC,WorkdayDesc,WorkdayEffDate,Refinablestring44,Refinablestring53,Refinablestring55,Refinablestring57'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel !== "" && TPteLabel === "" && TSmeLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA6975/Workday%20Learning%20Content%20for%20Enterprise%20Search\"+AND+contentclass:\"STS_ListItem_GenericList\"+AND+(Refinablestring44:\"" + TfunctionsLabel + "\" AND Refinablestring53:\"" + TDisciplineLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='WorkdayLC,WorkdayLLC,WorkdayDesc,WorkdayEffDate,Refinablestring44,Refinablestring53,Refinablestring55,Refinablestring57'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel === "" && TPteLabel === "" && TSmeLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA6975/Workday%20Learning%20Content%20for%20Enterprise%20Search\"+AND+contentclass:\"STS_ListItem_GenericList\"+AND+(Refinablestring44:\"" + TfunctionsLabel + "\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='WorkdayLC,WorkdayLLC,WorkdayDesc,WorkdayEffDate,Refinablestring44,Refinablestring53,Refinablestring55,Refinablestring57'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
        }
        else if (TfunctionsLabel === "" && TDisciplineLabel === "" && TPteLabel === "" && TSmeLabel === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA6975/Workday%20Learning%20Content%20for%20Enterprise%20Search\"+AND+contentclass:\"STS_ListItem_GenericList\"+AND+(Refinablestring44:null AND Refinablestring53:null)'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=true&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=true&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='WorkdayLC,WorkdayLLC,WorkdayDesc,WorkdayEffDate,Refinablestring44,Refinablestring53,Refinablestring55,Refinablestring57'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
        }
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(workdayID).update({
            Query: queryFinalString
        }).then((item: any) => {
            console.log('Updated')
            getWorkdayLearnings();
        }).catch(e => {
            console.log('Update failed')
        });

    }

    function getWorkdayLearnings() {
        setResponse([]);
        helpers.getKRListView("SpoAPageData", ((items: Array<{
            Query: string
            ID: string
            IsHidden: boolean
        }>) => {
            setIsHidden(items[0].IsHidden);
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let workDayQuery = items[i].Query;
                    setworkdayID(items[i].ID);
                    itemWorkDayID = items[i].ID;
                    var searchUrl = helpers.appweburl + "/_api/search/query?querytext=" + workDayQuery + "";
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync(
                        {
                            url: searchUrl,
                            method: "GET",
                            headers: { "Accept": "application/json; odata=verbose" },
                            success: onGetSearchResultsSuccess,
                            error: onGetSearchResultsFail
                        }
                    );
                }

            } else {
                //hideWebpart(true)
                setResponse([])
                setLoading(false)
            }
        }), "ID,Title,Query,Link,Section,Application,IsHidden", "Application eq 'WorkDay'");
    }

    function onGetSearchResultsSuccess(data) {
        let items = [];
        let workdayEffectiveDate = '';
        let workdayTitle = '';
        let WorkdayDescription = '';
        let workDayURL = '';
        let jsonObject = JSON.parse(data.body);
        if (jsonObject.d.query.PrimaryQueryResult.RelevantResults !== null) {
            let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
            //console.log(jsonresults);
            if (jsonresults !== null && jsonresults.length > 0) {
                for (let i = 0; i < jsonresults.length; i++) {
                    workdayEffectiveDate = '';
                    workdayTitle = '';
                    WorkdayDescription = '';
                    workDayURL = '';
                    for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                        if (jsonresults[i].Cells.results[j].Key === "WorkdayLC") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                workdayTitle = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "WorkdayLLC") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                workDayURL = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "WorkdayDesc") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                WorkdayDescription = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "WorkdayEffDate") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                workdayEffectiveDate = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                    }
                    items.push({
                        workdayTitle: workdayTitle,
                        workDayURL: workDayURL,
                        WorkdayDescription: WorkdayDescription,
                        workdayEffectiveDate: workdayEffectiveDate,
                    });
                }
                setResponse(items);
                setLoading(false)
            }
            else {
                //hideWebpart(true)
                setResponse([])
                setLoading(false)
            }
        }
        else {
            //hideWebpart(true)
            setResponse([])
            setLoading(false)
        }
    }
    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }
    function hideWebpart(toHide: boolean) {
        setLoading(true);
        if (workdayID === "") {
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(itemWorkDayID, 10)).update({ IsHidden: toHide }).then(i => {
                setIsHidden(toHide);
            }).catch(e => {
                console.log("error updating CustomWebpart!", e)
            });
        } else {
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(workdayID, 10)).update({ IsHidden: toHide }).then(i => {
                setIsHidden(toHide);
                setLoading(false);
            }).catch(e => {
                console.log("error updating CustomWebpart!", e)
            });
        }
    }
};

export default WorkdayLearning;
