import React, { useEffect, useState, useRef } from 'react';
import './announcementWidget.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';
import { sp as pnpsp } from '@pnp/sp';
import helpers from '../helpers';
import { sp, SPRequestExecutorClient, Web } from "sp-pnp-js";
import { SPFetchClient } from "@pnp/nodejs";

const AnnouncementWidget = (props) => {
    const [isHidden, setIsHidden] = useState(props.hidden);
    const [loading, setLoading] = useState(true);
    const [announcementItems, setAnnouncementItems] = useState([]);
    const skeletonHeight = 110;
    let location = useLocation();
    const Url_Paramater = location.pathname.split("/")[1];
    let announcementPath = '/' + Url_Paramater + '/announcement';
    const addNewLink = helpers.sitecollectionurl;
    const SPoAName = window.location.hash.split("/")[1];
    const dateOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
    }

    useEffect(() => {
        getAnnouncementData();
    }, [])

    return (
        <div id="announcement-widget">
            <header className="announcement-header">
                <div className='section-title'>Announcements</div>
                {
                    props.editMode &&
                    (!isHidden ? <div className="hide-unhide"><span className="hide" onClick={() => hideWebpart(true)} >Hide </span></div>
                        :
                        <div className="hide-unhide"><span onClick={() => hideWebpart(false)} >Unhide</span> </div>)

                }
                {!isHidden && <Link to={{
                    pathname: announcementPath
                }}>View All</Link>}
            </header>
            <div className="announcement-maincontainer">
                {loading ?
                    <div style={{ lineHeight: 2 }}>
                        <Skeleton count={3} height={skeletonHeight}></Skeleton>
                    </div> :
                    <>
                        {announcementItems.length ? announcementItems.map((o, i) => {
                            return (
                                <div className="announcement-container">
                                    <h1 onClick={() => { window.open(addNewLink + SPoAName + '/Lists/SpoAAnnouncements/DispForm.aspx?ID=' + o.ID); sendToAppInsights('Announcement'); }}> {o.Title} </h1>
                                    <div className="summary"> {(o.SpoASummary && o.SpoASummary.length > 250) ? o.SpoASummary.substr(0, 250) + "  ..." : o.SpoASummary} </div>
                                    <div className="date"> {o.ArticleStartDate ? new Date(o.ArticleStartDate).toLocaleDateString("en-GB", dateOptions).replace(/ /g, ' ') : new Date(o.ArticleStartDate1).toLocaleDateString("en-GB", dateOptions).replace(/ /g, ' ')} </div>
                                </div>
                            );
                        }) : <div className="no-announcments-message">No announcement available</div>
                        }
                    </>
                }
            </div>
        </div>
    );

    function getAnnouncementData() {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('Announcements').items.filter("isHidden eq 'false'").top(4999).get().then((items) => {
            if (items.length) {
                let sortBy = items[0].ArticleStartDate1 ? "ArticleStartDate1" : "ArticleStartDate";
                items = items.sort((a: any, b: any) => {
                    const aDate: any = new Date(a[sortBy]);
                    const bDate: any = new Date(b[sortBy]);
                    return bDate - aDate;
                });
                items = items.slice(0, 5);
                setAnnouncementItems(items);
            }
            setLoading(false);
        }).catch(e => {
            console.error('Fetching query failed!');
            setLoading(false);
        });
    }

    function hideWebpart(toHide: boolean) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Application eq 'Announcements'").get().then(res => {
            window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(res[0].Id, 10)).update({ IsHidden: toHide }).then(i => {
                setIsHidden(toHide);
                //getExpertsConfigurationItems();
                setLoading(false);
            }).catch(e => {
                console.log("error updating Experts Webpart!", e)
            });
        }).catch(e => {
            console.log("error updating Experts Webpart!", e)
        });
    }

    function sendToAppInsights(webpartname: string) {
        props.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Announcement Web Part',
            WebPartHeader: webpartname,
            UserEmail: props.userEmail
        });
        props.AppInsights.flush();
    }

};

export default AnnouncementWidget;