import React, { useState, useEffect, useRef } from 'react';
import './CustomWebpart.scss';
import { Modal, Form } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import helpers from '../helpers';
import { convertFromHTML, convertToRaw, EditorState, Modifier, ContentState, AtomicBlockUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { sp as pnpsp } from "@pnp/sp";
import { SPFetchClient } from "@pnp/nodejs";
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";
import Alert from 'react-bootstrap/Alert';

const EditCustomWebpart = (props: any) => {
    type TFile = {
        showError?: boolean,
        type?: any,
        message?: string
    }
    const [filecheck, setFileCheck] = useState({} as TFile);
    const [previewSrc, setPreviewSrc] = useState("");
    const { show, onHide, getCustomWebpart, response } = props;

    const [showProcessing, setShowProcessing] = useState(<span>Save Webpart</span>);
    const contentState = stateFromHTML(response.Body);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(contentState)
    );
     const [webpartName, setWebpartName] = useState(response.Title);
    const [webpartLink, setWebpartLink] = useState(response.LinkWebpage);
    const [loading, setLoading] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);

    let desc_options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: data.url,
                        target: '_blank'
                    },
                };
            }
        },
    };


    return (
        <Modal id="custom-webpart" onHide={onHide}
            backdrop="static"
            show={show}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton >
                <Modal.Title>
                    Edit Custom Webpart
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='add-form' noValidate>
                    <Form.Group className='add-field'>
                        <Form.Label>Webpart Name <span className="star">*</span></Form.Label>
                        <Form.Control type='input' pattern="^[a-zA-Z1-9].*" maxLength={500} value={webpartName}
                            onChange={(e: any) => {
                                setWebpartName(e.currentTarget.value);
                            }}
                            required></Form.Control>
                    </Form.Group>
                    {titleError ? <span className='error-new'>
                        Please provide a valid Webpart Name
                         </span> : ''}
                    <Form.Group className='add-field'>
                        <Form.Label>Body <span className="star">*</span></Form.Label>
                        <Editor editorState={editorState}
                            placeholder="Add Text"
                            onEditorStateChange={e => { setEditorState(e) }}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        />
                    </Form.Group>
                    <Form.Group controlId="formImage">
                        <Form.Label>Select image from computer (Supported formats - jpg, jpeg, png, tiff) </Form.Label>
                        {filecheck.showError ? <Alert variant={filecheck.type}> {filecheck.message} </Alert> : null}
                        <Form.Control disabled={loading} type="file" id="imageuploadbanner" onChange={(e: any) => showPreview(e)} />
                        {loading ? <div className="lds-ring"><div></div><div></div><div></div><div></div> <span className='loading-text'>Uploading image, please wait... </span> </div> : ""}

                    </Form.Group>
                    {descriptionError ? <span className='error-new'>
                        Please provide a valid Webpart Description
                         </span> : ''}
                    <Form.Group className='add-field'>
                        <Form.Label>Link to webpage</Form.Label>
                        <Form.Control type='input' pattern="^[a-zA-Z1-9].*" maxLength={1000} placeholder='Enter link to sharepoint page' value={webpartLink}
                            onChange={(e: any) => {
                                setWebpartLink(e.currentTarget.value)
                            }}
                        ></Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button className="button-cancel-menu" variant="secondary" onClick={() => { onHide(); setTitleError(false); setDescriptionError(false); setWebpartLink(response.LinkWebpage); setWebpartName(response.Title); setEditorState(EditorState.createWithContent(contentState)); }}>Cancel</Button>
                <Button className="proceed-button" variant="primary" onClick={() => {
                    createCustomWebpartDetails(webpartName, editorState, webpartLink);
                }}>
                    {showProcessing}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    function onEditorStateChange(editorState) {
        setEditorState(editorState)
    }

    function showPreview(e: any) {
        let filetype;
        if (e.target.files.length > 0) {
            const selectedFile = e;
            if (e.target.files[0] != undefined || e.target.files[0] != null) {
                filetype = e.target.files[0].type;
                if (["image/png", "image/jpeg", "image/jpg", "image/tiff"].includes(filetype) === false) {
                    setFileCheck({ showError: true, type: "warning", message: "Not a valid file type" });
                    const filePicker = document.getElementById('imageuploadbanner') as HTMLInputElement;
                    setPreviewSrc("");
                }
                else {
                    setFileCheck({ showError: false, type: "warning", message: "" });
                    setLoading(true)
                    initUploadFile(e.target.files[0]);
                }
            }
        }
        else {
            setFileCheck({ showError: true, type: "warning", message: "Please select a file to Upload" });
        }
    }


    function initUploadFile(file) {
        let newDigest: any;
        //Image upload
        if (file !== null) {
            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                window['SP_Propertyweb'].getFolderByServerRelativeUrl(helpers.siteSiteAssetsURL).files.addChunked(file.name, file, data => {
                }, true).then(resp => {
                    return resp
                });
            }
            else {
                getFileBuffer(file).then(function (arrayBuffer) {
                    arrayBuffer = arrayBuffer.result;
                    var bytes = new Uint8Array(arrayBuffer);
                    var binary = '';
                    for (var b = 0; b < bytes.length; b++) {
                        binary += String.fromCharCode(bytes[b]);
                    }

                    let listServiceUrl = helpers.appweburl + "/_api/contextinfo";
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync({
                        url: listServiceUrl,
                        method: "POST",
                        headers: {
                            "Accept": "application/json; odata=verbose",
                            "Content-Type": "application/json; odata=verbose"
                        },
                        success: function (data) {
                            var jsonObject = JSON.parse(data.body.toString());
                            newDigest = jsonObject.d.GetContextWebInformation.FormDigestValue;
                            var requestHeaders = {
                                "Accept": "application/json;odata=verbose",
                                "X-RequestDigest": newDigest,
                                "Content-Type": "application/json; odata=verbose"
                            }
                            executor.executeAsync({
                                url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/GetFolderByServerRelativeUrl('" + helpers.siteSiteAssetsURL + "')/Files/Add(url='" + file.name + "',overwrite=true)?@target='" + helpers.hostweburl + "'",
                                method: "POST",
                                headers: requestHeaders,
                                binaryStringRequestBody: true,
                                body: binary,
                                success: function (data) {
                                    var jsonObject = JSON.parse(data.body.toString());
                                    let imgURL = jsonObject.d.ServerRelativeUrl;
                                    if (imgURL.startsWith("/sites")) {
                                        imgURL = helpers.siteOrigin + imgURL;
                                    }
                                    else {
                                        imgURL = jsonObject.d.ServerRelativeUrl;
                                    }
                                    const newEditorState = insertImage(editorState, imgURL);
                                    setEditorState(newEditorState);
                                    setLoading(false);
                                    setFileCheck({ showError: true, type: "success", message: "File uploaded successfully" });

                                    setTimeout(() => {
                                        setFileCheck({ showError: false });
                                    }, 3000)
                                },
                                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                    //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                },
                                state: "Update"
                            });
                        },
                        error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                            //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                        }
                    });
                });
            }
        }
    }


    function insertImage(editorState, base64) {
        const entityData = { src: base64, width: "auto", height: "auto", alignment: "center" };
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            entityData
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity },
        );
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    }

    function getFileBuffer(file) {
        var deferred = $.Deferred();
        var reader = new FileReader();
        reader.onload = function (reader) {
            deferred.resolve(reader.target);
        }
        reader.onerror = function (e) {
            deferred.reject(e);
        }
        reader.readAsArrayBuffer(file);
        return deferred.promise();
    }
    

    function createCustomWebpartDetails(webpartName?: any, editorState?: any, webpartLink?: any) {
        setTitleError(false);
        setDescriptionError(false);
        if (webpartName.trim() === "") {
            setTitleError(true);
        }
        else if (editorState.getCurrentContent().getPlainText() ===" \n" || editorState.getCurrentContent().getPlainText().length === 0) {
            setDescriptionError(true);
        }
        else {
            setShowProcessing(<span>Saving...</span>);
            let desc = stateToHTML(editorState.getCurrentContent(), desc_options);
            let toAdd = {
                Title: webpartName,
                Body: desc,
                LinkWebpage: webpartLink,
            };
            helpers.updateData("CustomWebpart", toAdd, response.ID, function (iar: any) {
                onHide(true);
                getCustomWebpart();
                setShowProcessing(<span>Save Webpart</span>);
            });
        }

    }
};

export default EditCustomWebpart;