import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { Session } from '@pnp/sp-taxonomy';
import helpers from '../helpers';
import { SPFetchClient } from "@pnp/nodejs";
import { useSelector } from 'react-redux'
import {
    taxonomy,
    ITermStore,
    ITerms,
    ILabelMatchInfo,
    ITerm,
    ITermData
} from "@pnp/sp-taxonomy";
import { debug } from 'console';
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import './SearchFilters.scss'
//import UATResponse from './UATResponse.json';

const options = [{
    key: 0,
    value: 'A',
    label: 'A'
},
{
    key: 1,
    value: 'B',
    label: 'B'
}
]

const SearchFilters = ({ selectedMenu, headerLinks, getEnterpriseSignsearch, onAdditionalFilters, forumName, isLoading, showRefiners, defaultFunction, defaultDiscipline, setDefaultFilterState, setLoading }) => {

    const [initalData, setInitialData] = useState<any>({});
    const [taxonomyData, setTaxonomyData] = useState<any>({});
    const [disciplineData, setDisciplineData] = useState([]);
    const [PTEData, setPTEData] = useState([]);
    const [SMEData, setSMEData] = useState([]);
    const [showApply, setShowApply] = useState(false);

    const [functionOptions, setFunctionOptions] = useState([]);
    const [disciplineOptions, setDisciplineOptions] = useState([]);
    const [PTEOptions, setPTEOptions] = useState([]);
    const [SMEOptions, setSMEOptions] = useState([]);

    const [disableFilters, setDisabledFilters] = useState(false);

    const [selectedFunction, setSelectedFunction] = useState<any>([]);
    const [selectedDiscpline, setSelectedDiscpline] = useState<any>([]);
    const [selectedPrincipleArea, setSelectedPrincipleArea] = useState<any>([]);

    const functionRef = useRef<any>([])
    const disciplineRef = useRef<any>([])
    const principleRef = useRef<any>([])
    const subjectAreaRef = useRef<any>([])
    const prevCount: any = usePrevious(selectedMenu)
    const termStore = useSelector(state => state.termStore);

    useEffect(() => {
        if (isLoading) {
            setDisabledFilters(true);
        } else {
            setDisabledFilters(false);
        }
    }, [isLoading])

    useEffect(() => {

        //localhost setup

        //processResponse(UATResponse); //Use this for locahost

        ////Done use below code for localhost testing, the response differ from UAT 
        // taxonomy.setup({
        //  sp: {
        //    fetchClientFactory: () => {
        //      return new SPFetchClient("https://shelldevelopment.sharepoint.com/sites/SPODA0019/CSO", "a48d4dc1-a362-4632-bc11-ceb4be63131e", "D8uRZQgzS6dNkkNsjU2+pCFt3ncb7Y34Ch1L6+zsnUQ=");
        //    }
        //  },
        // });

        // getTermsetWithChildren(
        //  'https://shelldevelopment.sharepoint.com/sites/SPODA0019',
        //  //'Taxonomy_cqkQl+V9pzgHvkcONitnEA==',
        // //  'Taxonomy_EERjDrgg73uLLwmXrhW92w==',
        // 'Taxonomy_gDEyvD+7z7yw1UIiW72syA==',
        //  '6dbfc9a3-9d84-49aa-bef7-53e188059280'
        // ).then(data => {
        //     console.log('done')
        // }).catch(e => {

        //  console.error('Fetching terms failed!')
        //  console.error(e)
        // });





        //PHA Setup
        if (termStore.length !== undefined) {
            processResponse(termStore);
        } else {
            var executor = new SP.RequestExecutor(helpers.appweburl);
            executor.executeAsync({
                url: helpers.taxonomyURLAppWebURL + "/_api/web/getFileByServerRelativeUrl('" + helpers.taxonomyURL + "')/$value?binaryStringResponseBody=true",
                method: "GET",
                headers: {
                    "Accept": "application/json; odata=verbose"
                },
                success: function (data: any) {
                    processResponse(JSON.parse(data.body));
                },
                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                    console.log(thrownError);
                }
            });
        }
        // return () => {
        //     localStorage.clear();
        // }
        // console.log(localStorage);

    }, []);


    useEffect(() => {


        let temp = [];
        setShowApply(false);
        Object.keys(taxonomyData).forEach((o) => {

            if (taxonomyData[o].checked) {
                setShowApply(true);
                taxonomyData[o].items.forEach(obj => {
                    Object.keys(taxonomyData[o]).forEach(key => {
                        if (key !== "checked" && key !== "items" && key !== "ID") {
                            temp.push({
                                key: taxonomyData[o][key].ID,
                                value: key,
                                label: key,
                                items: taxonomyData[o][key],
                                checked: false,
                            })
                        }
                    })
                });

            }
        });
        setDisciplineOptions(temp);
        setDisciplineData(temp);
    }, [taxonomyData])

    useEffect(() => {
        let temp = [];

        Object.keys(disciplineData).forEach((o) => {
            if (disciplineData[o].checked) {


                Object.keys(disciplineData[o].items).forEach(key => {
                    if (key !== "checked" && key !== "items" && key !== "ID") {
                        temp.push({
                            key: disciplineData[o].items[key].ID,
                            value: key,
                            label: key,
                            items: disciplineData[o].items[key],
                            checked: false,
                        })
                    }
                })

            }
        });
        setPTEOptions(temp);
        setPTEData(temp);
    }, [disciplineData]);

    useEffect(() => {

        let temp = [];

        Object.keys(PTEData).forEach((o) => {
            if (PTEData[o].checked) {


                Object.keys(PTEData[o].items).forEach(key => {
                    if (key !== "checked" && key !== "items" && key !== "ID") {
                        temp.push({
                            key: PTEData[o].items[key].ID,
                            value: key,
                            label: key,
                            items: PTEData[o].items[key],
                            checked: false,
                        })
                    }
                })


            }
        });
        setSMEOptions(temp);
        setSMEData(temp);

    }, [PTEData]);

    useEffect(() => {
        if (selectedMenu > 2 && prevCount < 3) {
            [disciplineRef, principleRef, subjectAreaRef].forEach(o => {
                if (o.current && o.current.select) {
                    o.current.select.clearValue()
                }
            })
        }
    }, [selectedMenu]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    return (
        <Col md={showRefiners ? 12 : 2} className="text-left search-filters">

            {
                (showRefiners && selectedMenu === 4) &&
                <div className='custom-filters'>
                    {
                        headerLinks[4].topFilters.map((o, i) => {
                            return (
                                <div className="hr" key={i}>
                                    <label className="top-label"> {o.label} </label>
                                    {
                                        o.options.map((innerO, innerI) => {
                                            return (
                                                <div className='checkbox-filter-wrapper' key={innerI}>
                                                    <Form.Check type="checkbox" id={'chk-' + innerO.label} onChange={(e) => onAdditionalFilters(innerO.callBack, e)} />
                                                    <label htmlFor={'chk-' + innerO.label}> {innerO.label} </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            }

            {
                !showRefiners &&
                <>
                    <label>Function</label>
                    {

                        <Select ref={functionRef} isLoading={isLoading} loadingMessage={() => "Loading..."} isDisabled={disableFilters} options={functionOptions} isMulti={selectedMenu === 3 || selectedMenu === 4 ? false : true} isClearable={false} onChange={(newVal: any) => {


                            // if(newVal === null && localStorage.Functions && JSON.parse(localStorage.Functions).length) {
                            //     [disciplineRef, principleRef, subjectAreaRef].map( o => o.current.select.clearValue())
                            // }
                            if (newVal === null || selectedMenu > 2) {
                                [disciplineRef, principleRef, subjectAreaRef].forEach(o => o.current.select.clearValue())
                            }

                            const temp = { ...taxonomyData };
                            Object.keys(temp).forEach(key => temp[key].checked = false); //Need to optimize
                            if (newVal && newVal.key !== undefined) {
                                newVal && Object.keys(temp).forEach(key => newVal.key === key ? temp[key].checked = true : false);
                                if (localStorage.Functions && localStorage.Functions !== "null" && JSON.parse(localStorage.Functions).length > 1) {
                                    [disciplineRef, principleRef, subjectAreaRef].forEach(o => {
                                        if (o.current) {
                                            o.current.select.clearValue();
                                        }
                                    })
                                }
                            }
                            else {

                                if (newVal && localStorage.Functions && localStorage.Functions !== "null" && JSON.parse(localStorage.Functions).length > newVal.length) {
                                    [disciplineRef, principleRef, subjectAreaRef].forEach(o => {
                                        if (o.current) {
                                            o.current.select.clearValue();
                                        }
                                    })
                                }
                                newVal && newVal.forEach(o => temp[o.key].checked = true);
                            }


                            window.localStorage.setItem("Functions", JSON.stringify(newVal))
                            setTaxonomyData(temp);
                        }}></Select>

                    }

                    <label>Discipline</label>

                    {

                        <Select ref={disciplineRef} isLoading={isLoading} loadingMessage={() => "Loading..."} isDisabled={disableFilters} options={disciplineOptions} isMulti={true} maxMenuHeight={500} isClearable={false} required onChange={(newVal: any) => {
                            const temp = { ...disciplineData };

                            if (newVal === null) {
                                [principleRef, subjectAreaRef].forEach(o => o.current.select.clearValue())
                            } else if (newVal && localStorage.Discpline && localStorage.Discpline !== "null" && JSON.parse(localStorage.Discpline).length > newVal.length) {
                                [principleRef, subjectAreaRef].forEach(o => {
                                    if (o.current) {
                                        o.current.select.clearValue();
                                    }
                                })
                            }

                            Object.keys(temp).forEach(key => {
                                temp[key].checked = false;

                                newVal && newVal.forEach(o => {
                                    if (o.key === temp[key].key) {
                                        temp[key].checked = true;
                                    }
                                });
                            });
                            window.localStorage.setItem("Discpline", JSON.stringify(newVal))
                            setDisciplineData(temp);

                        }}></Select>

                    }


                    <label>Principle Area</label>
                    <Select ref={principleRef} isLoading={isLoading} loadingMessage={() => "Loading..."} isDisabled={disableFilters} options={PTEOptions} isMulti={true} isClearable={false} onChange={(newVal: any) => {
                        const temp = { ...PTEData };

                        if (newVal === null) {
                            [principleRef, subjectAreaRef].forEach(o => o.current.select.clearValue())
                        } else if (newVal && localStorage.Principle && localStorage.Principle !== "null" && JSON.parse(localStorage.Principle).length > newVal.length) {
                            [subjectAreaRef].forEach(o => {
                                if (o.current) {
                                    o.current.select.clearValue();
                                }
                            })
                        }

                        Object.keys(temp).forEach(key => {
                            temp[key].checked = false;

                            newVal && newVal.forEach(o => {
                                if (o.key === temp[key].key) {
                                    temp[key].checked = true;
                                }
                            });
                        });
                        window.localStorage.setItem("Principle", JSON.stringify(newVal))
                        setPTEData(temp);
                    }}></Select>

                    <label>Subject Area</label>
                    <Select ref={subjectAreaRef} isLoading={isLoading} loadingMessage={() => "Loading..."} isDisabled={disableFilters} options={SMEOptions} isMulti={true} isClearable={false} onChange={(newVal: any) => {
                        const temp = { ...SMEData };


                        Object.keys(temp).forEach(key => {
                            temp[key].checked = false;

                            newVal && newVal.forEach(o => {
                                if (o.key === temp[key].key) {
                                    temp[key].checked = true;
                                }
                            });
                        });
                        window.localStorage.setItem("Subject", JSON.stringify(newVal))
                        setSMEData(temp);

                    }}></Select>

                </>
            }


            {
                (showRefiners && selectedMenu !== 4 && headerLinks[selectedMenu].filters.length !== 0) &&
                <div className='custom-filters'>
                    {
                        headerLinks[selectedMenu].filters.forEach((o, i) => {
                            return (
                                o.type === 'react-select' ?
                                    <div key={i} className="forum-top-refiner">
                                        <label> {o.label} </label>
                                        <Select options={forumName} isClearable={false} isMulti={true} onChange={(e) => onAdditionalFilters(o.callBack, e)} maxMenuHeight={500}></Select>
                                    </div>
                                    : o.type === 'checkbox' && selectedMenu === 3 ?
                                        <div className='checkbox-filter-wrapper' key={i}>
                                            <Form.Check alt="" className="chk-fltr-wrper" type="checkbox" id={'chk-' + o.label} onChange={(e) => onAdditionalFilters(o.callBack, e)} />
                                            <label htmlFor={'chk-' + o.label}> {o.label} </label>
                                        </div>
                                        :
                                        <div className='checkbox-filter-wrapper' key={i}>
                                            <Form.Check type="checkbox" id={'chk-' + o.label} onChange={(e) => onAdditionalFilters(o.callBack, e)} />
                                            <label htmlFor={'chk-' + o.label}> {o.label} </label>
                                        </div>
                            )
                        })
                    }
                </div>
            }



            {
                (!showRefiners && showApply && isLoading) ? <div className="apply-filter-wrapper"><i className="fa fa-spinner fa-pulse fa-fw"></i></div> :
                    (!showRefiners && showApply && !isLoading) &&
                    <div className="apply-filter-wrapper" >
                        <div className="apply-filter" onClick={clearFilters}>Clear Filter</div>
                        <div className="apply-filter" onClick={applyFilters}>Apply Filter</div>
                    </div>
            }

        </Col>
    )

    function clearFilters() {

        [functionRef, disciplineRef, principleRef, subjectAreaRef].forEach(o => {
            if (o.current) {
                o.current.select.clearValue()
            }
        })
    }

    function applyFilters() {


        // Keeping commented code for localhost testing
        // setLoading(true);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 500);
        // return;

        let selectedFunctionsId = []
        let selectedFunctionsLabel = []
        let TfunctionsLabel = '';
        let TDisciplineLabel = '';
        let TPTELabel = '';
        let TSMELabel = '';

        let TfunctionsId = '';
        let TDisciplineId = '';
        let TPTEId = '';
        let TSMEId = '';

        Object.values(taxonomyData).forEach((o: any) => o.checked && selectedFunctionsId.push(o.ID))

        Object.keys(taxonomyData).forEach((o) => {
            if (taxonomyData[o].checked === true) {
                selectedFunctionsLabel.push(o);
                console.log(o)
            }
        });

        let selectedDisciplines = disciplineOptions.filter(o => o.checked);
        let selectedPTEValues = Object.values(PTEData).filter(o => o.checked)
        let seletedSMEValue = Object.values(SMEData).filter(o => o.checked)


        for (let j = 0; j < selectedFunctionsId.length; j++) {
            TfunctionsId = TfunctionsId + selectedFunctionsId[j] + ";";
        }

        for (let j = 0; j < selectedFunctionsLabel.length; j++) {
            TfunctionsLabel = TfunctionsLabel + selectedFunctionsLabel[j] + ";";
        }

        for (let j = 0; j < selectedDisciplines.length; j++) {
            TDisciplineLabel = TDisciplineLabel + selectedDisciplines[j].label + ";";
            TDisciplineId = TDisciplineId + selectedDisciplines[j].key + ";";
        }


        for (let j = 0; j < selectedPTEValues.length; j++) {
            TPTELabel = TPTELabel + selectedPTEValues[j].label + ";";
            TPTEId = TPTEId + selectedPTEValues[j].key + ";";
        }


        for (let j = 0; j < seletedSMEValue.length; j++) {
            TSMELabel = TSMELabel + seletedSMEValue[j].label + ";";
            TSMEId = TSMEId + seletedSMEValue[j].key + ";";
        }
        setDefaultFilterState(false);
        getEnterpriseSignsearch(null, TfunctionsLabel, TfunctionsId, TDisciplineLabel, TDisciplineId, TPTELabel, TPTEId, TSMELabel, TSMEId);

    }

    function getTermsetWithChildren(siteCollectionURL: string, termStoreName: string, termsetId: string) {
        return new Promise((resolve, reject) => {
            const taxonomy = new Session(siteCollectionURL);
            const store: any = taxonomy.termStores.getByName(termStoreName);
            store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()
                .then((data: any[]) => {
                    processResponse(data);
                    //resolve();
                }).catch(fail => {
                    console.warn(fail);
                    reject(fail);
                });
        });
    }

    function processResponse(data: any) {

        let results = {};
        data.map((o, i) => {

            if (o.Parent) {
                const categories = o.PathOfTerm ? o.PathOfTerm.split(";") : o.Parent.PathOfTerm.split(';');

                if (categories[0] && results[categories[0]] === undefined) {
                    results[categories[0]] = {}
                    results[categories[0]].checked = false;
                    results[categories[0]].items = []
                }

                if (categories[1] && results[categories[0]][categories[1]] === undefined) {
                    results[categories[0]][categories[1]] = {}
                    results[categories[0]][categories[1]].items = [];
                    results[categories[0]][categories[1]].checked = false;
                }

                if (categories[2] && results[categories[0]][categories[1]][categories[2]] === undefined) {
                    results[categories[0]][categories[1]][categories[2]] = {}
                    results[categories[0]][categories[1]][categories[2]].items = [];
                    results[categories[0]][categories[1]][categories[2]].checked = false;
                }

                if (categories[3] && results[categories[0]][categories[1]][categories[2]][categories[3]] === undefined) {
                    results[categories[0]][categories[1]][categories[2]][categories[3]] = {}
                    results[categories[0]][categories[1]][categories[2]][categories[3]].items = [];
                    results[categories[0]][categories[1]][categories[2]][categories[3]].checked = false;
                }

                if (categories.length && categories.length === 1) {
                    o.checked = false;
                    results[categories[0]].items.push(o);
                    results[categories[0]].ID = o.Id;
                }

                else if (categories.length && categories.length === 2) {
                    o.checked = false;
                    results[categories[0]][categories[1]].items.push(o);
                    results[categories[0]][categories[1]].ID = o.Id;
                }

                else if (categories.length && categories.length === 3) {
                    o.checked = false;
                    results[categories[0]][categories[1]][categories[2]].items.push(o);
                    results[categories[0]][categories[1]][categories[2]].ID = o.Id;
                }

                else if (categories.length && categories.length === 4) {
                    o.checked = false;
                    results[categories[0]][categories[1]][categories[2]][categories[3]].items.push(o);
                    results[categories[0]][categories[1]][categories[2]][categories[3]].ID = o.Id;
                }
            }
            else {
                if (results[o.Name] === undefined) {
                    results[o.Name] = {}
                    results[o.Name].ID = o.Id;
                    results[o.Name].items = [];
                    results[o.Name].checked = false;
                }
                results[o.Name].ID = o.Id;
            }
        })

        setInitialData(JSON.parse(JSON.stringify(results)));

        setTaxonomyData(results);
        let temp = [];
        Object.keys(results).forEach(key => {

            temp.push({
                key: key,
                value: key,
                label: key
            })
        })

        console.log(selectedFunction);
        setFunctionOptions(temp);


        return results;
    }


};

export default SearchFilters;