import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { GiTrashCan } from "react-icons/gi";
import { FaArrowLeft } from "react-icons/fa";
import helpers from '../helpers';

const DeleteCustomWebpart = (props: any) => {
    const { show, onHide, getCustomWebpart, response, setShowLoading } = props;
    const [showProcessing, setShowProcessing] = useState(<span>Delete Webpart</span>);
    return (
        <Modal id="delete-events-dialog" onHide={onHide}
            backdrop="static"
            show={show} dialogClassName="modal-90w" >
            <Modal.Header closeButton>Delete Custom Webpart</Modal.Header>
            <Modal.Body>
                {
                    <div className="warning">Are you sure you want to delete custom webpart "<span className="delete-title">{response.Title}</span>" ?</div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    Deletewebpart(response.ID);
                }}>
                    {showProcessing}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    function Deletewebpart(id) {
        setShowLoading(true);
        setShowProcessing(<span>Deleting...</span>);
        window['SP_Propertyweb'].lists.getByTitle('CustomWebpart').items.getById(parseInt(id, 10)).delete().then(i => {
            onHide(true);
            setShowProcessing(<span>Delete Webpart</span>);
            getCustomWebpart();
        }).catch(e => {
            console.log("error updating CustomWebpart!", e)
        });


    }
};


export default DeleteCustomWebpart;