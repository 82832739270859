import { UPDATE_TERM_STORE } from './actionTypes';

const initialState = {
    termStore: {},
    showGlobalLoader: true
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_TERM_STORE: 
            return {
                ...state,
                termStore: action.payLoad,
                showGlobalLoader: false,
            }
        default:
            return {
                ...state
            }

    }
}

export default reducer;