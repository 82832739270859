import React, { useState, useEffect, useRef, createRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import EditBannerForm from '../editBannerForm/EditBannerForm';
import './EditBanner.scss';
import { sp as pnpsp } from "@pnp/sp";
import { SPFetchClient } from "@pnp/nodejs";
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import * as $ from "jquery";
import helpers from '../helpers';
let formData: any = {};
let first = true;



const EditBanner = (props: any) => {
    // let childRef: any = ""
    // childRef = {
    //     "0": useRef(),
    //     "1": useRef(),
    //     "2": useRef()
    // }

    const { showDialog, onClose, getBanners, banners, archivedBanners } = props;
    const [tabsData, setTabsData] = useState<any>(banners);
    const [disableAdd, setdisableAdd] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [showWarningmsg, setShowWarningmsg] = useState(false);
    const [usedBanners, setUsedBanners] = useState<any>([]);
    const [disableSubmit, setDisablesubmit] = useState(false);
    const [selectedBanner, setselectedBanner] = useState<any>([]);
    const appweburl = helpers.appweburl;
    const hostweburl = helpers.hostweburl;
    //const childRef = tabsData.map( () => createRef())
    /*
        When user clicks on new tab - Newly added tab should be highlighted
        - Executes only when there is any changes in tabsData
    */
    useEffect(() => {

        if (!first) {
            let newTab = document.querySelectorAll("#banner-edit-modal .nav-item.nav-link")[tabsData.length - 1] as any;
            if (newTab) {
                newTab.click();
            }
        }
        else {
            first = false;
        }
    }, [tabsData]);

    return (
        <div id="edit-banner-wrapper">

            <Modal show={showWarning} backdrop="static">

                <Modal.Body>
                    Removed banner will not be visible in home page carousel.
                    But still it will be in previously used section, so that you can reuse in future,
                    if it is required.
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowWarning(false)}>
                        Cancel
                    </Button>

                    <Button variant="primary" onClick={() => removeBanner()}>
                        Proceed to remove
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showWarningmsg} backdrop="static">

                <Modal.Body>
                    You need to retain at-least one banner, Since carousel cannot be empty.
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowWarningmsg(false)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDialog} size="lg" id="banner-edit-modal" onShow={onEditDialogShow} onHide={() => onCancel()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add / Modify / Remove Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Tabs id="controlled-tab" onSelect={(k: string) => onTabChange(k)} >

                        {
                            tabsData ?
                                tabsData.map((o: { Id: number, Title: string }, i: number) => {

                                    return (
                                        <Tab key={i} eventKey={o.Id} title={o.Title} >
                                            <EditBannerForm onUpdate={onUserInput} showWarning={onTabRemove} data={o} tabName={'tab-id-' + o.Id}
                                                setDisablesubmit={setDisablesubmit} uploadFileFromControl={uploadFileFromControl} />
                                        </Tab>
                                    )

                                })
                                : ""

                        }

                        <Tab eventKey="add" title="Add" disabled={disableAdd ? true : false}>
                            <h1> Click on Add tab to create a new banner </h1>
                        </Tab>


                    </Tabs>

                </Modal.Body>
                <Modal.Footer>



                    <Button variant="outline-secondary" onClick={() => onCancel()}>
                        Cancel
                    </Button>

                    <Button variant="primary" disabled={disableSubmit} onClick={() => onSubmit()}>
                        Save Carousel
                    </Button>


                    {
                        archivedBanners.length ? <div className="used-banners-title">Previously used banners</div> : ""
                    }

                    <div className="previously-used-banners">

                        {

                            archivedBanners.length ?
                                archivedBanners.map((o: { Title: string, Description: string, Created: string }, i: number) => {
                                   
                                    return (

                                        <div key={'used-banners-' + i}>

                                            <div className="banner-review">
                                                <div>
                                                    <div className="banner-review-title">
                                                        {o.Title}
                                                    </div>
                                                    <div className="banner-review-description">
                                                        {o.Description}
                                                    </div>
                                                    <span className="author">
                                                        {o.Created ? new Date(o.Created).toLocaleDateString() : ''}
                                                    </span>
                                                </div>
                                            </div>
                                            <Button variant="outline-primary" onClick={() => restoreBanner(o)}>
                                                Reuse Banner
                                        </Button>
                                        </div>
                                    )

                                })

                                : ""

                        }

                    </div>


                </Modal.Footer>
            </Modal>

        </div>
    )

    // function callChild() {

    //     //eslint.disable
    //     childRef.current.getAlert()


    // }

    function onEditDialogShow() {
        setTabsData(banners);
        formData = {};
        banners.length === 6 ? setdisableAdd(true) : setdisableAdd(false);
    }


    function restoreBanner(o: any) {

        removeBanner(true, o);

    }
    /*
     Consolidate all the user inputs in different tabs
     Executed when user updates the edit carousel form
    */
    function onUserInput(data: any) {

        const tab = data.tab;
        const control = data.controlID;
        let value = data.value;

        if (!formData[tab]) {
            formData[tab] = {};
        }

        formData[tab][control] = value;

    }

    /* 
        Save edit banner changes will execute this
    */
    function onSubmit() {

        let validForm = true;
        //Prepare empty/resolved promise to iterate later
        var promise = Promise.resolve(null);
        /* 
            Get all the forms which exists in edit banner dialog and use checkValidity() to verify
        */
        let forms = document.querySelectorAll(".banner-form") as any;
        for (let i = 0; i < forms.length; i++) {

            if (!forms[i].checkValidity()) {
                let tmp = document.querySelectorAll("#banner-edit-modal .nav-item.nav-link")[i] as any;
                tmp.click();
                validForm = false; //If the validation fails, mark the flag as false and break the loop.
                break;
            }

        }


        if (validForm) {

            /* 
                Creating a SP Batch object.
                All the requests will be added to this batch object and will be executed once.
            */
            let batch = sp.createBatch();

            Object.keys(formData).forEach((o: any) => {

                /* 
                    If the user has added a new tab, this block will be executed
                */
                if (o.includes("New")) {

                    const fields: any = {
                        Title: "-formHeading",
                        Description: "-formDesc",
                        Link: "-formLink",
                        Image: "-image",
                        Background: "-formImageBgColor"
                    }

                    let updateObj: any = {};

                    Object.keys(fields).forEach(function (this: any, z: any, i: any) {
                        const id = this;
                        if (formData[id][id + fields[z]] !== undefined) {
                            updateObj[z] = formData[id][id + fields[z]];
                        }
                    }, o);
                    if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                        window['SP_Propertyweb'].lists.getByTitle("SPoABannerCarousel").items.inBatch(batch).add(updateObj).then((resp: any) => {
                            console.log(resp);
                            });
                    }else{
                    let stringProperties = JSON.stringify(updateObj);
                    stringProperties = stringProperties.substr(1, stringProperties.length).substr(0, stringProperties.length - 1);
                    stringProperties = stringProperties.substr(0, stringProperties.length - 1);
                    promise = promise.then(executeRequestInsert(stringProperties));
                    }
                }
                /* 
                    If the user has is updating the existing banner, this block will be executed
                */
                else if (o.includes("tab-id")) {

                    const fields: any = {
                        Title: "-formHeading",
                        Description: "-formDesc",
                        Link: "-formLink",
                        Image: "-image",
                        Background: "-formImageBgColor"
                    }

                    let updateObj: any = {};

                    Object.keys(fields).forEach(function (this: any, z: any, i: any) {
                        const id = this;
                        if (formData[id][id + fields[z]] !== undefined) {
                            updateObj[z] = formData[id][id + fields[z]];
                        }
                    }, o);
                    updateObj.Archived = "false";
                    if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                      window['SP_Propertyweb'].lists.getByTitle("SPoABannerCarousel").items.getById(parseInt(o.split("id-")[1],10)).inBatch(batch).update(updateObj).then(i => {
                console.log(i);
                });
                }else{
                    let update_stringProperties = JSON.stringify(updateObj);
                    update_stringProperties = update_stringProperties.substr(1, update_stringProperties.length).substr(0, update_stringProperties.length - 1);
                    update_stringProperties = update_stringProperties.substr(0, update_stringProperties.length - 1);
                    promise = promise.then(executeRequestUpdate(update_stringProperties, parseInt(o.split("id-")[1],10)));
                }

                }
                /* 
                    If the user has has removed a banner, this block will be executed
                */
                else {
                    Object.keys(formData).forEach(k => {
                        if (formData[k].removed) {
                            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                            window['SP_Propertyweb'].lists.getByTitle("SPoABannerCarousel").items.getById(parseInt(k,10)).inBatch(batch).update({Archived: true}).then( (i:any) => {
                                //console.log(i);
                            });
                        }else{
                            promise = promise.then(function () {
                                return new Promise(function (resolve, reject) {
                                    var requestUrl = appweburl + "/_api/SP.AppContextSite(@target)/web/lists/GetByTitle('SPoABannerCarousel')/items(" + parseInt(k,10) + ")?@target='" + hostweburl + "'";
                                    var spExecutor = new SP.RequestExecutor(appweburl);
                                    spExecutor.executeAsync({
                                        url: requestUrl,
                                        method: "POST",
                                        body: "{ '__metadata': { 'type': 'SP.Data.SPoABannerCarouselItem' }, 'Archived': 'true'}",
                                        headers: { "content-Type": "application/json;odata=verbose", "IF-MATCH": "*", "X-HTTP-Method": "MERGE" },
                                        success: function (data) {
                                            resolve(data);
                                        },
                                        error: function (data) {
                                            //alert(Error);
                                        }
                                    });
                                });
                            })
                        }
                        }
                    });
                }
            });
            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
            ////All set to execute the batch request
            batch.execute().then(() => {
              console.log("All done!")
                getBanners();
            });
        }
            else{
            //execute callback when all fields are created
            promise.then(() => {
                getBanners();
            });
        }

            setTabsData([]);
            onClose(true); //This will close the dialog and activates loading in dashboard banner carousel component.

        }

        //Promise returning request
        function executeRequestInsert(fieldConfig) {
            return function () {
                return new Promise(function (resolve, reject) {
                    var requestUrl = appweburl + "/_api/SP.AppContextSite(@target)/web/lists/GetByTitle('SPoABannerCarousel')/items?@target='" + hostweburl + "'";
                    var spExecutor = new SP.RequestExecutor(appweburl);
                    spExecutor.executeAsync({
                        url: requestUrl,
                        method: "POST",
                        body: "{ '__metadata': { 'type': 'SP.Data.SPoABannerCarouselItem' }, " + fieldConfig + "}",
                        headers: { "content-type": "application/json; odata=verbose" },
                        success: function (data) {
                            resolve(data);
                        },
                        error: function (data) {
                            //alert(Error);
                        }
                    });
                });
            };
        }

        //Update item in sharepoint list
        function executeRequestUpdate(updateconfig, itemid) {
            return function () {
                return new Promise(function (resolve, reject) {
                    var requestUrl = appweburl + "/_api/SP.AppContextSite(@target)/web/lists/GetByTitle('SPoABannerCarousel')/items(" + itemid + ")?@target='" + hostweburl + "'";
                    var spExecutor = new SP.RequestExecutor(appweburl);
                    spExecutor.executeAsync({
                        url: requestUrl,
                        method: "POST",
                        body: "{ '__metadata': { 'type': 'SP.Data.SPoABannerCarouselItem' }, " + updateconfig + "}",
                        //headers: { "content-type": "application/json; odata=verbose" },
                        headers: { "content-Type": "application/json;odata=verbose", "IF-MATCH": "*", "X-HTTP-Method": "MERGE" },
                        success: function (data) {
                            resolve(data);
                        },
                        error: function (data) {
                            //alert(Error);
                        }
                    });
                });
            };
        }

    }

    function onTabRemove(data: any) {
        setShowWarning(true);
    }

    function onCancel() {
        setTabsData(banners);
        onClose();
    }

    function removeBanner(useArchived?: boolean, o?: any) {
        let tmpTabs: any = [];
        let tmpUsedBanner = usedBanners;
        let activeBanner = document.querySelector("#banner-edit-modal .nav-item.nav-link.active") as any;

        if (activeBanner != null) {
            activeBanner = activeBanner.getAttribute("id")!.split("controlled-tab-tab-")[1];
        }
        if (tabsData.length > 1) {
            if (!activeBanner.includes('New')) {
                if (!formData[activeBanner]) {
                    formData[activeBanner] = {};
                }
                formData[activeBanner].removed = true;
                //Adding condition if users clicked or selecetd  multiple reuse banner in same section
                if (formData["tab-id-" + activeBanner]) {
                    delete formData["tab-id-" + activeBanner];
                }
            }
            //Added validation for reuse banner text overriding with user typed text
            else if (activeBanner.includes('New')) {
                if (formData["tab-id-" + activeBanner]) {
                    delete formData["tab-id-" + activeBanner];
                }
            }

            tabsData.map((o: any) => {

                if (activeBanner.includes("New")) {
                    o.Id !== activeBanner ? tmpTabs.push(o) : tmpUsedBanner.push(o);
                }
                else {

                    o.Id !== parseInt(activeBanner,10) ? tmpTabs.push(o) : tmpUsedBanner.push(o);
                }
                return false;

            });
        }
        else {
            tmpTabs = tabsData;
            setShowWarningmsg(true)
        }

        if (useArchived) {
            tmpTabs.push(
                {
                    Title: o.Title ? o.Title : "",
                    Description: o.Description ? o.Description : "",
                    Link: o.Link ? o.Link : "",
                    Id: o.Id ? o.Id : "",
                    Image: o.Image ? o.Image : "",
                    Background: o.Background,
                    Archived: o.Archived,
                    NewTab: true
                }
            )
            formData["tab-id-" + o.ID] = {}
            formData["tab-id-" + o.ID] = {
                ["tab-id-" + o.ID + "-formHeading"]: o.Title,
                ["tab-id-" + o.ID + "-formDesc"]: o.Description,
                ["tab-id-" + o.ID + "-formLink"]: o.Link,
                ["tab-id-" + o.ID + "-image"]: o.Image,
                ["tab-id-" + o.ID + "-formImageBgColor"]: o.Background
            }
        }

        setTabsData([]);

        setTimeout(() => {
            setTabsData(tmpTabs);
        }, 100);

        // setTabsData(tmpTabs);

        tmpUsedBanner.splice(0, tmpUsedBanner.length - 3);
        setUsedBanners(tmpUsedBanner);

        let firstTab = document.querySelector("#banner-edit-modal .nav-item.nav-link") as HTMLElement;
        firstTab.click();
        setShowWarning(false);
        if (tmpTabs.length === 6) {

            setdisableAdd(true);
        } else {

            setdisableAdd(false);
        }

    }

    function onTabChange(k: string) {

        let tempTabsData = [...tabsData];

        if (k === "add" && tempTabsData.length < 6) {

            let titileToAdd = "";

            if (tempTabsData.length === 0) {
                titileToAdd = "New Banner"
                k = "New-" + Date.now()
            }
            else if (tempTabsData.length === 1) {
                titileToAdd = "New Banner"
                k = "New-" + Date.now()
            }
            else if (tempTabsData.length === 2) {
                titileToAdd = "New Banner"
                k = "New-" + Date.now()
            }
            else if (tempTabsData.length === 3) {
                titileToAdd = "New Banner"
                k = "New-" + Date.now()
            }
            else if (tempTabsData.length === 4) {
                titileToAdd = "New Banner"
                k = "New-" + Date.now()
            }
            else if (tempTabsData.length === 5) {
                titileToAdd = "New Banner"
                k = "New-" + Date.now()
                setdisableAdd(true);
            } 
            else {
                alert("Max tab limit reached");
                return;
            }

            tempTabsData.push(
                {
                    Title: titileToAdd,
                    Description: "",
                    Link: "",
                    Id: k,
                    NewTab: true
                }
            )
            setTabsData(tempTabsData);
        }

    }

    function uploadFileFromControl(e: any, tabName: string, _callBack: any) {
        let filesread = document.getElementById(tabName + '-imageuploadbanner') as HTMLInputElement;
        let file: any;
        let newDigest: any;
        //Image upload
        if (filesread.files !== null) {
            file = filesread.files[0];
            if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
                window['SP_Propertyweb'].getFolderByServerRelativeUrl(helpers.siteSiteAssetsURL).files.addChunked(file.name, file, data => {
                }, true).then(resp => {
                    _callBack(resp);
                });
            }
            else {
                getFileBuffer(file).then(function (arrayBuffer) {
                    arrayBuffer = arrayBuffer.result;
                    var bytes = new Uint8Array(arrayBuffer);
                    var binary = '';
                    for (var b = 0; b < bytes.length; b++) {
                        binary += String.fromCharCode(bytes[b]);
                    }

                    let listServiceUrl = helpers.appweburl + "/_api/contextinfo";
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync({
                        url: listServiceUrl,
                        method: "POST",
                        headers: {
                            "Accept": "application/json; odata=verbose",
                            "Content-Type": "application/json; odata=verbose"
                        },
                        success: function (data) {
                            var jsonObject = JSON.parse(data.body.toString());
                            newDigest = jsonObject.d.GetContextWebInformation.FormDigestValue;
                            var requestHeaders = {
                                "Accept": "application/json;odata=verbose",
                                "X-RequestDigest": newDigest,
                                "Content-Type": "application/json; odata=verbose"
                            }
                            executor.executeAsync({
                                url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/GetFolderByServerRelativeUrl('" + helpers.siteSiteAssetsURL + "')/Files/Add(url='" + file.name + "',overwrite=true)?@target='" + helpers.hostweburl + "'",
                                method: "POST",
                                headers: requestHeaders,
                                binaryStringRequestBody: true,
                                body: binary,
                                success: function (data) {
                                    var jsonObject = JSON.parse(data.body.toString());
                                    _callBack(jsonObject);
                                },
                                error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                                    //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                                },
                                state: "Update"
                            });
                        },
                        error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                            //alert('Error:\n' + xhr.statusText + '\n' + thrownError + '\n' + xhr);
                        }
                    });
                });
            }
        }
    }
    function getFileBuffer(file) {
        var deferred = $.Deferred();
        var reader = new FileReader();
        reader.onload = function (reader) {
            deferred.resolve(reader.target);
        }
        reader.onerror = function (e) {
            deferred.reject(e);
        }
        reader.readAsArrayBuffer(file);
        return deferred.promise();
    }


};

export default EditBanner;