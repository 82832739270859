import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { MdAddCircleOutline } from "react-icons/md";
import Select from 'react-select';
import helpers from '../helpers';
import "./addLeader.scss";
import AddSection from "./addSection"

const AddLeader = (props: any) => {
    let timer;
    const { show, onHide, leaderSections, allLeaders, currentSection, getItems, addTo } = props;
    const [addSection, setAddSection] = useState(false);
    const [edited, setEdited] = useState(false);
    const [loadingContact, setLoadingContact] = useState(false);
    const [options, setOptions] = useState([]);
    const [userContactEmail, setUserContactEmail] = useState();
    const [noContactId, setNoContactId] = useState(false);
    const [duplicateLeader, setDuplicateLeader] = useState(false);
    const [noTitle, setNoTitle] = useState(false);
    const [noDesc, setNoDesc] = useState(false);
    const [noSectionSelected, setNoSectionSelected] = useState(false);
    const [contactId, setContactId] = useState();
    const [validated, setvalidated] = useState(false);
    const [leaderTitle, setLeaderTitle] = useState('');
    const [titleLength, setTitleLength] = useState(0);
    const [leaderDesc, setLeaderDesc] = useState('');
    const [descLength, setDescLength] = useState(0);
    const [leaderGroups] = useState([currentSection]);
    const [showProcessing, setShowProcessing] = useState(<span>Save Leader</span>);

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="add-leader-section"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <div id="add-leader-wrapper" className={addSection ? "not-active" : ""} >
                <Modal.Header closeButton><Modal.Title>Add Leaders / Sections</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Form.Group className='add-field'>
                            <Form.Label>Name (LastName, FirstName) <span className="star">*</span></Form.Label>
                            <Select isLoading={loadingContact} options={options} maxMenuHeight={100} onInputChange={(val) => {
                                clearTimeout(timer);
                                timer = setTimeout(() => {
                                    getOptions(val);
                                }, 1000)

                            }} onChange={(newVal) => handleChangeOptions(newVal)} placeholder='Enter LastName, FirstName of the leader here' required></Select>
                            {noContactId ?
                                <Form.Text className="text-nocontact">
                                    Please provide a valid Name/Email id</Form.Text> : ''}
                            {duplicateLeader ?
                                <Form.Text className="text-nocontact">
                                    Leader who you are trying to add already exists.
                                    If you need to add this leader to a different section. Use Edit Leaders/Sections option.</Form.Text> : ''}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Leader Title <span className="star">*</span></Form.Label>
                            <Form.Control
                                required
                                placeholder="Enter title for this leader here"
                                pattern="^[a-zA-Z1-9].*"
                                type="input"
                                maxLength={255}
                                autoFocus
                                onChange={(e) => { setLeaderTitle(e.currentTarget.value); setTitleLength(e.currentTarget.value.length) }}
                            ></Form.Control>
                            {noTitle ?
                                <Form.Text className="text-nocontact">
                                    Please provide a valid leader title</Form.Text> : ''}
                            <Form.Text className="text-muted">
                                {titleLength ? "" + (255 - titleLength) + " characters remaining" : "255 characters max"}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Leader Description <span className="star">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                required
                                placeholder="Describe the leader title here"
                                pattern="^[a-zA-Z1-9].*"
                                type="input"
                                maxLength={350}
                                autoFocus
                                onChange={(e) => { setLeaderDesc(e.currentTarget.value); setDescLength(e.currentTarget.value.length) }}
                            ></Form.Control>
                            {noDesc ?
                                <Form.Text className="text-nocontact">
                                    Please provide a valid leader description</Form.Text> : ''}
                            <Form.Text className="text-muted">
                                {descLength ? "" + (350 - descLength) + " characters remaining" : "350 characters max"}
                            </Form.Text>
                        </Form.Group>
                        <Form.Label>Display in Leadership sections <span className="star">*</span></Form.Label>
                        <Button className="add-link-btn" variant="link" onClick={() => handleAddSection()}>
                            <MdAddCircleOutline /> Add New Section</Button>
                        <Form.Group>
                            {leaderSections.map((item: any, index: any) => {
                                return (
                                    <Form.Check
                                        custom
                                        type='checkbox'
                                        id={`leader-category ${index}-1`}
                                        label={item.Title}
                                        defaultChecked={item.isChecked}
                                        onChange={(e) => { handleCheck(e.target.checked, item.Title); }}
                                    />
                                )
                            })}
                            {noSectionSelected ?
                                <Form.Text className="text-nocontact">
                                    Please select at least one leadership section</Form.Text> : ''}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-cancel-menu" variant="secondary" onClick={() => handleClose()}> Cancel </Button>
                    <Button variant="primary" className="proceed-button" onClick={() => { saveLeader() }}>{showProcessing}</Button>
                </Modal.Footer>
            </div>
            <div id="add-section-wrapper" className={addSection ? "active" : ""} >
                {addSection ? <AddSection onHide={(check?, sections?) => { setAddSection(false); setEdited(check); }} leaderSections={leaderSections} leaderGroups={leaderGroups} addTo={addTo} /> : ''}
            </div>
        </Modal>
    )

    function handleCheck(flag: boolean, group: string) {
        setNoSectionSelected(false);
        if (flag) {
            leaderGroups.push(group);
        }
        else {
            var index = leaderGroups.indexOf(group);
            if (index !== -1)
                leaderGroups.splice(index, 1);
        }
    }

    function handleClose() {
        onHide();
        edited && getItems();
    }

    function handleAddSection() {
        setvalidated(false);
        setAddSection(true);
    }

    function handleChangeOptions(newVal: any) {
        setContactId(newVal.value);
        setUserContactEmail(newVal.userAccname);
        setDuplicateLeader(false);
        setNoContactId(false);
    }

    function getOptions(name: string) {
        if (!name) return;
        setLoadingContact(true);
        const searchName = name.replace(/^.{1}/g, name[0].toUpperCase());
        let allOptions = [];

        var searchUrl = helpers.appweburl + "/_api/search/query?querytext='" + searchName + "'&rowlimit=100&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'";
        var executor = new SP.RequestExecutor(helpers.appweburl);
        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetSearchResultsSuccess,
                error: onGetSearchResultsFail
            }
        );


        //helpers.getUserNames(function (data) {
        //    data.map((item: any) => {
        //        allOptions.push({
        //            key: item.Id,
        //            value: item.Title,
        //            label: <div><img src={item.UserDispImg} className='img-add' />&nbsp;{item.Title}</div>
        //        })
        //    });
        //    //Before setting the options, filtering already added contacts
        //    setOptions(allOptions);
        //    setLoadingContact(false);
        //}, searchName)
    }

    function onGetSearchResultsSuccess(data) {
        let userEmail = '';
        let value = '';
        let userAccname = '';
        let allOptions = [];
        let jsonObject = JSON.parse(data.body);
        let results = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        if (results.length > 0 && results !== null) {
            for (let i = 0; i < results.length; i++) {
                userEmail = '';
                value = '';
                userAccname = '';
                for (var j = 0; j < results[i].Cells.results.length; j++) {
                    if (results[i].Cells.results[j].Key === "PreferredName") {
                        value = results[i].Cells.results[j].Value;
                    }
                    if (results[i].Cells.results[j].Key === "AccountName") {
                        userAccname = results[i].Cells.results[j].Value;
                    }
                    if (results[i].Cells.results[j].Key === "WorkEmail") {
                        userEmail = results[i].Cells.results[j].Value;
                    }
                }

                allOptions.push({
                    key: value,
                    value: value,
                    label: <div><img src={userEmail} className='img-add' />&nbsp;{value}</div>,
                    userAccname: userAccname,
                })
            }
        }
        console.log(allOptions);
        //Before setting the options, filtering already added contacts
        setOptions(allOptions);
        setLoadingContact(false);
    }


    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }

    function saveLeader() {
        setNoContactId(false);
        setNoTitle(false);
        setNoDesc(false);
        setDuplicateLeader(false);
        setNoSectionSelected(false);
        setvalidated(false);

        let exists = false;
        allLeaders.map(item => {
            if (item.SpoAUser.Title === contactId) {
                exists = true;
            }
        })

        if (contactId == null || contactId == undefined) {
            setvalidated(true);
            setNoContactId(true);
        }

        else if (exists) {
            setDuplicateLeader(true);
            setOptions([]);
        }

        else if (leaderTitle.trim() === '') {
            setvalidated(true);
            setNoTitle(true);
        }

        else if (leaderDesc.trim() === '') {
            setvalidated(true);
            setNoDesc(true);
        }
        else if (leaderGroups.length === 0) {
            setNoSectionSelected(true);
        }
        else {
            setNoContactId(false);
            setvalidated(false);
            setShowProcessing(<span>Saving... <i className="fa fa-spinner fa-pulse fa-fw"></i></span>);
            let toAdd;
            helpers.getWebEnsureUser(function (data) {
                if (addTo === "Leader") {
                    toAdd = {
                        SpoALeaderType: leaderTitle,
                        SpoADescription: leaderDesc,
                        SpoAExpertType: {
                            results: [addTo]
                        },
                        SpoALeaderGroup: {
                            results: leaderGroups
                        },
                        SpoAUserId: data.data.Id,
                    };
                }
                else if (addTo === "Other Role") {
                    toAdd = {
                        SpoALeaderType: leaderTitle,
                        SpoADescription: leaderDesc,
                        SpoAExpertType: {
                            results: [addTo]
                        },
                        SpoAOtherDiscplineGroup: {
                            results: leaderGroups
                        },
                        SpoAUserId: data.data.Id,
                    };
                }



                helpers.addData("Experts", toAdd, function (iar: any) {
                    setShowProcessing(<span>Save Leader</span>);
                    getItems();
                    onHide();
                });
            }, userContactEmail);
        }

    }

}

export default AddLeader;
