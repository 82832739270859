import React from 'react';
import { FaPaperclip } from 'react-icons/fa';
import helpers from '../helpers';

const LearningWidget = ({ o }) => {
    const Emphasize_Span = "<span>"
    return (
        <div className="result type-a">
            <header>
                <div className="title" title={o.learnTitle} onClick={() => {
                    window.open(o.learnPath)
                }}>
                    {(o.learnTitle && o.learnTitle.length > 70) ?
                        o.learnTitle.substr(0, 70) + "..." :
                        o.learnTitle
                    } <span className="result-source-text">({o.learnType} - Learning from Experience)</span>
                </div>
                <div className="help-text">{o.LearnStatus}</div>
            </header>

            <div className="result-description" title={o.LearnDesc}
                dangerouslySetInnerHTML={o.LearnDesc && o.LearnDesc.length > 350 ? helpers.createMarkup(o.LearnDesc.substr(0, 350) + "...") : helpers.createMarkup(o.LearnDesc)}>
            </div>

            <footer>
                {
                    o.lfilePath &&
                    <span className="data attachment-link" onClick={() => {
                        window.open(o.lfilePath)
                    }}> <FaPaperclip /> {o.lDocTitle} . {o.lfileType} </span>
                }

                <div className="benefits-div"><span className="benefits"> Benefits </span>
                    {o.learnValue != "0" ? <span className="data">Savings / Value Gained per annum (hrs/$): <span>$</span> <b>{o.learnValue}</b> </span> : ""}
                    {(o.learnBenefitEffort && o.learnBenefitEffort != "0") ? <span className="data"> Effort/Investment(hrs/$): <b>{o.learnBenefitEffort} </b> </span> : ""}
                    <span>Learning Benefit Type(s): {o.learnBenefitType} </span></div>
                <div className="statistics">
                    {(o.learnType == "LL" && o.lEmpCount != "0") && <span>{o.lEmpCount} Emphasize</span>}
                    {(o.learnType == "PWR" && o.lRepCount != "0") && <span>{o.lRepCount} Replications</span>}
                    {o.lRate != "0/0" && <span> {o.lRate}  Rating </span>}
                    {o.lShare != "0" && <span> {o.lShare} Shares </span>}
                    {o.lFollow != "0" && <span> {o.lFollow} Follows </span>}
                </div>
            </footer>

        </div>
    )
}



export default LearningWidget;